import axios from "axios";

const isUserNew = async (user) => {
  try {
    const response = await axios.get(`/browser/contactInformation/${user}`);
    return response.data;
  } catch (err) {
    console.log(err, "Something went wrong.");
    return null;
  }
};

export default isUserNew;
