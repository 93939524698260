import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { ImageComponent } from "./ImageComponent";

function CartTable({ equipment }) {
  const total =
    equipment.length &&
    equipment?.reduce((acc, item) => {
      return acc + (item?.quantity || 0);
    }, 0);

  return (
    <div className="  rounded-lg w-36 sm:w-36 md:w-72  bg-white ">
      {total > 0 ? (
        <div>
          {equipment?.map((item) => (
            <div key={item.providerAssignedId}>
              {item?.quantity > 0 ? (
                <div className=" flex flex-row shadow-sm h-16 items-center gap-3 mt-1 w-full">
                  <div className="flex relative">
                    <div className="p-1 w-10 sm:w-14 md:w-20  h-full ">
                      <ImageComponent item={item} />
                    </div>
                    <div className=" flex md:text-xs text-[8px] font-bold absolute right-1 top-1 bg-green-900 text-white rounded-full border-1 px-1 h-3 md:h-[17px] md:px-[5px] items-center ">
                      {item.quantity}
                    </div>
                  </div>
                  <div className=" scrollbar-hide w-56 break-words text-clip overflow-scroll">
                    {item.name}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="w-12 sm:w-16 shadow-2xl px-5"> No items selected</div>
      )}
    </div>
  );
}
CartTable.propTypes = {
  equipment: PropTypes.array,
};

function CartItemMenu({ equipment, gotoCheckout, fromStore }) {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  let validData = false;
  if (currentUser?.id) {
    const values = JSON.parse(
      sessionStorage.getItem(`values-${currentUser && currentUser?.id}`)
    );
    const allPageVisited = JSON.parse(sessionStorage.getItem("checkout"));
    const openValues = values?.openValues;

    if (openValues && openValues["Kiozzk"] > 0 && allPageVisited) {
      validData = true;
    }
  }

  return (
    <div className="bg-white text-sm h-fit p-2 md:p-4 w-fit rounded-md whitespace-nowrap text-black md:text-lg">
      <CartTable equipment={equipment} />
      {validData && !fromStore && (
        <div className="mt-4 flex w-full justify-center">
          <Button
            style={{
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => {
              if (gotoCheckout) {
                gotoCheckout();
              }
            }}
          >
            Go to checkout
          </Button>
        </div>
      )}
    </div>
  );
}
CartItemMenu.propTypes = {
  equipment: PropTypes.array,
  gotoCheckout: PropTypes.func,
  fromStore: PropTypes.bool,
};
export default CartItemMenu;
