import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
// import styled from "styled-components";

// const Button = styled.button`
//   display: block;
//   width: 20rem;
//   border-width: 1px;
//   border-color: #116FD7;
//   border-radius:4px;
//   background-color: #116FD7;
//   color: #000;
//   padding: 14px 28px;
//   font-size: 18px;
//   font-weight: bold;
//   cursor: pointer;
//   text-align: center;
//   font-weight: 500;
//   }
// `;

const InvalidLead = () => {
  return (
    <div
      className="h-screen flex flex-col justify-center items-center"
      style={{ backgroundColor: "#051527" }}
    >
      <div className="font-extrabold text-8xl text-slate-50 mb-9">404</div>
      <div className="text-slate-400 mb-1 text-2xl">
        Lead Information Not Found.
      </div>
      <div className="text-slate-400  text-lg">Please contact Sales.</div>
      <div className="flex flex-row gap-2">
        <PhoneIcon className="text-white " />
        <div className="text-slate-400 mb-6 text-base">(844) 838-4769</div>{" "}
      </div>

      <div className="mb-5">
        <hr className="w-20 text-slate-400" />
      </div>
    </div>
  );
};

export default InvalidLead;
