import _ from "lodash";
import { useEffect, useState } from "react";
import useItems from "./useItems";
import useLeadInfo from "./useLeadInfo";
import { saveItemsToSession } from "../../shoppingsite/utils";
import { fetchOrderDetailFromLeadId } from "../../../utils/signAgreementDocument";
import { updateLeadActivity } from "../../../hooks/updateLeadActivity";

// import { updateLeadActivity } from "../../../hooks/updateLeadActivity";

const useDeviceCounter = (
  leadId,
  loggedIn,
  proceedToLeadInfo,
  setGettingOrderInfo
) => {
  [
    {
      "Growthzilla Kiozzk Tablet": 2,
      "Growthzilla Cloud Receipt Printer": 3,
      "Large CashDrawer": 3,
      "Small CashDrawer": 3,
      "Aries8/Aries6 Stand": 3,
    },
  ];

  const currentUser = JSON.parse(sessionStorage.getItem("user"));

  const initialCartItemsForAcceptModal = {
    "Growthzilla Kiozzk Tablet": 0,
    "Growthzilla Cloud Receipt Printer": 0,
    "Large CashDrawer": 0,
    "Small CashDrawer": 0,
    "Aries8/Aries6 Stand": 0,
  };

  let initialValues = {
    Kiosk: 0,
    Kiozzk: 0,
    Computer: 0,
    SmartCheckout: 0,
    ReceiptPrinter: 0,
    CashdrawerSmall: 0,
    CashdrawerBig: 0,
    AriesStand: 0,
  };
  let initialAdditionalValues = {
    SmartCheckout: 0,
    Kiozzk: 0,
    Computer: 0,
    ReceiptPrinter: 0,
    CashdrawerSmall: 0,
    CashdrawerBig: 0,
    AriesStand: 0,
  };
  if (currentUser?.id) {
    const values = JSON.parse(
      sessionStorage.getItem(`values-${currentUser && currentUser?.id}`)
    );
    if (values) {
      initialValues = values.openValues;
      initialAdditionalValues = values.openAdditionalValues;
    }
  }
  const [openValues, setValues] = useState(initialValues);
  const [openAdditionalValues, setAdditionalValues] = useState(
    initialAdditionalValues
  );
  const [initialCartItems, setInitialCartItems] = useState(
    initialCartItemsForAcceptModal
  );

  const [equipmentPrice, setEquipmentPrice] = useState({
    SmartCheckout: 449,
    Kiozzk: 249,
    Computer: 0,
    ReceiptPrinter: 249,
    CashdrawerSmall: 99,
    CashdrawerBig: 99,
    AriesStand: 49,
  });

  const [qualifyingPlanPrice, setQualifyingPlanPrice] = useState(0);
  const [qualifyingEquipmentPrice, setQualifyingEquipmentPrice] = useState(0);
  const [equipDiscount, setEquipmentDiscount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [equipmentsFilledFromOrder, setEquipmentsFilledFromOrder] =
    useState(false);

  const [planPermDiscount, setPermanentDiscount] = useState(0);
  const [planTempDiscount, setTemporaryDiscount] = useState(0);
  const [tempDiscountExpiryDate, setTempDiscountExpiryDate] = useState("");
  const [monthlySMSQuota, setMonthlySMSQuota] = useState(0);
  const [installments, setInstallments] = useState(0);
  const [invalidLeadInfo, setInvalidLeadInfo] = useState(false);
  const [businessNameFromLeadInfo, setBusinessNameFromLeadInfo] = useState("");
  const [uzeliOrderFromDb, setUzeliOrderFromDb] = useState(null);
  // const [gettingOrderInfo, setGettingOrderInfo] = useState(false);
  // const [salesAgent, setSalesAgent] = use;
  const [minimumForInstallments, setMinimumForInstallments] = useState(0);

  const [growthzillaSignee, setGrowthzillaSignee] = useState("");
  const [salesAgent, setSalesAgent] = useState("");
  const [leadIdFromInfo, setleadIdFromInfo] = useState("");
  const [oldOrderToState, setOldOrderToState] = useState(false);

  const { getLeadInfo, loading: gettingLeadInfo } = useLeadInfo();

  const updateLeads = async (leadId, businessName) => {
    // console.log({ updatingLeadActivity: leadId });
    // console.log({ isFromProma });
    // const id = isFromProma?getLeadId
    // if
    const leadID = leadId;
    const data = {
      id: leadID,
      leadActivity: "User Logged In",
      businessName: businessName,
    };
    updateLeadActivity(data);
  };

  useEffect(() => {
    if (leadId && loggedIn?.id) {
      // console.log({ formDeviceCounter: leadId });
      // let tempAdditionalValues = {};
      // let tempEquipmentPrice = {};
      // eslint-disable-next-line no-unused-vars
      const updateCount = async () => {
        const leadInfo = await getLeadInfo(leadId);

        if (leadInfo?.error) {
          setInvalidLeadInfo(true);
        } else {
          // console.log({ leadInfo });
          // if (!Object.keys(leadInfo).length) {
          // }
          // console.log({ leadInfo });
          // leadInfo?.equipments?.forEach((item) => {
          //   tempAdditionalValues[item.name] = item.quantity;
          // });

          // leadInfo?.equipments?.forEach((item) => {
          //   tempEquipmentPrice[item.name] = item.price;
          // });
          if (Object.keys(leadInfo?.formInfo)) {
            saveItemsToSession(`formState_${loggedIn?.id}`, leadInfo?.formInfo);
          }
          if (leadInfo?.leadId) {
            setleadIdFromInfo(leadInfo?.leadId);
            saveItemsToSession("leadIdFromInfo", leadInfo?.leadId);
          }

          if (leadInfo?.orderId) {
            // setleadIdFromInfo(leadInfo?.leadId);
            saveItemsToSession("uzeliOrderId", leadInfo?.orderId);
          }

          if (leadInfo?.businessName) {
            setBusinessNameFromLeadInfo(leadInfo?.businessName);
            saveItemsToSession(
              "businessNameFromLeadInfo",
              leadInfo?.businessName
            );
          }
          if (!uzeliOrderFromDb?.orderId) {
            updateLeads(leadInfo?.leadId, leadInfo?.businessName);
          }
          setAdditionalValues({
            ...openAdditionalValues,
            // ...tempAdditionalValues,
          });

          setEquipmentPrice({
            ...equipmentPrice,
            // ...tempEquipmentPrice,
          });

          if (
            leadInfo?.equipments?.length &&
            Object.keys(leadInfo?.equipments[0]).length > 0
          ) {
            // console.log({ receivedEquipments: leadInfo?.equipments[0] });
            setInitialCartItems(leadInfo?.equipments[0]);
          }
          setQualifyingPlanPrice(leadInfo?.qualifyingPlanPrice);
          setQualifyingEquipmentPrice(leadInfo?.qualifyingEquipmentPrice);
          setEquipmentDiscount(leadInfo?.equipDiscount);
          setInstallments(leadInfo?.installments);
          setMinimumForInstallments(leadInfo?.minimumForInstallments);

          setPermanentDiscount(leadInfo?.planPermDiscount);
          setTemporaryDiscount(leadInfo?.planTempDiscount);
          setTempDiscountExpiryDate(leadInfo?.tempDiscountExpiryDate);
          setMonthlySMSQuota(leadInfo?.monthlySMSQuota);

          setGrowthzillaSignee(leadInfo?.growthzillaSignee);
          setSalesAgent(leadInfo?.salesAgent);
          if (leadInfo?.growthzillaSignee) {
            saveItemsToSession(
              "GrowthzillaSignee",
              leadInfo?.growthzillaSignee
            );
          }
          if (leadInfo?.salesAgent) {
            saveItemsToSession("Sales Agent", leadInfo?.salesAgent);
          }
          // const totalCount = leadInfo?.equipments?.reduce(
          //   (acc, item) => acc + item.quantity,
          //   0
          // );

          // if (totalCount > 0) {
          //   setEquipmentsFilledFromOrder(true);
          //   setCurrentPage(6);
          // }
        }
      };

      const setOldOrderData = async () => {
        await updateCount();
        populateCartItems();
      };

      const getOrderDetail = async () => {
        const orderDetail = await fetchOrderDetailFromLeadId(leadId);
        if (orderDetail?.orderId) {
          updateLeads(orderDetail?.leadId, orderDetail?.businessName);
        }
        setUzeliOrderFromDb(orderDetail);
        setGettingOrderInfo(false);
      };

      if (proceedToLeadInfo) {
        updateCount();
      } else if (oldOrderToState) {
        setOldOrderData();
      } else {
        getOrderDetail();
      }
    }
  }, [leadId, loggedIn, oldOrderToState, proceedToLeadInfo]);

  // console.log({
  //   proceedToLeadInfo,
  //   oldOrderToState,
  //   gettingLeadInfo,
  //   initialCartItems,
  // });

  useEffect(() => {
    // console.log("I should populate the items");
    if (oldOrderToState && Object.keys(initialCartItems ?? {})?.length) {
      // console.log("I should populate the items");
      // console.log({  });
      populateCartItems();
      // setOldOrderToState(false);
    }
  }, [initialCartItems]);

  const [uzeliItems, setItems] = useState([]);

  const toggleSwitch = (text) =>
    setValues({ ...openValues, [text]: openValues[text] === 0 ? 1 : 0 });

  const enable = (text) => setValues({ ...openValues, [text]: 1 });
  const disable = (texts) => {
    // texts = ['Kiosk','Computer']
    const toChange = texts.map((e) => ({ [e]: 0 }));
    // toChange = [{'Kiosk':0},{'Computer':0}]
    let changePairs = {};
    toChange.forEach((e) => {
      changePairs = _.extend(changePairs, { ...e });
    });
    // changePairs = {
    //   'Kiosk':0,
    // } 'Computer':0

    setValues({ ...openValues, ...changePairs });
  };
  // console.log({ openAdditionalValues });
  useEffect(() => {
    if (!openValues.Computer && !openValues.Kiozzk) {
      setValues({ ...openValues, SmartCheckout: 0 });
    }
  }, [openValues?.Kiosk, openValues?.Kiozzk]);

  const [currentPage, setCurrentPage] = useState(1);

  const incrementPage = () => setCurrentPage(currentPage + 1);
  const decrementPage = () => setCurrentPage(currentPage - 1);

  const totalKiozzkCount =
    openValues?.Kiosk + openValues?.Kiozzk + openAdditionalValues?.Kiozzk;
  const totalSmartCheckout =
    openValues?.SmartCheckout + openAdditionalValues?.SmartCheckout;
  const totalReceiptPrinter =
    openValues?.ReceiptPrinter + openAdditionalValues?.ReceiptPrinter;
  const totalCashDrawer =
    openValues?.CashdrawerSmall +
    openValues?.CashdrawerBig +
    openAdditionalValues?.CashdrawerSmall +
    openAdditionalValues?.CashdrawerBig;
  const totalComputer = openValues?.Computer + openAdditionalValues?.Computer;
  const totalAriesStand = openAdditionalValues?.AriesStand;
  // const totalStant =
  let setUpFee =
    totalKiozzkCount * equipmentPrice?.Kiozzk +
    totalSmartCheckout * equipmentPrice?.SmartCheckout +
    totalReceiptPrinter * equipmentPrice?.ReceiptPrinter +
    totalCashDrawer * equipmentPrice?.CashdrawerBig +
    totalAriesStand * equipmentPrice?.AriesStand;

  let discountOnSetupFee = false;

  // minimum hardware fee is $299
  if (setUpFee !== 0) {
    setUpFee = Math.max(299, setUpFee);
  }
  if (setUpFee >= qualifyingEquipmentPrice) {
    discountOnSetupFee = true;
  }

  let monthlyFee = 0;
  let discountOnMonthlyFee = false;
  let planType = "";
  let uzeliPlan = "";

  const deviceCount = totalKiozzkCount + totalComputer + totalSmartCheckout;

  switch (deviceCount) {
    case 0:
      monthlyFee = 0;
      planType = "Not selected";
      uzeliPlan = "Not selected";
      break;
    case 1:
      if (totalSmartCheckout === 1) {
        monthlyFee = 99;
        planType = "Smart Checkout";
        uzeliPlan = "Smart Checkout";
      } else {
        monthlyFee = 99;
        planType = "Uzeli Solo";
        uzeliPlan = "Uzeli Solo";
      }
      break;
    case 2:
      if (totalSmartCheckout === 0) {
        monthlyFee = 139;
        planType = "Uzeli Duo";
        uzeliPlan = "Uzeli Duo";
      } else if (totalKiozzkCount || totalComputer === 1) {
        monthlyFee = 139;
        planType = "Uzeli Solo + Smart Checkout";
        uzeliPlan = "Uzeli Solo + Smart Checkout";
      } else {
        monthlyFee = 139;
        planType = "Uzeli Duo + Smart Checkout";
        uzeliPlan = "Uzeli Duo + Smart Checkout";
      }
      break;
    case 3:
      if (totalSmartCheckout !== 0) {
        monthlyFee = 169;
        planType = "Uzeli Pro";
        uzeliPlan = "Uzeli Pro - 3 Devices";
      } else {
        monthlyFee = 169;
        planType = "Uzeli Duo + Smart Checkout";
        uzeliPlan = "Uzeli Duo + Smart Checkout";
      }
      break;
    case 4:
      monthlyFee = 189;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 4 Devices";
      break;
    case 5:
      monthlyFee = 199;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 5 Devices";
      break;
    case 6:
      monthlyFee = 209;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 6 Devices";
      break;
    case 7:
      monthlyFee = 219;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 7 Devices";
      break;
    case 8:
      monthlyFee = 229;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 8 Devices";
      break;
    case 9:
      monthlyFee = 239;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 9 Devices";
      break;
    case 10:
      monthlyFee = 249;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 10 Devices";
      break;
    case 11:
      monthlyFee = 259;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro -11 Devices";
      break;
    case 12:
      monthlyFee = 269;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 12 Devices";
      break;
    case 13:
      monthlyFee = 279;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 13 Devices";
      break;
    case 14:
      monthlyFee = 289;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro -14 Devices";
      break;
    case 15:
      monthlyFee = 299;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 15 Devices";
      break;
    case 16:
      monthlyFee = 309;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 16 Devices";
      break;
    case 17:
      monthlyFee = 319;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 17 Devices";
      break;
    default:
      monthlyFee = 299;
      planType = "Uzeli Pro";
  }

  if (monthlyFee >= qualifyingPlanPrice) {
    discountOnMonthlyFee = true;
  }

  const { getItems } = useItems();
  useEffect(() => {
    const e = async () => {
      const items = await getItems();
      // console.log({ items });
      setItems(items);
    };
    e();
  }, []);
  // console.log({ uzeliItems });
  const getItemByName = (text) => _.find(uzeliItems, (e) => e.name === text);
  const formattedItem = () =>
    uzeliItems.length
      ? [
          {
            ROWID: getItemByName("Kiozzk").ROWID,
            name: "Kiozzk",
            quantity: totalKiozzkCount,
            unitPrice:
              equipmentPrice.Kiozzk || getItemByName("Kiozzk").unitPrice,
          },
          {
            ROWID: getItemByName("Receipt Printer").ROWID,
            name: "Receipt Printer",
            quantity: totalReceiptPrinter,
            unitPrice:
              equipmentPrice.ReceiptPrinter ||
              getItemByName("Receipt Printer").unitPrice,
          },
          {
            ROWID: getItemByName("Smart Checkout").ROWID,
            name: "Smart Checkout",
            quantity: totalSmartCheckout,
            unitPrice:
              equipmentPrice.SmartCheckout ||
              getItemByName("Smart Checkout").unitPrice,
          },
          {
            ROWID: getItemByName('Cash Drawer Big(16")').ROWID,
            name: 'Cash Drawer Big(16")',
            quantity:
              openValues.CashdrawerBig + openAdditionalValues.CashdrawerBig,
            unitPrice:
              equipmentPrice.CashdrawerBig ||
              getItemByName('Cash Drawer Big(16")').unitPrice,
          },
          {
            ROWID: getItemByName('Cash Drawer Small(13")').ROWID,
            name: 'Cash Drawer Small(13")',
            quantity:
              openValues.CashdrawerSmall + openAdditionalValues.CashdrawerSmall,
            unitPrice:
              equipmentPrice.CashdrawerSmall ||
              getItemByName('Cash Drawer Small(13")').unitPrice,
          },
          {
            ROWID: getItemByName("Computer").ROWID,
            name: "Computer",
            quantity: openValues.Computer + openAdditionalValues.Computer,
            unitPrice: getItemByName("Computer").unitPrice,
          },
          {
            ROWID: getItemByName("Aries8/Aries6 Stand").ROWID,
            name: "Aries Stand",
            quantity: openValues.AriesStand + openAdditionalValues.AriesStand,
            unitPrice: getItemByName("Aries8/Aries6 Stand").unitPrice,
          },
        ]
      : [];

  // console.log({ formattedItem });
  const _equipment = {
    "Growthzilla Kiozzk Tablet": totalKiozzkCount,
    Computer: openValues?.Computer + openAdditionalValues?.Computer,
    "Pax Aries 8 Checkout Terminal": totalSmartCheckout,
    "Growthzilla Cloud Receipt Printer": totalReceiptPrinter,
    "Small CashDrawer":
      openValues?.CashdrawerSmall + openAdditionalValues?.CashdrawerSmall,
    "Large CashDrawer":
      openValues?.CashdrawerBig + openAdditionalValues?.CashdrawerBig,
    "Aries Stand": openAdditionalValues?.AriesStand,
  };

  const equipment = _.omitBy(_equipment, (e) => e === 0);

  const isOutOfStock = (productName) => {
    return (
      uzeliItems?.find((item) => item.name === productName)
        ?.sellableQuantity === 0
    );
  };

  const populateCartItems = () => {
    const value = { ...initialCartItems };
    if (value["Growthzilla Kiozzk Tablet"] >= 1) {
      openValues.Kiozzk = 1;
      value["Growthzilla Kiozzk Tablet"] =
        value["Growthzilla Kiozzk Tablet"] - 1;
    }
    if (value["Computer"] >= 1 && value["Growthzilla Kiozzk Tablet"] === 0) {
      openValues.Computer = 1;
      value["Computer"] = value["Computer"] - 1;
    }
    if (value["Growthzilla Kiozzk Tablet"] >= 1) {
      openValues.Kiosk = 1;
      value["Growthzilla Kiozzk Tablet"] =
        value["Growthzilla Kiozzk Tablet"] - 1;
    }
    if (value["Pax Aries 8 Checkout Terminal"] >= 1) {
      openValues.SmartCheckout = 1;
      value["Pax Aries 8 Checkout Terminal"] =
        value["Pax Aries 8 Checkout Terminal"] - 1;
    }
    if (value["Growthzilla Cloud Receipt Printer"] >= 1) {
      openValues.ReceiptPrinter = 1;
      value["Growthzilla Cloud Receipt Printer"] =
        value["Growthzilla Cloud Receipt Printer"] - 1;
    }
    if (value["Small CashDrawer"] >= 1) {
      openValues.CashdrawerSmall = 1;
      value["Small CashDrawer"] = Number(value["Small CashDrawer"]) - 1;
    }
    if (value["Large CashDrawer"] >= 1 && !openValues.CashdrawerSmall) {
      openValues.CashdrawerSmall = 1;
      value["Large CashDrawer"] = Number(value["Large CashDrawer"]) - 1;
    }

    if (value["Growthzilla Kiozzk Tablet"] >= 1) {
      openAdditionalValues.Kiozzk = Number(value["Growthzilla Kiozzk Tablet"]);
    }
    if (value["Pax Aries 8 Checkout Terminal"] >= 1) {
      openAdditionalValues.SmartCheckout = Number(
        value["Pax Aries 8 Checkout Terminal"]
      );
    }
    if (value["Growthzilla Cloud Receipt Printer"] >= 1) {
      openAdditionalValues.ReceiptPrinter = Number(
        value["Growthzilla Cloud Receipt Printer"]
      );
    }
    if (value["Computer"] >= 1) {
      openAdditionalValues.Computer = Number(value["Computer"]);
    }
    if (value["Small CashDrawer"] >= 1) {
      openAdditionalValues.CashdrawerSmall = Number(value["Small CashDrawer"]);
    }
    if (value["Large CashDrawer"] >= 1) {
      openAdditionalValues.CashdrawerBig = Number(value["Large CashDrawer"]);
    }
    if (value["Aries8/Aries6 Stand"] >= 1) {
      openAdditionalValues.AriesStand = Number(value["Aries8/Aries6 Stand"]);
    }
    // console.log({ value });
    saveItemsToSession(`values-${currentUser && currentUser?.id}`, {
      openValues: openValues,
      openAdditionalValues: openAdditionalValues,
    });
    saveItemsToSession("checkout", true);

    // console.log("Initial Cart Accepted", initialCartItems);
  };

  const acceptRecommendedCart = () => {
    populateCartItems();
    setCurrentPage(7);
  };

  const rejectRecommendedCart = () => {};

  const isUpperLimitReached = (productName) => {
    const sellableQuantity = uzeliItems?.find(
      (item) => item.name === productName
    )?.sellableQuantity;
    const currentQuantity = formattedItem().find(
      (item) => item.name === productName
    ).quantity;

    return currentQuantity === sellableQuantity || currentQuantity === 8;
  };

  // console.log({ initialCartItems });
  // console.log({ openValues, openAdditionalValues });

  return {
    minimumForInstallments,
    installments,
    openValues,
    openAdditionalValues,
    setValues,
    setAdditionalValues,
    toggleSwitch,
    enable,
    disable,
    currentPage,
    incrementPage,
    decrementPage,
    setUpFee,
    discountOnSetupFee,
    qualifyingEquipmentPrice,
    equipDiscount,
    monthlyFee,
    discountOnMonthlyFee,
    qualifyingPlanPrice,
    planType,
    formattedItem,
    equipment,
    uzeliPlan,
    totalSmartCheckout,
    equipmentPrice,
    equipmentsFilledFromOrder,
    planPermDiscount,
    planTempDiscount,
    tempDiscountExpiryDate,
    monthlySMSQuota,
    growthzillaSignee,
    uzeliItems,
    isOutOfStock,
    isUpperLimitReached,
    setCurrentPage,
    invalidLeadInfo,
    initialCartItems,
    acceptRecommendedCart,
    rejectRecommendedCart,
    salesAgent,
    gettingLeadInfo,
    businessNameFromLeadInfo,
    leadIdFromInfo,
    uzeliOrderFromDb,
    setOldOrderToState,
    oldOrderToState,
  };
};

export default useDeviceCounter;
