const ENV_VALUE = {
  development: {
    IS_DEV: true,
    LOGIN_URL: "https://accountstagingv2.growthzilla.com/getuser",
    PAYMENT_URL: "https://accountstagingv2.growthzilla.com/payment-method",
    EMBED_LOGIN_URL:
      "https://accountstagingv2.growthzilla.com/getuser/?embed=true",
    EMBED_PAYMENT_URL:
      "https://accountstagingv2.growthzilla.com/payment-method?embed=true",
    ORIGIN: "https://accountstagingv2.growthzilla.com",
    EVERSIGN_REDIRECT_URL:
      "http://orderslocal.growthzilla.com/app/orderstatus/",
    ORDER_SUCCESS: "http://orderslocal.growthzilla.com/app/ordersuccess",
    ENCRYPT_KEY: "NvJ2uRz3FlUig5zHjjiCAelfZdNNcoeUlkXh23sLY5UrnDGxCo",
    ANALYTICS_URL: "https://analyticsstaging.uzeli.com",
  },
  production: {
    SHORTEN_API_URL: "https://appt.cm/shorten",
    SHORTEN_API_KEY: "rAU70BoTay8NHuqrMqO7U4dKzpdoO2ne1FH9wlcN",
    LOGIN_URL: "https://accountv2.growthzilla.com/getuser",
    PAYMENT_URL: "https://accountv2.growthzilla.com/payment-method",
    EMBED_LOGIN_URL: "https://accountv2.growthzilla.com/getuser/?embed=true",
    EMBED_PAYMENT_URL:
      "https://accountv2.growthzilla.com/payment-method?embed=true",
    ORIGIN: "https://accountv2.growthzilla.com",
    EVERSIGN_REDIRECT_URL: "https://orders.growthzilla.com/app/orderstatus/",
    ORDER_SUCCESS: "https://orders.growthzilla.com/app/ordersuccess",
    ENCRYPT_KEY: "NvJ2uRz3FlUig5zHjjiCAelfZdNNcoeUlkXh23sLY5UrnDGxCo",
    CATALYST_API_KEY: "05ef8ecc39e1e8ddc7a3b9dc9ddc8dea",
    ANALYTICS_URL: "https://analytics.uzeli.com",
  },
};

export const getEnvironmentValue = () => {
  const environment =
    window.location.host == "orders.growthzilla.com"
      ? "production"
      : "development";

  return ENV_VALUE[environment];
};
