import axios from "axios";
import { DateTime } from "luxon";

export function getImageUrl(byte) {
  var blob = new Blob([byte], { type: "image/png" });
  return window.URL.createObjectURL(blob);
}

export function toBase64(arr) {
  //arr = new Uint8Array(arr) if it's an ArrayBuffer
  return btoa(
    arr.length &&
      arr?.reduce((data, byte) => data + String.fromCharCode(byte), "")
  );
}
export const getImageArray = (inputString) => {
  const trimmedString = inputString?.slice(1, -1);

  const resultArray = trimmedString?.split(",");

  const finalArray = resultArray?.map((element) => {
    const parsedElement =
      isNaN(element) && element === "false" ? false : element;
    return parsedElement;
  });
  console.log({ finalArray });
  return finalArray;
};

export const saveItemsToSession = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log("Error while saving", key);
  }
};

export const itemFromSession = (key) => {
  try {
    const value = sessionStorage.getItem(key) ?? "";
    // console.log({ value });
    try {
      const parsedValue = value || value !== "" ? JSON.parse(value) : "";
      return parsedValue;
    } catch (e) {
      console.error(e);
    }
  } catch (err) {
    console.log("Error while saving", key);
  }
};

export const getItemFromSession = (key) => {
  try {
    const value = sessionStorage.getItem(key);

    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (e) {
      console.error(e);
    }
  } catch (err) {
    console.log("Error while saving", key);
  }
};

export const isAuthorizedPaymentApprover = (user) => {
  return user?.role === "ADMIN";
};

export const getImageIds = async () => {
  try {
    const res = await axios.get("/browser/equipment/imageId", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (err) {
    console.log("Something went wrong");
    return err;
  }
};

export function debounce(callback, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
}

export function throttle(callback, limit) {
  let inThrottle;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      callback.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export const getFormattedDate = (date) => {
  // console.log({ date: typeof date });
  // const dateNew = date.split(" ")
  try {
    const dt = DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss:SSS");

    if (dt.isValid) {
      const formattedDate = dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      return formattedDate;
    } else {
      console.error("Invalid date format");
      return null; // or handle the error in another way
    }
  } catch (err) {
    console.error("Error while formatting date", err);
    return null; // or handle the error in another way
  }
};
