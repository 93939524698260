import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { H5 } from "../../document/components/CustomForm";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EquipmentStatusContent from "./EquipmentStatusContent";
import StatusModalPage from "../../document/components/StatusModalPage";
import { getFormattedDate, getItemFromSession } from "../utils";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import _ from "lodash";
const PendingEquipmentOrderCard = ({
  pendingOrder,
  approveOrder,
  cancelOrder,
  authPaymentAdmins,
  setLoading,
}) => {
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  const user = getItemFromSession("user-s");

  const isAuthorizedPaymentApprover = () => {
    const user = getItemFromSession("user-s");
    return (
      (user?.role === "ADMIN" &&
        authPaymentAdmins?.some(
          (approver) => approver?.email === user?.email
        )) ||
      (getEnvironmentValue().IS_DEV &&
        user?.role === "USER" &&
        authPaymentAdmins?.some((approver) => approver?.email === user?.email))
    );
  };

  const disabled = !isAuthorizedPaymentApprover();
  const toggleApproveDialog = () => {
    setOpenApproveDialog((prev) => !prev);
  };

  const uzeliUser = JSON.parse(pendingOrder?.uzeliUser ?? "{}");

  const toggleCancelDialog = () => {
    setOpenCancelDialog((prev) => !prev);
  };
  const closeDetail = () => {
    setOpenDetailDialog(false);
  };
  const orderItems = _.uniqBy(pendingOrder?.items, "ROWID");
  const orderStatus = pendingOrder?.orderStatus;
  // console.log({ uzeliUser });
  return (
    <div
      className={`${
        orderStatus === "pending"
          ? "bg-[#d3eaf2]/30"
          : orderStatus === "approved"
          ? "bg-[#cbedd3]/30"
          : orderStatus === "cancelled"
          ? "bg-[#f9c6c5]/25"
          : "bg-white"
      } mb-5 font-sans p-2 rounded-md shadow-xl`}
    >
      <Dialog open={openApproveDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to approve this order?
  This action sends the order to the supplier.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleApproveDialog}
            className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
            style={{
              textTransform: "none",
              backgroundColor: "#9b9fa3",
              color: "white",
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              toggleApproveDialog();
              approveOrder(pendingOrder?.orderId);
            }}
            className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
            style={{
              textTransform: "none",
              backgroundColor: "#7367f0",
              color: "white",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCancelDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleCancelDialog}
            className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
            style={{
              textTransform: "none",
              backgroundColor: "#9b9fa3",
              color: "white",
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              toggleCancelDialog();
              cancelOrder(pendingOrder?.orderId);
              setLoading(true);
            }}
            className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
            style={{
              textTransform: "none",
              backgroundColor: "#7367f0",
              color: "white",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="w-full flex justify-end">
        <div className=" w-full justify-between sm:justify-end flex flex-row items-center gap-1">
          <div className="font-semibold whitespace-nowrap ">
            Order Id <span>{pendingOrder?.orderId}</span>
          </div>
          {pendingOrder?.orderId && (
            <Tooltip title="Click to view order details">
              <InfoOutlinedIcon
                className="bg-white cursor-pointer transition-all duration-500 ease-out hover:scale-125 rounded-full text-[#4D1F70]"
                onClick={() => {
                  setOpenDetailDialog(true);
                  // console.log("Clicked");
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>

      <div className="block md:flex">
        <div className="md:w-1/3">
          <H5 className="text-base mt-2 font-semibold">Business Information</H5>
          <p>{`Business Id: ${pendingOrder?.businessId}`}</p>
          <p>{`${pendingOrder?.businessName}`}</p>
        </div>
        <div className="md:w-1/3">
          <H5 className="text-base mt-2 font-semibold">Shipping Address</H5>
          <p>{`${pendingOrder?.shippingAddress1}`}</p>
          {pendingOrder?.shippingAddress2 && (
            <p>{`${pendingOrder?.shippingAddress2}`}</p>
          )}
          <p>
            {pendingOrder?.shippingAddressCity +
              " " +
              pendingOrder?.shippingAddressState +
              " " +
              pendingOrder?.shippingAddressZipCode}
          </p>
        </div>
        <div className="md:w-1/3">
          <H5 className="text-base mt-2  font-semibold">Contact</H5>
          <p>{`Name: ${pendingOrder?.businessReprName}`}</p>
          <p>{`Email: ${pendingOrder?.businessReprEmail}`}</p>
          <p>{`Phone: ${pendingOrder?.businessReprPhone}`}</p>
        </div>
      </div>

      <H5 className="text-base mt-2 font-semibold">Items</H5>
      <ul>
        {orderItems.map((product, index) => {
          const { ROWID, name, unitPrice, provider, quantity } = product;
          return (
            <li key={ROWID}>
              {index + 1} Name: <span className="font-bold">{name}</span>,
              Quantity:
              <span className="font-bold">{quantity}</span> , UnitPrice: $
              {unitPrice} , Provider: {provider}
            </li>
          );
        })}
      </ul>
      <hr />
      <div className="w-full justify-between flex sm:flex-row gap-2 mt-2 flex-col">
        <p className="font-semibold">{`Total Price: $${pendingOrder?.totalPrice}`}</p>
        <p className="font-semibold">
          {`Created Date: ${getFormattedDate(pendingOrder?.createdDate)}`}
          {uzeliUser?.id && <span>-{uzeliUser?.name}</span>}
        </p>
      </div>
      {pendingOrder?.complimentaryReason && (
        <p className="text-base  font-semibold">
          Complimentary Reason: {pendingOrder?.complimentaryReason}
        </p>
      )}

      {orderStatus === "pending" && (
        <div className="  flex flex-col md:flex-row items-end justify-end gap-2 mt-2">
          <div
            className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <Button
              className="p-2 border-[1px] rounded-sm w-[10rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#9b9fa3",
                color: "white",
                disabled: true,
              }}
              onClick={toggleCancelDialog}
              disabled={disabled}
            >
              Cancel Order
            </Button>
          </div>
          <div
            className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <Button
              className="p-2 border-[1px] rounded-sm w-[10rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
                color: "white",
                disabled: true,
              }}
              onClick={toggleApproveDialog}
              disabled={disabled}
            >
              Approve Order
            </Button>
          </div>
        </div>
      )}
      {openDetailDialog && pendingOrder?.orderId && (
        <StatusModalPage
          content={
            <div className="w-full h-full ">
              <EquipmentStatusContent
                user={user}
                order={pendingOrder}
                setLoading={setLoading}
              />
            </div>
          }
          isOpen={openDetailDialog}
          closeDetail={closeDetail}
          fromStore={true}
        />
      )}
    </div>
  );
};

PendingEquipmentOrderCard.propTypes = {
  pendingOrder: PropTypes.object,
  approveOrder: PropTypes.func,
  cancelOrder: PropTypes.func,
  setLoading: PropTypes.func,
  authPaymentAdmins: PropTypes.array,
  tab: PropTypes.number,
};

export default PendingEquipmentOrderCard;
