import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";
import styled, { css } from "styled-components";
import UzeliLogo from "../../assets/images/growthzilla-logo.png";
import Spinner from "../../components/Spinner";
import { getEnvironmentValue } from "../../utils/getEnvironmentValues";

import isUserNew from "../../utils/isUserNew";
import Card from "./components/Card";
import Discounts from "./components/Discounts";
import HeaderButton from "./components/HeaderButton";
import SwitchContext from "./context";
import useDeviceCounter from "./hooks/useDeviceCounter";
import FifthPage from "./pages/FifthPage";
import FirstPage from "./pages/FirstPage";
import FourthPage from "./pages/FourthPage";
import SecondPage from "./pages/SecondPage";
import SeventhPage from "./pages/SeventhPage";
import { Navigate } from "react-router-dom";
import SixthPage from "./pages/SixthPage";
import ThirdPage from "./pages/ThirdPage";
import IFrameComponent from "./components/IFrameComponent";
import getItemId from "../../utils/getItemId";
import isItemIdValid, {
  getLeadId,
  getSearchParams,
} from "../../utils/isItemIdValid";
import User from "./components/User";
import Cart from "./components/Cart";
import { saveItemsToSession } from "../shoppingsite/utils";
import AcceptCartDailog from "./components/AcceptCartDialog";
import AcceptCart from "./components/AcceptCart";
import StandWarningDialog from "./components/StandWarningDialog";
import StandWarning from "./components/StandWarning";
import { savePageActivity } from "../../hooks/updateLeadActivity";
import InvalidAccess from "../../components/InvalidAccess";

export const FooterButton = styled.button`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(55, 22, 80, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 0.4rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  ${(props) =>
    props.disabled &&
    css`
      color: #aaaaaa;
      background-color: #eeeeee;
    `}
`;

const Document = () => {
  const [loading, setLoading] = useState(false);
  const { search, pathname } = useLocation();

  // console.log({ search });
  const searchParams = getSearchParams(search, pathname);
  // const [closeLoadingRecommendation, setCloseLoadingRecommendation] =
  //   useState(false);
  // const leadId = getLeadId(searchParams);
  const leadString = searchParams?.id;
  const [itemId, setItemId] = useState(null);
  const isFromProma = searchParams?.fromProma;
  // sessionStorage.setItem("fromProma", isFromProma);
  // sessionStorage.setItem("leadString", JSON.stringify(leadString));
  const [contactInfo, setContactInfo] = useState();
  const [gettingImageId, setGettingImageId] = useState(false);
  const [gettingOrderInfo, setGettingOrderInfo] = useState(false);
  const [contact, setContact] = useState();
  const [showFrame, setShowFrame] = useState(true);
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [standWarning, setStandWarning] = useState(true);
  const [nextButtonClicked, setNextButtonClicked] = useState(0);
  const [acceptModal, setAcceptModal] = useState(false);
  const [proceedToLeadInfo, setProceedToLeadInfo] = useState(false);
  const [showOrderStatus, setShowOrderStatus] = useState(null);
  // const [cartLoading, setCartLoading] = useState(false);
  const [iframeSource, setIframeSource] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isItemIdValid(leadId)) {
  //     navigate("/app/unauthorized");
  //   }
  // }, [leadId]);

  useEffect(() => {
    const init = async () => {
      // console.log({searc})
      const searchParams = getSearchParams(search, pathname);
      // console.log({ fromUseEffect: searchParams });
      const leadId = getLeadId(searchParams);
      const leadString = searchParams?.id;
      const isFromProma = searchParams?.fromProma;
      // console.log({ searchParams });
      sessionStorage.setItem("fromProma", searchParams?.fromProma);
      if (!isFromProma) {
        if (leadId && isItemIdValid(leadId)) {
          saveItemsToSession("leadId", leadId);
          const itemValue = await getItemId(leadString);
          if (itemValue) {
            setItemId(itemValue);
            sessionStorage.setItem("itemId", JSON.stringify(itemValue));
          } else {
            navigate("/app/formFailed");
          }
        }
      } else {
        if (leadString) {
          sessionStorage.setItem("leadString", JSON.stringify(leadString));
        }
      }
    };
    init();
  }, []);

  const closeAcceptCartDialog = () => {
    setAcceptModal(false);
    // setNextButtonClicked(false);
  };

  useEffect(() => {
    const init = async () => {
      if (user) {
        setContact(await isUserNew(user));
        setContactInfo(contact?.userId ? true : false);
        // if (isFromProma) {
        //   setAcceptModal(true);
        // }
      }
    };
    init();
  }, [user]);

  const showPaymentWindow = () => {
    setLoading(true);
    setIframeSource(getEnvironmentValue().EMBED_PAYMENT_URL);
  };
  const checkAndSetSessionData = (user) => {
    let initialValues = {
      Kiosk: 0,
      Kiozzk: 0,
      Computer: 0,
      SmartCheckout: 0,
      ReceiptPrinter: 0,
      CashdrawerSmall: 0,
      CashdrawerBig: 0,
    };
    let initialAdditionalValues = {
      SmartCheckbout: 0,
      Kiozzk: 0,
      Computer: 0,
      ReceiptPrinter: 0,
      CashdrawerSmall: 0,
      CashdrawerBig: 0,
    };
    const userFromSession = JSON.parse(sessionStorage.getItem("user"));

    if (userFromSession?.id && userFromSession !== user?.id) {
      const values = JSON.parse(
        sessionStorage.getItem(`values-${user && user?.id}`)
      );
      if (!values) {
        sessionStorage.setItem(`values-${user?.id}`, JSON.stringify({}));
      } else {
        initialValues = values.openValues;
        initialAdditionalValues = values.openAdditionalValues;
      }
      setAdditionalValues(initialAdditionalValues);
      setValues(initialValues);

      // const equipment = JSON.parse(
      //   sessionStorage.getItem(`equipment-${user && user?.id}`)
      // );
      // if (!equipment) {
      //   setAdditionalValues(initialAdditionalValues);
      //   setValues(initialValues);
      //   sessionStorage.setItem(`equipment-${user?.id}`, JSON.stringify({}));
      // } else {
      // }
    }
  };

  const closeStandWarning = () => {
    setStandWarning(false);
    setNextButtonClicked(false);
  };

  const listener = {
    loginResponse: (user) => {
      // console.log("User Changed");
      setUser(user?.id);
      setLoggedIn(user);
      setShowFrame(false);
      setIframeSource(null);
      checkAndSetSessionData(user);
      sessionStorage.setItem("user", JSON.stringify(user));
      setGettingOrderInfo(true);
      // console.log("User", user);
    },
    paymentSelectedResponse: (action, paymentData) => {
      if (action === "back") {
        setShowFrame(false);
        return;
      }

      localStorage.setItem("paymentData", JSON.stringify(paymentData));
      setShowFrame(false);
      navigate("/app/paymentloading");
    },
  };

  const {
    openValues,
    openAdditionalValues,
    setAdditionalValues,
    setValues,
    toggleSwitch,
    enable,
    disable,
    currentPage,
    incrementPage,
    decrementPage,
    setUpFee,
    discountOnSetupFee,
    qualifyingEquipmentPrice,
    equipDiscount,
    monthlyFee,
    discountOnMonthlyFee,
    qualifyingPlanPrice,
    planType,
    formattedItem,
    equipment,
    uzeliPlan,
    totalSmartCheckout,
    equipmentPrice,
    equipmentsFilledFromOrder,
    planPermDiscount,
    planTempDiscount,
    tempDiscountExpiryDate,
    monthlySMSQuota,
    growthzillaSignee,
    uzeliItems,
    isOutOfStock,
    isUpperLimitReached,
    setCurrentPage,
    installments,
    minimumForInstallments,
    initialCartItems,
    acceptRecommendedCart,
    salesAgent,
    gettingLeadInfo,
    invalidLeadInfo,
    businessNameFromLeadInfo,
    uzeliOrderFromDb,
    setOldOrderToState,
    oldOrderToState,
  } = useDeviceCounter(
    leadString,
    loggedIn,
    proceedToLeadInfo,
    setGettingOrderInfo
  );

  // console.log({ gettingOrderInfo, showFrame });

  const checkIfShowOrderStatus = (orderDetail) => {
    const uzeliUserId = JSON.parse(orderDetail?.uzeliUser ?? "{}")?.id;
    if (
      orderDetail?.signingStatus?.data?.signedByBusiness &&
      uzeliUserId === loggedIn?.id
    ) {
      return true;
    }
  };
  const checkIfUnauthorizedUser = (orderDetail) => {
    const uzeliUserId = JSON.parse(orderDetail?.uzeliUser ?? "{}")?.id;
    // console.log({ user });
    if (
      orderDetail?.signingStatus?.data?.signedByBusiness &&
      uzeliUserId !== loggedIn?.id
    ) {
      return true;
    }
  };
  const checkIfShowForm = (orderDetail) => {
    if (orderDetail?.orderId) {
      return true;
    }
  };
  const checkIfShowFormWithRecommendation = (orderDetail) => {
    if (!orderDetail?.orderId && isFromProma) {
      return true;
    }
  };

  useEffect(() => {
    if (loggedIn?.id && uzeliOrderFromDb?.success) {
      // console.log("Setting from here", uzeliOrderFromDb);
      const showStatus = checkIfShowOrderStatus(uzeliOrderFromDb);
      const unauthorizedUser = checkIfUnauthorizedUser(uzeliOrderFromDb);
      const showForm = checkIfShowForm(uzeliOrderFromDb);
      const showFormWithRecommendation =
        checkIfShowFormWithRecommendation(uzeliOrderFromDb);
      // console.log({
      //   showStatus,
      //   unauthorizedUser,
      //   showForm,
      //   showFormWithRecommendation,
      //   loggedIn,
      // });
      if (showStatus) {
        console.log("Show Status");
        setShowOrderStatus("status");
      } else if (unauthorizedUser) {
        console.log("Deny  Access");
        setShowOrderStatus("denied");
      } else if (showForm) {
        console.log("Show Old Form");
        setAcceptModal(false);
        setOldOrderToState(true);
        // setProceedToLeadInfo(true);
      } else if (showFormWithRecommendation) {
        console.log("Show Form With Recommendation");
        setAcceptModal(true);
        setProceedToLeadInfo(true);
      }
      // console.log({ showStatus });
    }
  }, [uzeliOrderFromDb, loggedIn]);

  // useEffect(() => {
  //   if (uzeliOrderFromDb?.orderStatus === "pending") {
  //
  //   }
  // }, [uzeliOrderFromDb]);

  // console.log({ currentPage, uzeliOrderFromDb });

  useEffect(() => {
    const hasCheckoutTerminal =
      openValues?.SmartCheckout > 0 || openAdditionalValues?.SmartCheckout > 0;
    const hasAriesStand = openAdditionalValues?.AriesStand > 0;
    // console.log({
    //   currentPage,
    //   nextButtonClicked,
    //   openAdditionalValues,
    //   openValues,
    // });
    if (nextButtonClicked === 6 && hasCheckoutTerminal && !hasAriesStand) {
      setStandWarning(true);
    } else {
      setStandWarning(false);
    }
  }, [
    currentPage,
    nextButtonClicked,
    openValues?.SmartCheckout,
    openAdditionalValues?.SmartCheckout,
    openAdditionalValues?.AriesStand,
  ]);

  // const updateLeads = async () => {
  //   // console.log({ updatingLeadActivity: leadId });
  //   // console.log({ isFromProma });
  //   // const id = isFromProma?getLeadId
  //   // if
  //   const leadID = getItemFromSession("leadIdFromInfo");
  //   const data = {
  //     id: leadID,
  //     leadActivity: "User Logged In",
  //     businessName: businessNameFromLeadInfo,
  //   };
  //   updateLeadActivity(data);
  // };

  // useEffect(async () => {
  //   if (loggedIn?.id) {
  //     await updateLeads();
  //     // setGettingOrderInfo(true);
  //   }
  // }, [loggedIn]);

  const gotoCheckout = () => {
    console.log("Going to Checkout");
    setCurrentPage(7);
  };

  const activeSection = () => {
    switch (currentPage) {
      case 1:
        return (
          <div className="my-4">
            <Card>
              <FirstPage />
            </Card>
          </div>
        );
      case 2:
        return (
          <div className="my-4">
            <SecondPage />
          </div>
        );
      case 3:
        return (
          <div className="my-4">
            <Card>
              <ThirdPage />
            </Card>
          </div>
        );
      case 4:
        return (
          <div className="my-4">
            <Card>
              <FourthPage />
            </Card>
          </div>
        );
      case 5:
        return (
          <div className="my-4">
            <Card>
              <FifthPage />
            </Card>
          </div>
        );
      case 6:
        return (
          <div className="my-4">
            <Card>
              <SixthPage />
            </Card>
          </div>
        );
      case 7:
        return (
          <div className="my-4">
            <SeventhPage />
          </div>
        );
      default:
        return <> </>;
    }
  };

  useEffect(() => {
    if (invalidLeadInfo) {
      console.log("Invalid Lead Information");
      navigate("/app/invalidLead");
    }
  }, [invalidLeadInfo]);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    if (currentUser?.id && equipment && Object.keys(equipment).length) {
      sessionStorage.setItem(
        `equipment-${currentUser && currentUser?.id}`,
        JSON.stringify(equipment)
      );
    }
  }, [equipment]);

  useEffect(() => {
    if (currentPage >= 5) {
      sessionStorage.setItem("checkout", JSON.stringify(true));
    }
  }, [currentPage]);

  const checkIfReset = (value) => {
    return !Object.keys(value).some((item) => value[item]);
  };
  useEffect(async () => {
    const userFromSession = JSON.parse(sessionStorage.getItem("user"));
    if (userFromSession?.id) {
      setLoggedIn(userFromSession);
      setUser(userFromSession?.id);
      // await updateLeads();
      setShowFrame(false);
      setGettingOrderInfo(true);
    }
  }, []);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user"));
    if (currentUser?.id) {
      const values = {
        openValues: openValues,
        openAdditionalValues: openAdditionalValues,
      };
      if (!checkIfReset(openValues)) {
        sessionStorage.setItem(
          `values-${currentUser && currentUser?.id}`,
          JSON.stringify(values)
        );
      }
    }
  }, [openValues, openAdditionalValues]);

  const displayIframeStyle = {
    background: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  const dueToday = installments
    ? (
        (setUpFee - equipDiscount * Number(discountOnSetupFee)).toFixed(2) /
        Number(installments)
      ).toFixed(2)
    : 0;

  const showInstallmentInfo =
    setUpFee > (minimumForInstallments ?? 0) ? true : false;
  // console.log({ dueToday, setUpFee, installments, minimumForInstallments });
  return (
    <SwitchContext.Provider
      value={{
        minimumForInstallments,
        installments,
        openValues,
        toggleSwitch,
        enable,
        disable,
        openAdditionalValues,
        setAdditionalValues,
        user,
        monthlyFee,
        discountOnMonthlyFee,
        planType,
        setUpFee,
        discountOnSetupFee,
        equipDiscount,
        formattedItem,
        contactInfo,
        itemId,
        uzeliPlan,
        equipment,
        totalSmartCheckout,
        qualifyingEquipmentPrice,
        contact,
        equipmentPrice,
        equipmentsFilledFromOrder,
        planPermDiscount,
        planTempDiscount,
        tempDiscountExpiryDate,
        monthlySMSQuota,
        growthzillaSignee,
        setShowFrame,
        setIframeSource,
        decrementPage,
        incrementPage,
        setLoading,
        loading,
        uzeliItems,
        isOutOfStock,
        isUpperLimitReached,
        showPaymentWindow,
        salesAgent,
        setCurrentPage,
        setNextButtonClicked,
        businessNameFromLeadInfo,
      }}
    >
      <div className="w-screen  z-50">
        <div className="flex w-full scrollbar-hide bg-[#f6f6f6] justify-center pt-4 rounded-sm ">
          <div className="w-full sm:w-4/5 h-full items-center   p-2 flex justify-between">
            <div className="flex justify-start h-full  w-3/5 pl-2 sm:w-3/5 md:1/4 lg:2/5 xl:w-2/5">
              <img src={UzeliLogo} className=" h-[40px] md:h-full" alt="logo" />
            </div>
            <div className="mr-3 h-full text-[35px] sm:text-[40px] md:text-[40px] items-center flex sm:mr-1">
              {loggedIn && (
                <div className=" flex  -mr-2 flex-row gap-3 sm:gap-5  justify-center  text-[#4D1F70] ">
                  <Cart equipment={equipment} gotoCheckout={gotoCheckout} />

                  <User
                    user={loggedIn}
                    switchUser={() => {
                      setShowFrame(true);
                      setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center">
          {iframeSource && (
            <div
              className="h-full w-full flex justify-center scrollbar-hide"
              style={showFrame ? displayIframeStyle : hiddenIframeStyle}
            >
              <IFrameComponent listener={listener} iFrameSrc={iframeSource} />
            </div>
          )}
          {loading ? (
            <div className="h-[80vh]">
              <Spinner
                loading={true}
                message={
                  iframeSource === getEnvironmentValue()?.EMBED_PAYMENT_URL
                    ? "Loading Payment Method"
                    : null
                }
              />
            </div>
          ) : (
            <></>
          )}
          {gettingOrderInfo ||
          (uzeliOrderFromDb?.orderId && oldOrderToState && gettingLeadInfo) ? (
            <div className="h-[80vh]">
              <Spinner loading={true} />
            </div>
          ) : showOrderStatus === "status" ? (
            <>
              <Navigate to={`/app/order/status/${uzeliOrderFromDb?.orderId}`} />
            </>
          ) : showOrderStatus === "denied" && !showFrame ? (
            <>
              <InvalidAccess />
              {/* <Navigate to="/app/invalidAccess" /> */}
            </>
          ) : (
            <>
              {!showFrame && (
                <div>
                  <div
                    style={{
                      display: showFrame || loading ? "none" : "block",
                    }}
                    className="flex px-2 w-full sm:w-4/5 md:mx-auto"
                  >
                    <div className="header text-center pt-6">
                      <h1 className="text-xl sm:text-2xl md:text-4xl font-medium mb-5">
                        Lets build the best solution for your business ...
                      </h1>
                      <p className="text-gray-500 text-justify">
                        With Uzeli, you can configure a solution that you want
                        for your business. Your monthly subscription fee will
                        depend on the number of interactive devices (kiosk
                        tablets, desktops and SmartCheckout terminals) you use.
                        Your upfront cost will depend on the devices you want to
                        buy from us. Your Uzeli plan, monthly fee and upfront
                        cost will be displayed on the boxes below as you
                        configure your solution.
                      </p>
                      <div
                        className="
                    mt-4
                    >
                    *
                    +
                    *
                    lg:mt-10 
                    >
                    *
                    +
                    *
                    space-y-0
                    >
                    *
                    +
                    *
                "
                      >
                        <div className="flex w-full mb-3 justify-around items-center">
                          <HeaderButton
                            label="Setup Cost"
                            type="price"
                            price={setUpFee}
                            discount={
                              equipDiscount * Number(discountOnSetupFee)
                            }
                          />
                          <HeaderButton
                            label="Monthly Fee"
                            type="price"
                            price={monthlyFee}
                            discount={
                              (planPermDiscount +
                                (new Date(tempDiscountExpiryDate) > new Date()
                                  ? planTempDiscount
                                  : 0)) *
                              Number(discountOnMonthlyFee)
                            }
                          />

                          <HeaderButton
                            label="Plan"
                            type="text"
                            plan={planType}
                          />
                        </div>

                        {showInstallmentInfo ? (
                          <>
                            {installments > 0 ? (
                              <div
                                className="text-left bg-yellow-100 border-l-4 border-r-4 border-yellow-500 text-yellow-700 px-4 py-2 gap-1"
                                role="alert"
                              >
                                <div>
                                  <p className="font-bold">
                                    You are offered to pay in{" "}
                                    <strong className="pl-0.5 pr-0.5">
                                      {installments}
                                    </strong>{" "}
                                    installments.
                                  </p>
                                  <ul>
                                    <li>
                                      <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
                                      <span>
                                        {" "}
                                        Your due today is
                                        <strong className="pl-1 ">
                                          ${dueToday}
                                        </strong>
                                        .
                                      </span>
                                    </li>

                                    <li>
                                      <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
                                      <span>
                                        {" "}
                                        You will have {Number(installments) -
                                          1}{" "}
                                        future installments of each
                                        <strong className="pl-1 pr-1">
                                          ${dueToday}
                                        </strong>
                                        each.
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {minimumForInstallments > 0 && installments > 0 && (
                              <div
                                className="text-left bg-yellow-100 border-l-4 border-r-4 border-yellow-500 text-yellow-700 px-4 pt-4 gap-1"
                                role="alert"
                              >
                                <p className="font-bold">
                                  You will be offered to pay in{" "}
                                  {/* <strong className="pl-0.5 pr-0.5">{installments}</strong>{" "} */}
                                  installments, when your setup cost exceeds{" "}
                                  <strong className="pl-0.5 pr-0.5">
                                    ${minimumForInstallments}.
                                  </strong>{" "}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                        <Discounts
                          equipDiscount={equipDiscount}
                          qualifyingEquipmentPrice={qualifyingEquipmentPrice}
                          qualifyingPlanPrice={qualifyingPlanPrice}
                          planPermDiscount={planPermDiscount}
                          planTempDiscount={planTempDiscount}
                          tempDiscountExpiryDate={tempDiscountExpiryDate}
                        />
                        {monthlySMSQuota > 0 ? (
                          <div
                            className="text-left bg-yellow-100 border-l-4 border-r-4 border-yellow-500 text-yellow-700 px-4  gap-1"
                            role="alert"
                          >
                            <ul>
                              <li>
                                <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
                                <span>
                                  Your subscription includes
                                  <span className="pl-1">
                                    {monthlySMSQuota}
                                  </span>{" "}
                                  text messages per month.
                                </span>
                              </li>

                              <li>
                                <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
                                <span>
                                  Additional text messages can be purchased at
                                  $15 per 1000 text messages.
                                </span>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {activeSection()}

                    {currentPage !== 7 && currentPage !== 2 && (
                      <div className="gap-x-4 pb-4 flex flex-row justify-end">
                        <FooterButton
                          className="flex text-white bg-[#4D1F70] hover:text-yellow-500"
                          disabled={
                            currentPage === 1 || equipmentsFilledFromOrder
                          }
                          onClick={async () => {
                            setNextButtonClicked(0);
                            await savePageActivity(currentPage, "Back");
                            decrementPage();
                          }}
                          data-analytics-name="Previous Button Element"
                          data-analytics-eventtype="Clicked"
                          data-analytics-payload={JSON.stringify({
                            fromPage: currentPage,
                            cartItems: equipment,
                          })}
                        >
                          <ChevronLeft />
                          Previous
                        </FooterButton>
                        <FooterButton
                          className="flex text-white bg-[#4D1F70] hover:text-yellow-500"
                          disabled={currentPage === 7}
                          onClick={async () => {
                            setNextButtonClicked(currentPage);
                            // if (currentPage !== 6 && !standWarning) {
                            await savePageActivity(currentPage, "Next");
                            incrementPage();
                            // }
                          }}
                          data-analytics-name="Next Button Element"
                          data-analytics-eventtype="Clicked"
                          data-analytics-payload={JSON.stringify({
                            fromPage: currentPage,
                            cartItems: equipment,
                          })}
                        >
                          Next
                          <ChevronRight />
                        </FooterButton>
                      </div>
                    )}
                  </div>
                  {acceptModal && !showFrame && (
                    <AcceptCartDailog
                      content={
                        <div className="w-[full%] h-full ">
                          <AcceptCart
                            initialCartItems={initialCartItems}
                            closeDetail={closeAcceptCartDialog}
                            acceptInitialCart={acceptRecommendedCart}
                            gettingLeadInfo={gettingLeadInfo}
                            gettingImageId={gettingImageId}
                            setGettingImageId={setGettingImageId}
                          />
                        </div>
                      }
                      isOpen={acceptModal}
                      closeDetail={closeAcceptCartDialog}
                      loading={gettingLeadInfo || gettingImageId}
                    />
                  )}
                </div>
              )}
            </>
          )}
          {/* <>
          {!closeLoadingRecommendation &&
            gettingLeadInfo &&
            isFromProma &&
            user && (
              <div className="  bg-[#f2f2f2] px-2 py-4 sm:py-6 sm:px-4 rounded-lg shadow-lg  w-50 left-4 bottom-4 sm:left-10 sm:bottom-10 md:left-14 md:bottom-14 lg:left-18 lg:bottom-18 xl:bottom-24 xl-left-24 fixed ">
                <div
                  onClick={() => {
                    setCloseLoadingRecommendation(true);
                  }}
                  className="absolute cursor-pointer h-fit py-[1px]  lg:text-[13px] items-center text-[10px] font-bold right-1 top-[2px] sm:right-[2px] px-[5px]  sm:px-[6px]   rounded-full text-[#4D1F70]  transition-all 
                duration-500 ease-out hover:scale-125 bg-slate-300 shadow-2xl"
                >
                  x
                </div>
                <div className="font-bold text-[12px] sm:text-[16px]">
                  Loading Recommendation
                </div>
              </div>
            )}
        </> */}
          <>
            {standWarning && (
              <StandWarningDialog
                content={
                  <div className="w-[full] h-full ">
                    <StandWarning closeDetail={closeStandWarning} />
                  </div>
                }
                isOpen={standWarning}
                closeDetail={closeStandWarning}
              />
            )}
          </>
        </div>
      </div>
    </SwitchContext.Provider>
  );
};

export default Document;
