import React from "react";
import PropTypes from "prop-types";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";

function CartMenuLogo({ equipment }) {
  const total =
    equipment &&
    equipment.length &&
    equipment?.reduce((acc, item) => {
      return acc + (item?.quantity || 0);
    }, 0);

  return (
    <div className="flex items-center  relative ">
      <div
        className="text-[15px] px-[4px] py-[0.5px]  sm:px-[6px] sm:py-[1px] md:px-[7px] md:py-[1.5px] sm:text-[20px] md:text-[20px] border-1 md:border-2"
        style={{
          borderRadius: "100%",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
          backgroundColor: "white",
        }}
        data-analytics-name="Uzeli Cart Element"
        data-analytics-eventtype="Clicked"
        data-analytics-payload={JSON.stringify({
          fromPage: "store",
        })}
      >
        <ShoppingCartRoundedIcon
          className=" rounded-e-md rounded-s-lg   text-[#4D1F70]"
          fontSize="inherit"
        />
      </div>
      {total > 0 && (
        <div className="sm:text-xs  text-[8px]  absolute -top-[5px] -left-[6px] sm:-top-2 sm:-left-2 bg-red-600  text-black-900 px-[0.2rem] sm:px-[0.3rem] rounded-full">
          {total}
        </div>
      )}
    </div>
  );
}
CartMenuLogo.propTypes = {
  equipment: PropTypes.array,
};
export default CartMenuLogo;
