import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import NewUser from "./components/NewUser";
import AuthContext from "./context";
import isItemIdValid, {
  getLeadId,
  getSearchParams,
} from "../../utils/isItemIdValid";

const Authentication = () => {
  const { search } = useLocation();
  const searchParams = getSearchParams(search);
  const leadId = getLeadId(searchParams);

  return isItemIdValid(leadId) ? (
    <AuthContext.Provider value={{ leadId }}>
      <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
        <NewUser />
      </div>
    </AuthContext.Provider>
  ) : (
    <Navigate to="/app/unauthorized" />
  );
};

export default Authentication;
