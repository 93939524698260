/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import React from "react";
import { signDocument } from "../../../utils/signAgreementDocument";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import {
  saveLeadActivity,
  updateLeadActivity,
} from "../../../hooks/updateLeadActivity";

const SignDocument = ({ embeddedSigningUrl, fromPending, leadData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const data = {
    id: leadData?.id,
    businessName: leadData?.businessName,
  };
  // const isFromAgent = agent ? true : false;
  const finishLoadingCallback = async () => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: " Agreement Document",
        eventtype: "loaded",
      });
    setLoading(false);
    updateLeadActivity({
      ...data,
      leadActivity: `${
        leadData?.agent ? "Growthzilla" : "Customer"
      } Document Loaded`,
    });
    // await saveLeadActivity("Signing Document Loaded");
  };

  const finishedSigningCallback = async () => {
    const orderId = localStorage.getItem("orderId");
    const redirectURL = `/app/orderstatus/${
      fromPending ? "p" : "n"
    }/${orderId}`;
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Agreement Document",
        eventtype: "signed",
      });
    updateLeadActivity({
      ...data,
      leadActivity: leadData?.agent ? "Growthzilla signed" : "Customer Signed",
    });
    // await saveLeadActivity(agent ? "Growthzilla signed" : "Customer Signed");
    navigate(redirectURL, { replace: true });
  };

  const errorCallback = async (e) => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: " Agreement Document",
        eventtype: "error",
      });
    console.log("Error Occurred", e);
    let url = `/app/error/order/${fromPending ? "p" : "n"}/failed`;
    if (e === "Document already signed") {
      url = `/app/error/${fromPending ? "p" : "n"}/alreadySigned`;
    }
    navigate(url, { replace: true });
    updateLeadActivity({
      ...data,
      leadActivity: `Error while ${
        leadData?.agent ? "Growthzilla" : "Customer"
      } Signing Document`,
    });
    // await saveLeadActivity("Error in signing document");
    setLoading(false);
  };

  useEffect(() => {
    if (embeddedSigningUrl) {
      signDocument(
        embeddedSigningUrl,
        finishLoadingCallback,
        finishedSigningCallback,
        errorCallback
      );
    }
  }, [embeddedSigningUrl]);
  return (
    <div className="h-[100vh] w-[100wv]">
      {loading || !embeddedSigningUrl ? (
        <Spinner
          loading={true}
          message={"Please wait while agreement document is being prepared"}
        />
      ) : null}
      <div className="flex justify-center" id="everSignId"></div>
    </div>
  );
};
SignDocument.propTypes = {
  embeddedSigningUrl: PropTypes.string,
  fromPending: PropTypes.bool,
  leadData: PropTypes.object,
};
export default SignDocument;
