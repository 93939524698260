/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import placeHolder from "../../../assets/images/placeholder.png";
function ProductCard({ index, item }) {
  //   console.log({ item });
  const [image, setImage] = useState();

  const getImageArray = (inputString) => {
    const trimmedString = inputString?.slice(1, -1);

    const resultArray = trimmedString?.split(",");

    const finalArray = resultArray?.map((element) => {
      const parsedElement =
        isNaN(element) && element === "false" ? false : element;
      return parsedElement;
    });
    // console.log({ finalArray });
    return finalArray;
  };

  const findImage = async (inputString) => {
    const imageIdArray = getImageArray(inputString);
    // console.log({ imageIdArray });
    if (!!imageIdArray && imageIdArray.length) {
      const imageByteArray = imageIdArray[0];
      if (imageByteArray) {
        setImage(imageByteArray);
      }
    }
  };
  useEffect(() => {
    findImage(item?.imageId);
  }, [item]);

  return (
    <div className="h-full w-full flex flex-col md:flex-row md:gap-5 lg:gap-10 mb-5 md:mb-1  items-center md:shadow-none  shadow-md justify-center">
      <div className="h-full  items-center hidden md:flex justify-center">
        {index + 1}
      </div>
      <div className="md:font-[500] w-full px-2 md:px-0 md:w-[25%]  lg:w-[1/3] font-semibold text-center md:text-start ">
        {" "}
        {item?.name}
      </div>
      <div className="flex w-full md:w-[50%]  lg:w-[1/3] justify-evenly md:flex-row flex-col md:gap-10 items-center">
        <div>
          <img
            src={image ? `/browser/image/${image}` : placeHolder}
            alt={item?.name}
            className="mx-auto  justify-start    transition-all duration-500 ease-out hover:scale-125 lg:w-[70px] md:w-[55px] w-[100px]"
            style={{ height: "auto" }}
          />
        </div>
        <div className="flex gap-2  w-full md:w-[25%] lg:w-[1/3]  justify-center md:justify-between ">
          <div className="flex gap-2 md:w-[1/2]">
            <div className="font-semibold">{item?.quantity}</div>
            <div>X</div>
            <div>${item?.price}</div>
          </div>
          <div>=</div>
          <div className="flex gap-2 md:w-[1/2] ">
            <div>${item?.quantity * item?.price}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
ProductCard.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

export default ProductCard;
