/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { getProductDiscount } from "../../../utils/discount";
import Incrementor from "./Incrementor";
import LinkGeneratorComponent from "./LinkGeneratorComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Tooltip } from "@mui/material";
import { getImageArray } from "../utils";
import getImageByteArray from "../hooks/getImage";
import placeHolder from "../../../assets/images/placeholder.png";
import ImageWithAuth from "./ImageWithAuth";

const ProductCard = ({
  product,
  cartItems,
  setCartItems,
  discountObj,
  linkGenerator,
  discounts,
  setDiscounts,
  description,
  imageId,
}) => {
  const [warning, setWarning] = useState("");
  const [image, setImage] = useState();
  console.log({
    product,
    cartItems,
    setCartItems,
    discountObj,
    linkGenerator,
    discounts,
    setDiscounts,
    description,
    imageId,
  });
  const openLinkGenerator = !!(
    linkGenerator && discounts?.find((item) => item?.itemId === product?.id)
  );
  const incrementValue = (product) => {
    if (product?.sellableQty === 0) {
      if (product)
        window.GZAnalytics.customLog({
          name: "Cart Item",
          eventtype: "outOfStock",
          payload: { product: product },
        });
      setWarning("Currently out of stock");
      setTimeout(() => {
        setWarning("");
      }, 2000);
    } else if (product?.quantity === product?.sellableQty) {
      if (product)
        window.GZAnalytics.customLog({
          name: "Cart Item",
          eventtype: "maxQuantity",
          payload: { product: product },
        });
      setWarning("Maximum available quantity reached");
      setTimeout(() => {
        setWarning("");
      }, 2000);
    } else {
      const newProduct = {
        ...product,
        quantity: (product?.quantity || 0) + 1,
      };
      const discount = getProductDiscount(discountObj, newProduct);
      if (product)
        window.GZAnalytics.customLog({
          name: "Cart Items",
          eventtype: "added",
          payload: { product: product, discount: discount },
        });
      const temp = cartItems?.map((item) => {
        if (item?.id === product?.id) {
          return {
            ...newProduct,
            discount: Number(discount),
          };
        } else return item;
      });

      setCartItems([...temp]);
    }
  };

  useEffect(() => {
    findImage();
  }, []);

  const findImage = async () => {
    const imageIdArray = getImageArray(imageId);
    if (!!imageIdArray && imageIdArray.length) {
      const imageByteArray = imageIdArray[0];
      if (imageByteArray) {
        setImage(imageByteArray);
      }
    }
  };

  const decrementValue = (product) => {
    if (product?.quantity > 0) {
      const newProduct = {
        ...product,
        quantity: (product?.quantity || 0) - 1,
      };
      const discount = getProductDiscount(discountObj, newProduct);
      const temp = cartItems?.map((item) => {
        if (item?.id === product?.id) {
          return {
            ...newProduct,
            discount: Number(discount),
          };
        } else return item;
      });

      setCartItems([...temp]);
    }
  };

  return (
    <div className="w-full  flex flex-col justify-start" key={product?.ROWID}>
      <div
        className={`-m-2 p-2 md:p-0 
         ${
           (product?.quantity || 0) === 0
             ? "flex flex-col md:flex-row sm:m-2 "
             : "flex flex-col md:flex-row sm:m-2  bg-[#fbe9c5]"
         }`}
        key={product?.ROWID}
      >
        <div
          className={`md:w-2/3 ${
            !linkGenerator ? "md:my-4" : ""
          }     md:order-2 flex-col`}
        >
          <div
            className={` flex flex-row  ${
              !linkGenerator ? "md:py-4" : ""
            } py-2  justify-center items-center`}
          >
            <div
              className={`w-6/7 md:w-1/2 m-2${
                !linkGenerator ? "md:py-4" : ""
              } py-2`}
            >
              <div className="flex justify-between">
                <h5 className="text-lg mb-1">{product?.name}</h5>
              </div>
              <p className="align-middle whitespace-pre-line text-[15px] ">
                {description}
              </p>
            </div>
            <div className="md:w-1/2 md:my-4 py-2">
              <Incrementor
                product={product}
                onIncrease={() => incrementValue(product)}
                onDecrease={() => decrementValue(product)}
                warning={warning}
              />
            </div>
          </div>
          <div className=" w-full flex justify-center md:pb-4">
            <LinkGeneratorComponent
              showElements={openLinkGenerator}
              product={product}
              setDiscounts={setDiscounts}
              discounts={discounts}
            />
          </div>
        </div>

        <div className="md:w-1/3  sm:gap-2 flex flex-col justify-center relative items-center md:order-1 mb-5  sm:mb-3">
          <div className="relative">
            {/* <>
              {image ? (
        <ImageWithAuth url={`/browser/image/${image}`} />
      ) : (  */}

            <img
              src={image ? `/browser/image/${image}` : placeHolder}
              alt={product?.name}
              className="mx-auto    transition-all duration-500 ease-out hover:scale-125 lg:w-[150px] md:w-[120px] w-[160px]"
              style={{ height: "auto" }}
            />
            {/* )}
            </> */}

            <div className="absolute    right-[43%] text-purple-800 font-bold text-sm -mb-1 sm:mb-3">
              ${product?.unitPrice}
            </div>
          </div>

          {linkGenerator && (
            <div
              className={`flex justify-end w-full absolute bottom-6 right-5 ${
                openLinkGenerator ? "pb-3" : ""
              } `}
            >
              {!openLinkGenerator && (
                <Tooltip title="Add Discount">
                  <AddCircleIcon
                    style={{ color: "purple" }}
                    color="white"
                    onClick={() => {
                      setDiscounts((old) => [
                        ...old,
                        {
                          itemId: product?.id,
                          name: product?.name,
                          percent: true,
                          type: "total",
                        },
                      ]);
                    }}
                  />
                </Tooltip>
              )}
              {openLinkGenerator && (
                <Tooltip title="Remove Discount">
                  <RemoveCircleIcon
                    style={{ color: "purple" }}
                    color="white"
                    onClick={() => {
                      setDiscounts((old) =>
                        old.filter((x) => x?.itemId !== product?.id)
                      );
                    }}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  cartItems: PropTypes.array.isRequired,
  setCartItems: PropTypes.func.isRequired,
  discountObj: PropTypes.array,
  linkGenerator: PropTypes.bool,
  discounts: PropTypes.array,
  setDiscounts: PropTypes.func,
  description: PropTypes.string,
  imageId: PropTypes.string,
};

export default ProductCard;
