import axios from "axios";

const updateEquipmentOrder = () => {
  const updateOrder = async ({
    orderId,
    orderStatus,
    approvedBy,
    invoiceId,
  }) => {
    try {
      const res = await axios.post(
        `/browser/updateEquipmentOrder`,
        {
          orderId,
          orderStatus,
          approvedBy,
          invoiceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res?.data;
    } catch (err) {
      console.log("Something went wrong");
      return err;
    }
  };

  return { updateOrder };
};

export default updateEquipmentOrder;
