/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import IFrameComponent from "../../document/components/IFrameComponent";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { COMPLIMENTARY } from "../../document/const";
import { ORDERSTATUS } from "../../../../../../functions/sales_marketing_function/utils/constants";
import useDispatchEquipmentOrder from "../../../hooks/useDispatchEquipmentOrder";
import PlacingEquipmentOrder from "./PlacingOrderPage";
import Header from "../components/Header";
import CustomMenu from "../../document/components/CustomMenu";
import LoggedInUser from "../../document/components/LoggedInUser";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getItemFromSession } from "../utils";
import updateEquipmentOrder from "../hooks/updateEquipmentOrder";
import useComplimentaryPaymentAdmins from "../hooks/usePaymentAdmins";
import axios from "axios";
import { fetchEquipmentOrderDetail } from "../../../utils/signAgreementDocument";
import Spinner from "../../../components/Spinner";
import { getStateAbbreviation } from "../../../utils/USStates";

function PlaceEquipmentOrder() {
  const navigate = useNavigate();
  const params = useParams();
  const { orderId } = params;
  const [user, setUser] = useState(null);
  const [preInvoice, setPreInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dispatchEquipmentOrder } = useDispatchEquipmentOrder();
  const { updateOrder } = updateEquipmentOrder();
  const [authPaymentAdmins, setAuthPaymentAdmins] = useState(null);
  const [taxRates, setTaxRates] = useState(null);

  // console.log({ orderId });
  const getAuthPaymentAdmins = async () => {
    try {
      const res = await axios.get("/browser/authPaymentAdmins", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAuthPaymentAdmins(res?.data);
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  const fetchTaxRates = async () => {
    try {
      const taxResponse = await axios.get("/browser/taxrates", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setTaxRates(taxResponse?.data);
    } catch (err) {
      console.log("Error occurred while getting taxes ", err);
    }
  };

  const getOrderDetail = async () => {
    setLoading(true);
    const orderDetail = await fetchEquipmentOrderDetail(orderId);
    approveOrder(orderDetail);
    setLoading(false);
  };

  useEffect(async () => {
    if (user?.id) {
      // console.log({ user });
      await getOrderDetail();
    }
  }, [orderId, user]);

  useEffect(async () => {
    let ifMounted = true;
    if (ifMounted) {
      const currentUser = getItemFromSession("user-s");
      if (currentUser?.id) {
        await getAuthPaymentAdmins();
        await fetchTaxRates();
        setUser(currentUser);
      }
    }
  }, []);

  const listener = {
    invoiceResponse: (status, invoiceData) => {
      // console.log({ status, invoiceData });
      if (status) {
        const invoiceId = invoiceData?.invoice?.id || invoiceData?.id;
        const paymentStatus =
          invoiceData?.invoice?.status || invoiceData?.status;
        dispatchOrder(invoiceId, paymentStatus);
      } else {
        toast.error("Payment failed. Error in creating invoice.", {
          toastId: "payment-error",
        });
        navigate("/app/error/store/p/failed");
      }
    },
  };
  const getApproverId = (authPaymentAdmins, user) => {
    const authUser = authPaymentAdmins?.find((item) => {
      // console.log({ user: user?.email, item: item?.email });
      if (item?.email === user?.email) {
        return item;
      }
    });
    return authUser?.ROWID;
  };
  const saveApproverId = async (orderDetail, user) => {
    const updatedOrder = {
      orderId: orderDetail?.orderId,
      approvedBy: getApproverId(authPaymentAdmins, user),
    };
    updateOrder(updatedOrder);
  };

  const getTaxRateByState = (state) => {
    if (!taxRates || !taxRates.length) return 0;
    return (
      taxRates?.find((item) => item?.state === getStateAbbreviation(state))
        ?.taxRate || 0
    );
  };

  function generateInvoice(orderDetail) {
    // console.log({ orderDetail });
    const user = getItemFromSession("user-s");
    const invoiceDetails = {
      userId: user?.id,
      paymentMethodId: Number(orderDetail?.paymentMethodId),
      items: orderDetail?.items.map((item) => {
        const { name, quantity, unitPrice } = item;
        return { label: name, quantity, price: unitPrice };
      }),
      currency: "USD",
      discountType: "a",
      discountValue: 0,
      taxPercentage: getTaxRateByState(orderDetail?.shippingAddressState),
      discountLabel:
        orderDetail?.paymentName === "complimentary"
          ? "complimentary"
          : "Orders discount",
      onlyInvoice: orderDetail?.paymentName === "complimentary" ? true : false,
    };
    // console.log("Generating Invoice ", invoiceDetails);
    return invoiceDetails;
  }
  const approveOrder = async (order) => {
    const user = getItemFromSession("user-s");
    // console.log({ order, user });
    const paymentStatus = order?.paymentStatus;
    const invoiceId = order?.invoiceId || order?.invoice?.id;
    const isDispachOrder =
      paymentStatus === "paid" || paymentStatus === "pending" ? true : false;
    const orderStatus = order?.orderStatus;
    if (orderStatus === ORDERSTATUS.PENDING) {
      if (invoiceId && isDispachOrder) {
        await dispatchOrder(invoiceId, paymentStatus);
        await saveApproverId(order, user);
      } else if (!invoiceId) {
        const invoiceDetails = generateInvoice(order);
        setPreInvoice({
          send: true,
          type: "generateInvoice",
          postMessage: {
            from: "orders-growthzilla",
            createInvoice: true,
            invoiceData: invoiceDetails,
          },
          src: getEnvironmentValue().ORIGIN,
        });
        //send to generate invoice
      } else {
        const paymentName = order?.paymentName;
        if (paymentName === COMPLIMENTARY) {
          await dispatchOrder(invoiceId, paymentStatus);
        } else {
          const invoiceDetails = {
            userId: user?.id,
            invoiceId: order?.invoiceId || order?.invoice?.id,
            paymentMethodId: Number(order?.paymentMethodId),
          };
          console.log({
            charge: "Charging data",
            invoiceDetails: invoiceDetails,
          });
          setPreInvoice({
            send: true,
            type: "chargeInvoice",
            postMessage: {
              from: "orders-growthzilla",
              chargeInvoice: true,
              invoiceData: invoiceDetails,
            },
            src: getEnvironmentValue().ORIGIN,
          });
        }
      }
    }
  };

  async function dispatchOrder(invoiceId, paymentStatus) {
    const pendingOrder = JSON.parse(sessionStorage.getItem("order"));
    const dispatchedOrderRes = await dispatchEquipmentOrder({
      recipientName: pendingOrder?.businessReprName,
      recipientAddress: {
        shippingAddress1: pendingOrder?.shippingAddress1,
        shippingAddress2: pendingOrder?.shippingAddress2,
        shippingAddressCity: pendingOrder?.shippingAddressCity,
        shippingAddressState: pendingOrder?.shippingAddressState,
        shippingAddressZipCode: pendingOrder?.shippingAddressZipCode,
        businessName: pendingOrder?.businessName,
      },
      recipientEmail: pendingOrder?.businessReprEmail,
      recipientPhone: pendingOrder?.businessReprPhone,
      products: pendingOrder?.items,
      referenceId: `STO:${pendingOrder?.orderId}-GZ-INVOICE:${invoiceId}`,
      paymentStatus: paymentStatus,
      invoiceId: invoiceId,
      approvedBy: getApproverId(authPaymentAdmins, user),
    });
    const dispatchedOrderStatus =
      dispatchedOrderRes?.data?.dispatchedOrderStatus;
    const providerId = dispatchedOrderStatus?.shipbobId;
    const emailStatus = dispatchedOrderStatus?.emailStatus;

    if (providerId && emailStatus) {
      toast.success("Order dispatched successfully", {
        toastId: "order-dispatched-success",
      });
      // const pendingOrders = navigate("/app/store/pendingOrders");
      // const tempPending = pendingOrders?.filter(
      //   (item) => item?.orderId !== pendingOrder?.orderId
      // );
      // sessionStorage.setItem("pendingOrders", JSON.stringify(tempPending));
      // window.open(
      //   `${window.location.protocol}//${window.location.host}/app/store/pendingOrders`,
      //   "_self"
      // );

      navigate("/app/store/pendingOrders");
    } else {
      toast.error("Error while dispatching order", {
        toastId: "error-dispatch-order",
      });
      navigate("/app/error/store/p/failed");
    }
  }

  return (
    <div className="w-screen flex flex-col   h-screen">
      <div className=" flex-col flex  justify-center   w-full items-center ">
        <div className=" flex justify-center sm:w-4/5  w-full">
          <div className="w-full">
            <Header />
          </div>
          <div className="h-full items-center text-[#4D1F70] text-3xl sm:text-4xl top-3 right-9">
            {user?.id && (
              <CustomMenu
                menuIcon={
                  <AccountCircleIcon color="inherit" fontSize="inherit" />
                }
                menuContent={<LoggedInUser user={user} />}
              />
            )}{" "}
          </div>
        </div>
        <div style={{ display: "none" }}>
          <IFrameComponent
            listener={listener}
            messageToIFrame={preInvoice}
            iFrameSrc={getEnvironmentValue().ORIGIN}
          />
        </div>
        <div className="flex flex-col sm:py-3  mb-3 rounded-xl w-full h-full items-center justify-center">
          <PlacingEquipmentOrder />
        </div>
      </div>
    </div>
  );
}
export default PlaceEquipmentOrder;
