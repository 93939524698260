import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Send, X } from "react-feather";
import CreatableSelect from "react-select/creatable";
import { H5 } from "../../document/components/CustomForm";

const ComplimentaryPayment = ({
  complimentaryPayment,
  setComplimentaryPayment,
  cartItems,
  setPaymentData,
  setShowAdminModal,
  totalPrice,
  user,
  showPaymentWindow,
  setLoading,
  differentShippingAddress,
}) => {
  const formData = JSON.parse(sessionStorage.getItem("formdata_s_" + user?.id));

  const [reason, setReason] = useState(null);

  return (
    <div
      className="h-fit sm:w-[600px]   scrollbar-hide  text-[#5b5963]  text-sm p-2"
      style={{
        fontFamily: '"Montserrat",Helvetica,Arial,serif',
        backgroundColor: "white",
      }}
    >
      {complimentaryPayment ? (
        <div className=" sm:m-8 font-sans h-fit sm:p-7 block">
          <div className="flex flex-col">
            <div className="flex justify-end ">
              <X
                className="cursor-pointer"
                onClick={() => {
                  window.GZAnalytics &&
                    window.GZAnalytics.customLog({
                      name: "Cross Button_Complimentary",
                      eventtype: "clicked",
                    });
                  setShowAdminModal(false);
                  setComplimentaryPayment(false);
                }}
              />
            </div>
            <H5 className="capitalize text-gray-900 text-2xl text-center leading-tight font-bold mb-4">
              Confirm your approval request
            </H5>

            <div className="ml-5 gp-2 flex flex-col mb-4">
              <H5 className="text-base  capitalize mb-2 font-semibold">
                Requester/Business
              </H5>
              <div className="flex flex-col gap-1">
                <div>
                  <span className="font-semibold ">Business Name: </span>
                  <span>
                    {formData?.selectedBusiness?.business?.name ||
                      formData?.selectedBusiness?.name}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">Business Address: </span>
                  <span>
                    {formData?.selectedBusiness?.business?.address ||
                      formData?.selectedBusiness?.address}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">City: </span>
                  <span>
                    {formData?.selectedBusiness?.business?.city ||
                      formData?.selectedBusiness?.city}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">State: </span>
                  <span>
                    {formData?.selectedBusiness?.business?.state ||
                      formData?.selectedBusiness?.state}
                  </span>
                </div>
                <div>
                  <span className="font-semibold">Shipping Address1: </span>
                  <span>
                    {!differentShippingAddress
                      ? formData?.selectedBusiness?.business?.address ||
                        formData?.selectedBusiness?.address
                      : formData?.shippingAddress1}
                  </span>
                </div>
                {formData?.shippingAddress2 && (
                  <div>
                    <span className="font-semibold">Shipping Address2: </span>
                    <span>{formData?.shippingAddress2}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="ml-5 mb-4">
              <H5 className="text-base  capitalize mb-2 font-semibold">
                Items
              </H5>
              <ul>
                {cartItems
                  ?.filter((product) => product?.quantity >= 1)
                  .map((product, index) => {
                    const { id, name, unitPrice, quantity } = product;
                    return (
                      <li key={id}>
                        {index + 1} Name:{" "}
                        <span className="font-bold">{name}</span>, Quantity:
                        <span className="font-bold">{quantity}</span> ,
                        UnitPrice: ${unitPrice}
                      </li>
                    );
                  })}
              </ul>
              <hr />
              <p className="font-semibold mt-2">{`Total Price: $${totalPrice}`}</p>
            </div>
            <div className="ml-5 mb-4">
              <H5 className="text-base  capitalize mb-2 font-semibold">
                Complimentary Reason
              </H5>
              <CreatableSelect
                isClearable
                required
                isMulti
                placeholder="Type to add new"
                options={[
                  { value: "Damage", label: "Damage" },
                  { value: "Malfunction", label: "Malfunction" },
                ]}
                onChange={(value) => {
                  sessionStorage.setItem(
                    "complimentaryReason",
                    JSON.stringify(value)
                  );
                  window.GZAnalytics &&
                    window.GZAnalytics.customLog({
                      name: "Complimentary Reason",
                      eventtype: "selected",
                      payload: { complimentaryValue: value },
                    });
                  setReason(value);
                }}
              />
            </div>
            {(!reason || reason.length === 0) && (
              <p className="ml-5 font-semibold text-red-500">
                Please select a reason
              </p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <Button
              className="mb-4 flex flex-row justify-center items-center p-2 rounded-sm  mt-10 font-semibold cursor-pointer hover:shadow-lg"
              disabled={
                !sessionStorage.getItem("complimentaryReason") ||
                sessionStorage.getItem("complimentaryReason") === "[]"
              }
              onClick={() => {
                setPaymentData({
                  name: "complimentary",
                });
                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "Send Button-complimentary",
                    eventtype: "clicked",
                  });
                setShowAdminModal(false);
                setLoading(true);
              }}
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
              }}
            >
              Send
              <Send size="15" className="ml-2" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-white m-8 font-sans h-[90%] p-7 flex flex-col">
          <div className="flex justify-end">
            <X
              className="cursor-pointer "
              onClick={() => {
                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "Cross Button_SelectPayment",
                    eventtype: "clicked",
                  });
                setShowAdminModal(false);
              }}
            />
          </div>
          <h6 className="capitalize text-gray-900 text-xl sm:text-2xl text-center leading-tight font-bold mb-8">
            Select Payment Strategy
          </h6>

          <div className=" flex flex-col justify-center items-center text-lg text-center gap-4">
            <Button
              className="mb-4 p-2 border-[1px] rounded-sm w-[15rem] cursor-pointer font-semibold hover:shadow-lg"
              onClick={() => {
                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "PaymentMethod-Complimentary",
                    eventtype: "selected",
                  });
                setComplimentaryPayment(true);
              }}
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
              }}
            >
              Complimentary Payment
            </Button>
            <Button
              className="mb-4 p-2 border-[1px] rounded-sm w-[15rem] cursor-pointer font-semibold hover:shadow-lg"
              onClick={() => {
                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "PaymentMethod-Others",
                    eventtype: "selected",
                  });
                setShowAdminModal(false);
                showPaymentWindow();
              }}
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
              }}
            >
              Other
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ComplimentaryPayment.propTypes = {
  complimentaryPayment: PropTypes.bool.isRequired,
  setComplimentaryPayment: PropTypes.func.isRequired,
  cartItems: PropTypes.array.isRequired,
  setPaymentData: PropTypes.func.isRequired,
  setShowAdminModal: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  showPaymentWindow: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  differentShippingAddress: PropTypes.bool,
};
export default ComplimentaryPayment;
