import React, { useContext, useState } from "react";
import ReactSwitch from "react-switch";
import SmartCheckout from "../../../assets/images/a11.png";
import SwitchContext from "../context";
import ReactTooltip from "react-tooltip";
import { Alert } from "@mui/material";

const ThirdPage = () => {
  const { openValues, toggleSwitch, isOutOfStock, isUpperLimitReached } =
    useContext(SwitchContext);
  const [warning, setWarning] = useState("");
  return (
    <div>
      <div className="mb-3 flex justify-end">
        {warning && (
          <div className="flex justify-center items-center h-[0.1rem] m-6">
            <Alert variant="filled" severity="warning">
              {warning}
            </Alert>
          </div>
        )}
        <div className="flex flex-col items-center gap-y-0.2">
          <span
            data-tip={
              openValues.Computer === 0 && openValues.Kiozzk === 0
                ? "Cashier Device must be selected before to select Smart Checkout."
                : undefined
            }
          ></span>
        </div>
      </div>
      <div className="block md:flex md:justify-center md:items-center">
        <div className="w-full md:w-3/5">
          <p className="test text-xl md:text-3xl capitalize mb-4 font-medium">
            3. Smart Checkout
          </p>
          <div className="pl-0 md:pl-8 text-paragraph">
            <p className="mb-4 leading-relaxed">
              Uzeli SmartCheckout client facing payment terminal significantly
              upgrades check out experience for clients and helps you:
            </p>
            <ul className="ml-1" style={{ listStyleType: "disc" }}>
              <li>
                Minimize error by showing clients their line items and total
                before payment
              </li>
              <li>
                Prevent cash shorting by staff by displaying the items rung up
                on the POS to clients
              </li>
              <li>Let clients use Apple Pay, Google Pay or Tap cards</li>
              <li>Collect tips input and client feedback</li>
            </ul>
            <p></p>
            <p className="leading-relaxed">
              Recommended for businesses that accept credit cards.
            </p>
          </div>
        </div>
        <div className="w-full md:w-2/5 flex flex-col justify-center">
          <img src={SmartCheckout} alt="" />
          <div className="flex items-center gap-4 w-full justify-center pt-6">
            <div className="text-xs">
              {openValues.SmartCheckout === 1
                ? "Click to unselect"
                : "Click to select"}
            </div>
            <ReactSwitch
              onChange={() => {
                if (isOutOfStock("Smart Checkout")) {
                  setWarning("Currently out of stock");
                  setTimeout(() => {
                    setWarning("");
                  }, 2000);
                  return;
                } else if (isUpperLimitReached("Smart Checkout")) {
                  setWarning("Maximum quantity reached");
                  setTimeout(() => {
                    setWarning("");
                  }, 2000);
                  return;
                }
                toggleSwitch("SmartCheckout");
                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "Cart Items",
                    eventtype: "changed",
                    payload: {
                      SmartCheckout: !openValues.SmartCheckout,
                    },
                  });
              }}
              checked={openValues.SmartCheckout === 1}
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={openValues.Computer === 0 && openValues.Kiozzk === 0}
            />
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};

export default ThirdPage;
