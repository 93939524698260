import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useEquipmentInventory from "./useEquipmentInventory";
import { getImageArray } from "../utils";
const useEquipments = () => {
  const [loading, setLoading] = useState(true);
  const [inventory, setInventory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [cartItems, setCartItems] = useState([]);

  const { getEquipmentInventory } = useEquipmentInventory();

  const incrementPage = () => setCurrentPage(currentPage + 1);
  const decrementPage = () => setCurrentPage(currentPage - 1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInventory = async () => {
      const data = await getEquipmentInventory();
      if (data?.length) {
        setInventory(data);
        console.log({ data });
        setCartItems(
          data?.map((item) => {
            return {
              name: item.name,
              id: item.ROWID,
              providerAssignedId: item.providerAssignedId,
              unitPrice: Number(item.unitPrice),
              sellableQty: Number(item.sellableQuantity),
              imageid: getImageArray(item?.imageId),
            };
          })
        );
        setLoading(false);
      } else {
        navigate("/app/formFailed");
      }
    };

    fetchInventory();
  }, []);

  // const equipmentPrices = useMemo(() => {
  //   return inventory?.reduce((acc, item) => {
  //     acc[item?.name] = item?.unitPrice;
  //     return acc;
  //   }, {});
  // }, [inventory]);

  const getTotalPrice = () => {
    let totalPrice = cartItems?.reduce((acc, item) => {
      return acc + (item.quantity || 0) * item?.unitPrice;
    }, 0);

    return totalPrice.toFixed(2);
  };

  const getTotalDiscountPrice = () => {
    // console.log("Cart Items", cartItems);
    let totalDiscount = cartItems?.reduce((acc, item) => {
      return acc + (item.discount || 0);
    }, 0);
    // console.log({ totalDiscount });
    return totalDiscount.toFixed(2);
  };

  return {
    loading,
    currentPage,
    incrementPage,
    decrementPage,
    inventory,
    cartItems,
    setCartItems,
    getTotalPrice,
    getTotalDiscountPrice,

    // equipmentPrices,
  };
};

export default useEquipments;
