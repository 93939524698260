import axios from "axios";

const useDispatchEquipmentOrder = () => {
  // used for both equipment only order and uzeli order for setup devices
  const dispatchEquipmentOrder = async ({
    recipientName,
    recipientAddress: {
      shippingAddress1,
      shippingAddress2,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressZipCode,
      businessName,
    },
    recipientEmail,
    recipientPhone,
    products,
    referenceId,
    invoiceId,
    paymentStatus,
    approvedBy,
  }) => {
    try {
      const res = await axios.post(
        `/browser/dispatchEquipmentOrder`,
        {
          recipientName: recipientName,
          recipientAddress: {
            shippingAddress1,
            shippingAddress2,
            shippingAddressCity,
            shippingAddressState,
            shippingAddressZipCode,
            businessName,
          },
          recipientEmail,
          recipientPhone,
          products,
          referenceId,
          paymentStatus,
          approvedBy,
          invoiceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res?.data;
    } catch (err) {
      console.log("Something went wrong");
      return err;
    }
  };

  return { dispatchEquipmentOrder };
};

export default useDispatchEquipmentOrder;
