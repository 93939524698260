import PropTypes from "prop-types";
import React from "react";

const Card = ({ children }) => (
  <div className="step-form p-4 md:p-8 border rounded-lg bg-white">
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
