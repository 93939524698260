module.exports = Object.freeze({
  ORDERSTATUS: {
    PENDING: "pending",
    APPROVED: "approved",
    CANCELLED: "cancelled",
  },

  UZELIORDERS: "UzeliOrders",
  EQUIPMENTORDERS: "EquipmentOrders",
  UzeliItems: "UzeliItems",
  ContactPhone: "08616518-7889-4553-9e04-378f9d3f3660",
  ContactName: "18247a58-9b7e-41d7-9da2-7b20d3ac70cb",
  BusinessName: "26b15a2a-36e9-4eaa-a142-f6af938178e9",
  Equipments: "e37ea089-c998-424d-ba4c-701a2f5ebe07",
  Stage: "35a13389-cc75-4d0a-848c-08a30bc6414a",
  BusinessStreetAddress: "8121377e-d6c2-47a0-806c-a0617b47e32b",
  Website: "02e15c53-28bb-4404-8ee9-8d3eb7ca5841",
  BusinessLegalName: "2d6518fc-7ebe-4c09-8d9c-9a77ff9125da",
  BusinessCityStateZip: "62febfa0-04a9-4303-80b4-12abd4b29c7e",
  UzeliPlan: "3af63927-53ed-469d-befd-d1b176660b8e",
  Phone: "818e52ff-b860-4384-b08b-90ec22aeb448",
  UzeliPlan: "3af63927-53ed-469d-befd-d1b176660b8e",
  Email: "ec7abd94-f353-4fff-9358-3728856e535e",
  ServiceStartDate: "e2cef666-7880-4e85-8060-26fb04f9f1e3",
  CurrentSoftware: "0d71abf1-7ec7-4443-ac46-7c39fa274aa5",
  YearsInBusiness: "9e52b0f7-c463-4ca9-8027-174363bee1ff",
  EquipmentPrice: "65f84cd9-0f14-4b54-ac61-07ee30a93706",
  EquipmentDiscount: "d153b01e-23f0-46e5-a1b1-89c192ad3a74",
  BusinessAddress2: "4cb90946-bfce-4f74-8b3b-5a39d78c4057",
  ShippingAddress: "6c83f837-c91d-45c6-804c-dda538443a88",
  LeadId: "90590df6-c592-4415-a627-e6371d1faead",
  Installments: "4ad16905-23ec-4400-916b-ba907d69a5a0",
  InstallmentThreshold: "15370170-48fb-42e1-bc66-0f71e89369d4",
  PermanentDiscount: "fd82c693-15e0-4d84-bfa5-f29ca9a698e6",
  TemporaryDiscount: "cf4faf71-69fb-4000-a0b9-2cf3fb0f0e5b",
  TemporaryDiscountExpiryDate: "0a5022b3-2f90-4d99-8aab-dc7b67228661",
  UzeliPlans: {
    "Smart Checkout": "",
    "Uzeli Duo": "12ecddb7-c510-4a1a-b7cc-999100234665",
    "Uzeli Solo + Smart Checkout": "9accb1c3-d214-459e-ade5-6ca07b45bbf2",
    "Uzeli Solo": "9cb44ec3-5ab1-476b-8e8a-8f5ba4db2c57",
    "Uzeli Duo + Smart Checkout": "07fb8fbf-863b-4d49-ba4d-d12f698028db",
    "Uzeli Pro -3 Devices": "858fdc19-f4aa-4e65-84d9-e2c61e935a10",
    "Uzeli Pro -4 Devices": "2ac7600d-4b41-4bf6-80e3-451e4ac6bc48",
    "Uzeli Pro -5 Devices": "1e729ad0-8dc5-4fa8-a0ee-2c943a341266",
    "Uzeli Pro -6 Devices": "08595043-ce62-438d-9b86-d455cc4789c9",
    "Uzeli Pro -7 Devices": "a5c683ad-adbf-4b58-a8b9-915754b2bcb6",
    "Uzeli Pro -8 Devices": "cfc8cb3b-f215-4bf4-82bf-276d68b58b4c",
    "Uzeli Pro -9 Devices": "7690ef60-7ab0-4473-9c32-fc604dbfa2f0",
    "Uzeli Pro -10 Devices": "64fd0926-3396-4b2c-a4c3-040fa09e4820",
    "Uzeli Pro -11 Devices": "1e671b1e-a315-423a-9419-0d65af8f9b6e",
    "Uzeli Pro -12 Devices": "9edee531-f88f-4004-8860-4d3f93fbcb64",
    "Uzeli Pro -13 Devices": "a90d4e16-6123-4441-8e3e-f5da30da86a9",
    "Uzeli Pro -14 Devices": "6af0bfe5-fb34-4652-9fd9-0b582fbe394c",
    "Uzeli Pro -15 Devices": "ecd9d73c-75db-4cba-a0ee-d5ec66548543",
    "Uzeli Pro -16 Devices": "9fbbbd3e-9d46-4b28-8ba2-347cc0bce887",
    "Uzeli Pro -17 Devices": "51dc2923-2721-4dc4-a098-0e9f34a1980a",
  },
  GrowthzillaSigners: {
    "Don Coppersmith": "a846aacb-cd64-4ab6-b00c-e1fc2c8aa73a",
    "Shishir Bashyal": "5a817755-93a4-49b8-a7a8-8683ef3979d7",
    "Bandana Paudel": "6eb05280-b732-403a-ac5e-f67b7a535422",
    "David Galvan": "7f306c53-8343-4521-8508-7b4246ee6575",
  },
  Stage: "35a13389-cc75-4d0a-848c-08a30bc6414a",
  Stages: {
    Deciding: "f6643eb9-c6fa-4d83-b0f9-cf2c609c9512",
    Agreement: "5f99acc2-1bd7-4654-85a8-c21d6090160b",
  },
  Phase1: "5TBdZZ8ri9f",
  Phase2: "mvHuZmMbrKu",
  SigneeEmail: "ce48f949-caae-46d2-b7dc-f26a919faf7e",
  PaymentMethod: "02a4df8e-1e17-4514-8632-966215beffd3",
  MonthlySMSQuota: "f2bbe542-0e7c-46b5-9c4b-e3cd365fd541",
  BillingStartDate: "15fc1de3-53a9-44c4-b45c-a89f1de95435",
  SigneeName: "ced82149-643b-4e60-aa02-dba5a1f4b0d2",
  SigneePhone: "08616518-7889-4553-9e04-378f9d3f3660",
  MerchantTerminal: "956deef7-ce73-492d-855b-7cab64f1c181",
  BusinessEmail: "707f70fb-3539-464f-90e0-6c54d6392632",
  BusinessPhoneNumber: "c7ab1791-a459-4093-a0ab-b5b5c2868e6d",
  GrowthzillaSignee: "b0a32d58-0cf5-4c32-b772-62a0baf83037",
  ARIES8: "9417000000245444",
  ARIES8_STAND: "9417000004440719",
  PAX_TERMINAL: "Pax Aries 8 Checkout Terminal",
  ARIES_STAND: "Aries8 Stand",
});
