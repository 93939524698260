import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import {
  fetchOrderDetail,
  getEmbeddedSigningUrl,
  getTransactionRef,
} from "../../../utils/signAgreementDocument";
import SignDocument from "../../document/components/SignDocument";
import Redirect from "../../../components/Redirect";
import IFrameComponent from "../../document/components/IFrameComponent";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
// import { getLeadId } from "../../../utils/isItemIdValid";

function EverSignDocument() {
  const params = useParams();
  const { from, orderId } = params;
  // console.log({ from, orderId });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [embeddedSigningUrl, setEmbeddedSigningUrl] = useState(null);
  const [user, setUser] = useState(null);
  const [showFrame, setShowFrame] = useState(true);
  const iframeSource = getEnvironmentValue().EMBED_LOGIN_URL;
  const [order, setOrder] = useState();

  const fetchUrl = async (from, count) => {
    if (count && count > 10)
      setError("Unable to load PDF this time. Please try again later.");
    try {
      const orderDetail = await fetchOrderDetail(orderId);
      setOrder({
        id: orderDetail?.leadId,
        businessName: orderDetail?.businessName,
        agent: from === "agent" ? true : false,
      });
      const transactionRef = await getTransactionRef(orderId);
      const agreementDetails = await getEmbeddedSigningUrl(transactionRef);
      // console.log({ agreementDetails });
      // const orderDetails = await getOrderDetailsFromHash(documentHash);
      const embeddedSigningUrl =
        from === "agent"
          ? agreementDetails?.embeddedSigningUrlAgent
          : agreementDetails?.embeddedSigningUrl;
      console.log("EMbedded Signing URL", embeddedSigningUrl);
      localStorage.setItem("orderId", orderId);
      localStorage.setItem("transaction_ref", agreementDetails?.transactionRef);
      // console.log("Signing Url", embeddedSigningUrl);
      if (embeddedSigningUrl) {
        setEmbeddedSigningUrl(embeddedSigningUrl);
        setLoading(false);
      } else {
        setTimeout(async () => {
          const newCount = (count || 0) + 1;
          await fetchUrl(from, newCount);
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  };

  useEffect(async () => {
    const userFromSession = JSON.parse(sessionStorage.getItem("user"));
    if (userFromSession?.id) {
      setUser(userFromSession);
      setShowFrame(false);
      // console.log("User", userFromSession);
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      setLoading(true);
      fetchUrl(from, 0);
    }
  }, [user]);

  const listener = {
    loginResponse: (user) => {
      setUser(user);
      sessionStorage.setItem("user", JSON.stringify(user));
      setShowFrame(false);
    },
  };
  const displayIframeStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  return showFrame ? (
    <div
      className="h-full"
      style={showFrame ? displayIframeStyle : hiddenIframeStyle}
    >
      <IFrameComponent
        listener={listener}
        // messageToIFrame={preInvoice}
        iFrameSrc={iframeSource}
      />
    </div>
  ) : loading ? (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-[100vh] flex justify-center items-center">
      <Spinner
        loading={true}
        message={"Please wait while agreement document is being prepared"}
      />
    </div>
  ) : error ? (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      Error
    </div>
  ) : embeddedSigningUrl && !loading && !showFrame && user?.id ? (
    <SignDocument
      embeddedSigningUrl={embeddedSigningUrl}
      fromPending={false}
      leadData={order}
    />
  ) : (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      <Redirect message="Preparing your agreement document, please wait..." />
    </div>
  );
}
export default EverSignDocument;
