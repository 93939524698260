import { Navigate, Route } from "react-router-dom";
import isLoggedIn from "./isLoggedIn";
import PropTypes from "prop-types";
import React from "react";

const ProtectedRoute = ({ path, element }) => {
  return (
    <Route
      path={path}
      element={isLoggedIn() ? element : <Navigate to="/app/unauthorized" />}
    />
  );
};

ProtectedRoute.propTypes = {
  path: PropTypes.any.isRequired,
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
