import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../../../assets/json/successlottie.json";

function PlacingEquipmentOrder() {
  return (
    <div className="flex  h-full w-full justify-center items-center  mx-2">
      <div className="block sm:py-3 py-6 px-10 rounded-xl bg-white ">
        <div className="text-center text-xl sm:text-4xl mt-3 mb-5 font-bold">
          Please Wait!
        </div>
        {/* <div className="flex justify-center">
          <Check color="#00ba37" size={65} strokeWidth={3} />
        </div> */}

        {/* <h5 className="text-gray-900 text-2xl text-center leading-tight font-bold mb-2">
          Thank You
        </h5> */}
        <p className="text-gray-700 text-sm sm:text-lg text-center mb-4">
          Please wait while I prepare your order, please do not close the
          browser yet.
        </p>
        <div className="flex justify-center">
          <Lottie
            className="w-20 sm:w-36 md:w-44 h-full"
            loop
            animationData={lottieJson}
            play
            style={{ width: 240, height: 350 }}
          />
        </div>
      </div>
    </div>
  );
}
export default PlacingEquipmentOrder;
