import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const CustomButton = styled.button`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: auto;
  margin: 0.2rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgba(55, 22, 80, var(--tw-text-opacity));
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(55, 22, 80, var(--tw-border-opacity));
  &:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 22, 80, var(--tw-bg-opacity));
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
`;

const HeaderButton = ({ label, type, price, plan, discount }) => {
  // console.log({ label, price, discount });
  const isPrice = type === "price";
  const applyDiscount = price > 0 && discount > 0;
  return (
    <div className="flex flex-col w-1/3 gap-1">
      {label}
      <CustomButton>
        {isPrice ? <span className="font-bold">$</span> : <> </>}
        {isPrice ? (
          applyDiscount ? (
            <>
              <span className="font-bold line-through line-through-2 text-[#cccccc]">
                {price}
              </span>{" "}
              <span className="font-bold">
                {Number(price) - Number(discount)}
              </span>
            </>
          ) : (
            <span className="font-bold">{price}</span>
          )
        ) : (
          <span className="font-extrabold">{plan}</span>
        )}
      </CustomButton>
    </div>
  );
};

HeaderButton.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["price", "text"]),
  price: PropTypes.number,
  discount: PropTypes.number,
  plan: PropTypes.string,
};
HeaderButton.defaultProps = {
  type: null,
  price: null,
  plan: null,
};

export default HeaderButton;
