import React, { useContext, useEffect } from "react";
import Spinner from "../../../components/Spinner";
import AuthContext from "../context";

const NewUser = () => {
  const { leadId } = useContext(AuthContext);
  useEffect(() => {
    localStorage.setItem("leadId", leadId);
    const redirectUrl = `${window.location.protocol}//${window.location.host}/app/order?id=${leadId}`;
    window.location.href = redirectUrl;
  }, [leadId]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default NewUser;
