import React, { useEffect, useState } from "react";
// import { Check } from "react-feather";
import Lottie from "react-lottie-player";
import { toast } from "react-toastify";
import lottieJson from "../../../assets/json/successlottie.json";
import { useNavigate, useParams } from "react-router-dom";
import IFrameComponent from "../components/IFrameComponent";
import { getStateAbbreviation } from "../../../utils/USStates";
import {
  fetchOrderDetail,
  fetchTaxRates,
} from "../../../utils/signAgreementDocument";

import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import useDispatchOrder from "../../../hooks/useDispatchOrder";
import { clearSessionStorage } from "../../../utils/clearSessionStorage";
import { ORDERSTATUS } from "../../../../../../functions/sales_marketing_function/utils/constants";
// import Header from "../../shoppingsite/components/Header";

// import CustomMenu from "../components/CustomMenu";

import updateUzeliOrder from "../hooks/updateUzeliOrder";
// import User from "../components/User";
import TopNavBar from "../../shoppingsite/components/TopNavBar";
import { updateLeadActivity } from "../../../hooks/updateLeadActivity";
// import { getLeadId } from "../../../utils/isItemIdValid";

const OrderStatus = () => {
  const params = useParams();
  const { orderId, init } = params;
  const navigate = useNavigate();
  const [preInvoice, setPreInvoice] = useState(null);
  const [iframeSrc, setIframeSrc] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );
  const [user, setUser] = useState(null);
  const [showFrame, setShowFrame] = useState(true);
  const [dispatching, setDispatching] = useState(false);
  const { dispatchOrder } = useDispatchOrder();
  const { updateOrder } = updateUzeliOrder();
  const getOrderId = () => {
    const localItemRef = localStorage.getItem("orderId");
    const uzeliOrderId = orderId ? orderId : localItemRef;
    return uzeliOrderId;
  };

  useEffect(() => {
    const placeOrder = async () => {
      if (user?.id) {
        setDispatching(true);
        await afterSignedCallback(getOrderId());
      }
    };

    placeOrder();
  }, [user]);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    if (currentUser?.id) {
      setShowFrame(false);
      setUser(currentUser);
    }
  }, []);

  const listener = {
    loginResponse: (user) => {
      setUser(user);
      setShowFrame(false);
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    loadedResponse: (status) => {
      if (status) {
        console.log("IFrame Loaded");
      }
    },
    invoiceResponse: (status, invoiceData) => {
      console.log("Invoice Response from IFrame", invoiceData);
      const transactionRef = getOrderId();
      const orderDetail = getOrderInSession(transactionRef);
      if (status) {
        const invoiceId = invoiceData?.invoice?.id;

        const paymentStatus = invoiceData?.invoice?.status;
        // console.log({ invoiceId, paymentStatus });
        if (invoiceId) {
          const paymentLeadActivity = {
            id: orderDetail?.leadId,
            businessName: orderDetail?.businessName,
            leadActivity: "Payment Successful",
          };
          updateLeadActivity(paymentLeadActivity);
          dispatchOrders(invoiceId, paymentStatus);
        } else {
          setDispatching(false);
          const paymentLeadActivity = {
            id: orderDetail?.leadId,
            businessName: orderDetail?.businessName,
            leadActivity: "Payment Error",
          };
          updateLeadActivity(paymentLeadActivity);
          toast.error("Error while generating Invoice", {
            toastId: "payment-error",
          });
          if (init === "p") {
            navigate(`/app/error/order/p/failed`);
          } else {
            navigate(`/app/error/store/n/failed`);
          }
        }
      } else {
        // console.log("Invoice Response from IFrame", invoiceData);
        const paymentLeadActivity = {
          id: orderDetail?.leadId,
          businessName: orderDetail?.businessName,
          leadActivity: "Payment Error",
        };
        updateLeadActivity(paymentLeadActivity);
        toast.error(invoiceData, {
          toastId: "payment-error",
        });
        if (init === "p") {
          navigate(`/app/error/order/p/failed`);
        } else {
          navigate(`/app/error/order/e/failed`);
        }
      }
      setShowFrame(false);
    },
  };
  const getTaxRateByState = async (state) => {
    const taxRates = await fetchTaxRates();
    return (
      taxRates?.find((item) => item?.state === getStateAbbreviation(state))
        ?.taxRate || 0
    );
  };

  async function generateInvoice(orderDetail) {
    const totalPrice = orderDetail?.items?.reduce(
      (acc, item) => acc + Number(item?.unitPrice) * Number(item?.quantity),
      0
    );
    const userId = JSON.parse(orderDetail?.uzeliUser ?? "{}")?.id;

    // minimum hardware fee is $299. Implementation fee should be added when
    // total price is less than $299(without discount)
    const implementationFee =
      totalPrice > 0 && totalPrice < 299 ? 299 - totalPrice : 0;

    const finalizedEquipDiscount =
      totalPrice + implementationFee > orderDetail?.equipDiscount
        ? orderDetail?.equipDiscount
        : 0;

    const invoiceDetails = {
      paymentMethodId: Number(orderDetail?.paymentMethodId),
      items:
        orderDetail &&
        orderDetail.items?.map((item) => {
          const { name, unitPrice, quantity } = item;
          return { label: name, quantity, price: unitPrice };
        }),
      currency: "USD",
      taxPercentage: await getTaxRateByState(orderDetail?.shippingAddressState),
      discountType: "a",
      discountValue: finalizedEquipDiscount,
      implementationFee: implementationFee,
      discountLabel: finalizedEquipDiscount > 0 ? "Setup fee discount" : "",
      installments:
        Number(orderDetail?.installments ?? 0) > 1
          ? Number(orderDetail?.installments)
          : 1,
      title: `One Time Setup Fee (${orderDetail?.subscribedPlan}): ${orderDetail?.businessName}`,
      onlyInvoice: false,
      invoiceId: orderDetail?.invoice?.id,
      userId: userId,
    };
    console.log("Invoice Details", invoiceDetails);
    return invoiceDetails;
  }

  useEffect(() => {
    //clears session storage if the borwser is reloaded
    clearSessionStorage();
  }, []);

  const setOrderInSession = (orderDetail, transactionRef) => {
    sessionStorage.setItem(
      `orderId of + ${transactionRef}`,
      JSON.stringify(orderDetail)
    );
  };
  const getOrderInSession = (transactionRef) => {
    return JSON.parse(sessionStorage.getItem(`orderId of + ${transactionRef}`));
  };

  async function orderEquipment(transactionRef) {
    // console.log(user, transactionRef);
    if (user?.id) {
      const orderDetail = getOrderInSession(transactionRef);
      const invoiceId = orderDetail?.invoice?.id;
      const paymentStatus = orderDetail?.invoice?.paymentStatus;
      //pending or paused status can automatically tu
      if (invoiceId && paymentStatus === "paid") {
        console.log("Invoice already generated");
        dispatchOrders(invoiceId, paymentStatus);
      } else if (!invoiceId) {
        const invoice = await generateInvoice(orderDetail);
        setPreInvoice({
          send: true,
          type: "generateInvoice",
          postMessage: {
            from: "orders-growthzilla",
            createInvoice: true,
            invoiceData: invoice,
          },
          src: getEnvironmentValue().ORIGIN,
        });
      } else {
        const invoiceData = {
          invoiceId: invoiceId,
          paymentMethodId: orderDetail?.paymentMethodId,
        };

        setPreInvoice({
          send: true,
          type: "chargeInvoice",
          postMessage: {
            from: "orders-growthzilla",
            chargeInvoice: true,
            invoiceData: invoiceData,
          },
          src: getEnvironmentValue().ORIGIN,
        });
      }
    }
  }
  const saveApproverId = async (orderDetail, user) => {
    const updatedOrder = {
      orderId: orderDetail?.orderId,
      approvedBy: user?.email,
    };
    updateOrder(updatedOrder);
  };

  // console.log("Details", orderDetail);

  const navigateToPendingUzeliOrders = async (orderId) => {
    // console.log("Navigating to pending orders");
    const pendingOrders = JSON.parse(
      sessionStorage.getItem("pendingOrders") || null
    );
    // console.log("Pending Orders", pendingOrders);

    const updatedOrders =
      pendingOrders?.length &&
      pendingOrders?.filter((item) => item?.orderId !== orderId);
    sessionStorage.setItem(
      "pendingOrders",
      JSON.stringify(updatedOrders || {})
    );
    navigate("/app/uzeli/pendingOrders");
  };

  const setGZAnalyticsForOrder = async (leadId) => {
    // console.log("Setting leadId in logs");
    window.GZAnalytics.setCustomConstants({
      userDetail: leadId,
    });
  };

  async function afterSignedCallback(transactionRef) {
    const orderDetail = await fetchOrderDetail(transactionRef);
    // console.log("Order Details", orderDetail, user);
    await setGZAnalyticsForOrder(orderDetail?.leadId);
    setOrderInSession(orderDetail, transactionRef);
    if (orderDetail?.orderStatus === ORDERSTATUS.PENDING) {
      const signStatus = orderDetail?.signingStatus?.data;
      const isSignedByGrowthzilla = signStatus.signedByGrowthzilla;
      const isSignedByBusiness = signStatus.signedByBusiness;

      // console.log({ isSignedByBusiness, isSignedByGrowthzilla });
      if (isSignedByGrowthzilla && isSignedByBusiness) {
        console.log("Signed By both Parties");
        const bothSignedLeadActivity = {
          id: orderDetail?.leadId,
          businessName: orderDetail?.businessName,
          leadActivity: "Agreement Completed",
        };
        updateLeadActivity(bothSignedLeadActivity);
        if (!orderDetail?.approvedBy) {
          await saveApproverId(orderDetail, user);
        }

        await orderEquipment(transactionRef);
      } else if (isSignedByBusiness && !isSignedByGrowthzilla) {
        console.log("CUSTOMER HAS SIGNED");
        navigate(`/app/ordersuccess/${getOrderId()}/waiting1`);
      } else if (!isSignedByBusiness && isSignedByGrowthzilla) {
        console.log("AGENT HAS SIGNED");
        await saveApproverId(orderDetail, user);
        if (init == "p") {
          navigateToPendingUzeliOrders(orderDetail?.orderId);
        } else {
          navigate(`/app/ordersuccess/${getOrderId()}/waiting2`);
        }
      }
    } else if (orderDetail?.orderStatus === ORDERSTATUS.APPROVED) {
      console.log("Order already approved ");
      toast.success("Order already approved", {
        toastId: "order-approved-success",
      });
      if (init === "n") {
        navigate(`/app/ordersuccess/${getOrderId()}/approved`);
      } else if (init === "p") {
        navigateToPendingUzeliOrders(orderDetail?.orderId);
      }
    } else if (orderDetail?.orderStatus === ORDERSTATUS.CANCELLED) {
      console.log("Order is cancelled ");
      toast.error("Order is cancelled", {
        toastId: "order-cancelled-error",
      });
      navigate("/app/error/order/cancelled");
    } else {
      if (init === "p") {
        navigate(`/app/error/order/p/failed`);
      } else {
        navigate(`/app/error/store/e/failed`);
      }
    }
  }

  async function dispatchOrders(invoiceId, paymentStatus) {
    let dispatchedOrder;
    const uzeliOrderDetails = getOrderInSession(getOrderId());
    const providerStatus = uzeliOrderDetails?.providerId;
    const emailStatus = uzeliOrderDetails?.emailStatus;
    const uzeliOrderStatus = uzeliOrderDetails?.orderStatus;

    if (uzeliOrderStatus === ORDERSTATUS.APPROVED) {
      dispatchedOrder = {
        data: {
          dispatchedOrderStatus: {
            shipbobId: providerStatus,
            emailStatus: emailStatus,
          },
          message: "Order is approved",
          referenceId: `ORD:${uzeliOrderDetails?.orderId}-GZ-INVOICE:${invoiceId}`,
        },
      };
    } else {
      dispatchedOrder = await dispatchOrder({
        recipientName: uzeliOrderDetails?.businessReprName,
        recipientAddress: {
          shippingAddress1: uzeliOrderDetails?.shippingAddress1,
          shippingAddress2: uzeliOrderDetails?.shippingAddress2,
          shippingAddressCity: uzeliOrderDetails?.shippingAddressCity,
          shippingAddressState: uzeliOrderDetails?.shippingAddressState,
          shippingAddressZipCode: uzeliOrderDetails?.shippingAddressZipCode,
          businessName: uzeliOrderDetails?.businessName,
        },
        recipientEmail: uzeliOrderDetails?.businessReprEmail,
        recipientPhone: uzeliOrderDetails?.businessReprPhone,
        products: uzeliOrderDetails?.items,
        referenceId: `ORD:${uzeliOrderDetails?.orderId}-GZ-INVOICE:${invoiceId}`,
        paymentStatus: paymentStatus,
        invoiceId: invoiceId,
      });
    }
    const leadData = {
      id: uzeliOrderDetails?.leadId,
      businessName: uzeliOrderDetails?.businessName,
      leadActivity: "Order dispatched",
    };

    updateLeadActivity(leadData);
    // console.log("Dispatched Order", dispatchedOrder);
    const dispatchedOrderStatus = dispatchedOrder?.data?.dispatchedOrderStatus;
    const toastMessage = dispatchedOrder?.data?.message;
    if (
      dispatchedOrderStatus?.shipbobId &&
      dispatchedOrderStatus?.emailStatus
    ) {
      console.log("Order Placed");
      toast.success(toastMessage, {
        toastId: "order-approved-success",
      });

      if (init === "n") {
        navigate(`/app/ordersuccess/${getOrderId()}/finished`);
      } else if (init === "p") {
        navigateToPendingUzeliOrders(uzeliOrderDetails?.orderId);
      }
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Dispatch Order",
          eventtype: "dispatched",
        });
    } else {
      console.log("Error While dispatching order");
      toast.error(toastMessage, {
        toastId: "order-approve-failed",
      });
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Dispatch Order",
          eventtype: "error",
        });

      if (init === "p") {
        navigate(`/app/error/order/p/failed`);
      } else {
        navigate(`/app/error/store/e/failed`);
      }
    }
  }
  const displayIframeStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };
  const switchUser = () => {
    setShowFrame(true);
    setIframeSrc(getEnvironmentValue().EMBED_LOGIN_URL);
  };

  return (
    <div className="flex-col flex w-screen h-[50vh] sm:h-[80vh]  ">
      <TopNavBar user={user} noCart={true} switchUserCallBack={switchUser} />

      {
        <div
          style={
            showFrame && !dispatching ? displayIframeStyle : hiddenIframeStyle
          }
        >
          <IFrameComponent
            listener={listener}
            messageToIFrame={preInvoice}
            iFrameSrc={iframeSrc}
          />
        </div>
      }

      {!showFrame && dispatching && (
        <div className="flex mt-10 flex-col sm:py-3 py-6 mb-3 rounded-xl w-full h-full items-center justify-center">
          <div className="text-center text-xl sm:text-4xl mb-3  font-bold">
            Please Wait!
          </div>
          <p className="text-gray-700 w-3/5 text-sm sm:text-lg text-center mb-4">
            Please wait while I prepare your order, please do not close the
            browser yet.
          </p>
          <div className="flex justify-center">
            <Lottie
              className="w-28 sm:w-36 md:w-44 h-full"
              loop
              animationData={lottieJson}
              play
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(OrderStatus);
