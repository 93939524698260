import React, { useContext } from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../components/CustomModal";
import SwitchContext from "../context";

const AcceptCartDailog = ({ content, isOpen, closeDetail, loading }) => {
  // console.log({ content, isOpen, closeDetail });
  const { salesAgent } = useContext(SwitchContext);
  //   if(salesAgent===""){}
  //   let isOpen = true;
  const titleText = loading
    ? "Loading Recommendation"
    : !salesAgent
    ? "Recommended Configuration"
    : `Configuration Recommended by ${salesAgent}`;
  return (
    <div className="random">
      <CustomModal
        content={content}
        title={titleText}
        isOpen={isOpen}
        closeModal={closeDetail}
      />
    </div>
  );
};

AcceptCartDailog.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  closeDetail: PropTypes.func,
};

export default AcceptCartDailog;
