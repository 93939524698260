import React from "react";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import PropTypes from "prop-types";

const Toggle = (props) => {
  const { enabled, setEnabled } = props;

  return (
    <div>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? "bg-slate-500" : "bg-slate-300"}
          relative inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 `}
      >
        {/* <span className="sr-only">Use setting</span> */}
        <span
          aria-hidden="true"
          className={`${enabled ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out `}
        />
      </Switch>
    </div>
  );
};
Toggle.propTypes = {
  enabled: PropTypes.bool,
  setEnabled: PropTypes.func,
};
const useToggle = (props) => {
  const [enabled, setEnabled] = useState(!!props?.initialValue);

  return {
    setEnabled,
    enabled,
    Toggle: (
      <Toggle
        enabled={enabled}
        setEnabled={(value) => {
          setEnabled(value);
          if (value) props.onEnable();
          else props.onDisable();
        }}
      />
    ),
  };
};
useToggle.propTypes = {
  initialValue: PropTypes.bool,
  onEnable: PropTypes.func,
  onDisable: PropTypes.func,
};

export default useToggle;
