import { useState } from "react";
import { CURRENT_SOFTWARES } from "../const";
import { getStateAbbreviation } from "../../../utils/USStates";
import { itemFromSession, saveItemsToSession } from "../../shoppingsite/utils";
import { useLocation } from "react-router-dom";
// import { fetchOrderDetail } from "../../../utils/signAgreementDocument";

const useUpdateItem = ({ onSuccess, onFailure }) => {
  // const itemId = "666c354b-bf1e-452e-81c3-39646413c822";
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { search } = location;
  const updateItem = async ({
    data,
    monthlyFee,
    setUpFee,
    formattedItems,
    user,
    itemId,
    equipment,
    uzeliPlan,
    totalSmartCheckout,
    equipDiscount,
    billingNote,
    installments,
    minimumForInstallments,
    loggedIn,
    leadId,
  }) => {
    setLoading(true);
    // console.log("From Inside Update item ");
    const id = itemFromSession("uzeliOrderId");
    // console.log({ id, bool: Object.keys(id).length > 0 });
    const uzeliOrderId =
      id || id !== "undefined" || Object.keys(id).length > 0 ? id : "";

    // if search
    // console.log({ location });
    // console.log({ uzeliOrderId, itemId });
    if (itemId) {
      try {
        let url = `/browser/uzeliOrder/items/${itemId}`;
        if (search === "?test") {
          console.log("From Test");
          url = `/browser/uzeliOrder/items/${itemId}?test=true`;
        }
        // if (!uzeliOrderId) {
        const response = await fetch(url, {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",

            "Content-Type": "application/json",
          }),
          cache: "no-cache",
          body: JSON.stringify({
            userId: user,
            businessName: data.businessName,
            contactName: data.contactName,
            businessPhoneNumber: data.businessPhoneNumber,
            businessEmail: data.businessEmail,
            uzeliPlanType: uzeliPlan,
            equipment,
            totalSmartCheckout,
            equipmentPrice: setUpFee,
            monthlyFee: monthlyFee,
            businessWebsite: data.businessWebsite,
            businessAddress: data.businessAddress,
            businessUnitOrSuite: data.businessUnitOrSuite,
            businessCity: data.businessCity,
            businessState: getStateAbbreviation(data.businessState),
            businessZip: data.businessZip,
            contactNumber: data.contactNumber,
            shippingAddress1: data.shippingAddress1,
            shippingAddress2: data.shippingAddress2,
            shippingAddressCity: data.shippingAddressCity,
            shippingAddressState: getStateAbbreviation(
              data.shippingAddressState
            ),
            shippingAddressZip: data.shippingAddressZip,
            email: data.contactEmail,
            formattedItem: formattedItems,
            software:
              data.currentSoftware + 1 === CURRENT_SOFTWARES.length
                ? data.otherSoftware
                : CURRENT_SOFTWARES[data.currentSoftware],
            location: data.location === 4 ? 5 : data.location,
            startDate: data.startDate,
            equipDiscount: equipDiscount,
            billingNote: billingNote,
            installments: installments,
            minimumForInstallments: minimumForInstallments,
            uzeliUser: loggedIn,
            leadId: leadId,
            uzeliOrderId: uzeliOrderId,
          }),
        });
        const resJson = await response.json();
        // console.log({ resJson });
        setLoading(false);
        console.log("Putting Order");
        if (resJson && resJson["success"]) {
          saveItemsToSession("uzeliOrderId", resJson.oid);
          onSuccess(resJson, data);
        } else {
          onFailure();
        }
        // }
        // else {
        //   try {
        //     setLoading(false);
        //     const itemId = getItemFromSession("itemId");
        //     const response = {
        //       oid: uzeliOrderId,
        //       data: { id: itemId },
        //     };
        //     console.log({ response });
        //     onSuccess(response, data);
        //   } catch (err) {
        //     console.log("Error while creating uzeli order", err);
        //     setLoading(false);
        //     onFailure();
        //   }
        // }
      } catch (err) {
        console.log(err);
        setLoading(false);
        onFailure();
      }
    } else {
      setLoading(false);
      throw new Error("No Item Id");
    }
  };
  return { loading, updateItem };
};

export default useUpdateItem;
