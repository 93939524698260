import axios from "axios";
import { useEffect, useState } from "react";

// authorized complimentary payment approvers (admins)
const useComplimentaryPaymentAdmins = () => {
  const [authPaymentAdmins, setAuthPaymentAdmins] = useState(null);

  useEffect(() => {
    const getAuthPaymentAdmins = async () => {
      try {
        const res = await axios.get("/browser/authPaymentAdmins", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setAuthPaymentAdmins(res?.data);
      } catch (err) {
        console.log("Something went wrong");
      }
    };
    getAuthPaymentAdmins();
  }, []);

  return { authPaymentAdmins };
};

export default useComplimentaryPaymentAdmins;
