import React, { useContext, useEffect, useState } from "react";
import a11 from "../../../assets/images/a11.png";
import a22 from "../../../assets/images/a22.png";
import a33 from "../../../assets/images/a33.png";
import a44 from "../../../assets/images/a44.png";
import a55 from "../../../assets/images/a55.png";
import Incrementor from "../components/Incrementor";
import SwitchContext from "../context";
import Computer from "../../../assets/images/mac-uzeli-left1.png";
import StandWarning from "../components/StandWarning";
import StandWarningDialog from "../components/StandWarningDialog";

const SixthPage = () => {
  const {
    openAdditionalValues,
    setAdditionalValues,
    equipmentsFilledFromOrder,
    equipmentPrice,
    isOutOfStock,
    openValues,
    isUpperLimitReached,
    nextButtonClicked,
  } = useContext(SwitchContext);
  // console.log({
  //   price: equipmentPrice.AriesStand,
  //   value: openAdditionalValues,
  // });
  const [kiozzkWarning, setKiozzkWarning] = useState("");
  const [smartCheckoutWarning, setSmartCheckoutWarning] = useState("");
  const [computerWarning, setComputerWarning] = useState("");
  const [receiptPrinterWarning, setReceiptPrinterWarning] = useState("");
  const [smallCashDrawerWarning, setSmallCashDrawerWarning] = useState("");
  const [bigCashDrawerWarning, setBigCashDrawerWarning] = useState("");
  const [ariesStandWarning, setAriesStandWarning] = useState("");

  const [standWarning, setStandWarning] = useState(false);

  const closeStandWarning = () => {
    setStandWarning(false);
  };

  useEffect(() => {
    const hasCheckoutTerminal =
      openValues?.SmartCheckout > 0 || openAdditionalValues?.SmartCheckout > 0;
    const hasAriesStand = openAdditionalValues?.AriesStand > 0;
    // console.log({
    //   nextButtonClicked,
    //   openAdditionalValues,
    //   openValues,
    // });
    if (nextButtonClicked && hasCheckoutTerminal && !hasAriesStand) {
      setStandWarning(true);
      // setCurrentPage(6);
    }
  }, [
    nextButtonClicked,
    openValues?.SmartCheckout,
    openAdditionalValues?.SmartCheckout,
    openAdditionalValues?.AriesStand,
  ]);
  const incrementValue = (text) => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Cart Items",
        eventtype: "changed",
        payload: {
          [text]: openAdditionalValues[text] + 1,
        },
      });
    setAdditionalValues({
      ...openAdditionalValues,
      [text]: openAdditionalValues[text] + 1,
    });
  };

  // const closeStandWarning = () => {};

  const decrementValue = (text) => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Cart Items",
        eventtype: "changed",
        payload: {
          [text]: openAdditionalValues[text] - 1,
        },
      });
    if (openAdditionalValues[text] > 0)
      setAdditionalValues({
        ...openAdditionalValues,
        [text]: openAdditionalValues[text] - 1,
      });
  };

  return (
    <div>
      <div className="w-full lg:mr-auto mb-[4rem]">
        <p className="text-xl md:text-3xl capitalize mb-1 font-medium">
          {equipmentsFilledFromOrder
            ? "6. Review Your Equipments"
            : "6. Additional Equipments"}
        </p>
        <div className="pl-0 md:pl-8 text-paragraph">
          <p className="mb-1 leading-relaxed">
            Uzeli supports the use of multiple devices for check-in, POS and
            checkout as needed. If you want additional devices on top of what
            you selected in the previous steps, please add them below.
          </p>
        </div>
      </div>
      <div
        className={`h-full items-center ${
          openAdditionalValues.Kiozzk > 0
            ? "flex bg-[#fbe9c5] my-2"
            : "flex my-2"
        }`}
      >
        <div className="md:w-1/3 ">
          <img
            src={a22}
            alt="Growthzilla Kiozzk Tablet"
            className="mx-auto  my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>

        <div className="md:w-1/3 md:my-4 py-2">
          <h5 className="text-lg mb-1">Growthzilla Kiozzk Tablet</h5>
          <p className="text-paragraph text-[15px] ">
            If you want additional check-in kiosks or POS stations, get more
            Kiozzk tablets.
          </p>
        </div>
        <div className="md:w-1/3 md:my-4 py-2">
          <Incrementor
            value={openAdditionalValues.Kiozzk}
            price={equipmentPrice.Kiozzk}
            onIncrease={() => {
              if (isOutOfStock("Kiozzk")) {
                setKiozzkWarning("Currently out of stock");
                setTimeout(() => {
                  setKiozzkWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Kiozzk")) {
                setKiozzkWarning("Maximum quantity reached");
                setTimeout(() => {
                  setKiozzkWarning("");
                }, 2000);
                return;
              }
              incrementValue("Kiozzk");
            }}
            onDecrease={() => decrementValue("Kiozzk")}
            warning={kiozzkWarning}
          />
        </div>
      </div>

      <div
        className={`h-full items-center ${
          openAdditionalValues.SmartCheckout > 0
            ? "flex bg-[#fbe9c5]  my-2"
            : "flex my-2"
        }`}
      >
        <div className="md:w-1/3 ">
          <img
            src={a11}
            alt=""
            className="mx-auto  my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Pax Aries 8 Checkout Terminal</h5>
          <p className="text-paragraph text-[15px]">
            If you want additional client-facing credit card SmartCheckout
            terminal, update the count below.
          </p>
        </div>

        <div className="md:w-1/3 my-4 py-2">
          <Incrementor
            value={openAdditionalValues.SmartCheckout}
            price={equipmentPrice.SmartCheckout}
            onIncrease={() => {
              if (isOutOfStock("Smart Checkout")) {
                setSmartCheckoutWarning("Currently out of stock");
                setTimeout(() => {
                  setSmartCheckoutWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Smart Checkout")) {
                setSmartCheckoutWarning("Maximum quantity reached");
                setTimeout(() => {
                  setSmartCheckoutWarning("");
                }, 2000);
                return;
              }
              incrementValue("SmartCheckout");
            }}
            onDecrease={() => decrementValue("SmartCheckout")}
            warning={smartCheckoutWarning}
          />
        </div>
      </div>

      <div
        className={`h-full items-center ${
          openAdditionalValues.ReceiptPrinter > 0
            ? "flex bg-[#fbe9c5] my-2"
            : "flex my-2"
        }`}
      >
        <div className="md:w-1/3">
          <img
            src={a33}
            alt=""
            className="mx-auto w-2/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Growthzilla Cloud Receipt Printer</h5>
          <p className="text-paragraph text-[15px]">
            If you want multiple-checkout stations, you may want to add
            additional receipt printers below.
          </p>
        </div>

        <div className="md:w-1/3 my-4 py-2">
          <Incrementor
            value={openAdditionalValues.ReceiptPrinter}
            price={equipmentPrice.ReceiptPrinter}
            onIncrease={() => {
              if (isOutOfStock("Receipt Printer")) {
                setReceiptPrinterWarning("Currently out of stock");
                setTimeout(() => {
                  setReceiptPrinterWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Receipt Printer")) {
                setReceiptPrinterWarning("Maximum quantity reached");
                setTimeout(() => {
                  setReceiptPrinterWarning("");
                }, 2000);
                return;
              }
              incrementValue("ReceiptPrinter");
            }}
            onDecrease={() => decrementValue("ReceiptPrinter")}
            warning={receiptPrinterWarning}
          />
        </div>
      </div>

      <div
        className={`h-full items-center ${
          openAdditionalValues.Computer > 0
            ? "flex bg-[#fbe9c5] my-2"
            : "flex my-2"
        }`}
      >
        <div className="md:w-1/3">
          <img
            src={Computer}
            alt=""
            className="mx-auto w-2/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Bring Your Own Desktop</h5>
          <p className="text-paragraph text-[15px]">
            If you want to run Uzeli on additional desktop computers (bring your
            own), increase the count below.
          </p>
        </div>

        <div className="md:w-1/3 my-4 py-2">
          <Incrementor
            value={openAdditionalValues.Computer}
            price={equipmentPrice.Computer}
            onIncrease={() => {
              if (isOutOfStock("Computer")) {
                setComputerWarning("Currently out of stock");
                setTimeout(() => {
                  setComputerWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Computer")) {
                setComputerWarning("Maximum quantity reached");
                setTimeout(() => {
                  setComputerWarning("");
                }, 2000);
                return;
              }
              incrementValue("Computer");
            }}
            onDecrease={() => decrementValue("Computer")}
            warning={computerWarning}
          />
        </div>
      </div>

      <div
        className={`h-full items-center ${
          openAdditionalValues.CashdrawerSmall > 0
            ? "flex bg-[#fbe9c5] my-2"
            : "flex my-2"
        }`}
      >
        <div className="md:w-1/3">
          <img
            src={a44}
            alt=""
            className="mx-auto w-2/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Small Cash Drawer</h5>
          <p className="text-paragraph text-[15px]">
            If you want multiple-checkout stations taking cash, add receipt
            printers below.
          </p>
        </div>

        <div className="md:w-1/3  my-4 py-2">
          <Incrementor
            value={openAdditionalValues.CashdrawerSmall}
            price={equipmentPrice.CashdrawerSmall}
            onIncrease={() => {
              if (isOutOfStock('Cash Drawer Small(13")')) {
                setSmallCashDrawerWarning("Currently out of stock");
                setTimeout(() => {
                  setSmallCashDrawerWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached('Cash Drawer Small(13")')) {
                setSmallCashDrawerWarning("Maximum quantity reached");
                setTimeout(() => {
                  setSmallCashDrawerWarning("");
                }, 2000);
                return;
              }
              incrementValue("CashdrawerSmall");
            }}
            onDecrease={() => decrementValue("CashdrawerSmall")}
            warning={smallCashDrawerWarning}
          />
        </div>
      </div>

      <div
        className={`h-full items-center ${
          openAdditionalValues.CashdrawerBig > 0
            ? "flex bg-[#fbe9c5] mt-2"
            : "flex mt-2"
        }`}
      >
        <div className="md:w-1/3">
          <img
            src={a44}
            alt=""
            className="mx-auto w-2/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Large Cash Drawer</h5>
          <p className="text-paragraph text-[15px]">
            If you want multiple-checkout stations taking cash, add receipt
            printers below.
          </p>
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <Incrementor
            value={openAdditionalValues.CashdrawerBig}
            price={equipmentPrice.CashdrawerBig}
            onIncrease={() => {
              if (isOutOfStock('Cash Drawer Big(16")')) {
                setBigCashDrawerWarning("Currently out of stock");
                setTimeout(() => {
                  setBigCashDrawerWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached('Cash Drawer Big(16")')) {
                setBigCashDrawerWarning("Maximum quantity reached");
                setTimeout(() => {
                  setBigCashDrawerWarning("");
                }, 2000);
                return;
              }
              incrementValue("CashdrawerBig");
            }}
            onDecrease={() => decrementValue("CashdrawerBig")}
            warning={bigCashDrawerWarning}
          />
        </div>
      </div>
      <div
        className={`h-full items-center ${
          openAdditionalValues.AriesStand > 0
            ? "flex bg-[#fbe9c5] mt-2"
            : "flex mt-2"
        }`}
      >
        <div className="md:w-1/3">
          <img
            src={a55}
            alt=""
            className="mx-auto w-2/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "140px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <h5 className="text-lg mb-1">Aries Stand</h5>
          <p className="text-paragraph text-[15px]">
            This stand is an essential tool for your Smart Checkout. It holds
            the Smart Checkout in place and provides a convenient way for the
            clients to pay their bills.
          </p>
        </div>
        <div className="md:w-1/3 my-4 py-2">
          <Incrementor
            value={openAdditionalValues.AriesStand}
            price={equipmentPrice.AriesStand}
            onIncrease={() => {
              if (isOutOfStock("Aries Stand")) {
                setAriesStandWarning("Currently out of stock");
                setTimeout(() => {
                  setAriesStandWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Aries Stand")) {
                setAriesStandWarning("Maximum quantity reached");
                setTimeout(() => {
                  setAriesStandWarning("");
                }, 2000);
                return;
              }
              incrementValue("AriesStand");
            }}
            onDecrease={() => decrementValue("AriesStand")}
            warning={ariesStandWarning}
          />
        </div>
      </div>
      <>
        {standWarning && (
          <StandWarningDialog
            content={
              <div className="w-[full%] h-full ">
                <StandWarning />
              </div>
            }
            isOpen={standWarning}
            closeDetail={closeStandWarning}
          />
        )}
      </>
    </div>
  );
};

export default SixthPage;
