/* eslint-disable no-unused-vars */
import cryptoJs from "crypto-js";

import { getEnvironmentValue } from "./getEnvironmentValues";
import {
  EMPTY_DISCOUNT_OBJECT,
  INVALID_DISCOUNT_OBJECT,
  NO_PARAMS,
  SUCCESSFULL,
  discountObjectValue,
} from "../views/document/const";
import { getSearchParams } from "./isItemIdValid";
import { useCallback } from "react";
import axios from "axios";
import { getItemFromSession } from "../views/shoppingsite/utils";
// Import CryptoJS

// Define a secret key and IV (Initialization Vector)
const secretKey = cryptoJs.enc.Hex.parse(getEnvironmentValue().ENCRYPT_KEY);
const iv = cryptoJs.enc.Hex.parse("abcdef9876543210abcdef9876543210");

export async function getShortUrl(data) {
  const url = encodeURIComponent(data);
  try {
    const res = await axios.get(`/browser/shortenUrl`, {
      params: {
        url: url,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res?.data;
  } catch (err) {
    console.log("Something went wrong");
    return null;
  }
}

const getDiscountObject = (searchObj) => {
  const discount = searchObj?.d;
  if (discount === null || !discount) return null;
  return discount;
};

export const encrypt = (data) => {
  const encrypted = cryptoJs.AES.encrypt(data, secretKey, {
    iv: iv,
    mode: cryptoJs.mode.CFB,
    padding: cryptoJs.pad.Pkcs7,
  });
  return encrypted.toString();
};

// Decrypt the message
export const decrypt = (data) => {
  if (!data) return null;
  const decrypted = cryptoJs.AES.decrypt(data, secretKey, {
    iv: iv,
    mode: cryptoJs.mode.CFB,
    padding: cryptoJs.pad.Pkcs7,
  });
  return decrypted.toString(cryptoJs.enc.Utf8);
};

export const decryptDiscountObject = () => {
  return discountObjectValue;
};

const calculateDiscount = (discount, item) => {
  let discountPrice = 0;

  if ((item?.quantity || 0) > discount?.qtyThreshold) {
    if (discount?.type === "total") {
      const grossAmount = Number(item?.unitPrice) * Number(item?.quantity);
      if (discount?.percent) {
        const percent = Number(discount?.discount / 100);

        discountPrice = percent * grossAmount;
      } else {
        discountPrice = discount?.discount;
      }
    } else if (discount?.type === "each") {
      let discountModifiedPrice;
      if (discount?.percent) {
        discountModifiedPrice =
          Number(discount?.discount / 100) * item?.unitPrice;
      } else {
        discountModifiedPrice = Number(discount?.discount);
      }
      discountPrice = Number(discountModifiedPrice) * (item?.quantity || 0);
    }
  } else {
    return 0;
  }
  return discountPrice > 0 ? Number(discountPrice).toFixed(2) : null;
};

export const checkIfUserIsWithBusiness = (bid, user) => {
  const adminBusiness = getItemFromSession(`admin-${user?.id}`);
  // console.log({ adminBusiness });
  return adminBusiness?.some((item) => item?.id === bid);
};

export const getProductDiscount = (discountObj, product) => {
  // console.log("discount for product ", discountObj);
  const discountForProduct = discountObj?.find(
    (item) => item?.itemId === product?.id
  );
  // console.log("here", discountForProduct, product);
  let discountAmount;
  if (discountForProduct?.itemId) {
    discountAmount = calculateDiscount(discountForProduct, product);
  } else {
    discountAmount = 0;
  }
  return discountAmount;
};

export const getDiscountObj = (search) => {
  if (!search) return null;
  const searchParams = getSearchParams(search);
  if (
    Object.keys(searchParams).length &&
    Object.keys(searchParams)[0] === "d"
  ) {
    const encryptedDiscountObj = getDiscountObject(searchParams);

    if (encryptedDiscountObj?.length) {
      const discountObjString = decrypt(encryptedDiscountObj);
      // console.log({ discountObjString });
      const discountObj = JSON.parse(discountObjString);
      // console.log({ discountObj });
      if (
        discountObj?.bid &&
        discountObj?.approvedBy?.id &&
        discountObj?.approvedBy?.name &&
        (discountObj?.discounts?.length || discountObj?.items?.length)
      ) {
        return {
          data: discountObj,
          message: SUCCESSFULL,
          status: true,
        };
      } else {
        return {
          message: INVALID_DISCOUNT_OBJECT,
          status: false,
        };
      }
    } else {
      return {
        message: EMPTY_DISCOUNT_OBJECT,
        status: false,
      };
    }
  } else {
    return {
      message: NO_PARAMS,
      status: false,
    };
  }
};
