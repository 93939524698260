import React, { useContext } from "react";

import PropTypes from "prop-types";
import SwitchContext from "../context";
// import { getImageIds } from "../../shoppingsite/utils";
// import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import styled from "styled-components";
function RecommendedDevices({
  initialCartItems,
  items,

  //   settLoading,
}) {
  //   console.log({ initialCartItems });
  let monthlyFee, planType, uzeliPlan;
  //   const navigate = useNavigate();

  const totalSmartCheckout = initialCartItems["Pax Aries 8 Checkout Terminal"];
  const totalKiozzkCount = initialCartItems["Growthzilla Kiozzk Tablet"];
  const totalComputer = initialCartItems["Computer"];

  const deviceCount = totalKiozzkCount + totalComputer + totalSmartCheckout;
  //   console.log({ deviceCount, planType });

  const CustomButton = styled.button`
   { padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0.2rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgba(55, 22, 80, var(--tw-text-opacity));
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(55, 22, 80, var(--tw-border-opacity));
    &:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(55, 22, 80, var(--tw-bg-opacity));
      color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
  `;
  switch (deviceCount) {
    case 0:
      monthlyFee = 0;
      planType = "Not selected";
      uzeliPlan = "Not selected";
      break;
    case 1:
      if (totalSmartCheckout === 1) {
        monthlyFee = 99;
        planType = "Smart Checkout";
        uzeliPlan = "Smart Checkout";
      } else {
        monthlyFee = 99;
        planType = "Uzeli Solo";
        uzeliPlan = "Uzeli Solo";
      }
      break;
    case 2:
      if (totalSmartCheckout === 0) {
        monthlyFee = 139;
        planType = "Uzeli Duo";
        uzeliPlan = "Uzeli Duo";
      } else if (totalKiozzkCount || totalComputer === 1) {
        monthlyFee = 139;
        planType = "Uzeli Solo + Smart Checkout";
        uzeliPlan = "Uzeli Solo + Smart Checkout";
      } else {
        monthlyFee = 139;
        planType = "Uzeli Duo + Smart Checkout";
        uzeliPlan = "Uzeli Duo + Smart Checkout";
      }
      break;
    case 3:
      if (totalSmartCheckout !== 0) {
        monthlyFee = 169;
        planType = "Uzeli Pro";
        uzeliPlan = "Uzeli Pro - 3 Devices";
      } else {
        monthlyFee = 169;
        planType = "Uzeli Duo + Smart Checkout";
        uzeliPlan = "Uzeli Duo + Smart Checkout";
      }
      break;
    case 4:
      monthlyFee = 189;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 4 Devices";
      break;
    case 5:
      monthlyFee = 199;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 5 Devices";
      break;
    case 6:
      monthlyFee = 209;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 6 Devices";
      break;
    case 7:
      monthlyFee = 219;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 7 Devices";
      break;
    case 8:
      monthlyFee = 229;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 8 Devices";
      break;
    case 9:
      monthlyFee = 239;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 9 Devices";
      break;
    case 10:
      monthlyFee = 249;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 10 Devices";
      break;
    case 11:
      monthlyFee = 259;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro -11 Devices";
      break;
    case 12:
      monthlyFee = 269;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 12 Devices";
      break;
    case 13:
      monthlyFee = 279;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 13 Devices";
      break;
    case 14:
      monthlyFee = 289;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro -14 Devices";
      break;
    case 15:
      monthlyFee = 299;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 15 Devices";
      break;
    case 16:
      monthlyFee = 309;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 16 Devices";
      break;
    case 17:
      monthlyFee = 319;
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro - 17 Devices";
      break;
    default:
      monthlyFee = 299;
      // eslint-disable-next-line no-unused-vars
      planType = "Uzeli Pro";
      uzeliPlan = "Uzeli Pro";
  }

  const productImage = (name) => {
    if (name === "Growthzilla Kiozzk Tablet") {
      return items?.find((item) => item?.name === "Kiozzk");
    } else if (name === "Pax Aries 8 Checkout Terminal") {
      return items?.find((item) => item?.name === "Smart Checkout");
    } else if (name === "Growthzilla Cloud Receipt Printer") {
      return items?.find((item) => item?.name === "Receipt Printer");
    } else if (name === "Small CashDrawer") {
      return items?.find((item) => item?.name === 'Cash Drawer Small(13")');
    } else if (name === "Large CashDrawer") {
      return items?.find((item) => item?.name === 'Cash Drawer Big(16")');
    } else if (name === "Computer") {
      return items?.find((item) => item?.name === "Computer");
    } else if (name === "Aries8/Aries6 Stand") {
      return items?.find((item) => item?.name === "Aries8/Aries6 Stand");
    }
  };

  //   useEffect(() => {
  //     fetchInventory();
  //   }, []);
  const totalAmount = Object.keys(initialCartItems).reduce((acc, item) => {
    // console.log({
    //   item,
    //   quantity: initialCartItems[item],
    //   price: productImage(item)?.unitPrice,
    // });
    const itemTotal =
      Number(productImage(item)?.unitPrice) * Number(initialCartItems[item]);
    // console.log({ itemTotal });
    return acc + itemTotal;
  }, 0);

  //   console.log({ items });
  //   const getItemName
  const { qualifyingEquipmentPrice, equipDiscount } = useContext(SwitchContext);
  const discountOnSetupFee = totalAmount >= qualifyingEquipmentPrice;
  //   console.log({
  //     uzeliPlan,
  //     planType,
  //     monthlyFee,
  //     qualifyingEquipmentPrice,
  //     equipDiscount,
  //   });
  const totalAfterDiscount = totalAmount - equipDiscount;
  //   console.log({ planType });
  return (
    <div className="w-full">
      <div className=" flex flex-col md:flex-row justify-evenly md:px-10 mb-5 gap-2">
        <CustomButton className="w-full md:[w-40%]">
          <div className="flex flex-col gap-1 ">
            <div className="text-sm">Uzeli Plan</div>
            {/* <br /> */}
            <div className="font-semibold">{uzeliPlan}</div>
          </div>
        </CustomButton>
        {/* <br /> */}
        <CustomButton className="w-full md:[w-40%]">
          <div className="flex flex-col   gap-1 ">
            <div className="text-sm">Plan Monthly Fee</div>
            <div className="font-semibold">${monthlyFee}</div>
          </div>
        </CustomButton>
        {/* <CustomButton>
          <div className="flex  gap-3 ">
            <div className="text-sm">Plan Type</div>
            <div className="font-semibold">{planType}</div>
          </div>
        </CustomButton> */}
      </div>
      <div className="w-full flex flex-col justify-center gap-3">
        {/* <h3 className="w-full flex justify-center">
          Your Growthzilla partner has offered you following equipment as part
          of package
        </h3> */}
        {initialCartItems && Object.keys(initialCartItems).length > 0 && (
          <div className="w-full md:px-5 lg:px-20  flex flex-col justify-center">
            <ol className="w-full">
              {Object.keys(initialCartItems).map((item, index) => {
                const product = {
                  name: item,
                  quantity: initialCartItems[item],
                  imageId: productImage(item)?.imageId,
                  price: productImage(item)?.unitPrice,
                };
                return (
                  <div key={item} className="flex w-full justify-center  ">
                    <ProductCard index={index} item={product} />
                  </div>
                );
              })}
            </ol>
            <div className="flex w-full justify-evenly font-semibold">
              <table className="w-full justify-center">
                <tbody>
                  <tr className="flex justify-center w-full  ">
                    <td className="w-[50%]">Total</td>
                    <td className="pl-[10%]">${totalAmount}</td>
                  </tr>
                  {discountOnSetupFee && (
                    <>
                      <tr className="flex justify-center w-full ">
                        <td className="w-[50%]">Discount</td>
                        <td className="pl-[10%]">
                          <span className="flex gap-1   ">
                            <div>-</div>
                            <div>${equipDiscount}</div>
                          </span>
                        </td>
                      </tr>
                      <tr className="flex w-full my-1 justify-center">
                        <td className="w-full">
                          <hr className="w-[90%] md:[w-80%] font-bold justify-center " />
                        </td>
                      </tr>
                      <tr className="flex justify-center capitalize w-full items-center ">
                        <td className="w-[50%] ">Total after discount</td>
                        <td className="pl-[10%]">${totalAfterDiscount}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
RecommendedDevices.propTypes = {
  initialCartItems: PropTypes.object,
  items: PropTypes.array,
};

export default RecommendedDevices;
