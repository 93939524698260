import React, { useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { encrypt, getShortUrl } from "../../../utils/discount";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";

import ShoppingSiteContext from "../context";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

function ClipboardComponent({ copyText }) {
  const [value, setValue] = useState(copyText);
  // console.log({ copyText });
  async function copyTextToClipboard(text) {
    // console.log({ text });
    if ("clipboard" in navigator) {
      // console.log("Clipboard is present ");
      return await navigator.clipboard.writeText(text);
    } else {
      console.log("Failed");
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(value)
      .then(() => {
        toast.success("Copied Successfully", {
          toastId: "copy-url-success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log("Copiedd", copied);

  return (
    <div>
      <div className="flex relative border-[2px] border-gray-400 rounded-md">
        <input
          type="text"
          className="w-11/12 text-left p-1 h-8 text-sm font-medium font-mono  outline-none"
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          cols="45"
        ></input>
        <button
          onClick={() => {
            handleCopyClick();
          }}
          className="border-l-[1px] border-black px-2 hover:shadow-xl"
        >
          copy
        </button>
      </div>
      <div className="absolute top-6 right-24">
        {/* <CopyToClipboard text={value} onCopy={() => onCopy()}> */}
        {/* <button>
          <Tooltip title={copied}>
            <div className="text-gray-400 hover:animate-pulse active:animate-ping">
              <ContentCopyIcon color="inherit" />
            </div>{" "}
          </Tooltip>
        </button> */}
        {/* </CopyToClipboard> */}
      </div>
    </div>
  );
}

ClipboardComponent.propTypes = {
  copyText: PropTypes.string,
  onClose: PropTypes.func,
};

export default function CopyDiscounts(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { cartItems } = useContext(ShoppingSiteContext);

  const itemsFromCart = cartItems
    ?.map((item) => {
      return {
        id: item?.id,
        quantity: item?.quantity,
      };
    })
    ?.filter((item) => item?.quantity);
  const data = {
    ...props?.data,
    items: itemsFromCart,
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openModal = async () => {
    // console.log({ data });
    const encryptedText = encrypt(JSON.stringify(data));
    const url = `${
      getEnvironmentValue().IS_DEV
        ? "https://sales-marketing-759987269.development.catalystserverless.com"
        : "https://orders.growthzilla.com"
    }/app/store?d=${encryptedText}`;
    setLoading(true);
    setIsOpen(true);
    const previousUrl = JSON.parse(sessionStorage.getItem(encryptedText));
    let shortenedUrl = "";
    if (previousUrl) {
      shortenedUrl = previousUrl;
    } else {
      shortenedUrl = await getShortUrl(url);
      sessionStorage.setItem(encryptedText, JSON.stringify(shortenedUrl));
    }
    // console.log({ shortenedUrl });
    if (shortenedUrl) {
      setValue(shortenedUrl);
    }
    setLoading(false);
  };

  return (
    <>
      <div className=" flex items-center justify-center">
        <button type="button" onClick={openModal} className="">
          Generate Link
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center backdrop-blur justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg w-full flex justify-center font-medium leading-6 text-gray-900 font-sans"
                  >
                    Copy to Clipboard
                  </Dialog.Title>

                  {loading ? (
                    <div className="h-20 flex flex-col w-full justify-center items-center">
                      <CircularProgress className="text-xs font-thin" />
                    </div>
                  ) : (
                    <div className="mt-4">
                      <ClipboardComponent
                        onClose={closeModal}
                        copyText={value ?? ""}
                      />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
CopyDiscounts.propTypes = {
  data: PropTypes.object,
};
