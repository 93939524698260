import React, { useEffect } from "react";
import "react-phone-number-input/style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SuccessFailure from "./components/SubmitFailure";
import UnAuthorized from "./components/UnAuthorized";
import ProtectedRoute from "./utils/ProtectedRoute";
import Authentication from "./views/authentication/Authentication";
import Document from "./views/document/Document";
import AgreementLoading from "./views/document/pages/AgreementLoading";
import PaymentLoading from "./views/document/pages/PaymentLoading";
import SuccessPage from "./views/document/pages/SuccessPage";
import ShoppingSite from "./views/shoppingsite/ShoppingSite";
import PendingEquipmentOrders from "./views/shoppingsite/pages/PendingEquipmentOrders";
import PendingUzeliOrders from "./views/document/pages/PendingUzeliOrders";
import OrderStatus from "./views/document/pages/OrderStatusPage";
import ErrorPage from "./views/document/components/ErrorSignin";
import StatusPage from "./views/document/pages/StatusPage";
import EquipmentOrderStatus from "./views/shoppingsite/pages/EquipmentOrderStatus";
import PlaceEquipmentOrder from "./views/shoppingsite/pages/PlaceEquipmentOrder";
import AgentSignDocument from "./views/shoppingsite/components/AgentSignDocument";
import { getEnvironmentValue } from "./utils/getEnvironmentValues";
import { getItemFromSession } from "./views/shoppingsite/utils";
import EverSignDocument from "./views/shoppingsite/components/EverSignDocument";
import InvalidLead from "./components/InvalidLead";
import InvalidAccess from "./components/InvalidAccess";
// import { getItemFromSession } from "./views/shoppingsite/utils";

const App = () => {
  const location = window.location.href.toLocaleLowerCase();
  useEffect(() => {
    if (!window.GZA_INITIALIZED) {
      // console.log("Here");
      const script3 = document.createElement("script");
      script3.async = true;
      script3.src =
        getEnvironmentValue()?.ANALYTICS_URL + "/gz_analytics.min.js";
      // script3.src = process.env.ANALYTICS_URL + "/out.js"
      // script3.src = process.env.ANALYTICS_URL + "/gz_analytics.js"

      script3.onload = async () => {
        let userDetail;
        if (location?.includes("?id")) {
          userDetail = getItemFromSession("leadId");
        } else {
          const user = getItemFromSession("loggedIn");
          userDetail = user?.id;
        }

        // console.log({ userDetail });
        // console.log("user detail fetched", userDetail)
        let envCode =
          window.location.host === "orders.growthzilla.com"
            ? "-production"
            : window.location.host === "orderslocal.growthzilla.com"
            ? "-development"
            : "-staging";
        if (userDetail) {
          window.GZAnalytics &&
            window.GZAnalytics.init("orders" + envCode, userDetail);
          // window.GZAnalytics &&
          window.GZAnalytics.setCustomConstants({
            userDetail: userDetail,
          });
        } else {
          window.GZAnalytics && window.GZAnalytics.init("orders" + envCode, 0);
          //   window.GZAnalytics &&
          //     window.GZAnalytics &&
          //     window.GZAnalytics.setCustomConstants({
          //       networkId: 1,
          //     });
        }
      };

      document.head.appendChild(script3);
    }
  }, []);
  return (
    <div className="app" id="appContainer">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Routes>
          <Route path="/app" element={<Authentication />} />
          <Route path="/app/unauthorized" element={<UnAuthorized />} />
          <Route path="/app/invalidAccess" element={<InvalidAccess />} />
          <Route path="/app/paymentloading" element={<PaymentLoading />} />
          <Route path="/app/formFailed" element={<SuccessFailure />} />
          <Route path="/app/invalidLead" element={<InvalidLead />} />
          <Route path="/app/store" element={<ShoppingSite />} />
          <Route
            path="/app/store/pendingorders"
            element={<PendingEquipmentOrders />}
          />
          <Route
            path="/app/uzeli/pendingorders"
            element={<PendingUzeliOrders />}
          />
          {ProtectedRoute({
            path: "/app/order/:leadId",
            element: <Document />,
          })}
          {ProtectedRoute({
            path: "/app/order",
            element: <Document />,
          })}

          {ProtectedRoute({
            path: "/app/ordersuccess/:orderId/:status",
            element: <SuccessPage />,
          })}
          {ProtectedRoute({
            path: "/app/store/ordersuccess/:id/:status",
            element: <SuccessPage />,
          })}
          {ProtectedRoute({
            path: "/app/store/status/:orderId",
            element: <EquipmentOrderStatus />,
          })}
          {ProtectedRoute({
            path: "/app/orderstatus/:init/:orderId",
            element: <OrderStatus />,
          })}
          {ProtectedRoute({
            path: "/app/placeEquipmentOrder/:orderId",
            element: <PlaceEquipmentOrder />,
          })}
          {ProtectedRoute({
            path: "/app/status/:orderId",
            element: <StatusPage />,
          })}
          {ProtectedRoute({
            path: "/app/order/status/:orderId",
            element: <StatusPage />,
          })}
          {ProtectedRoute({
            path: "/app/signDocument/:documentHash",
            element: <AgentSignDocument />,
          })}
          {ProtectedRoute({
            path: "/app/sign/:from/:orderId",
            element: <EverSignDocument />,
          })}
          {ProtectedRoute({
            path: "/app/agreementloading",
            element: <AgreementLoading />,
          })}
          <Route
            path="/app/error/:page/:pending/:status"
            element={<ErrorPage />}
          />
          <Route path="/app/error/:page/:status" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
