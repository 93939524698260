import PropTypes from "prop-types";
import React from "react";
import { Alert } from "@mui/material";

const Incrementor = ({
  value,
  price,
  onIncrease,
  onDecrease,
  warning,
  fromWarning,
}) => {
  // console.log({ value, price });
  return (
    <div>
      <div className="flex   justify-center items-center mx-2 text-center">
        {!fromWarning && (
          <div className="font-bold w-1/2 justify-around">
            ${Number(price) * Number(value)}
          </div>
        )}
        <div
          className={`flex ${
            fromWarning ? "flex-row" : "flex-col"
          }  justify-center items-center w-1/2`}
        >
          <button
            type="button"
            className="add"
            onClick={(e) => {
              if (fromWarning) {
                e.preventDefault();
              }
              onIncrease();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <p className="text-center w-10">{value}</p>
          <button type="button" className="minus" onClick={onDecrease}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
              id="minus"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </div>
      {warning && (
        <div className="flex justify-center items-center h-[0.1rem]">
          <Alert variant="filled" severity="warning">
            {warning}
          </Alert>
        </div>
      )}
    </div>
  );
};

Incrementor.propTypes = {
  value: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  warning: PropTypes.string,
  fromWarning: PropTypes.bool,
};

export default Incrementor;
