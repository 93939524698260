import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../components/CustomModal";

const StatusModalPage = ({ content, isOpen, closeDetail }) => {
  // console.log({ content, isOpen, closeDetail });

  return (
    <div>
      <CustomModal
        content={content}
        title={"Order Summary"}
        isOpen={isOpen}
        closeModal={closeDetail}
      />
    </div>
  );
};

StatusModalPage.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeDetail: PropTypes.func,
};

export default StatusModalPage;
