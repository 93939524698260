import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";

import updateEquipmentOrder from "../hooks/updateEquipmentOrder";
import useComplimentaryPaymentAdmins from "../hooks/usePaymentAdmins";
import IFrameComponent from "../../document/components/IFrameComponent";
import EquipmentOrderStatus from "./PlaceEquipmentOrder";

import Header from "../components/Header";

import { fetchEquipmentOrders } from "../../../utils/signAgreementDocument";
import { clearSessionStorage } from "../../../utils/clearSessionStorage";
import Spinner from "../../../components/Spinner";
import PendingEquipmentMenu from "../components/PendingEquipmentMenu";
import { CircularProgress } from "@mui/material";
import { saveItemsToSession } from "../utils";
import User from "../../document/components/User";
// import { getItemFromSession } from "../utils";

const PendingEquipmentOrders = () => {
  const [user, setUser] = useState(null);

  const [showFrame, setShowFrame] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gettingOrders, setGettingOrders] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(false);
  const [getPagedData, setGetPagedData] = useState(false);
  const [limits, setLimits] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);

  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { authPaymentAdmins } = useComplimentaryPaymentAdmins();

  const [iframeSource, setIframeSource] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );
  const { updateOrder } = updateEquipmentOrder();

  const isAuthorizedPaymentApprover = () => {
    return (
      user?.role === "ADMIN" ||
      (getEnvironmentValue().IS_DEV && user?.role === "USER")
    );
  };

  useEffect(() => {
    if (user?.id && authPaymentAdmins) {
      if (!isAuthorizedPaymentApprover()) {
        navigate("/app/unauthorized");
      }
    }
  }, [user, authPaymentAdmins]);

  const cancelOrder = async (orderId) => {
    setUpdatingOrder(true);
    const updateOrderRes = await updateOrder({
      orderId,
      orderStatus: "cancelled",
      approvedBy: authPaymentAdmins?.find(
        (approver) => approver.email === user?.email
      )?.ROWID,
    });

    if (!updateOrderRes.success) {
      toast.error("Error while cancelling order", {
        toastId: "cancel-order-error",
      });
      setUpdatingOrder(false);
      return;
    }
    toast.success("Order cancelled successfully", {
      toastId: "cancel-order-success",
    });
    await refreshOrder();

    setUpdatingOrder(false);
  };

  const approveOrder = async (orderId) => {
    setLoading(true);
    const pendingOrder = pendingOrders?.find(
      (pendingOrder) => pendingOrder?.orderId === orderId
    );
    sessionStorage.setItem(`order`, JSON.stringify(pendingOrder));
    navigate(`/app/placeEquipmentOrder/${orderId}`);
  };

  const displayIframeStyle = {
    backgroundColor: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  const listener = {
    loginResponse: (user) => {
      setUser(user);
      setShowFrame(false);
      saveItemsToSession("user-s", user);
    },
  };

  useEffect(() => {
    clearSessionStorage();
  }, []);

  const getCurrentTab = (tab) => {
    return tab === 0
      ? "all"
      : tab === 1
      ? "pending"
      : tab === 2
      ? "approved"
      : "cancelled";
  };

  const getMoreData = async (status, offset) => {
    try {
      setGetPagedData(true);
      const currentTab = getCurrentTab(tab);
      const ordersData = await fetchEquipmentOrders(status, offset);
      // console.log({ ordersData });
      if (ordersData.data.length) {
        const newOrders = _.uniq([...pendingOrders, ...ordersData.data]);
        // console.log({ newOrders });
        setPendingOrders(newOrders);
        setLimits((limit) => {
          return {
            ...limit,
            [currentTab]: ordersData.page,
          };
        });
        saveItemsToSession(`${currentTab}EquipmentOrders`, newOrders);
      } else if (!ordersData.data.length && !ordersData.page.hasMore) {
        setLimits((limit) => {
          return {
            ...limit,
            [currentTab]: ordersData.page,
          };
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetPagedData(false);
    }
  };

  const handleGetDataOnScroll = async () => {
    const currentTab = getCurrentTab(tab);
    const limit = limits[currentTab];
    // console.log({ limit, currentTab });
    if (limit?.hasMore && !getPagedData) {
      const offset = limit.offset;
      await getMoreData(currentTab, offset);
    }
  };

  const refreshOrder = async () => {
    const currentTab = getCurrentTab(tab);
    try {
      const orders = await fetchEquipmentOrders(currentTab, 1);
      if (orders?.data.length) {
        setPendingOrders(_.uniq(orders.data));
        setLimits((limits) => {
          return { ...limits, [currentTab]: orders.page };
        });
      }
    } catch (err) {
      console.log("Error while getting data", err);
    }
  };

  useEffect(() => {
    const init = async () => {
      const currentTab = getCurrentTab(tab);
      if (user?.id) {
        setGettingOrders(true);
        try {
          const orders = await fetchEquipmentOrders(currentTab, 1);
          if (orders?.data.length) {
            setPendingOrders(_.uniq(orders.data));
            setLimits((limits) => {
              return { ...limits, [currentTab]: orders.page };
            });
          }
        } catch (err) {
          console.log("Error while getting data", err);
        } finally {
          setGettingOrders(false);
        }
      }
    };

    init();
  }, [user, tab]);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user-s") || "{}");
    if (currentUser?.id) {
      setUser(currentUser);
      setShowFrame(false);
    }
  }, []);

  // const handleScrollCapture = (event) => {
  //   console.log({ event });
  // };

  Modal.setAppElement("#root");
  return (
    <div className="overflow-hidden flex flex-col justify-center items-center h-screen w-screen">
      <div className="z-50  w-full flex justify-center  ">
        <div className=" flex  w-full sm:w-4/5  relative ">
          <div className="w-full">
            <Header />
          </div>
          <div className="  absolute h-full text-[#4D1F70] text-3xl sm:text-4xl top-3 right-5 ">
            {user?.id && (
              <User
                user={user}
                switchUser={() => {
                  setShowFrame(true);
                  setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
                }}
              />
            )}{" "}
          </div>
        </div>
      </div>
      {(gettingOrders || updatingOrder) && (
        <div className="h-full w-full">
          <Spinner
            loading={true}
            message={gettingOrders ? "Getting equipment orders" : ""}
          />
        </div>
      )}

      <div style={showFrame ? displayIframeStyle : hiddenIframeStyle}>
        <div
          className="flex justify-center items-center "
          style={{ height: "94vh" }}
        >
          <IFrameComponent
            listener={listener}
            // messageToIFrame={preInvoice}
            iFrameSrc={iframeSource}
          />
        </div>
      </div>

      {loading && !showFrame && !gettingOrders && !updatingOrder ? (
        <div className="w-full pt-40 h-full">
          {" "}
          <EquipmentOrderStatus />
        </div>
      ) : (
        <></>
      )}
      {!showFrame && !loading && !gettingOrders && !updatingOrder && (
        <div className=" flex  flex-1 sm:w-4/5  flex-col overflow-hidden     md:mx-auto  ">
          <PendingEquipmentMenu
            selectedIndex={tab}
            setSelectedIndex={setTab}
            pendingOrders={pendingOrders}
            approveOrder={approveOrder}
            cancelOrder={cancelOrder}
            setGettingOrders={setGettingOrders}
            setLoading={setUpdatingOrder}
            authPaymentAdmins={authPaymentAdmins}
            getDataCallback={handleGetDataOnScroll}
          />
          {getPagedData && (
            <div className="w-full h-10 absolute sm:w-4/5  bg-white flex justify-center bottom-0 ">
              <CircularProgress className="text-xs font-thin" />{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PendingEquipmentOrders;
