import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

import Spinner from "../../../components/Spinner";

import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";

import updateUzeliOrder from "../hooks/updateUzeliOrder";
import IFrameComponent from "../components/IFrameComponent";
import { fetchUzeliOrders } from "../../../utils/signAgreementDocument";
import { clearSessionStorage } from "../../../utils/clearSessionStorage";
import Header from "../../shoppingsite/components/Header";
import useComplimentaryPaymentAdmins from "../../shoppingsite/hooks/usePaymentAdmins";
import User from "../components/User";
import PendingOrderMenu from "../components/PendingMenu";
import { saveItemsToSession } from "../../shoppingsite/utils";
import { CircularProgress } from "@mui/material";
import { resetUzeliOrder } from "../hooks/resetOrder";

const PendingUzeliOrders = () => {
  const [showFrame, setShowFrame] = useState(true);
  const [loading, setLoading] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [gettingOrder, setGettingOrders] = useState(false);
  const [getPagedData, setGetPagedData] = useState(false);
  const [limits, setLimits] = useState(null);
  const [tab, setTab] = useState(0);

  const [iframeSource, setIframeSource] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );

  // const [invoiceData, setInvoiceData] = useState(null);
  // const [preInvoice, setPreInvoice] = useState(null);
  // const [currentPendingOrder, setCurrentPendingOrder] = useState(null);
  // const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();
  // const { pendingOrders, loading: loadingPendingOrders } =
  //   usePendingUzeliOrders(refetch);
  // const { dispatchOrder } = useDispatchOrder();
  const { authPaymentAdmins } = useComplimentaryPaymentAdmins();
  const { updateOrder } = updateUzeliOrder();

  // const getTaxRateByState = (state) => {
  //   return (
  //     taxRates?.find((item) => item?.state === getStateAbbreviation(state))
  //       ?.taxRate || 0
  //   );
  // };
  // console.log("Here");
  const displayIframeStyle = {
    backgroundColor: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  const getCurrentTab = (tab) => {
    return tab === 0
      ? "all"
      : tab === 1
      ? "pending"
      : tab === 2
      ? "approved"
      : "cancelled";
  };

  const getMoreData = async (status, offset) => {
    try {
      setGetPagedData(true);
      const currentTab = getCurrentTab(tab);
      const ordersData = await fetchUzeliOrders(status, offset);
      // console.log({ ordersData });
      if (ordersData.data.length) {
        const newOrders = _.uniqBy(
          [...pendingOrders, ...ordersData.data],
          "orderId"
        );
        console.log({ newOrders });
        setPendingOrders(newOrders);
        setLimits((limit) => {
          return {
            ...limit,
            [currentTab]: ordersData.page,
          };
        });
        saveItemsToSession(`${currentTab}UzeliOrders`, newOrders);
      } else if (!ordersData.data.length && !ordersData.page.hasMore) {
        setLimits((limit) => {
          return {
            ...limit,
            [currentTab]: ordersData.page,
          };
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetPagedData(false);
    }
  };

  const handleGetDataOnScroll = async () => {
    const currentTab = getCurrentTab(tab);
    const limit = limits[currentTab];
    if (limit?.hasMore && !getPagedData) {
      const offset = limit.offset;
      console.log({ limit, currentTab });
      await getMoreData(currentTab, offset);
    }
  };

  const refreshOrder = async () => {
    const currentTab = getCurrentTab(tab);
    // console.log({ currentTab });
    try {
      const orders = await fetchUzeliOrders(currentTab, 1);
      if (orders?.data.length) {
        setPendingOrders(_.uniq(orders.data));
        setLimits((limits) => {
          return { ...limits, [currentTab]: orders.page };
        });
      }
    } catch (err) {
      console.log("Error while getting data", err);
    }
  };

  useEffect(() => {
    const init = async () => {
      const currentTab = getCurrentTab(tab);
      if (loggedIn?.id) {
        setGettingOrders(true);
        try {
          const orders = await fetchUzeliOrders(currentTab, 1);
          if (orders?.data.length) {
            setPendingOrders(_.uniq(orders.data));
            setLimits((limits) => {
              return { ...limits, [currentTab]: orders.page };
            });
          } else {
            setPendingOrders([]);
          }
        } catch (err) {
          console.log("Error while getting data", err);
        } finally {
          setGettingOrders(false);
        }
      }
    };
    init();
  }, [loggedIn, tab]);

  // useEffect(() => {
  //   const init = async () => {
  //     if (loggedIn?.id) {
  //       setGettingOrders(true);
  //       let ordersToPanel = await populateTabPanel(tab);
  //       setPendingOrders(ordersToPanel);
  //       setGettingOrders(false);
  //     }
  //   };
  //   init();
  // }, [loggedIn, tab]);

  useEffect(() => {
    setLoading(true);
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    if (currentUser?.id) {
      setLoggedIn(currentUser);
      setShowFrame(false);
    }
    setLoading(false);
  }, []);

  const isAuthorizedPaymentApprover = () => {
    return (
      loggedIn?.role === "ADMIN" ||
      (getEnvironmentValue().IS_DEV && loggedIn?.role === "USER")
    );
  };

  useEffect(() => {
    if (loggedIn?.id) {
      if (!isAuthorizedPaymentApprover()) {
        navigate("/app/unauthorized");
      }
    }
  }, [loggedIn]);

  const listener = {
    loginResponse: (user) => {
      setLoggedIn(user);
      setShowFrame(false);
      sessionStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      setLoading(false);
    },
  };

  const approveOrder = async (orderId) => {
    if (orderId) {
      const redirectURL = `/app/orderstatus/p/${orderId}`;
      navigate(redirectURL);
      setLoading(false);
    }
  };

  const resetOrder = async (orderId, documentHash) => {
    setLoading(true);
    const updateOrderRes = await resetUzeliOrder({
      orderId,
      documentHash,
    });

    if (!updateOrderRes.success) {
      toast.error("Error ocurred while reseting order", {
        toastId: "reset-order-error",
      });
      setLoading(false);
      return;
    }
    toast.success("Order reseted successfully", {
      toastId: "reset-order-success",
    });

    // const user = getItemFromSession("user");
    await refreshOrder();

    setLoading(false);
  };

  const cancelOrder = async (orderId) => {
    setLoading(true);
    const updateOrderRes = await updateOrder({
      orderId,
      orderStatus: "cancelled",
      approvedBy: loggedIn?.email,
      invoiceId: null,
    });

    if (!updateOrderRes.success) {
      toast.error("Error ocurred while cancelling order", {
        toastId: "cancel-order-error",
      });
      setLoading(false);
      return;
    }
    toast.success("Order cancelled successfully", {
      toastId: "cancel-order-success",
    });

    // const user = getItemFromSession("user");
    await refreshOrder();

    setLoading(false);
  };

  useEffect(() => {
    let ifMounted = true;
    setLoading(true);
    if (ifMounted) {
      const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
      if (currentUser?.id) {
        setShowFrame(false);
        setLoggedIn(currentUser);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    clearSessionStorage();
  }, []);

  Modal.setAppElement("#root");
  return (
    <div className="h-screen flex-col flex w-screen justify-center items-center overflow-hidden ">
      <div className="flex  w-full z-50  justify-center">
        <div className=" flex  w-full sm:w-4/5  relative">
          <div className="w-full ">
            <Header />
          </div>
          <div className=" absolute h-full text-[#4D1F70] text-3xl sm:text-4xl top-3 right-5  ">
            {loggedIn?.id && (
              <User
                user={loggedIn}
                switchUser={() => {
                  setShowFrame(true);
                  setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
                  setLoading(true);
                }}
              />
            )}{" "}
          </div>
        </div>
      </div>
      {(gettingOrder || loading) && !showFrame && (
        <div className="h-full w-full">
          <Spinner
            loading={true}
            message={
              gettingOrder
                ? `Loading ${
                    getCurrentTab(tab) !== "all" ? getCurrentTab(tab) : ""
                  }  orders`
                : ""
            }
          />
        </div>
      )}
      {showFrame && (
        <div style={showFrame ? displayIframeStyle : hiddenIframeStyle}>
          <div
            className="flex justify-center items-center "
            style={{ height: "94vh" }}
          >
            <IFrameComponent
              listener={listener}
              // messageToIFrame={preInvoice}
              iFrameSrc={iframeSource}
            />
          </div>
        </div>
      )}
      {!showFrame && !loading && !gettingOrder && (
        <div className="flex  flex-1 sm:w-4/5  flex-col overflow-hidden     md:mx-auto  ">
          <div>
            <PendingOrderMenu
              selectedIndex={tab}
              setSelectedIndex={setTab}
              pendingOrders={pendingOrders}
              approveOrder={approveOrder}
              resetOrder={resetOrder}
              cancelOrder={cancelOrder}
              setGettingOrders={setGettingOrders}
              setLoading={setLoading}
              authPaymentAdmins={authPaymentAdmins}
              getDataCallback={handleGetDataOnScroll}
            />
          </div>
          {getPagedData && (
            <div className="w-full h-10 absolute sm:w-4/5  bg-white flex justify-center bottom-0">
              <CircularProgress className="text-xs font-thin" />{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PendingUzeliOrders;
