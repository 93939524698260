import React from "react";
// import TopNavBar from "../views/shoppingsite/components/TopNavBar";
// import IFrameComponent from "../views/document/components/IFrameComponent";
// import { getEnvironmentValue } from "../utils/getEnvironmentValues";
// import styled from "styled-components";

// const Button = styled.button`
//   display: block;
//   width: 20rem;
//   border-width: 1px;
//   border-color: #116FD7;
//   border-radius:4px;
//   background-color: #116FD7;
//   color: #000;
//   padding: 14px 28px;
//   font-size: 18px;
//   font-weight: bold;
//   cursor: pointer;
//   text-align: center;
//   font-weight: 500;
//   }
// `;

const InvalidAccess = () => {
  //   const [user, setUser] = useState(null);
  //   const [showFrame, setShowFrame] = useState(true);
  //   const [iframeSource, setIframeSource] = useState(
  //     getEnvironmentValue().EMBED_LOGIN_URL
  //   );

  //   const displayIframeStyle = {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   };

  //   const hiddenIframeStyle = {
  //     display: "none",
  //   };

  //   useEffect(async () => {
  //     const userFromSession = JSON.parse(sessionStorage.getItem("user"));
  //     if (userFromSession?.id) {
  //       setUser(userFromSession);
  //       setShowFrame(false);

  //       // console.log("User", userFromSession);
  //     }
  //   }, []);

  //   const listener = {
  //     loginResponse: (user) => {
  //       setUser(user);
  //       sessionStorage.setItem("user", JSON.stringify(user));
  //       setShowFrame(false);
  //     },
  //   };

  //   const switchUser = () => {
  //     setShowFrame(true);
  //     setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
  //   };
  return (
    <div
      className="h-screen -mt-20 flex flex-col justify-center text-black items-center"
      //   style={{ backgroundColor: "#051527" }}
    >
      {/* <div className="w-full flex justify-center">
        <TopNavBar
          noCart={true}
          user={user}
          switchUserCallBack={user?.id ? switchUser : null}
        />
      </div>
      <div className="h-full flex  justify-center ">
        {showFrame && (
          <div
            className="h-full"
            style={showFrame ? displayIframeStyle : hiddenIframeStyle}
          >
            <IFrameComponent
              listener={listener}
              // messageToIFrame={preInvoice}
              iFrameSrc={iframeSource}
            />
          </div>
        )}
      </div> */}
      {/* <div className="font-extrabold text-8xl text-slate-50 mb-9">401</div> */}
      <div className=" mb-3 font-semibold text-2xl"> Invalid Access </div>
      <div className="mb-5">
        <hr className="w-40" />
      </div>
      <div className=" mb-7 text-xl">
        This account has no access to this order. Please login with the correct
        account.
      </div>
      {/* <div>
        <Button
          onClick={() => {
            window.open(
              `https://account.growthzilla.com?redirectTo=localhost:8000/document/${itemId}`,
              "_self"
            );
          }}
        >
          Login Again
        </Button>
      </div> */}
    </div>
  );
};

export default InvalidAccess;
