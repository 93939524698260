import { Tab } from "@headlessui/react";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
import PropTypes from "prop-types";
import PendingUzeliOrderCard from "./PendingUzeliOrderCard";
import _ from "lodash";

export default function PendingOrderMenu(props) {
  const handleInfinitScroll = _.throttle(async (e) => {
    const scrollHeight = e.target.scrollHeight;
    const topHeight = e.target.scrollTop;
    const endHeight = scrollHeight - topHeight;

    if (endHeight <= 1000) {
      await props.getDataCallback();
    }
  }, 1000);

  const tabs = ["All", "Pending", "Approved", "Cancelled"];
  return (
    <div className="w-full   flex flex-col justify-center">
      <Tab.Group
        selectedIndex={props.selectedIndex}
        onChange={props.setSelectedIndex}
      >
        <Tab.List className="z-[10] flex gap-2 w-full flex-row rounded-xl bg-white p-1 ">
          {tabs.map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 z-10 text-sm font-medium leading-5 text-black border-[2px] border-black",
                  "   focus:outline-none ",
                  selected
                    ? " bg-[#371650] text-white shadow"
                    : "text-black bg-white"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels
          onScroll={(e) => handleInfinitScroll(e)}
          className="h-[85vh] sm:w-full overflow-y-scroll "
        >
          {tabs.map((item) => (
            <Tab.Panel
              key={item}
              className={classNames(
                "rounded-xl bg-white p-3 mb-10",
                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
              )}
            >
              <h1 className="text-2xl font-medium mb-2 mt-4 text-center">
                {item?.toLocaleLowerCase() === "all"
                  ? "All Uzeli Orders"
                  : item?.toLocaleLowerCase() === "pending"
                  ? "Pending Uzeli Orders Waiting for Approval"
                  : item?.toLocaleLowerCase() === "approved"
                  ? "Approved Uzeli Orders"
                  : "Cancelled Uzeli Orders"}
              </h1>

              <div>
                {props?.pendingOrders?.length === 0 ? (
                  <h4 className="text-center p-10 text-lg">
                    There are no{" "}
                    <span className=" lowercase">
                      {item === "All" ? "" : item}
                    </span>{" "}
                    orders!
                  </h4>
                ) : (
                  props?.pendingOrders?.length &&
                  props?.pendingOrders?.map((pendingOrder) => (
                    <PendingUzeliOrderCard
                      key={pendingOrder?.orderId}
                      pendingOrder={pendingOrder}
                      approveOrder={props?.approveOrder}
                      cancelOrder={props?.cancelOrder}
                      resetOrder={props?.resetOrder}
                      setLoading={props?.setLoading}
                      tab={props?.selectedIndex}
                      authPaymentAdmins={props?.authPaymentAdmins}
                    />
                  ))
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
PendingOrderMenu.propTypes = {
  children: PropTypes.node,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  pendingOrders: PropTypes.array,
  approveOrder: PropTypes.func,
  resetOrder: PropTypes.func,
  cancelOrder: PropTypes.func,
  setLoading: PropTypes.func,
  authPaymentAdmins: PropTypes.array,
  getDataCallback: PropTypes.func,
};
