import React from "react";
import PropTypes from "prop-types";
import Kiosk from "../../../assets/images/251.png";
import Computer from "../../../assets/images/mac-uzeli-left1.png";
import SmartCheckout from "../../../assets/images/a11.png";
import ReceiptPrinter from "../../../assets/images/a33.png";
import b from "../../../assets/images/b.png";
import a55 from "../../../assets/images/a55.png";

// eslint-disable-next-line no-unused-vars
function getIcon(itemName) {
  // console.log("Item Name", itemName);
  if (
    itemName.toLowerCase().includes("growthzilla kiozzk tablet") ||
    itemName.toLowerCase().includes("kiosk")
  ) {
    // console.log("Here");
    return Kiosk;
  } else if (itemName.toLowerCase().includes("computer")) {
    return Computer;
  } else if (
    itemName.toLowerCase().includes("pax aries 8 checkout terminal") ||
    itemName.toLowerCase().includes("smart checkout ")
  ) {
    return SmartCheckout;
  } else if (
    itemName.toLowerCase().includes("growthzilla cloud receipt printer") ||
    itemName.toLowerCase().includes("receipt printer")
  ) {
    return ReceiptPrinter;
  } else if (
    itemName.toLowerCase().includes("cashdrawer") ||
    itemName.toLowerCase().includes("cash drawer ")
  ) {
    return b;
  } else if (
    itemName.toLowerCase().includes("aries stand") ||
    itemName.toLowerCase().includes("ariesstand")
  ) {
    return a55;
  }
}

function CartTable({ equipment }) {
  let data = [];

  if (equipment && Object.keys(equipment).length) {
    const totalItems = Object.keys(equipment).filter(
      (item) => equipment[item] > 0
    );
    totalItems?.forEach((item, index) => {
      data.push({
        key: index,
        itemName: item,
        quantity: equipment[item],
        icon: getIcon(item),
      });
    });
  }

  const total =
    equipment &&
    Object.keys(equipment).length &&
    Object.keys(equipment)?.reduce((acc, item) => {
      return acc + equipment[item];
    }, 0);
  // console.log("Data", data);
  return (
    <div className="   rounded-lg w-48 sm:w-64 md:w-80  bg-white ">
      {total > 0 ? (
        <div>
          {data?.map((item) => (
            <div key={item.itemName}>
              {item?.quantity > 0 ? (
                <div
                  id="cartItem"
                  className=" flex  flex-row shadow-sm h-12 md:h-16 items-center gap-3 mt-1 w-full"
                >
                  <div className="flex relative">
                    <div className="p-1 w-10 sm:w-14 md:w-20  h-full ">
                      <img
                        width={60}
                        height={25}
                        src={item?.icon}
                        alt={item?.icon}
                      />
                    </div>
                    <div
                      id="cartItemQty"
                      className=" flex md:text-xs text-[8px] font-bold absolute right-1 top-1 bg-green-900 text-white rounded-full border-1 px-1 h-3 md:h-[17px] md:px-[5px] items-center "
                    >
                      {item.quantity}
                    </div>
                  </div>
                  <div
                    id="cartItemName"
                    className=" scrollbar-hide w-fit text-xs sm:text-sm md:text-base break-words text-clip overflow-scroll"
                  >
                    {item.itemName}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="w-28 shadow-2xl  text-base md:text-xl px-5">
          {" "}
          No items selected
        </div>
      )}
    </div>
  );
}
CartTable.propTypes = {
  equipment: PropTypes.object,
};

function CartItemMenu({ equipment }) {
  return (
    <div className="bg-white h-fit p-4 w-fit  rounded-md whitespace-nowrap text-black ">
      <CartTable equipment={equipment} />
    </div>
  );
}
CartItemMenu.propTypes = {
  equipment: PropTypes.object,
  gotoCheckout: PropTypes.func,
  fromStore: PropTypes.bool,
};
export default CartItemMenu;
