import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import Header from "../components/Header";
import IFrameComponent from "../../document/components/IFrameComponent";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import Spinner from "../../../components/Spinner";
import { fetchEquipmentOrderDetail } from "../../../utils/signAgreementDocument";

import TopNavBar from "../components/TopNavBar";

import EquipmentStatusContent from "../components/EquipmentStatusContent";

const EquipmentOrderStatus = () => {
  const params = useParams();
  const [user, setUser] = useState(null);
  const [showFrame, setShowFrame] = useState(true);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);

  const { orderId } = params;
  const navigate = useNavigate();
  const getOrderDetail = async () => {
    setLoading(true);
    const orderDetail = await fetchEquipmentOrderDetail(orderId);

    if (orderDetail?.orderId) {
      setOrder(orderDetail);
    } else {
      navigate("/app/error/store/failed");
    }

    setLoading(false);
  };
  useEffect(() => {
    if (user?.id) {
      getOrderDetail();
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Orders Status",
          eventtype: "viewed",
          payload: { user: user?.id, orderId: orderId },
        });
    }
  }, [orderId, user]);

  useEffect(() => {
    setLoading(true);
    const currentUser = JSON.parse(sessionStorage.getItem("user-s") || "{}");
    if (currentUser?.id) {
      setUser(currentUser);
      setShowFrame(false);
    }
    setLoading(false);
  }, []);

  const displayIframeStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  const listener = {
    loginResponse: (user) => {
      setShowFrame(false);
      setUser(user);
      sessionStorage.setItem("user", JSON.stringify(user));
    },
  };
  const switchUser = () => {
    setShowFrame(true);
  };

  // console.log({ order });
  return (
    <div className=" flex flex-col  w-screen h-fit">
      <div className="w-full flex justify-center">
        <TopNavBar
          noCart={true}
          user={user}
          switchUserCallBack={user?.id ? switchUser : null}
        />
      </div>
      <div className="h-full flex   justify-center ">
        {showFrame && (
          <div
            className="h-full"
            style={showFrame ? displayIframeStyle : hiddenIframeStyle}
          >
            <IFrameComponent
              listener={listener}
              // messageToIFrame={preInvoice}
              iFrameSrc={getEnvironmentValue().EMBED_LOGIN_URL}
            />
          </div>
        )}
        {loading && !showFrame && (
          <div className="h-[85vh]">
            <Spinner loading={true} message={"Loading Order Status"} />
          </div>
        )}

        {!loading && !showFrame && order?.orderId && (
          <div className="w-full h-full flex flex-col  md:w-4/5  pt-14  justify-center">
            <div className=" w-full flex bg-white font-bold  justify-center text-2xl">
              Order Summary
            </div>
            <EquipmentStatusContent
              user={user}
              order={order}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default EquipmentOrderStatus;
