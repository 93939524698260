import React from "react";
import PropTypes from "prop-types";
function DataTable({ data, admin, orderId }) {
  return (
    <table className=" shadow-lg  rounded-lg   bg-white w-full">
      <thead className="data-table">
        <tr className=" whitespace-nowrap">
          <th className="pl-7 p-2">Name</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          {admin && <th>Provider</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr
            className="odd:bg-gray-200  even:bg-white  whitespace-nowrap "
            key={`${item.ROWID}_${orderId}`}
          >
            <td className=" p-2">{item.name}</td>
            <td>{item.quantity}</td>
            <td>{item.unitPrice}</td>
            {admin && (
              <td>
                {item?.provider === "shipbob"
                  ? "ShipBob"
                  : item?.provider === "mscashdrawer"
                  ? "MsCashDrawer"
                  : item?.provider}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
DataTable.propTypes = {
  admin: PropTypes.bool,
  data: PropTypes.array,
  orderId: PropTypes.string,
};
export default DataTable;
