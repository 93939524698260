import React, { useEffect, useState } from "react";
import Redirect from "../../../components/Redirect";
import SignDocument from "../components/SignDocument";
import { getEmbeddedSigningUrl } from "../../../utils/signAgreementDocument";

import Spinner from "../../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { getItemFromSession } from "../../shoppingsite/utils";
// import { getLeadId } from "../../../utils/isItemIdValid";

const AgreementLoading = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [embeddedSigningUrl, setEmbeddedSigningUrl] = useState(null);
  const navigate = useNavigate();

  const businessNameFromLeadInfo = getItemFromSession(
    "businessNameFromLeadInfo"
  );
  const leadId = getItemFromSession("leadIdFromInfo");
  //   const leadValue = leadString?.split("-");
  // const leadId = getLeadId({ id: leadValue });

  const leadData = {
    businessName: businessNameFromLeadInfo,
    id: leadId,
    agent: false,
  };

  const fetchUrl = async (count) => {
    console.log({ count });
    if (count && count > 6) {
      console.log("Here");
      setError("Unable to load PDF this time. Please try again later.");
      navigate("/app/error/order/n/failed");
      return;
    }
    try {
      const transactionRef = localStorage.getItem("transaction_ref");
      if (transactionRef) {
        const agreementDetails = await getEmbeddedSigningUrl(transactionRef);
        const embeddedSigningUrl = agreementDetails?.embeddedSigningUrl;
        console.log("Signing Url", embeddedSigningUrl);
        if (embeddedSigningUrl) {
          setEmbeddedSigningUrl(embeddedSigningUrl);
          setLoading(false);
        } else {
          setTimeout(async () => {
            const newCount = (count || 0) + 1;
            await fetchUrl(newCount);
          }, 5000);
        }
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      setTimeout(async () => {
        const newCount = (count || 0) + 1;
        await fetchUrl(newCount);
      }, 5000);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUrl(0);
  }, []);

  // useEffect(() => {
  //   if (embeddedSigningUrl) {
  //     signDocument(embeddedSigningUrl);
  //     // window.location.href = embeddedSigningUrl;
  //   }
  // }, [embeddedSigningUrl]);

  return loading ? (
    <div className=" px-4  z-4 h-[100vh] flex justify-center items-center">
      <Spinner
        loading={true}
        message={"Please wait while agreement document is being prepared"}
      />
    </div>
  ) : error ? (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      Error
    </div>
  ) : embeddedSigningUrl ? (
    <SignDocument
      embeddedSigningUrl={embeddedSigningUrl}
      fromPending={false}
      leadData={leadData}
    />
  ) : (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      <Redirect message="Preparing your agreement document, please wait..." />
    </div>
  );
};

export default AgreementLoading;
