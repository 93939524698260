import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

function LoggedInUser({ user, switchUser, login }) {
  return (
    <div className="flex flex-col gap-3 min-w-fit text-xs sm:text-sm md:text-base text-black shadow-md rounded-md bg-white p-5">
      <div className="flex justify-center  min-w-fit uppercase whitespace-nowrap">
        <div>{user?.fullname}</div>
      </div>
      {switchUser && (
        <div className="w-full justify-center whitespace-nowrap">
          <Button
            style={{
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "black",
              border: "1px solid black",
              width: "100%",
            }}
            onClick={() => {
              if (switchUser) {
                switchUser();
              }
            }}
            data-analytics-name="Switch User"
            data-analytics-eventtype="Clicked"
            className="text-xs sm:text-sm md:text-base"
          >
            Switch User
          </Button>
        </div>
      )}
      {!!login && (
        <div className="w-full justify-center whitespace-nowrap">
          <Button
            style={{
              textTransform: "none",
              backgroundColor: "#ffffff",
              color: "black",
              border: "1px solid black",
              width: "100%",
            }}
            onClick={() => {
              if (login) {
                login();
              }
            }}
          >
            Login
          </Button>
        </div>
      )}
    </div>
  );
}
LoggedInUser.propTypes = {
  user: PropTypes.object,
  switchUser: PropTypes.func,
  login: PropTypes.func,
};
export default LoggedInUser;
