/* eslint-disable react/no-unescaped-entities */
import { Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import ReactSwitch from "react-switch";
import Kiosk from "../../../assets/images/251.png";
import SwitchContext from "../context";

const FirstPage = () => {
  const { openValues, toggleSwitch, isOutOfStock, isUpperLimitReached } =
    useContext(SwitchContext);
  const [warning, setWarning] = useState("");

  return (
    <div>
      <div className="mb-3 flex justify-end">
        {warning && (
          <div className="flex justify-center items-center h-[0.1rem] m-5">
            <Alert variant="filled" severity="warning">
              {warning}
            </Alert>
          </div>
        )}
      </div>
      <div className="block md:flex md:justify-center md:items-center">
        <div className="w-full md:w-3/5">
          <p className="test text-xl md:text-3xl capitalize mb-4 font-medium">
            1. check in kiosk
          </p>
          <div className="pl-0 md:pl-8 text-paragraph">
            <p className="mb-4 leading-relaxed">
              Uzeli's check-in kiosk tablet (Kiozzk) let's both walk-ins and
              appointment clients self check-in at the salon. Check-in tablet is
              a very effective way to build your client database as clients
              enter their name and phone number at check-in. Moreover, clients
              provide text messaging consent at check-in which is required to
              send coupons and promotions to your clients.
            </p>
            <p className="leading-relaxed">
              Recommended if you plan to use Uzeli's loyalty or text-message
              marketing functionality and want to build client database with
              minimum effort.
            </p>
          </div>
        </div>
        <div className="w-full md:w-2/5 flex flex-col">
          <img src={Kiosk} alt="" />

          <div className="flex  items-center gap-4 justify-center w-full pt-7">
            <div className="flex flex-col items-center gap-y-0.">
              <div className="text-xs">
                {openValues.Kiosk === 1
                  ? "Click to unselect"
                  : "Click to select"}
              </div>
            </div>
            <ReactSwitch
              onChange={() => {
                if (isOutOfStock("Kiozzk")) {
                  setWarning("Currently out of stock");
                  setTimeout(() => {
                    setWarning("");
                  }, 2000);
                  return;
                } else if (isUpperLimitReached("Kiozzk")) {
                  setWarning("Maximum quantity reached");
                  setTimeout(() => {
                    setWarning("");
                  }, 2000);
                  return;
                }
                toggleSwitch("Kiosk");

                window.GZAnalytics &&
                  window.GZAnalytics.customLog({
                    name: "Cart Items",
                    eventtype: "changed",
                    payload: {
                      checkInKiosk: !openValues.Kiosk,
                    },
                  });
              }}
              checked={openValues.Kiosk === 1}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
