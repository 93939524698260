import React, { useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Fragment, useState } from "react";

const CustomSelect = (props) => {
  const [query, setQuery] = useState(props?.selected ?? "");

  useEffect(() => {
    if (props?.selected) {
      setQuery(props?.selected);
    }
  }, [props?.selected]);
  const { options } = props;

  const filteredPeople =
    query === ""
      ? options
      : options.filter((person) => {
          return person.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div>
      <div
        className={`${
          props?.configProperties?.width
            ? `w-${props?.configProperties?.width}`
            : "44"
        }`}
      >
        <Combobox
          value={props?.selected}
          onChange={(e) => {
            props?.setSelected(e);
            setQuery(e);
          }}
          disabled={props?.configProperties?.disabled}
          as="div"
          clearable="true"
        >
          <div className="relative mt-1 ">
            <div className="relative flex flex-row  h-9  ">
              <Combobox.Button className=" flex flex-1 h-full items-center pr-2 justify-between  rounded-lg text-lightPrimaryText bg-lightButtons dark:bg-darkButtons dark:text-darkPrimaryText  shadow-md ">
                {/* <Combobox.Label className="w-full text-left  pl-5  text-sm leading-5 text-lightPrimaryText dark:text-darkPrimaryText "></Combobox.Label> */}
                <Combobox.Input
                  className="bg-inherit pl-3 outline-none w-full"
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                />
                {props?.configProperties?.clearable ? (
                  <ClearIcon
                    className="h-5 w-5 text-lightIcons dark:text-darkIcons "
                    aria-hidden="true"
                    onClick={(event) => {
                      event.stopPropagation();
                      props.setSelected("");
                    }}
                  />
                ) : null}
                &nbsp;
                <ExpandMoreIcon
                  className="h-5 w-5  text-lightIcons dark:text-darkIcons"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={`
                absolute z-10 mt-1 
  
                 w-full overflow-auto scrollbar-hide rounded-md
               bg-white dark:bg-darkCellColor py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 
               focus:outline-none sm:text-sm`}
                style={{
                  maxHeight: props?.configProperties?.dropdownHeight ?? "100px",
                }}
              >
                <Combobox.Options static>
                  {filteredPeople?.map((option, id) => (
                    <Combobox.Option
                      key={id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10  text-gray-900 ${
                          active ? "bg-teal-600  " : ""
                        }`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate text-black  ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-teal-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </div>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
  setSelected: PropTypes.func,
  configProperties: PropTypes.object,
};

// configProperties Keys {
//   className:
//   clearable:
//   dropdownHeight:
//   width:
//   disabled:
// }
useCustomSelect.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  initialValue: PropTypes.string,
  selectedBusiness: PropTypes.object,
  configProperties: PropTypes.object,
};
function useCustomSelect(props) {
  const [selected, setSelected] = useState(props.initialValue ?? "");

  useEffect(() => {
    if (props?.selectedBusiness?.id) {
      setSelected(props?.selectedBusiness?.name);
      // console.log("SelectedValue is changed", props?.selectedBusiness);
    }
  }, [props?.selectedBusiness]);

  return {
    selected,
    CustomSelect: (
      <CustomSelect
        configProperties={props?.configProperties}
        options={props.options}
        selected={selected}
        setSelected={(e) => {
          // console.log("value", e);
          setSelected(e);
          props?.onSelect(e);
        }}
      />
    ),
  };
}

export default useCustomSelect;
