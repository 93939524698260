/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import a from "../../../assets/images/a.png";
import b from "../../../assets/images/b.png";
import SwitchContext from "../context";
import { Alert } from "@mui/material";

const FifthPage = () => {
  const {
    openValues,
    toggleSwitch,
    disable,
    isOutOfStock,
    isUpperLimitReached,
  } = useContext(SwitchContext);
  const cashdrawerSmall = openValues.CashdrawerSmall;
  const cashdrawerBig = openValues.CashdrawerBig;
  const [cashdrawerSmallWarning, setCashdrawerSmallWarning] = useState("");
  const [cashdrawerBigWarning, setCashdrawerBigWarning] = useState("");

  useEffect(() => {
    if (cashdrawerSmall === 1) {
      disable(["CashdrawerBig"]);
    }
  }, [cashdrawerSmall]);

  useEffect(() => {
    if (cashdrawerBig === 1) {
      disable(["CashdrawerSmall"]);
    }
  }, [cashdrawerBig]);

  return (
    <div>
      <div className="flex justify-end"></div>
      <div>
        <div className="w-full lg:mr-auto">
          <p className="text-xl md:text-3xl capitalize mb-4 font-medium">
            5. Cash Drawer
          </p>
          <div className="pl-0 md:pl-8 text-paragraph">
            <p className="mb-4 leading-relaxed">
              Uzeli integrates with the cash drawer via the receipt printer to
              auto open the cash drawer when a client chooses to pay by cash.
              Uzeli is compatible with most cash drawers that connect to the
              printer using RJ11 cable.
            </p>
          </div>
        </div>
        <div className="block md:flex px-10  justify-between md:space-x-5 > * + *">
          <div className="text-center">
            <img src={a} alt="" className="mx-auto w-3/4 my-8" />
            <h5 className="text-lg md:text-2xl mb-4">13 inch, 4 bills</h5>

            {
              <div className="flex">
                <div className="w-[55%] flex justify-end items-end mt-3">
                  <ReactSwitch
                    onChange={() => {
                      if (isOutOfStock('Cash Drawer Small(13")')) {
                        setCashdrawerSmallWarning("Currently out of stock");
                        setTimeout(() => {
                          setCashdrawerSmallWarning("");
                        }, 2000);
                        return;
                      } else if (
                        isUpperLimitReached('Cash Drawer Small(13")')
                      ) {
                        setCashdrawerSmallWarning("Maximum quantity reached");
                        setTimeout(() => {
                          setCashdrawerSmallWarning("");
                        }, 2000);
                        return;
                      }
                      toggleSwitch("CashdrawerSmall");
                      window.GZAnalytics &&
                        window.GZAnalytics.customLog({
                          name: "Cart Items",
                          eventtype: "changed",
                          payload: {
                            SmallCashDrawer: !openValues.cashdrawerSmall,
                          },
                        });
                    }}
                    checked={cashdrawerSmall === 1}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
                <div className="w-[45%] flex justify-start items-start ml-4">
                  {cashdrawerSmallWarning && (
                    <div className=" h-[0.09rem]">
                      <Alert
                        variant="filled"
                        severity="warning"
                        className="p-1"
                      >
                        {cashdrawerSmallWarning}
                      </Alert>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>

          <div className="text-center">
            <img src={b} alt="" className="mx-auto w-3/4 my-8" />
            <h5 className="text-lg md:text-2xl mb-4">16 inch, 5 bills</h5>

            {
              <div className="flex">
                <div className="w-[55%] flex justify-end items-end mt-3">
                  <ReactSwitch
                    onChange={() => {
                      if (isOutOfStock('Cash Drawer Big(16")')) {
                        setCashdrawerBigWarning("Currently out of stock");
                        setTimeout(() => {
                          setCashdrawerBigWarning("");
                        }, 2000);
                        return;
                      } else if (isUpperLimitReached('Cash Drawer Big(16")')) {
                        setCashdrawerBigWarning("Maximum quantity reached");
                        setTimeout(() => {
                          setCashdrawerBigWarning("");
                        }, 2000);
                        return;
                      }
                      toggleSwitch("CashdrawerBig");
                      window.GZAnalytics &&
                        window.GZAnalytics.customLog({
                          name: "Cart Items",
                          eventtype: "changed",
                          payload: {
                            BigCashDrawer: !openValues.cashdrawerBig,
                          },
                        });
                    }}
                    checked={cashdrawerBig === 1}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
                <div className="w-[45%] flex justify-start items-start ml-4">
                  {cashdrawerBigWarning && (
                    <div className=" h-[0.09rem]">
                      <Alert
                        variant="filled"
                        severity="warning"
                        className="p-1"
                      >
                        {cashdrawerBigWarning}
                      </Alert>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthPage;
