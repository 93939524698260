import axios from "axios";

export const resetUzeliOrder = async ({ orderId, documentHash }) => {
  try {
    const res = await axios.post(
      `/browser/reset/${orderId}`,
      {
        orderId,
        documentHash,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (err) {
    console.log("Something went wrong while reseting order", err);
    return false;
  }
};
