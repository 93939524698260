export const LOCATION_TYPES = [
  "New Location",
  "Less than one year",
  "Between 1-2 years",
  "Between 3-5 years",
  "More than 5 years",
];

export const CURRENT_SOFTWARES = [
  "No",
  "Square",
  "Clover",
  "Booker",
  "Millenium",
  "Shortcuts",
  "Vagaro",
  "myTime",
  "MindBody",
  "Squire",
  "Zenoti",
  "Salon Ultimate",
  "Other",
];

export const INVALID_DISCOUNT_OBJECT = "invalidDiscountObject";
export const EMPTY_DISCOUNT_OBJECT = "noDiscountObject";
export const NO_PARAMS = "noParams";
export const SUCCESSFULL = "successfull";

export const CONCAT_KEY = "UZELI";
export const COMPLIMENTARY = "complimentary";

export const discountObjectValue = {
  bid: 130,
  approvedBy: {
    id: "222",
    name: "Ram Sharan Rimal",
  },
  discounts: [
    {
      itemId: "9417000000245438",
      qtyThreshold: 1,
      discount: 10,
      percent: false,
      type: "each",
    },
    {
      itemId: "9417000001653846",
      qtyThreshold: 3,
      discount: 10,
      percent: true,
      type: "each",
    },
  ],
};
export const PAX_TERMINAL = "Pax Aries 8 Checkout Terminal";
export const ARIES_STAND = "Aries8 Stand";
