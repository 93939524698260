import { DateTime } from "luxon";
import { getItemFromSession } from "../views/shoppingsite/utils";
// import { getLeadId } from "../utils/isItemIdValid";

// eslint-disable-next-line no-unused-vars
const { default: axios } = require("axios");

export const saveLeadActivity = async (activityName) => {
  const businessNameFromLeadInfo = getItemFromSession(
    "businessNameFromLeadInfo"
  );
  const leadId = getItemFromSession("leadIdFromInfo");
  //   const leadValue = leadString?.split("-");
  // const leadId = getLeadId({ id: leadString });
  //   console.log({ leadString, leadId });
  const data = {
    id: leadId,
    leadActivity: activityName,
    businessName: businessNameFromLeadInfo,
  };
  updateLeadActivity(data);
};

export const savePageActivity = async (pageNumber, activity) => {
  const pageName = getPageName(pageNumber);
  const activityName = `${activity} button clicked from ${pageName}`;
  await saveLeadActivity(activityName);
  //   console.log({ activityName });
};

export const getPageName = (currentPageIndex) => {
  switch (currentPageIndex) {
    case 1:
      return "Check In Kioskk";
    case 2:
      return "Cashier Device";
    case 3:
      return "Smart CheckOut";
    case 4:
      return "Receipt Printer";
    case 5:
      return "Cash Drawer";
    case 6:
      return "Additional Devices";
    case 7:
      return "Business Information Form";
  }
};

export const updateLeadActivity = async (data) => {
  const time = new Date();
  const activityTime = DateTime.fromJSDate(time).toFormat(
    "yyyy-MM-dd HH:mm:ss"
  );

  const leadActivity = {
    id: data?.id,
    data: {
      BusinessName: data?.businessName,
      ActivityDate: activityTime,
      LeadStage: "Order",
      LeadActivity: data?.leadActivity,
    },
  };
  try {
    // console.log({ leadActivity });
    // eslint-disable-next-line no-unused-vars
    const res = await axios.post(
      `https://activepieces.proma.ai/api/v1/webhooks/mQWSoQCCGUOAqajqEJrXr`,
      {
        ...leadActivity,
      }
    );
    // console.log({ res });
  } catch (err) {
    console.log("Something went wrong");
    return err;
  }
};
