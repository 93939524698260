import React, { useEffect, useState } from "react";
import { FooterButton } from "../Document";
import PropTypes from "prop-types";
import RecommendedDevices from "./RecommendDevices";
import { getImageIds } from "../../shoppingsite/utils";
import Spinner from "../../../components/Spinner";
import { saveLeadActivity } from "../../../hooks/updateLeadActivity";

function AcceptCart({
  closeDetail,
  initialCartItems,
  acceptInitialCart,
  gettingLeadInfo,
  gettingImageId,
  setGettingImageId,

  //   settLoading,
}) {
  const [imageIds, setImageIds] = useState([]);

  const totalEquipments = Object.keys(initialCartItems).reduce((acc, items) => {
    return acc + initialCartItems[items];
  }, 0);

  //   console.log({ gettingLeadInfo, gettingImageId, totalEquipments });
  const loading = (totalEquipments === 0 && gettingLeadInfo) || gettingImageId;
  const noRecommendations =
    !gettingLeadInfo && !gettingImageId && totalEquipments === 0;
  //   console.log({ totalEquipments, initialCartItems });

  useEffect(() => {
    const fetchInventory = async () => {
      setGettingImageId(true);
      const data = await getImageIds();
      if (data?.length) {
        setImageIds(data);
        // console.log({ data });
        setGettingImageId(false);
        //   setLoading(false);
      } else {
        setImageIds([]);
        setGettingImageId(false);
      }
    };
    fetchInventory();
  }, []);
  return (
    <div className="w-full">
      {loading ? (
        <div className="h-60 flex w-full justify-center items-center">
          <Spinner className="text-2" loading={true} />
        </div>
      ) : noRecommendations ? (
        <div className="h-60 flex w-full  font-bold justify-center items-center">
          No Any Recommended Configuration!
        </div>
      ) : (
        <RecommendedDevices
          items={imageIds}
          initialCartItems={initialCartItems}
        />
      )}
      {!loading && (
        <div className="w-full   flex md:flex-row flex-col items-center  justify-center md:gap-10 gap-2 mt-5">
          <div className="text-center w-[85%] md:w-[30%] h-20 flex justify-center">
            <FooterButton
              className="flex text-white bg-[#4D1F70] capitalize w-full h-full justify-center items-center hover:text-yellow-500"
              onClick={async () => {
                console.log("Initial Cart Rejected");
                closeDetail();
                await saveLeadActivity("Recommendation Rejected");
              }}
              // disabled={currentPage === 7}
              // onClick={incrementPage}
              // data-analytics-name="Next Button Element"
              // data-analytics-eventtype="Clicked"
              //  data-analytics-payload={JSON.stringify({
              //       fromPage: currentPage,
              //       cartItems: equipment,
              //     })}
            >
              Build my own
              {/* <ChevronRight /> */}
            </FooterButton>
          </div>
          {!noRecommendations && (
            <div className="text-center w-[85%] md:w-[30%] h-20 flex justify-center">
              <FooterButton
                className="flex text-white text-center capitalize w-full h-full justify-center  items-center bg-[#4D1F70] hover:text-yellow-500"
                onClick={async () => {
                  acceptInitialCart();
                  closeDetail();
                  await saveLeadActivity("Recommendation Accepted");
                }}
                // disabled={currentPage === 7}
                // onClick={incrementPage}
                // data-analytics-name="Next Button Element"
                // data-analytics-eventtype="Clicked"
                //  data-analytics-payload={JSON.stringify({
                //       fromPage: currentPage,
                //       cartItems: equipment,
                //     })}
              >
                Accept recommendation
                {/* <ChevronRight /> */}
              </FooterButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
AcceptCart.propTypes = {
  closeDetail: PropTypes.func,
  acceptInitialCart: PropTypes.func,
  initialCartItems: PropTypes.object,
  gettingLeadInfo: PropTypes.bool,
  gettingImageId: PropTypes.bool,
  setGettingImageId: PropTypes.func,
};

export default AcceptCart;
