import axios from "axios";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const useLeadInfo = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { search } = location;

  // if search
  // console.log({ location });
  const getLeadInfo = async (leadId) => {
    // console.log({ leadId });
    let url = `/browser/leadInfo/${leadId}`;
    if (search === "?test") {
      console.log("From Test");
      url = `/browser/leadInfo/${leadId}?test=true`;
    }
    try {
      setLoading(true);
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      return res.data;
    } catch (err) {
      console.log("Something went wrong", err);
      setLoading(false);
      return {
        error: true,
      };
    }
  };

  return { getLeadInfo, loading };
};

export default useLeadInfo;
