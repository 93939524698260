import PropTypes from "prop-types";
import React from "react";

const CheckBox = ({ text, checked, onChange }) => {
  return (
    <div className="form-check mb-2">
      <input
        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        type="checkbox"
        value=""
        checked={checked}
        onChange={onChange}
        id={text}
      />
      <label
        className="form-check-label inline-block"
        htmlFor="flexCheckDefault"
      >
        {text}
      </label>
    </div>
  );
};

CheckBox.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
