import React from "react";
import UzeliLogo from "../../../assets/images/vuexy-logo.webp";
import Cart from "./Cart";
import PropTypes from "prop-types";
import User from "../../document/components/User";
export default function TopNavBar({
  cartItems,
  user,
  loginCallBack,
  switchUserCallBack,
  noCart,
}) {
  return (
    <div className=" w-full  flex justify-center">
      <div className=" z-50  flex w-full sm:w-screen md:w-4/5 items-center  justify-between align bg-[#f6f6f6] p-2 fixed">
        <img
          className="w-4/7 pl-2 sm:w-3/7 h-[30px]  sm:h-[40px] lg:h-full  lg:2/5 xl:w-1/5"
          src={UzeliLogo}
          alt="logo"
        />
        <div className=" ml-7 flex flex-row gap-1 sm:gap-5 md:gap-7 h-full justify-center sm:mr-2 text-[#4D1F70] items-center ">
          {!noCart && <Cart equipment={cartItems} />}
          <div className="  h-full items-center text-[#4D1F70] text-3xl sm:text-4xl top-3 right-9">
            <User
              user={user}
              switchUser={() => {
                if (switchUserCallBack) {
                  switchUserCallBack();
                }
              }}
              login={!user?.id && loginCallBack ? loginCallBack : null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
TopNavBar.propTypes = {
  cartItems: PropTypes.array,
  user: PropTypes.object,
  switchUserCallBack: PropTypes.func,
  loginCallBack: PropTypes.func,
  noCart: PropTypes.bool,
};
