import axios from "axios";

const useEquipmentOrder = () => {
  const createEquipmentOrder = async ({
    businessId,
    businessName,
    businessAddress,
    businessCity,
    businessState,
    businessZipCode,
    shippingAddress1,
    shippingAddress2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressZipCode,
    items,
    totalPrice,
    taxAmount,
    discountAmount,
    discountObject,
    salesPrice,
    orderStatus,
    approvedBy,
    approvalLink,
    uzeliUser,
    businessReprName,
    businessReprEmail,
    businessReprPhone,
    complimentaryReason,
    paymentName,
    paymentMethodId,
    invoiceId,
    paymentStatus,
  }) => {
    try {
      const res = await axios.post(
        `/browser/createEquipmentOrder`,
        {
          businessId,
          businessName,
          businessAddress,
          businessCity,
          businessState,
          businessZipCode,
          shippingAddress1,
          shippingAddress2,
          shippingAddressCity,
          shippingAddressState,
          shippingAddressZipCode,
          items,
          totalPrice,
          taxAmount,
          discountAmount,
          discountObject,
          salesPrice,
          orderStatus,
          approvalLink,
          uzeliUser,
          businessReprName,
          businessReprEmail,
          businessReprPhone,
          complimentaryReason,
          paymentName,
          paymentMethodId,
          invoiceId,
          paymentStatus,
          approvedBy,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res?.data;
    } catch (err) {
      console.log("Something went wrong");
      return err;
    }
  };

  return { createEquipmentOrder };
};

export default useEquipmentOrder;
