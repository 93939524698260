import React from "react";
import PropTypes from "prop-types";
import CustomMenu from "./CustomMenu";
import CartMenuLogo from "./CartItemMenu";
import CartItemMenu from "./CartItems";

function Cart({ equipment, gotoCheckout }) {
  return (
    <div className=" flex w-full justify-center items-center ">
      <CustomMenu
        menuIcon={<CartMenuLogo equipment={equipment} />}
        menuContent={
          <CartItemMenu equipment={equipment} gotoCheckout={gotoCheckout} />
        }
        gotoCheckout={gotoCheckout}
        className="right-7 sm:right-20 md:right-32"
      />
    </div>
  );
}
Cart.propTypes = {
  equipment: PropTypes.object,
  gotoCheckout: PropTypes.func,
};
export default Cart;
