import axios from "axios";

const getItemId = async (leadId) => {
  if (leadId && leadId !== "") {
    try {
      const response = await axios.get(`/browser/itemId/${leadId}`);
      return response.data?.data;
    } catch (err) {
      console.log(err, "Something went wrong.");
      return null;
    }
  } else {
    throw new Error("Invalid Lead Id ", leadId);
  }
};

export default getItemId;
