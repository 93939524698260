import React, { useEffect, useState } from "react";
import DataTable from "../../document/components/ItemTable";
import { H5 } from "../../document/components/CustomForm";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PaymentIcon from "@mui/icons-material/Payment";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import {
  getShipbobLogDetails,
  setOrderTrackingUrl,
} from "../../../utils/signAgreementDocument";
import { getFormattedDate, isAuthorizedPaymentApprover } from "../utils";
// import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";

const EquipmentStatusContent = ({ order, setLoading, user }) => {
  const [trackingUrl, setTrackingUrl] = useState(null);
  const [orderStatusDetails, setOrderStatusDetails] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);

  const shipbobProducts = order?.items?.filter(
    (product) => product?.provider === "shipbob"
  );

  const createdDate = getFormattedDate(
    order?.createdDate || order?.createdTime
  );

  const placedBy = JSON.parse(order?.uzeliUser ?? "{}");

  useEffect(async () => {
    const statusDetail = await getShipbobLogDetails(order?.providerId);
    setOrderStatusDetails(statusDetail);
    setTrackingUrl(order?.trackingUrl);
    setLoading(false);
  }, []);

  const modifiedTime = getFormattedDate(order?.modifiedTime);
  // if(getEnvironmentValue().)
  // console.log({ order });
  return (
    <div className="bg-white    px-4 py-5 pb-7 mb-3  font-sans justify-center w-full  rounded-md shadow-md flex flex-col  gap-4">
      <div className="w-full flex flex-col sm:flex-row ">
        <div className=" w-full md:w-2/5 whitespace-break-spaces">
          <div className="text-straight font-bold">
            Order#:
            <span id="orderId" className="pl-1">
              {order?.orderId}
            </span>
          </div>
          <div className="text-straight">
            {order?.orderStatus === "cancelled" ? "Cancelled" : "Placed"}:
            <span className="whitespace-nowrap pl-1">
              {order?.orderStatus === "cancelled" ? modifiedTime : createdDate}
            </span>
          </div>
        </div>
        {user?.role === "ADMIN" && placedBy?.id && (
          <div className=" w-full md:w-1/3 whitespace-break-spaces">
            <div>
              <div className="text-straight font-bold">Placed by</div>
              <div className="flex flex-col ">
                <div className="text-straight">
                  name:
                  <span className="whitespace-nowrap pl-1 ">
                    {placedBy?.name}
                  </span>
                </div>
                <div className="text-straight">
                  id:
                  <span className="whitespace-nowrap pl-1 ">
                    {placedBy?.id}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr className="w-100"></hr>

      <div className="md:flex w-full  ">
        <div className="w-full md:w-2/5">
          <H5 className="text mb-1  font-semibold">Business Name</H5>
          <div className="text-sm">
            <p>{`${order?.businessName}`}</p>
            <p>Business Id: {`${order?.businessId}`}</p>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <H5 className="text mb-1 font-semibold">Contact</H5>
          <div className="text-sm">
            <p>{`${order?.businessReprName}`}</p>
            <p>{`Email: ${order?.businessReprEmail}`}</p>
            <p>{`Phone: ${order?.businessReprPhone}`}</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-2/5">
        <H5 className="text mb-1  font-semibold">Shipping Address</H5>
        <div className="text-sm">
          <p>{`${order?.shippingAddress1}`}</p>
          {order?.shippingAddress2 && <p>{`${order?.shippingAddress2}`}</p>}
          <p>
            {order?.shippingAddressCity +
              " " +
              order?.shippingAddressState +
              " " +
              order?.shippingAddressZipCode}
          </p>
        </div>
      </div>
      <hr className="w-100"></hr>

      <div className="flex flex-col w-full md:w-2/5 whitespace-nowrap">
        <H5 className="text flex  mb-1  font-semibold">Payment Details</H5>
        <div className="flex flex-col lg:flex-row lg:gap-20">
          <div className="flex flex-col  md:flex-row   text-sm  gap-1 md:gap-20">
            <div className="flex flex-row ">
              <p>{`Invoice Id: ${
                order?.invoice?.id || order?.invoiceId || "No invoice"
              }`}</p>
              {(order?.invoice?.id || order?.invoiceId) && (
                <a
                  target="_blank"
                  href={`https://accountstagingv2.growthzilla.com/growthzilla/remote/public/protected/business/${
                    order?.businessId
                  }/billing?uid=${user?.id}&dest=api/invoice/:UID:/pdf/${
                    order?.invoice?.id || order?.invoiceId
                  }`}
                  rel="noreferrer"
                >
                  <span>
                    <Tooltip className="h-2 w-2" title="Click to invoice">
                      <InfoOutlinedIcon
                        className="bg-white mb-0.5   text-black"
                        fontSize="inherit"
                        fontWeight="900"
                        onClick={() => {
                          // setOpenDetailDialog(true);
                          // console.log("Clicked");
                        }}
                      />
                    </Tooltip>
                  </span>
                </a>
              )}
            </div>
            <div className="flex flex-row ">
              <div className="flex text-sm text-straight">
                <p className="whitespace-nowrap">Payment Name: </p>
                <PaymentIcon className="text-blue-500" />
                <p className="whitespace-nowrap">
                  {order?.paymentName || "Not specified"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex text-sm gap-2">
            <p> Payment Status:</p>
            {order?.paymentName !== "complimentary" &&
            order?.paymentMethodId ? (
              <p className="font-bold uppercase">
                {(order?.invoice?.paymentStatus || order?.paymentStatus) ??
                  "pending"}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <H5 className="text mb-1 font-semibold">Order Details</H5>
        <div className="flex text-sm sm:gap-20 gap-1 flex-col sm:flex-row whitespace-nowrap">
          <div>
            {orderStatusDetails?.shippingStatus ? (
              <p>{`Status: ${orderStatusDetails?.shippingStatus}`}</p>
            ) : (
              <div className="flex text-sm gap-2 ">
                <p>Status: </p>
                <p className="uppercase"> {order?.orderStatus}</p>

                {/* {isAuthorizedPaymentApprover(user) ? (
                        <div
                          onClick={() => {
                            console.log("Open Input");
                          }}
                        >
                          <EditIcon fontSize="5" />
                        </div>
                      ) : null} */}
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row text-sm gap-2">
            <p>{`${open ? "Add Tracking Url" : "Track your order"}`}:</p>
            {open ? (
              <div className="flex  gap-1">
                <input
                  className="border-2 border-blue-600 pl-1 rounded-md outline-none"
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                />
                <div className="flex flex-row">
                  <div
                    onClick={async () => {
                      setTrackingUrl(inputValue);

                      if (
                        (shipbobProducts.length && order?.providerId) ||
                        !shipbobProducts.length
                      ) {
                        await setOrderTrackingUrl({
                          orderId: order?.orderId ?? null,
                          trackingUrl: inputValue,
                          isEquipment: true,
                        });
                        setOpen(false);
                      } else {
                        toast.error("Order Not dispatched Yet");
                        console.log("Order Not dispatched Yet");
                      }
                    }}
                  >
                    <Tooltip title="Save" placement="top" arrow>
                      <CheckIcon className="text-green-700" color="green" />
                    </Tooltip>
                  </div>
                  <span
                    onClick={() => {
                      // setTrackingUrl(tr);
                      setOpen(false);
                    }}
                  >
                    <Tooltip title="Cancel" placement="top" arrow>
                      <CloseIcon className="text-red-700" />
                    </Tooltip>
                  </span>
                </div>
              </div>
            ) : (
              <span className="flex flex-row gap-1">
                {trackingUrl ? (
                  <a href={trackingUrl} className="text-blue-600 ml-2">
                    {`${trackingUrl ? "here" : null}`}
                  </a>
                ) : (
                  <p>Tracking available soon</p>
                )}
                <span>
                  {isAuthorizedPaymentApprover(user) ? (
                    <div
                      onClick={() => {
                        if (order?.providerId) {
                          setOpen(true);
                          setInputValue(trackingUrl ?? "");
                          console.log("Open Input", trackingUrl);
                        } else {
                          toast.error("Order Not dispatched Yet");
                          console.log("Order Not dispatched Yet");
                        }
                      }}
                    >
                      <Tooltip title="Add Tracking Url" placement="top" arrow>
                        <EditIcon fontSize="5" />
                      </Tooltip>
                    </div>
                  ) : null}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col">
        <H5 className="text  flex font-bold w-full  h-10 text-black   items-center border-b-2">
          Items
        </H5>
        <div className="w-full  overflow-y-scroll scrollbar-hide">
          <DataTable
            admin={isAuthorizedPaymentApprover(user)}
            data={order?.items}
          />
        </div>
      </div>

      <div className="flex-col ">
        <p className="font-semibold">
          Total Price:{" "}
          <span className="font-normal text-sm">${order?.totalPrice}</span>
        </p>
      </div>
    </div>
  );
};
EquipmentStatusContent.propTypes = {
  order: PropTypes.object,
  setLoading: PropTypes.func,
  user: PropTypes.object,
};
export default EquipmentStatusContent;
