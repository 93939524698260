import React from "react";
import PropTypes from "prop-types";
import CustomMenu from "../../document/components/CustomMenu";
import CartMenuLogo from "./CartItemMenu";
import CartItemMenu from "./CartItems";

function Cart({ equipment, gotoCheckout }) {
  return (
    <div className=" flex w-full justify-center items-center ">
      <CustomMenu
        menuIcon={<CartMenuLogo equipment={equipment} />}
        menuContent={
          <CartItemMenu
            equipment={equipment}
            gotoCheckout={gotoCheckout}
            fromStore={true}
          />
        }
      />
    </div>
  );
}
Cart.propTypes = {
  equipment: PropTypes.array,
  gotoCheckout: PropTypes.func,
};
export default Cart;
