import React, { useContext, useEffect, useState } from "react";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import IFrameComponent from "../../document/components/IFrameComponent";
// import UzeliLogo from "../../../assets/images/vuexy-logo.webp";

// import Cart from "../components/Cart";
import ShoppingSiteContext from "../context";
import BusinessInformation from "./BusinessInformation";
// import User from "../../document/components/User";
import TopNavBar from "../components/TopNavBar";
import { getItemFromSession, saveItemsToSession } from "../utils";

const CheckoutPage = () => {
  const [showFrame, setShowFrame] = useState(true);
  const [user, setUser] = useState(null);
  const listener = {
    loginResponse: (user, adminBusiness) => {
      setUser(user);
      saveItemsToSession("user-s", user);
      saveItemsToSession(`admin-${user?.id}`, adminBusiness);
      setShowFrame(false);
    },
  };

  const displayIframeStyle = {
    background: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };
  useEffect(() => {
    const currentUser = getItemFromSession("user-s");
    if (currentUser?.id) {
      setShowFrame(false);
      setUser(currentUser);
    }
  }, []);
  const { cartItems } = useContext(ShoppingSiteContext);
  const switchUser = () => {
    setShowFrame(true);
  };
  return (
    <div>
      <div className="w-full flex justify-center">
        {/* <div className=" z-50 flex w-4/5  justify-between align bg-[#4D1F70] p-2 fixed">
            <img src={UzeliLogo} alt="logo" />
            <div className="flex justify-end text-white items-center text-4xl">
              <Cart equipment={cartItems} />
              {user?.id && (
                <User
                  user={user}
                  switchUser={() => {
                    setShowFrame(true);
                  }}
                />
              )}
            </div>
          </div> */}
        <TopNavBar
          cartItems={cartItems}
          user={user}
          switchUserCallBack={user?.id ? switchUser : null}
        />
      </div>
      {showFrame ? (
        <div style={showFrame ? displayIframeStyle : hiddenIframeStyle}>
          {
            <IFrameComponent
              listener={listener}
              iFrameSrc={getEnvironmentValue().EMBED_LOGIN_URL}
            />
          }
        </div>
      ) : (
        <BusinessInformation />
      )}
    </div>
  );
};
export default CheckoutPage;
