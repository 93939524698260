import axios from "axios";
import { US_STATES } from "./USStates";
// import { ARIES_STAND, PAX_TERMINAL } from "../views/document/const";
import { itemFromSession } from "../views/shoppingsite/utils";

export const getGrowthzillaSigneeEmail = (signee) => {
  return signee?.includes("Don")
    ? "dcoppersmith@growthzilla.com"
    : "sbashyal@growthzilla.com";
};

const getStateAbbreviation = (state) => {
  return state?.length === 2 ? state : US_STATES[state];
};

export const generateBillingNote = (
  minimumForInstallments,
  installments,
  taxRates,
  businessState,
  tempDiscountExpiryDate,
  equipDiscount,
  discountOnSetupFee,
  setUpFee,
  planTempDiscount,
  monthlyFee,
  planPermDiscount,
  discountOnMonthlyFee
) => {
  // console.log({
  //   taxRates,
  //   businessState,
  //   tempDiscountExpiryDate,
  //   equipDiscount,
  //   discountOnSetupFee,
  //   setUpFee,
  //   planTempDiscount,
  //   monthlyFee,
  //   planPermDiscount,
  //   discountOnMonthlyFee,
  // });
  const state = getStateAbbreviation(businessState?.toLocaleUpperCase());
  const tax = taxRates?.find((item) => item?.state === state)?.taxRate || 0;
  // console.log({ tax });
  equipDiscount = equipDiscount || 0;
  tempDiscountExpiryDate = tempDiscountExpiryDate || null;
  discountOnMonthlyFee = discountOnMonthlyFee || 0;
  setUpFee = setUpFee || 0;
  planPermDiscount = planPermDiscount || 0;
  monthlyFee = monthlyFee || 0;
  planTempDiscount = planTempDiscount || 0;

  let isTempDiscountExpired = false;
  if (tempDiscountExpiryDate) {
    isTempDiscountExpired = new Date() > new Date(tempDiscountExpiryDate);
  }
  let billingNote1 = "";
  let installmentNote = "";
  let setUpAfterTax;
  let taxInclusionNote = `, inclusive of a ${tax}% ${state} (State) tax. `;

  // console.log({ setUpFee, equipDiscount, tax });
  if (equipDiscount > 0 && discountOnSetupFee) {
    const setupAfterDiscount = Number(setUpFee) - Number(equipDiscount);
    // console.log({ setupAfterDiscount, equipDiscount });
    setUpAfterTax =
      setupAfterDiscount + (Number(tax) / 100) * setupAfterDiscount;
    // console.log((Number(tax) / 100) * setupAfterDiscount);
    billingNote1 = `Total Setup fee of $${setUpAfterTax.toFixed(
      2
    )} after $${equipDiscount} discount`;
  } else {
    setUpAfterTax = Number(setUpFee) + (Number(tax) / 100) * Number(setUpFee);
    billingNote1 = "Total Setup fee of $" + setUpAfterTax.toFixed(2);
  }
  // console.log({ installments, minimumForInstallments, setUpAfterTax });
  if (
    Number(installments) > 0 &&
    Number(minimumForInstallments ?? 0) < Number(setUpFee)
  ) {
    const individualInstallment = (
      Number(setUpAfterTax) / Number(installments)
    ).toFixed(2);
    const remainingInstallments = installments - 1;
    installmentNote =
      "Your due today is $" +
      individualInstallment +
      "." +
      " You will have " +
      remainingInstallments +
      " future installments of $" +
      individualInstallment;
    if (remainingInstallments <= 1) {
      installmentNote = installmentNote + ".";
    } else {
      installmentNote = installmentNote + " each.";
    }
  }

  let billingNote2 = " Monthly service fee will be $";
  const appliedTotalDiscount =
    (planPermDiscount +
      (new Date(tempDiscountExpiryDate) > new Date() ? planTempDiscount : 0)) *
    Number(discountOnMonthlyFee);
  if (planTempDiscount > 0 && !isTempDiscountExpired && discountOnMonthlyFee) {
    billingNote2 =
      billingNote2 +
      (monthlyFee - appliedTotalDiscount) +
      " until " +
      tempDiscountExpiryDate +
      " and after that it will be $";
  }
  const appliedPermDiscount = planPermDiscount * Number(discountOnMonthlyFee);
  billingNote2 =
    billingNote2 + (monthlyFee - appliedPermDiscount) + " per month";

  if (appliedPermDiscount > 0) {
    billingNote2 =
      billingNote2 + " ($" + appliedPermDiscount + " monthly discount).";
  } else {
    billingNote2 = billingNote2 + ".";
  }

  let billingNote =
    billingNote1 + taxInclusionNote + installmentNote + billingNote2;
  // console.log({ billingNote });
  return billingNote;
};

const prepareDocument = ({
  minimumForInstallments,
  installments,
  businessInfo,
  taxRates,
  businessState,
  equipment,
  uzeliPlan,
  setUpFee,
  equipDiscount,
  discountOnSetupFee,
  monthlyFee,
  planPermDiscount,
  planTempDiscount,
  tempDiscountExpiryDate,
  growthzillaSignee,
  monthlySMSQuota,
  discountOnMonthlyFee,
  uzeliOrderId,
}) => {
  let billingNote = generateBillingNote(
    minimumForInstallments ?? 0,
    installments ?? 0,
    taxRates,
    businessState,
    tempDiscountExpiryDate,
    equipDiscount,
    discountOnSetupFee,
    setUpFee,
    planTempDiscount,
    monthlyFee,
    planPermDiscount,
    discountOnMonthlyFee
  );

  //Add ARIES STAND FOR EVERY PAX TERMINAL
  // if (equipment[PAX_TERMINAL] > 0) {
  //   equipment[ARIES_STAND] = equipment[PAX_TERMINAL];
  // }
  const formattedEquipments = Object.entries(equipment)
    .map(([key, value]) => `${value} X ${key}${value === 1 ? "" : "s"}`)
    .join("<br/>");

  const growthzillaSigneeTitle = growthzillaSignee?.includes("Don")
    ? "Chief of Sales"
    : "CEO";

  const getFormattedDate = (date) => {
    if (!date)
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const getBillingDate = (date) => {
    const newDate = new Date(date);

    if (newDate.getDate() > 15) {
      newDate.setMonth(newDate.getMonth() + 1);
      newDate.setDate(1);
    } else {
      newDate.setDate(15);
    }

    return newDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const signeeFromSession = itemFromSession("GrowthzillaSignee");

  return {
    BusinessName: businessInfo?.businessName,
    Address: businessInfo?.businessAddress,
    CityZip: businessInfo?.businessCityStateZip,
    ContactName: businessInfo?.contactName,
    ContactPhone: "+" + businessInfo?.contactNumber,
    ContactEmail: businessInfo?.contactEmail,
    EffectiveDate: getFormattedDate(),
    MonthlyFee: monthlyFee,
    SetupFee: setUpFee,
    Installments: installments,
    MinimumForInstallments: minimumForInstallments,
    ServiceStartDate: getFormattedDate(businessInfo?.startDate),
    BillingStartDate: getBillingDate(businessInfo?.startDate),
    MonthlySMSQuota: monthlySMSQuota,
    Additional1KSMSPrice: "15", //default 15
    Equipment: formattedEquipments,
    Plan: uzeliPlan,
    BillingNotes: billingNote,
    GrowthzillaSignee:
      growthzillaSignee !== "" ? growthzillaSignee : signeeFromSession,
    GrowthzillaSigneeTitle: growthzillaSigneeTitle,
    UzeliOrderId: uzeliOrderId,
  };
};
// eslint-disable-next-line no-unused-vars
const getEverSignInfomation = async (orderId) => {
  if (orderId) {
    try {
      const response = await axios.get(`/browser/everSignInfo/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("IN Client", response);
      const data = response?.data;
      const orderDetail = data?.data;
      // console.log({ orderDetail });
      if (orderDetail?.templateTransactionRef) {
        // console.log("Embedded SIgning URL", agreementDetails);
        return { data: orderDetail?.templateTransactionRef, success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log(error, "Something went wrong.");
      return null;
    }
  }
};
export const requestPdf = async (data) => {
  try {
    // console.log({ preparingDocument: data });
    // const everSignInfo = await getEverSignInfomation(data?.uzeliOrderId);
    // console.log({ everSignInfo });
    // if (everSignInfo?.data) {
    // return everSignInfo;
    // } else {
    const formattedData = prepareDocument(data);
    const response = await axios.post("/browser/requestPdf", formattedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response?.data;
    // }
  } catch (error) {
    console.log(error, "Something went wrong. while creating pdf", error);
    return null;
  }
};
