import React, { useEffect, useState } from "react";
// import { Check } from "react-feather";
// import Lottie from "react-lottie-player";
// import lottieJson from "../../../assets/json/successlottie.json";
import { Link, useParams } from "react-router-dom";
// import { fetchOrderDetail } from "../../../utils/signAgreementDocument";
import Spinner from "../../../components/Spinner";
import Cart from "../../../assets/images/Cart.png";
// import Header from "../../shoppingsite/components/Header";
import IFrameComponent from "../components/IFrameComponent";
// import User from "../components/User";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import TopNavBar from "../../shoppingsite/components/TopNavBar";

const SuccessPage = () => {
  const params = useParams();
  const { status, orderId, id } = params;
  // const [uzeliOrderId, setUzeliOrderId] = useState(orderId);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const [iframeSource, setIframeSource] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );
  const [showFrame, setShowFrame] = useState(true);

  // const getOrderId = async () => {
  //   if (transactionRef) {
  //     setLoading(true);
  //     const orderDetail = await fetchOrderDetail(transactionRef);
  //     const user = JSON.parse(sessionStorage.getItem("user") ?? "{}");
  //     setUser(user);
  //     if (orderDetail?.orderId) {
  //       setUzeliOrderId(orderDetail?.orderId);
  //     }
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    setLoading(true);
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    const currentUserStore = JSON.parse(
      sessionStorage.getItem("user-s") || "{}"
    );
    if (currentUser?.id || currentUserStore?.id) {
      setShowFrame(false);
      setUser(currentUser);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Orders Finished ",
        eventtype: "viewed",
        payload: { user: user?.id, orderId: orderId, status: status },
      });
  }, [user]);
  // useEffect(() => {
  //   if (!id) {
  //     getOrderId();
  //   }
  // }, [transactionRef]);

  const displayIframeStyle = {
    background: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };
  const listener = {
    loginResponse: (user) => {
      setUser(user);
      setShowFrame(false);
      sessionStorage.setItem("user", JSON.stringify(user));
    },
  };
  const switchUser = () => {
    setShowFrame(true);
    setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
  };

  return (
    <div className=" flex flex-col  w-screen ">
      {/* <div className="z-50 flex w-screen md:w-4/5 justify-center ">
        <div className=" flex justify-between flex-row  bg-[#4D1F70]  w-full">
          <Header />
          <div className="flex   text-white text-4xl">
            <div>
              <User
                user={user}
                switchUser={() => {
                  setShowFrame(true);
                  setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
      <TopNavBar user={user} noCart={true} switchUserCallBack={switchUser} />
      <div>
        {iframeSource && (
          <div
            className="h-full w-full flex justify-center scrollbar-hide"
            style={showFrame ? displayIframeStyle : hiddenIframeStyle}
          >
            <IFrameComponent listener={listener} iFrameSrc={iframeSource} />
          </div>
        )}
      </div>
      {orderId && loading && (
        <div className="h-[85vh] w-full">
          <Spinner loading={true} />
        </div>
      )}
      {!loading && !showFrame && (
        <div className="shadow-sm rounded-md p-10">
          <div className="w-full">
            <div className="flex  justify-center mt-6 sm:mt-10 mb-4">
              <img
                className="w-20 sm:w-32"
                width={125}
                height={125}
                src={Cart}
                color="#00ba37"
                size={75}
                strokeWidth={4}
              />
            </div>
            <h5 className="text-gray-900 text-xl sm:text-4xl text-center leading-tight font-bold">
              Thank You
            </h5>
          </div>

          {status === "finished" || status === "waiting1" ? (
            <p className="text-gray-700 text-xs sm:text-base text-center mb-4">
              We have received your order and will start working on it asap..
            </p>
          ) : status === "waiting2" ? (
            <p className="text-gray-700 text-xs sm:text-base text-center mb-4">
              We are waiting for customer to sign the agreement before
              processing the order..
            </p>
          ) : status === "approved" ? (
            <p className="text-gray-700 text-xs sm:text-base text-center mb-4">
              Order is already approved
            </p>
          ) : (
            <></>
          )}
          <div className=" mb-4  flex justify-center">
            <hr
              style={{ height: "5px" }}
              className="w-40 text-back-900 font-bold "
            />
          </div>
          {(orderId || id) && (
            <div>
              <div className="flex font-bold text-base  sm:text-xl justify-center whitespace-nowrap ">
                Your Order Id is{" "}
                <span id="orderId" className="font-bold ml-2 mr-1">
                  {" "}
                  {orderId ?? id}
                </span>
                .
              </div>
              <div className="flex   text-xs sm:text-base justify-center whitespace-nowrap">
                Check your order status
                <Link
                  to={
                    id
                      ? `../app/store/status/${id}`
                      : `../app/status/${orderId}`
                  }
                  className="text-blue-600 ml-2"
                >
                  here
                </Link>
              </div>
              <div className="flex w-full mt-10 font-semibold hover:underline  text-xs sm:text-base justify-center">
                <Link to={`../app/store`} className="text-blue-600 ml-2">
                  <button className=" bg-[#4D1F70]  text-white rounded-md p-2 shadow-xl">
                    Go to Store
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SuccessPage;
