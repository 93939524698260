import axios from "axios";
export const signDocument = (
  embeddedSigningUrl,
  finishedLoadingCallback,
  finishedSigningCallback,
  errorCallback
) => {
  // console.log("Embedded signing Url", embeddedSigningUrl);

  window.eversign.open({
    url: embeddedSigningUrl,
    containerID: "everSignId",
    width: 1200,
    height: 1080,
    events: {
      loaded: async function () {
        await finishedLoadingCallback();
        console.log("loaded Callback");
      },
      signed: async function () {
        await finishedSigningCallback();
      },
      declined: async function (e) {
        await errorCallback(e);
        console.log("declined Callback");
      },
      error: async function (e) {
        await errorCallback(e);
      },
    },
  });
};

export const getEmbeddedSigningUrl = async (transactionRef, hash) => {
  if (transactionRef) {
    try {
      let url = `/browser/embeddedSigningUrl/${transactionRef}`;
      if (hash) {
        url = `/browser/hash/embeddedSigningUrl/${transactionRef}`;
      }
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("IN Client", response);
      const agreementDetails = response?.data;
      if (agreementDetails?.embeddedSigningUrl) {
        // console.log("Embedded SIgning URL", agreementDetails);
        return agreementDetails;
      }
    } catch (error) {
      console.log(error, "Something went wrong.", error);
      return null;
    }
  }
};

export const getTransactionRef = async (orderId) => {
  if (orderId) {
    try {
      const response = await axios.get(`/browser/transactionRef/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("IN Client", response);
      const orderDetail = response?.data;
      if (orderDetail?.templateTransactionRef) {
        // console.log("Embedded SIgning URL", agreementDetails);
        return orderDetail?.templateTransactionRef;
      }
    } catch (error) {
      console.log(error, "Something went wrong.");
      return null;
    }
  }
};

export async function fetchOrderDetail(orderId) {
  // console.log("Here", transactionRef);
  try {
    const res = await axios.get(`/browser/getOrderDetail/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Data", res.data);
    if (res.status === 200) {
      return res?.data;
    } else {
      console.log("No Data Found");
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}

export async function fetchOrderDetailFromLeadId(leadId) {
  // console.log("Here", transactionRef);
  try {
    const res = await axios.get(`/browser/getOrderLead/${leadId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Data", res.data);
    if (res.status === 200) {
      return res?.data;
    } else {
      console.log("No Data Found");
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}
export async function getShipbobLogDetails(providerId) {
  // console.log("Here", providerId);
  try {
    if (providerId) {
      const res = await axios.get(`/browser/getShipbobLog/${providerId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("Data", res.data);
      if (res.status === 200) {
        return res?.data;
      } else {
        console.log("No Data Found");
        return null;
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}
export async function setOrderTrackingUrl(data) {
  try {
    const res = await axios.post(
      `/browser/setTrackingUrl`,
      {
        orderId: data?.orderId,
        trackingUrl: data?.trackingUrl,
        isEquipment: data?.isEquipment,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (err) {
    console.log("Something went wrong");
    return err;
  }
}
export async function fetchEquipmentOrderDetail(orderId) {
  // console.log("Here", transactionRef);
  try {
    const res = await axios.get(`/browser/getEquipmentOrderDetail/${orderId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Data", res.data);
    if (res.status === 200) {
      return res?.data;
    } else {
      console.log("No Data Found");
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}

export async function dispatchOrder({
  recipientName,
  recipientAddress: {
    shippingAddress1,
    shippingAddress2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressZipCode,
    businessName,
  },
  recipientEmail,
  recipientPhone,
  products,
  referenceId,
}) {
  try {
    const res = await axios.post(
      `/browser/dispatchOrder`,
      {
        recipientName: recipientName,
        recipientAddress: {
          shippingAddress1,
          shippingAddress2,
          shippingAddressCity,
          shippingAddressState,
          shippingAddressZipCode,
          businessName,
        },
        recipientEmail,
        recipientPhone,
        products,
        referenceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (err) {
    console.log("Something went wrong");
    return err;
  }
}
export async function dispatchEquipmentOrder({
  recipientName,
  recipientAddress: {
    shippingAddress1,
    shippingAddress2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressZipCode,
    businessName,
  },
  recipientEmail,
  recipientPhone,
  products,
  referenceId,
}) {
  try {
    const res = await axios.post(
      `/browser/dispatchEquipmentOrder`,
      {
        recipientName: recipientName,
        recipientAddress: {
          shippingAddress1,
          shippingAddress2,
          shippingAddressCity,
          shippingAddressState,
          shippingAddressZipCode,
          businessName,
        },
        recipientEmail,
        recipientPhone,
        products,
        referenceId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res?.data;
  } catch (err) {
    console.log("Something went wrong");
    return err;
  }
}

export const fetchTaxRates = async () => {
  const taxResponse = await axios.get("/browser/taxrates", {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return taxResponse?.data;
};

export async function fetchUzeliOrders(status, page) {
  const orderStatus = status !== "" && status ? status : null;
  try {
    const res = await axios.get(
      `/browser/uzeliOrders/${orderStatus}/${page ?? 1}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log("Data", res.data);
    if (res.status === 200) {
      return res?.data;
    } else {
      console.log("No Data Found");
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}

export async function fetchEquipmentOrders(status, page) {
  const orderStatus = status !== "" && status ? status : null;
  if (orderStatus) {
    try {
      const res = await axios.get(
        `/browser/equipmentOrders/${orderStatus}/${page ?? 1}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Data", res.data);
      if (res.status === 200) {
        return res?.data;
      } else {
        console.log("No Data Found");
        return null;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  }
}
