import React, { useEffect, useState } from "react";
// import useAgreementStatus from "../../../hooks/useAgreementStatus";

import { fetchOrderDetail } from "../../../utils/signAgreementDocument";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import IFrameComponent from "../components/IFrameComponent";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import { clearSessionStorage } from "../../../utils/clearSessionStorage";
import TopNavBar from "../../shoppingsite/components/TopNavBar";
import UzeliStatusContent from "./UzeliStatusContent";
import InvalidAccess from "../../../components/InvalidAccess";

const StatusPage = () => {
  const [pendingOrder, setPendingOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showFrame, setShowFrame] = useState(true);
  const [error, setError] = useState(false);
  const [iframeSource, setIframeSource] = useState(
    getEnvironmentValue().EMBED_LOGIN_URL
  );

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { orderId } = params;
  const { pathname } = location;
  const fromFirstPage = pathname.split("/")[2] === "order";

  // console.log({ fromFirstPage });

  const getOrderDetail = async () => {
    setLoading(true);
    const orderDetail = await fetchOrderDetail(orderId);
    return orderDetail;

    // setLoading(false);
  };

  useEffect(() => {
    clearSessionStorage();
  }, []);

  const displayIframeStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  const checkIfUnauthorizedUser = (orderDetail) => {
    const uzeliUserId = JSON.parse(orderDetail?.uzeliUser ?? "{}")?.id;
    // console.log({ user, uzeliUserId });
    if (uzeliUserId !== user?.id) {
      return true;
    }
  };

  useEffect(async () => {
    if (user?.id) {
      const orderDetail = await getOrderDetail();
      const isUnauthorized = checkIfUnauthorizedUser(orderDetail);
      if (fromFirstPage && isUnauthorized) {
        setError(true);
      } else if (orderDetail?.orderId) {
        setError(false);
        window.GZAnalytics &&
          window.GZAnalytics.customLog({
            name: "Orders Status",
            eventtype: "viewed",
            payload: { user: user?.id, orderId: orderId },
          });
        setPendingOrder(orderDetail);
      } else {
        navigate("/app/error/store/failed");
      }
      setLoading(false);
    }
  }, [user]);

  useEffect(async () => {
    const userFromSession = JSON.parse(sessionStorage.getItem("user"));
    if (userFromSession?.id) {
      setUser(userFromSession);
      setShowFrame(false);

      // console.log("User", userFromSession);
    }
  }, []);

  const switchUser = () => {
    setShowFrame(true);
    setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
  };

  const listener = {
    loginResponse: (user) => {
      setUser(user);
      sessionStorage.setItem("user", JSON.stringify(user));
      setShowFrame(false);
    },
  };

  return (
    <>
      <div
        data-analytics-name="Uzeli Orders Status"
        data-analytics-eventtype="statusLoaded"
        data-analytics-payload={JSON.stringify({
          orderId: orderId,
          user: user?.id,
        })}
        className=" flex flex-col  w-screen h-fit "
      >
        {/* <div className=" flex  relative w-full">
        <div className="w-full">
          <Header />
        </div>
        <div className=" absolute  text-white  top-4 right-5">
          {user?.id && (
            <User
              user={user}
              switchUser={() => {
                setShowFrame(true);
                setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
              }}
            />
          )}{" "}
        </div>
      </div> */}
        <div className="w-full flex justify-center">
          <TopNavBar
            noCart={true}
            user={user}
            switchUserCallBack={user?.id ? switchUser : null}
          />
        </div>
        <div className="h-full flex  justify-center ">
          {showFrame && (
            <div
              className="h-full"
              style={showFrame ? displayIframeStyle : hiddenIframeStyle}
            >
              <IFrameComponent
                listener={listener}
                // messageToIFrame={preInvoice}
                iFrameSrc={iframeSource}
              />
            </div>
          )}
          {loading && !showFrame && (
            <div className="h-[85vh]">
              <Spinner loading={true} message={"Loading Order Status"} />
            </div>
          )}
          {error && !loading && !showFrame ? <InvalidAccess /> : <></>}
          {!loading && !showFrame && pendingOrder?.orderId && !error && (
            <div className="w-full h-full flex flex-col  md:w-4/5    pt-14 lg:pt-20  justify-center">
              <div className=" w-full flex bg-white font-bold  pt-2 justify-center text-2xl">
                Order Summary
              </div>
              <UzeliStatusContent
                user={user}
                orderDetail={pendingOrder}
                setLoading={setLoading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusPage;
