import axios from "axios";
import { useEffect, useState } from "react";

const useAgreementStatus = (agreementDocumentHash) => {
  const [agreementStatus, setAgreementStatus] = useState(null);
  useEffect(() => {
    const getAgreementStatus = async () => {
      if (agreementDocumentHash) {
        try {
          let res = {};

          res = await axios.get(
            `/browser/signingStatus/${agreementDocumentHash}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (res?.data.success) {
            setAgreementStatus(res?.data?.data);
          } else {
            setAgreementStatus({});
          }
        } catch (err) {
          console.log("Something went wrong");
          return err;
        }
      }
    };
    getAgreementStatus();
  }, []);
  return { agreementStatus };
};

export default useAgreementStatus;
