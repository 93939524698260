/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Spinner from "../../components/Spinner";
import useEquipments from "./hooks/useEquipments";
import Equipments from "./pages/Equipments";
import BusinessInformation from "./pages/BusinessInformation";
import ShoppingSiteContext from "./context";
import CheckoutPage from "./pages/CheckoutPage";
const ShoppingSite = () => {
  const {
    loading,
    currentPage,
    incrementPage,
    decrementPage,
    inventory,
    cartItems,
    setCartItems,
    getTotalPrice,
    getTotalDiscountPrice,
  } = useEquipments();

  const activeSection = () => {
    if (currentPage === 1) return <Equipments />;
    else return <CheckoutPage />;
  };

  return loading || !cartItems?.length ? (
    <Spinner loading={loading} />
  ) : (
    <ShoppingSiteContext.Provider
      value={{
        loading,
        currentPage,
        incrementPage,
        decrementPage,
        inventory,
        cartItems,
        setCartItems,
        getTotalPrice,
        getTotalDiscountPrice,
        // equipmentPrices,
      }}
    >
      <div>{activeSection()}</div>
    </ShoppingSiteContext.Provider>
  );
};

export default ShoppingSite;
