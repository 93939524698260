import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { CONCAT_KEY } from "../const";
import usePaymentName from "../hooks/updatePaymentName";

const PaymentLoading = () => {
  const [error, setError] = useState(null);

  const { loading, setLoading, updatePayment } = usePaymentName();

  useEffect(async () => {
    try {
      const id = localStorage.getItem("id");
      const paymentData = JSON.parse(localStorage.getItem("paymentData"));
      const splitted = id.split(CONCAT_KEY);
      const oid = splitted[0];
      const iid = splitted[1];
      await updatePayment(oid, iid, paymentData.name, paymentData.id);
      setLoading(false);

      // localStorage.removeItem("orderid");
    } catch (error) {
      console.log(error);
      setError(error || "Something went wrong");
      setLoading(false);
    }
  }, []);

  return loading ? (
    <div className="w-full h-full">
      <Spinner
        loading={loading}
        message={"Please wait while agreement document is being prepared"}
      />
    </div>
  ) : error ? (
    <div>Error</div>
  ) : (
    <Navigate to="/app/agreementloading" />
  );
};

export default PaymentLoading;
