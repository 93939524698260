import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ChevronRight } from "react-feather";
import { toast } from "react-toastify";
import { FooterButton } from "../../document/Document";
import ProductCard from "../components/ProductCard";
import ShoppingSiteContext from "../context";

import IFrameComponent from "../../document/components/IFrameComponent";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import { useLocation } from "react-router-dom";
import {
  EMPTY_DISCOUNT_OBJECT,
  SUCCESSFULL,
  // discountObjectValue,
} from "../../document/const";

import {
  // encrypt,
  getDiscountObj,
  getProductDiscount,
} from "../../../utils/discount";
import useCustomSelect from "../../document/components/SelectComponent";
import CopyToClipBoard from "../components/CopyDiscounts";
import TopNavBar from "../components/TopNavBar";
import { getItemFromSession, saveItemsToSession } from "../utils";
import { clearSessionStorage } from "../../../utils/clearSessionStorage";

const Equipment = () => {
  const {
    incrementPage,
    inventory,
    cartItems,
    setCartItems,
    getTotalPrice,
    getTotalDiscountPrice,
  } = useContext(ShoppingSiteContext);

  const { search } = useLocation();
  const [adminBusiness, setAdminBusiness] = useState(null);
  const [user, setUser] = useState(null);
  const [discountObj, setDiscountObj] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [showFrame, setShowFrame] = useState(null);
  const [discounts, setDiscounts] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  //
  // console.log({ cartItems });

  const totalItemsSelected = cartItems.reduce((acc, item) => {
    const v = item.quantity ? Number(item?.quantity) : 0;
    // console.log({ v });
    acc = acc + v;
    return acc;
  }, 0);
  // console.log({ totalItemsSelected, discounts });
  const { CustomSelect: SelectBusiness } = useCustomSelect({
    options: adminBusiness?.map((item) => {
      return item?.name;
    }),
    onSelect: (value) => {
      // console.log("Seleceted ", value);
      const selectedValue = adminBusiness?.find((item) => item?.name === value);
      saveItemsToSession(`linkBusiness-${user?.id}`, selectedValue);
      setSelectedBusiness(selectedValue);
    },
    configProperties: {
      dropdownHeight: "200px",
    },
    initialValue: selectedBusiness,
    selectedBusiness: selectedBusiness,
  });

  const fetchTaxRates = async () => {
    const taxResponse = await axios.get("/browser/taxrates", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Taxrates", taxResponse);
    return taxResponse?.data;
  };

  const fetchAndSetTaxrates = async () => {
    const sessionTaxRates = getItemFromSession("taxRates");
    if (!sessionTaxRates) {
      const taxResponse = await fetchTaxRates();
      saveItemsToSession("taxRates", taxResponse);
    }
  };

  const handleAssociatedBusiness = (adminBusiness) => {
    const isFromCheckout = getItemFromSession("fromCheckout");
    // console.log("Here", adminBusiness);
    if (!isFromCheckout) {
      const discountObj = search && getDiscountObj(search);
      const isValid = isValidDiscountObj(discountObj);
      console.log({ isValid });
      let discountFor = {};
      if (isValid) {
        discountFor = adminBusiness?.find(
          (item) => item?.id === discountObj?.data?.bid
        );
        // console.log({ discountFor, discountObj });
        if (discountFor?.id) {
          // console.log({ selectedBusiness });
          setSelectedBusiness(discountFor);
        }
      }
    }
  };

  useEffect(() => {
    // console.log({ user });
    if (!user?.id) return;
    const business = getItemFromSession(`admin-${user?.id}`);
    let discountFor = getItemFromSession(`linkBusiness-${user?.id}`);
    const taxRates = getItemFromSession("taxRates");

    if (!taxRates || taxRates.length) {
      fetchAndSetTaxrates();
    }
    // console.log({ selectedBusiness });
    if (business?.length) {
      // console.log(business);
      setAdminBusiness(business);
    }
    if (discountFor?.id) {
      // console.log({ selectedBusiness });
      setSelectedBusiness(discountFor);
    }
  }, [user]);

  const isDiscountObjectValid = (data) => {
    if (!data) return false;
    if (
      data?.bid &&
      data?.approvedBy?.id &&
      data?.approvedBy?.name &&
      (data?.discounts?.length || data?.items?.length)
    ) {
      return true;
    } else return false;
  };

  const isValidDiscountObj = (discountObj) => {
    const hasDiscountsObject = discountObj?.status;
    const statusMessage = discountObj?.message;
    const data = discountObj?.data;
    const hasValidData = isDiscountObjectValid(data);
    if (
      hasDiscountsObject &&
      data &&
      hasValidData &&
      statusMessage === SUCCESSFULL
    )
      return true;
    else false;
  };

  useEffect(() => {
    const discountObj = search && getDiscountObj(search);
    const isFromCheckout = getItemFromSession("fromCheckout");
    if (!isFromCheckout) {
      const hasDiscountsObject = discountObj?.status;
      const statusMessage = discountObj?.message;
      const data = discountObj?.data;
      const hasValidData = isDiscountObjectValid(data);

      if (
        hasDiscountsObject &&
        data &&
        hasValidData &&
        statusMessage === SUCCESSFULL
      ) {
        setDiscountObj(data);
        setDiscounts(data?.discounts);

        saveItemsToSession("discounts", data?.discounts);
        saveItemsToSession("isEligibleLink", true);
      }
      if (
        (hasDiscountsObject &&
          data &&
          hasValidData &&
          statusMessage === SUCCESSFULL) ||
        (!hasDiscountsObject && statusMessage === EMPTY_DISCOUNT_OBJECT)
      ) {
        setShowFrame(true);
      } else {
        setShowFrame(false);
      }
    }
  }, []);

  const ifEligibleForDiscount = () => {
    return adminBusiness?.some((item) => item?.id === discountObj?.bid);
  };

  // console.log(discountObj);
  useEffect(() => {
    const isEligibleForDiscount = ifEligibleForDiscount();
    if (isEligibleForDiscount) {
      // console.log({ discountObj });
      addItemsToCart();
    }
    const isFromCheckout = getItemFromSession("fromCheckout");
    if (ifEligibleForDiscount() && !isFromCheckout) {
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Link with discount",
          eventtype: "received",
          payload: { user: user?.id, discountObj: discountObj },
        });
    }
  }, [discountObj, adminBusiness]);

  useEffect(() => {
    const storeFailed = getItemFromSession("storeFailed");
    if (!storeFailed) {
      clearSessionStorage();
    }
  }, []);

  // const encrypted = encrypt(JSON.stringify(discountObjectValue));
  // console.log("ENcrypted", encrypted);

  const isAuthorizedLinkGenerator = () => {
    // console.log("user in checkup function", user);
    if (user?.role === "ADMIN" && selectedBusiness) return true;
    else return false;
  };

  const showSelectBusiness = () => {
    if (user?.role === "ADMIN") return true;
    else return false;
  };

  const withDiscountObject = () => {
    return discountObj?.bid && discountObj?.discounts?.length ? true : false;
  };

  useEffect(() => {
    if (!user?.id) return;
    if (user?.id && adminBusiness?.length) {
      handleAssociatedBusiness(adminBusiness);
    }
  }, [adminBusiness]);

  // const withDiscountObject =

  // console.log("Encrypted", encrypted);

  // const decrypted = decrypt(encrypted);
  // console.log("Decrypted", JSON.parse(decrypted));

  const listener = {
    loginResponse: (user, adminBusiness) => {
      setUser(user);
      setAdminBusiness(adminBusiness);
      saveItemsToSession("user-s", user);
      saveItemsToSession(`admin-${user?.id}`, adminBusiness);
      setShowFrame(false);
      // handleAssociatedBusiness(adminBusiness);
    },
  };

  const addItemsToCart = () => {
    const associatedBusiness = adminBusiness?.find(
      (item) => item?.id === discountObj?.bid
    );
    saveItemsToSession(`linkBusiness-${user?.id}`, associatedBusiness);
    const itemsInDiscountLink = discountObj?.items;
    const temp = cartItems?.map((v) => {
      const itemInDiscounts = itemsInDiscountLink?.find(
        (item) => item?.id === v?.id
      );
      const itemWithQuantity = {
        ...v,
        quantity: Number(itemInDiscounts?.quantity),
      };
      const discount = getProductDiscount(
        discountObj.discounts,
        itemWithQuantity
      );

      if (itemInDiscounts?.id && itemInDiscounts?.quantity) {
        return {
          ...v,
          quantity: Number(itemInDiscounts?.quantity),
          discount: Number(discount),
        };
      } else return v;
    });
    // console.log({ temp });
    setCartItems(temp);
    // console.log({ temp });
  };

  // useEffect(() => {}, [applyDiscount]);

  useEffect(() => {
    let items;
    if (!user?.id) return;
    if (withDiscountObject()) {
      items = getItemFromSession(`items-${user?.id}`);
    } else {
      items = getItemFromSession("items");
      console.log({ items });
    }
    if (items?.length) {
      setCartItems(items);
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      setEquipments(cartItems);

      const currentUser = getItemFromSession("user-s");
      if (withDiscountObject()) {
        if (currentUser?.id) {
          saveItemsToSession(`items-${currentUser?.id}`, cartItems);
          saveItemsToSession("items", cartItems);
        }
      } else {
        saveItemsToSession("items", cartItems);
      }
    }
  }, [cartItems]);

  useEffect(() => {
    const currentUser = getItemFromSession("user-s");
    if (currentUser?.id) {
      setUser(currentUser);
      setShowFrame(false);
    }
  }, []);

  const gotoNextPage = () => {
    if (getTotalPrice() === 0) {
      window.GZAnalytics.customLog({
        name: "Next Button Element",
        eventtype: "withNoItems",
      });
      toast.warning("Please select at least one item");
      return;
    }

    window.GZAnalytics.customLog({
      name: "Next Button",
      eventtype: "withItems",
      payload: { cartItems: cartItems, totalAmount: totalAmount },
    });
    saveItemsToSession("discounts", discounts);
    incrementPage();
  };

  const displayIframeStyle = {
    background: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hiddenIframeStyle = {
    display: "none",
  };

  useEffect(() => {
    const discountsFromSession = JSON.parse(
      sessionStorage.getItem("discounts")
    );
    if (discountsFromSession?.length) {
      setDiscounts(discountsFromSession);
    }
    // }, [user]);
  }, [selectedBusiness]);

  useEffect(() => {
    if (!user?.id) return;

    if (user?.id) {
      const temp = {
        bid: selectedBusiness?.id,
        approvedBy: {
          id: user?.id,
          email: user?.email,
          name: user?.fullname,
        },
        discounts: discounts,
      };

      // if (discounts.length)
      //   window.GZAnalytics.customLog({
      //     name: "Applied Discount",
      //     eventtype: "applied",
      //     payload: { user: user?.id, discounts: discounts },
      //   });
      // console.log("discounts", temp);

      const tempCartItems = cartItems.map((items) => {
        const discount = getProductDiscount(temp.discounts, items);
        // console.log("Discount", discount);
        return {
          ...items,
          discount: Number(discount),
        };
      });
      setCartItems(tempCartItems);

      setDiscountObj(temp);
    }
  }, [discounts, selectedBusiness]);

  const switchUser = () => {
    setShowFrame(true);
  };
  const totalAmount = getTotalPrice() - getTotalDiscountPrice() ?? 0;
  // console.log({ total: getTotalPrice(), discount: getTotalDiscountPrice() });

  return (
    <div className=" ">
      {/* <div className=" w-full flex justify-center">
        <div className=" z-50  flex w-full md:w-4/5 items-center  justify-between align bg-[#4D1F70] p-2 fixed">
          <img
            className="w-3/5 pl-2 sm:w-1/4 md:1/4 lg:2/5 xl:w-1/5"
            src={UzeliLogo}
            alt="logo"
          />
          <div className=" ml-7 flex flex-row gap-1 sm:gap-5 md:gap-7 h-full justify-center sm:mr-2 text-white items-center ">
            <Cart equipment={equipments} />
            <User
              user={user}
              switchUser={user?.id ? switchUser : null}
              login={!user?.id ? switchUser : null}
            />
          </div>
        </div>
      </div> */}
      <TopNavBar
        cartItems={equipments}
        user={user}
        switchUserCallBack={user?.id ? switchUser : null}
        loginCallBack={!user?.id ? switchUser : null}
      />
      <div>
        {showFrame && (
          <div
            className="w-full flex justify-center"
            style={showFrame ? displayIframeStyle : hiddenIframeStyle}
          >
            {
              <IFrameComponent
                listener={listener}
                iFrameSrc={getEnvironmentValue().EMBED_LOGIN_URL}
              />
            }
          </div>
        )}
      </div>
      {!showFrame && (
        <div className="container pt-20 scrollbar-hide sm:w-4/5  lg:w-4/5 mx-auto">
          {showSelectBusiness() && (
            <div className="flex sm:mb-0.5 mb-2 flex-row justify-start items-center gap-2">
              <label className="font-medium text-base ">Select Business</label>
              <div className="w-72">{SelectBusiness}</div>
            </div>
          )}
          <div>
            {inventory?.map((product) => {
              const item = cartItems?.find(
                (item) => item?.id === product?.ROWID
              );
              return (
                <ProductCard
                  linkGenerator={isAuthorizedLinkGenerator()}
                  discountObj={
                    ifEligibleForDiscount() ? discountObj?.discounts : null
                  }
                  setDiscountObj={setDiscountObj}
                  key={product?.ROWID}
                  product={item}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                  description={product?.description}
                  discounts={discounts}
                  imageId={product?.imageId}
                  setDiscounts={setDiscounts}
                />
              );
            })}

            <div className="flex flex-col md:flex-row items-end  justify-end">
              {!(getTotalDiscountPrice() > 0) ? (
                <div className="w-full flex flex-row  md:w-2/3 text-lg md:order-2 py-4">
                  {totalAmount > 0 ? (
                    <div className="flex flex-row w-full">
                      <div className="w-1/2 md:w-1/2 m-2">Total price</div>
                      <div className="w-1/2 md:w-1/2 font-bold flex flex-row justify-center items-center">
                        <div>{totalAmount > 0 ? `$${totalAmount}` : null}</div>
                        <div className="w-1/2"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="w-full flex flex-col justify-end md:w-2/3 text-lg md:order-2 py-4 ">
                  <div className="flex flex-row">
                    <div className="w-1/2  md:w-1/2 ">Sales price</div>
                    <div className="w-1/2 md:w-1/2 text-red-800 line-through font-bold flex flex-row justify-center items-center">
                      <div>${getTotalPrice()}</div>
                      <div className="w-1/2"></div>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="w-1/2 md:w-1/2 ">Total discount price </div>
                    <div className="w-1/2 md:w-1/2 font-bold flex flex-row justify-center items-center text-blue-800">
                      <div>- ${getTotalDiscountPrice()}</div>
                      <div className="w-1/2"></div>
                    </div>
                  </div>

                  <div className="w-3/4 bg-black text-black">
                    <hr />
                  </div>
                  <div className="flex flex-row">
                    <div className="w-1/2 md:w-1/2 ">Total</div>
                    <div className="w-1/2 md:w-1/2 font-bold flex flex-row justify-center items-center text-green-800">
                      <div> ${totalAmount}</div>
                      <div className="w-1/2"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end py-4">
            {isAuthorizedLinkGenerator() &&
            (discounts?.length || totalItemsSelected > 0) ? (
              <div
                style={{
                  backgroundColor: "#1F704D",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  paddingTop: "0.65rem",
                  paddingBottom: "0.65rem",
                  fontWeight: "500",
                  color: "rgba(255, 255, 255, var(--tw-text-opacity))",
                  borderRadius: "0.4rem",
                  borderWidth: "1px",
                }}
                className="flex  text-white  hover:animate-none"
              >
                <CopyToClipBoard data={discountObj ?? {}} />
              </div>
            ) : null}
            {totalItemsSelected > 0 ? (
              <FooterButton
                onClick={() => {
                  if (withDiscountObject() && user?.id) {
                    gotoNextPage();
                  } else if (!withDiscountObject()) {
                    gotoNextPage();
                  }
                }}
                className="flex text-white bg-[#4D1F70] hover:text-yellow-500"
              >
                Next
                <ChevronRight />
              </FooterButton>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Equipment;
