import PropTypes from "prop-types";
import React from "react";
import { Check } from "react-feather";

const Button = ({ active, label, type, handleClick }) => {
  const isPrimary = type === "primary";
  return (
    <div className="flex h-full items-center justify-center">
      <button
        type="button"
        className={`text-sm md:text-base  border ${
          isPrimary ? "border-blue-500" : "border-green-500"
        } ${
          isPrimary ? "text-blue-500" : "text-green-500"
        } py-1 sm:py-2  px-1 sm:px-4 ${isPrimary ? "rounded-l" : "rounded-r"} 
        ${
          active
            ? isPrimary
              ? "bg-blue-500 text-white"
              : "bg-green-500 text-white"
            : ""
        }
        `}
        onClick={handleClick}
      >
        <div className="flex flex-row gap-1 text-xs sm:text-base items-center">
          {active && (
            <div>
              <Check color="white" strokeWidth={2} />
            </div>
          )}
          <div>{label}</div>
        </div>
      </button>
    </div>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  handleClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  handleClick: null,
};

export default Button;
