import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useAgreementStatus from "../../../hooks/useAgreementStatus";
import { H5 } from "../components/CustomForm";
import { toast } from "react-toastify";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import Tooltip from "@mui/material/Tooltip";
import PaymentIcon from "@mui/icons-material/Payment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DrawIcon from "@mui/icons-material/Draw";
import { useNavigate } from "react-router-dom";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UzeliStatusContent from "../pages/UzeliStatusContent";
import StatusModalPage from "./StatusModalPage";
import _ from "lodash";
// import useLeadInfo from "../hooks/useLeadInfo";

const PendingUzeliOrderCard = ({
  pendingOrder,
  cancelOrder,
  approveOrder,
  resetOrder,
  setLoading,
  authPaymentAdmins,
}) => {
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  // const [growthzillaSignee, setGrowthzillaSignee] = useState(
  //   pendingOrder?.growthzillaSigneeName
  // );
  const navigate = useNavigate();

  const documentHash = pendingOrder?.agreementDocumentHash;
  const leadId = pendingOrder?.leadId;
  // console.log({ documentHash, leadId, order: pendingOrder?.orderId });
  // console.log("Here");
  const { agreementStatus } = useAgreementStatus(
    documentHash ? documentHash : leadId
  );

  // console.log({ agreementStatus });
  const orderDetail = {
    fromUzeliOrders: true,
    ...pendingOrder,
    signingStatus: {
      data: {
        ...agreementStatus,
      },
    },
  };
  // console.log(getEnvironmentValue());
  // let growthzillaSignee = agreementStatus?.growthzillaSigneeName;
  // const hasGrowthzillaSignee = agreementStatus?.growthzillaSigneeName;
  // if (!hasGrowthzillaSignee) {
  //   const { getLeadInfo } = useLeadInfo();
  //   // setFetchLeadInfo(true);
  //   //       const leadInfo = await getLeadInfo(leadId);
  //   // const leadInfo = await getLe

  //   useEffect(async () => {
  //     const hasGrowthzillaSignee = agreementStatus?.growthzillaSigneeName;
  //     if (!hasGrowthzillaSignee) {
  //       const leadInfo = await getLeadInfo(pendingOrder?.leadId);
  //       setGrowthzillaSignee(leadInfo?.growthzillaSignee);
  //     }
  //   }, []);
  // }

  // let agreementStatus = {};

  const closeApproveDialog = () => {
    setOpenApproveDialog(false);
  };

  const closeCancelDialog = () => {
    setOpenCancelDialog(false);
  };
  const closeResetDialog = () => {
    setOpenResetDialog(false);
  };
  const user = JSON.parse(sessionStorage.getItem("user"));
  const isAuthorizedPaymentApprover = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    return (
      (user?.role === "ADMIN" &&
        authPaymentAdmins?.some(
          (approver) => approver?.email === user?.email
        )) ||
      (getEnvironmentValue().IS_DEV && user?.role === "USER")
    );
  };

  const closeDetail = () => {
    setOpenDetailDialog(false);
  };

  const isAgent = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const agentMail = agreementStatus?.growthzillaSigneeEmail;

    const userMail = user?.email;
    if (userMail === agentMail) return true;
    else return false;
  };
  const signButton = isAgent();
  const disabled = !isAuthorizedPaymentApprover();

  const uzeliUser = JSON.parse(pendingOrder?.uzeliUser ?? "{}");
  const orderStatus = pendingOrder?.orderStatus;
  const orderItems = _.uniqBy(pendingOrder?.items, "ROWID");

  // console.log({ pendingOrder });
  const orderIsPending =
    pendingOrder?.orderStatus === "pending" && pendingOrder?.signedByBusiness;

  const showResetButton = isAuthorizedPaymentApprover() && orderIsPending;

  return (
    <div
      className={`${
        orderStatus === "pending"
          ? "bg-[#d3eaf2]/30"
          : orderStatus === "approved"
          ? "bg-[#cbedd3]/30"
          : orderStatus === "cancelled"
          ? "bg-[#f9c6c5]/25"
          : "bg-white"
      } mb-5 font-sans p-2 rounded-md shadow-xl`}
    >
      <div className="pb-2">
        <Dialog open={openApproveDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Are you sure you want to approve this order?
            This action sends the order to supplier and charges the customer's credit card.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeApproveDialog}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#9b9fa3",
                color: "white",
              }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => {
                if (!agreementStatus?.is_completed) {
                  toast.error(
                    "Agreement has not been signed by both parties yet."
                  );
                  closeApproveDialog();
                  return;
                }
                closeApproveDialog();
                approveOrder(pendingOrder?.orderId);
                setLoading(true);
              }}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
                color: "white",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openCancelDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel this order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeCancelDialog}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#9b9fa3",
                color: "white",
              }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => {
                closeCancelDialog();
                cancelOrder(pendingOrder?.orderId);
                setLoading(true);
              }}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
                color: "white",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openResetDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Are you sure you want to reset this order?
            This action will void the previous agreement.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeResetDialog}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#9b9fa3",
                color: "white",
              }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={async () => {
                // if (!agreementStatus?.is_completed) {
                //   toast.error(
                //     "Agreement has not been signed by both parties yet."
                //   );
                //   closeApproveDialog();
                //   return;
                // }
                closeResetDialog();
                resetOrder(
                  pendingOrder?.orderId,
                  pendingOrder?.agreementDocumentHash
                );
                setLoading(true);
              }}
              className="p-2 border-[1px] rounded-sm w-[5rem] cursor-pointer font-semibold hover:shadow-lg"
              style={{
                textTransform: "none",
                backgroundColor: "#7367f0",
                color: "white",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <div className="flex sm:flex-row w-full flex-col sm:justify-between mb-2">
          <div className="flex font-semibold">
            <p>Subscribed Plan: </p>
            <p className="font-bold">{pendingOrder?.subscribedPlan}</p>
          </div>
          <div className=" justify-between flex flex-row items-center gap-1">
            <p className="font-semibold ">{`Order Id: ${pendingOrder?.orderId}`}</p>
            {pendingOrder?.orderId && (
              <Tooltip title="Click to view order details">
                <InfoOutlinedIcon
                  className="bg-white cursor-pointer  transition-all duration-500 ease-out hover:scale-125 rounded-full text-[#4D1F70]"
                  onClick={() => {
                    setOpenDetailDialog(true);
                    // console.log("Clicked");
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 sm:flex-row">
          <div className="w-full md:w-1/3">
            <H5 className="text-sm  font-semibold">
              Business Name and Address
            </H5>
            <p>{`${pendingOrder?.businessName}`}</p>
            <p>{`${pendingOrder?.businessAddress}`}</p>
            {pendingOrder?.businessUnitOrSuite && (
              <p>{`${pendingOrder?.businessUnitOrSuite}`}</p>
            )}
            <p>
              {pendingOrder?.businessCity +
                " " +
                pendingOrder?.businessState +
                " " +
                pendingOrder?.businessZipCode}
            </p>
          </div>
          <div className="w-full md:w-1/3">
            <H5 className="text-sm  font-semibold">Shipping Address</H5>
            <p>{`${pendingOrder?.shippingAddress1}`}</p>
            {pendingOrder?.shippingAddress2 && (
              <p>{`${pendingOrder?.shippingAddress2}`}</p>
            )}
            <p>
              {pendingOrder?.shippingAddressCity +
                " " +
                pendingOrder?.shippingAddressState +
                " " +
                pendingOrder?.shippingAddressZipCode}
            </p>
          </div>
          <div className="w-full  md:w-1/3">
            <H5 className="text-sm font-semibold">Contact</H5>
            <p>{`${pendingOrder?.businessReprName}`}</p>
            <p>{`Email: ${pendingOrder?.businessReprEmail}`}</p>
            <p>{`Phone: ${pendingOrder?.businessReprPhone}`}</p>
          </div>
        </div>

        <div>
          <H5 className="text-sm mt-2 font-semibold">Payment Details</H5>
          <div className="flex flex-col sm:flex-row sm:gap-1">
            <p>{`Payment Id: ${
              pendingOrder?.paymentMethodId || "Not specified"
            }`}</p>
            <div className="flex">
              <p>Payment Name: </p>
              <PaymentIcon className="text-blue-500" />
              <p>{pendingOrder?.paymentName || "Not specified"}</p>
            </div>
          </div>
        </div>
        <div>
          <H5 className="text-sm mt-2 font-semibold">Agreement Signers</H5>
          <div className="block md:flex gap-2">
            <div className="w-[100%] md:w-1/2">
              <p>{`Growthzilla Signee: ${agreementStatus?.growthzillaSigneeName}`}</p>
              <div className="flex">
                <p>Signing Status:</p>
                {pendingOrder?.signedByGrowthzilla ? (
                  // {agreementStatus?.signedByGrowthzilla ? (
                  <Tooltip title="Signed" placement="top" arrow>
                    <VerifiedIcon
                      className="ml-1"
                      style={{
                        color: "purple",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <div className="flex flex-row ">
                    <Tooltip title="Not Signed" placement="top" arrow>
                      <PendingIcon
                        style={{
                          color: "#e68c07",
                        }}
                      />
                    </Tooltip>
                    {signButton && (
                      <Tooltip title="Sign" placement="top" arrow>
                        <div className="rounded-lg hover:shadow-2xl hover:bg-gray-100 w-full justify-center  ">
                          <DrawIcon
                            onClick={() => {
                              navigate(
                                `/app/signDocument/${pendingOrder?.orderId}`
                              );
                            }}
                            style={{
                              color: "purple",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="w-[100%] md:w-1/2">
              <p>{`Business Signee: ${
                agreementStatus?.businessReprName
                  ? agreementStatus?.businessReprName
                  : pendingOrder?.businessReprName
              }`}</p>
              <div className="flex">
                <p>Signing Status:</p>
                {pendingOrder?.signedByBusiness ? (
                  // {agreementStatus?.signedByBusiness ? (
                  <Tooltip title="Signed" placement="top" arrow>
                    <VerifiedIcon
                      className="ml-1"
                      style={{
                        color: "purple",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Signed" placement="top" arrow>
                    <PendingIcon
                      style={{
                        color: "#e68c07",
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
        <H5 className="text-sm mt-2 font-semibold">Items</H5>
        <ul>
          {orderItems.map((product, index) => {
            const { ROWID, name, unitPrice, provider, quantity } = product;
            return (
              <li key={`${ROWID}_${pendingOrder?.orderId}`}>
                {index + 1} Name: <span className="font-bold">{name}</span>,
                Quantity:
                <span className="font-bold">{quantity}</span> , UnitPrice: $
                {unitPrice} , Provider: {provider}
              </li>
            );
          })}
        </ul>
        <hr />
        <p className="font-semibold mt-2">{`Billing note: ${pendingOrder?.billingNote}`}</p>
        <div className=" w-full flex flex-col sm:flex-row justify-between">
          <p className="font-semibold">{`Start date: ${new Date(
            pendingOrder?.startDate
          ).toDateString()}`}</p>
          <p className="font-semibold">
            {`Created Date: ${new Date(
              pendingOrder?.createdTime
            ).toDateString()}`}
            {uzeliUser?.id && <span>-{uzeliUser?.name}</span>}
          </p>
        </div>
        {orderStatus === "pending" && (
          <div className="  flex flex-col sm:flex-row items-end justify-end gap-2 mt-2 mb-4">
            <div
              className={`${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <Button
                className="p-10 border-[1px]  rounded-sm w-[10rem] cursor-pointer font-semibold hover:shadow-lg"
                style={{
                  textTransform: "none",
                  backgroundColor: "#ffffff",
                  color: "black",
                  border: "1px solid black",
                }}
                onClick={() => {
                  setOpenCancelDialog(true);
                }}
                disabled={disabled}
              >
                Cancel Order
              </Button>
            </div>
            <div
              className={`${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <Button
                className="  p-2 border-[1px] rounded-sm w-[10rem] cursor-pointer font-semibold hover:shadow-lg"
                style={{
                  textTransform: "none",
                  backgroundColor: "#7367f0",
                  color: "white",
                  disabled: true,
                }}
                onClick={() => {
                  setOpenApproveDialog(true);
                }}
                disabled={disabled}
              >
                Approve Order
              </Button>
            </div>
            {showResetButton && (
              <div
                className={`${
                  disabled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                <Button
                  className="  p-2 border-[1px] rounded-sm w-[10rem] cursor-pointer font-semibold hover:shadow-lg"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#BF6EF4",
                    color: "white",
                    disabled: true,
                  }}
                  onClick={() => {
                    setOpenResetDialog(true);
                  }}
                  disabled={disabled}
                >
                  Reset Order
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {openDetailDialog && (
        <StatusModalPage
          content={
            <div className="w-full h-full ">
              <UzeliStatusContent
                user={user}
                orderDetail={orderDetail}
                setLoading={setLoading}
              />
            </div>
          }
          isOpen={openDetailDialog}
          closeDetail={closeDetail}
        />
      )}
    </div>
  );
};

PendingUzeliOrderCard.propTypes = {
  pendingOrder: PropTypes.object,
  cancelOrder: PropTypes.func,
  approveOrder: PropTypes.func,
  resetOrder: PropTypes.func,
  setLoading: PropTypes.func,
  authPaymentAdmins: PropTypes.array,
  tab: PropTypes.number,
};
export default PendingUzeliOrderCard;
