import React from "react";
import PropTypes from "prop-types";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
// import useDeviceCounter from "../hooks/useDeviceCounter";
// import { getItemFromSession } from "../../shoppingsite/utils";

function CartMenuLogo({ equipment }) {
  // const leadId = getItemFromSession("leadId");
  // const { currentPage } = useDeviceCounter(leadId);
  // console.log({ currentPage });
  const total =
    equipment &&
    Object.keys(equipment).length &&
    Object.keys(equipment)?.reduce((acc, item) => {
      return acc + equipment[item];
    }, 0);
  // console.log("Total Items", total);
  return (
    <div className="flex items-center  relative ">
      <div
        className="text-[19px] px-[5px] py-[0.9px] sm:text-[21px]  sm:px-[7px] sm:py-[1.5px] md:px-[8px] md:py-[3px]  md:text-[20px] border-1 md:border-2"
        style={{
          borderRadius: "100%",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "white",
          backgroundColor: "#4D1F70",
        }}
        data-analytics-name="Uzeli Cart Element"
        data-analytics-eventtype="Clicked"
        data-analytics-payload={JSON.stringify({
          from: "uzeliOrders",
        })}
      >
        <ShoppingCartRoundedIcon
          className=" rounded-e-md rounded-s-lg   text-white"
          fontSize="inherit"
        />
      </div>
      {total > 0 && (
        <div className="sm:text-xs  text-[8px]  absolute -top-[3px] -left-[5px] sm:-top-1 sm:-left-2 bg-red-600  text-white px-[0.2rem] sm:px-[0.3rem] rounded-full">
          {total}
        </div>
      )}
    </div>
  );
}
CartMenuLogo.propTypes = {
  equipment: PropTypes.object,
};
export default CartMenuLogo;
