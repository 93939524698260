import PropTypes from "prop-types";
import React from "react";
import { Alert } from "@mui/material";

const Incrementor = ({ product, onIncrease, onDecrease, warning }) => {
  const grossTotalAmount = (product?.unitPrice || 0) * (product?.quantity || 0);
  const productDiscount = product?.discount || 0;
  return (
    <div>
      <div className="flex w-full   justify-center items-center mx-2 text-center">
        <div className=" flex flex-col w-1/2 justify-center items-center ">
          <div className="flex flex-row gap-1">
            <div
              className={`${
                productDiscount > 0
                  ? "text-red-800 line-through font-bold "
                  : ""
              }font-bold w-1/2 justify-around`}
            >
              ${grossTotalAmount.toFixed(2)}
            </div>

            {/* {productDiscount > 0 && (
              <div className=" flex flex-row gap-1 text-blue-800 font-bold w-1/2 justify-around">
                <div>-</div>${productDiscount}
              </div>
            )} */}
          </div>
          {productDiscount > 0 && (
            <div className="w-full justify-start font-bold text-green-800">
              $
              {Number(grossTotalAmount).toFixed(2) -
                Number(productDiscount).toFixed(2)}
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center w-1/2 ">
          <button type="button" className="add" onClick={onIncrease}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <p className="text-center w-10">{product?.quantity || 0}</p>
          <button type="button" className="minus" onClick={onDecrease}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
              id="minus"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
      </div>
      {warning && (
        <div className="flex justify-center items-center h-[0.1rem]">
          <Alert variant="filled" severity="warning">
            {warning}
          </Alert>
        </div>
      )}
    </div>
  );
};

Incrementor.propTypes = {
  product: PropTypes.object.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  warning: PropTypes.string,
};

export default Incrementor;
