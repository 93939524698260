import React from "react";
// import styled from "styled-components";

// const Button = styled.button`
//   display: block;
//   width: 20rem;
//   border-width: 1px;
//   border-color: #116FD7;
//   border-radius:4px;
//   background-color: #116FD7;
//   color: #000;
//   padding: 14px 28px;
//   font-size: 18px;
//   font-weight: bold;
//   cursor: pointer;
//   text-align: center;
//   font-weight: 500;
//   }
// `;

const UnAuthorized = () => {
  return (
    <div
      className="h-screen flex flex-col justify-center items-center"
      style={{ backgroundColor: "#051527" }}
    >
      <div className="font-extrabold text-8xl text-slate-50 mb-9">401</div>
      <div className="text-slate-400 mb-7 text-2xl">Unauthorized</div>
      <div className="mb-5">
        <hr className="w-20 text-slate-400" />
      </div>
      {/* <div>
        <Button
          onClick={() => {
            window.open(
              `https://account.growthzilla.com?redirectTo=localhost:8000/document/${itemId}`,
              "_self"
            );
          }}
        >
          Login Again
        </Button>
      </div> */}
    </div>
  );
};

export default UnAuthorized;
