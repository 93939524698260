const isItemIdValid = (leadId) => {
  // console.log("CHecking Validity", leadId);
  return leadId && !!leadId && leadId >= 3000 && leadId <= 9999;
};

export default isItemIdValid;

export const getLeadId = (searchObj) => {
  const leadstring = searchObj?.id;
  const leadId = leadstring?.split("-")[0];
  // console.log({ leadId });
  if (leadId === null || !leadId) return "0";
  const final_phrase = leadId.replace("GZ", "").replace("gz", "");
  return Number(final_phrase || "0");
};

export const getLeadIdFromParams = (pathname) => {
  // console.log({ pathname });
  const pathArray = pathname?.split("/");
  let pathObject = {};
  // console.log({ pathArray });
  if (pathname?.length) {
    pathArray?.map((pair) => {
      // const kv = pair?.split("-");
      // console.log({ kv });
      if (pair && pair?.length && pathArray[3]) {
        // const regex = /\d\w+/g;
        const identifierRegex = /^([a-zA-Z]){2}/g;
        // const leadId = kv[1].match(regex);
        const identifier = pathArray[3]
          ? pathArray[3].match(identifierRegex)
          : "";
        // console.log({ leadId, identifier });
        pathObject["id"] = pathArray[3] ? pathArray[3] : null;
        pathObject["fromProma"] = false;
        // console.log();
        if (identifier && (identifier[0] === "GZ" || identifier[0] === "gz")) {
          pathObject["fromProma"] = true;
        }
      }
    });
  }
  // console.log({ pathObject });
  return pathObject;
};

export const getSearchParams = (search, pathName) => {
  const searchString = search?.substring(1);
  const searchArray = searchString?.split("&");
  // console.log({ searchString, searchArray, pathName });
  const pathArray = pathName?.split("/");
  if (pathName?.length && pathArray[3]) {
    const pathObject = getLeadIdFromParams(pathName);
    return pathObject;
    // console.log({ leadId });
  } else {
    let searchObject = {};
    if (searchArray?.length) {
      searchArray?.map((pair) => {
        const kv = pair?.split("=");
        // console.log({ kv });
        if (kv && kv.length && kv[0]) {
          if (kv[0] === "id") {
            // const regex = /\d\w+/g;
            const identifierRegex = /^([a-zA-Z]){2}/g;
            // const leadId = kv[1].match(regex);
            const identifier = kv[1].match(identifierRegex);
            // console.log({ leadId, identifier });
            searchObject[kv[0]] = kv.length > 1 && kv[1] ? kv[1] : null;
            searchObject["fromProma"] = false;
            console.log();
            if (
              identifier &&
              (identifier[0] === "GZ" || identifier[0] === "gz")
            ) {
              searchObject["fromProma"] = true;
            }
          }
        }
      });
    }
    return searchObject;
  }
  // console.log({ searchObject });
};
