/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { FooterButton } from "../Document";
import SwitchContext from "../context";
import PropTypes from "prop-types";
import a55 from "../../../assets/images/a55.png";
import Incrementor from "./Incrementor";
import { saveLeadActivity } from "../../../hooks/updateLeadActivity";

function StandWarning({ closeDetail }) {
  const {
    decrementPage,
    openAdditionalValues,
    equipmentPrice,
    isUpperLimitReached,
    setAdditionalValues,
    isOutOfStock,
    setCurrentPage,
    setNextButtonClicked,
  } = useContext(SwitchContext);
  const incrementValue = (text) => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Cart Items",
        eventtype: "changed",
        payload: {
          [text]: openAdditionalValues[text] + 1,
        },
      });
    setAdditionalValues({
      ...openAdditionalValues,
      [text]: openAdditionalValues[text] + 1,
    });
  };

  // const closeStandWarning = () => {};

  const decrementValue = (text) => {
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Cart Items",
        eventtype: "changed",
        payload: {
          [text]: openAdditionalValues[text] - 1,
        },
      });
    if (openAdditionalValues[text] > 0)
      setAdditionalValues({
        ...openAdditionalValues,
        [text]: openAdditionalValues[text] - 1,
      });
  };
  const [ariesStandWarning, setAriesStandWarning] = useState("");
  return (
    <div className="w-full">
      <div>
        <div className="w-full justify-center">
          <img
            src={a55}
            alt=""
            className="mx-auto w-3/5 my-4 transition-all duration-500 ease-out hover:scale-125"
            style={{ width: "160px", height: "auto" }}
          />
        </div>
        {/* <div className="w-full justify-center">
          <Incrementor
            fromWarning={true}
            value={openAdditionalValues.AriesStand}
            price={equipmentPrice.AriesStand}
            onIncrease={() => {
              if (isOutOfStock("Aries Stand")) {
                setAriesStandWarning("Currently out of stock");
                setTimeout(() => {
                  setAriesStandWarning("");
                }, 2000);
                return;
              } else if (isUpperLimitReached("Aries Stand")) {
                setAriesStandWarning("Maximum quantity reached");
                setTimeout(() => {
                  setAriesStandWarning("");
                }, 2000);
                return;
              }
              incrementValue("AriesStand");
            }}
            onDecrease={() => decrementValue("AriesStand")}
            warning={ariesStandWarning}
          />
        </div> */}
      </div>
      <div className="w-full   flex md:flex-row flex-col items-center  justify-center md:gap-10 gap-2 mt-5">
        <div className="text-center w-[85%] md:w-[20%] h-14 flex justify-center">
          <FooterButton
            className="flex text-white bg-[#4D1F70] capitalize w-full h-full justify-center items-center hover:text-yellow-500"
            onClick={async () => {
              // console.log("Add Stand");
              setCurrentPage(6);
              closeDetail();
              setNextButtonClicked(0);
              await saveLeadActivity("Add Stand");
            }}
            // disabled={currentPage === 7}
            // onClick={incrementPage}
            // data-analytics-name="Next Button Element"
            // data-analytics-eventtype="Clicked"
            //  data-analytics-payload={JSON.stringify({
            //       fromPage: currentPage,
            //       cartItems: equipment,
            //     })}
          >
            Add Stand
            {/* <ChevronRight /> */}
          </FooterButton>
        </div>

        <div className="text-center w-[85%] md:w-[20%] h-14 flex justify-center">
          <FooterButton
            className="flex text-white text-center capitalize w-full h-full justify-center  items-center bg-[#4D1F70] hover:text-yellow-500"
            onClick={async () => {
              closeDetail();
              setNextButtonClicked(0);
              await saveLeadActivity("Continue without stand");
            }}
            // disabled={currentPage === 7}
            // onClick={incrementPage}
            // data-analytics-name="Next Button Element"
            // data-analytics-eventtype="Clicked"
            //  data-analytics-payload={JSON.stringify({
            //       fromPage: currentPage,
            //       cartItems: equipment,
            //     })}
          >
            Continue Without Stand
            {/* <ChevronRight /> */}
          </FooterButton>
        </div>
      </div>
    </div>
  );
}
StandWarning.propTypes = {
  closeDetail: PropTypes.func,
};

export default StandWarning;
