import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../../../components/CustomModal";

const StandWarningDialog = ({ content, isOpen = true, closeDetail }) => {
  // console.log({ content, isOpen, closeDetail });
  //   if(salesAgent===""){}
  //   let isOpen = true;
  return (
    <div className="random">
      <CustomModal
        content={content}
        title={`You have added PAX Aries 8 Terminal without stand. Do you want to add it?`}
        isOpen={isOpen}
        closeModal={closeDetail}
      />
    </div>
  );
};

StandWarningDialog.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeDetail: PropTypes.func,
};

export default StandWarningDialog;
