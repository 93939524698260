import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import {
  fetchOrderDetail,
  getEmbeddedSigningUrl,
  getTransactionRef,
} from "../../../utils/signAgreementDocument";
import SignDocument from "../../document/components/SignDocument";
import Redirect from "../../../components/Redirect";
// import { getLeadId } from "../../../utils/isItemIdValid";

function AgentSignDocument() {
  const params = useParams();
  const { documentHash } = params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [embeddedSigningUrl, setEmbeddedSigningUrl] = useState(null);
  const [order, setOrder] = useState();

  const fetchUrl = async (count) => {
    if (count && count > 10)
      setError("Unable to load PDF this time. Please try again later.");
    try {
      const orderDetail = await fetchOrderDetail(documentHash);
      setOrder({
        id: orderDetail?.leadId,
        businessName: orderDetail?.businessName,
        agent: true,
      });
      const transactionRef = await getTransactionRef(documentHash);
      const agreementDetails = await getEmbeddedSigningUrl(transactionRef);
      // const orderDetails = await getOrderDetailsFromHash(documentHash);
      const embeddedSigningUrl = agreementDetails?.embeddedSigningUrlAgent;
      // console.log("EMbedded Signing URL", embeddedSigningUrl);
      localStorage.setItem("orderId", documentHash);
      localStorage.setItem("transaction_ref", agreementDetails?.transactionRef);
      // console.log("Signing Url", embeddedSigningUrl);
      if (embeddedSigningUrl) {
        setEmbeddedSigningUrl(embeddedSigningUrl);
        setLoading(false);
      } else {
        setTimeout(async () => {
          const newCount = (count || 0) + 1;
          await fetchUrl(newCount);
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUrl(0);
  }, []);

  console.log({ order });

  return loading ? (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-[100vh] flex justify-center items-center">
      <Spinner
        loading={true}
        message={"Please wait while agreement document is being prepared"}
      />
    </div>
  ) : error ? (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      Error
    </div>
  ) : embeddedSigningUrl ? (
    <SignDocument
      embeddedSigningUrl={embeddedSigningUrl}
      fromPending={true}
      leadData={order}
    />
  ) : (
    <div className="container px-4 lg:w-3/5 md:mx-auto z-4 h-full flex justify-center items-center">
      <Redirect message="Preparing your agreement document, please wait..." />
    </div>
  );
}
export default AgentSignDocument;
