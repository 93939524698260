import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  getShipbobLogDetails,
  setOrderTrackingUrl,
} from "../../../utils/signAgreementDocument";
import PaymentIcon from "@mui/icons-material/Payment";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { H5 } from "../components/CustomForm";
import DataTable from "../components/ItemTable";
import { toast } from "react-toastify";
import VerifiedIcon from "@mui/icons-material/Verified";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import {
  getFormattedDate,
  isAuthorizedPaymentApprover,
} from "../../shoppingsite/utils";

const UzeliStatusContent = ({ orderDetail, setLoading, user }) => {
  const [orderStatusDetails, setOrderStatusDetails] = useState(null);
  const [trackingUrl, setTrackingUrl] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);

  // console.log({ orderDetail });

  useEffect(async () => {
    const statusDetail = await getShipbobLogDetails(orderDetail?.providerId);
    setOrderStatusDetails(statusDetail);
    setTrackingUrl(orderDetail?.trackingUrl);
    setLoading(false);
  }, []);

  const createdDate = getFormattedDate(orderDetail?.createdTime);
  const modifiedTime = getFormattedDate(orderDetail?.modifiedTime);
  const placedBy = JSON.parse(orderDetail?.uzeliUser ?? "{}");
  return (
    <div className="bg-white   w-full   px-4 md:py-5 pb-7 mx-auto mb-3 font-sans justify-center   rounded-md shadow-md flex flex-col h-full gap-4">
      <div className="flex  align-middle items-center justify-between w-full ">
        <div className="flex font-medium text-lg flex-col">
          <div className="font-semibold">Subscribed Plan: </div>

          <div className="font-extrabold  uppercase text-lg sm:text-3xl">
            {orderDetail?.subscribedPlan}
          </div>
        </div>
        <div className="h-full flex flex-end items-center  "></div>
        {/* <p className="font-semibold ">{`Order Id: ${orderDetail?.orderId}`}</p> */}
      </div>
      <hr className="w-100"></hr>
      <div className="w-full flex flex-col sm:flex-row ">
        <div className=" w-full md:w-2/5 whitespace-break-spaces">
          <div className="text-straight font-bold">
            Order#:
            <span id="orderId" className="pl-1">
              {orderDetail?.orderId}
            </span>
          </div>
          <div className="text-straight">
            {orderDetail?.orderStatus === "cancelled" ? "Cancelled" : "Placed"}:
            <span className="whitespace-nowrap pl-1">
              {orderDetail?.orderStatus === "cancelled"
                ? modifiedTime
                : createdDate}
            </span>
          </div>
        </div>
        {user?.role === "ADMIN" && placedBy?.id && (
          <div className=" w-full md:w-1/3 whitespace-break-spaces">
            <div>
              <div className="text-straight font-bold">Placed by</div>
              <div className="flex flex-col ">
                <div className="text-straight">
                  Name:
                  <span className="whitespace-nowrap pl-1 ">
                    {placedBy?.name}
                  </span>
                </div>
                <div className="text-straight">
                  Id:
                  <span className="whitespace-nowrap pl-1 ">
                    {placedBy?.id}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr className="w-100"></hr>

      <div className="md:flex w-full  sm:block">
        <div className="w-full md:w-2/5">
          <H5 className="text mb-1  font-semibold">
            Business Name and Address
          </H5>
          <div className="text-sm">
            <p>{`${orderDetail?.businessName}`}</p>
            <p>{`${orderDetail?.businessAddress}`}</p>
            {orderDetail?.businessUnitOrSuite && (
              <p>{`${orderDetail?.businessUnitOrSuite}`}</p>
            )}
            <p>
              {orderDetail?.businessCity +
                " " +
                orderDetail?.businessState +
                " " +
                orderDetail?.businessZipCode}
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <H5 className="text mb-1 font-semibold">Contact</H5>
          <div className="text-s">
            <p className=" ">{`${orderDetail?.businessReprName}`}</p>
            <p className="w-full overflow-y-auto scrollbar-hide whitespace-pre-line ">
              Email:
              <span className="pl-1">
                {orderDetail?.businessReprEmail}
              </span>{" "}
            </p>
            <p className="w-full overflow-y-auto scrollbar-hide whitespace-pre-line ">
              Phone:{" "}
              <span className="pl-1">{orderDetail?.businessReprPhone}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-2/5">
        <H5 className="text mb-1  font-semibold">Shipping Address</H5>
        <div className="text-sm">
          <p>{`${orderDetail?.shippingAddress1}`}</p>
          {orderDetail?.shippingAddress2 && (
            <p>{`${orderDetail?.shippingAddress2}`}</p>
          )}
          <p>
            {orderDetail?.shippingAddressCity +
              " " +
              orderDetail?.shippingAddressState +
              " " +
              orderDetail?.shippingAddressZipCode}
          </p>
        </div>
      </div>
      <hr className="w-100"></hr>

      <div className="flex flex-col w-full md:w-2/5  ">
        <H5 className="text  mb-1  font-semibold">Payment Details</H5>
        <div className="flex flex-col  md:flex-row   text-sm  gap-1 sm:gap-20">
          <div className="flex whitespace-nowrap flex-row ">
            <p>{`Invoice Id: ${orderDetail?.invoice?.id || "No invoice"}`}</p>
            {/* {orderDetail?.invoice?.id && (
              <a
                target="_blank"
                href={`https://accountstagingv2.growthzilla.com/growthzilla/remote/public/protected/business/${orderDetail?.businessId}/billing?uid=${user?.id}&dest=api/invoice/:UID:/pdf/${orderDetail?.invoice?.id}`}
                rel="noreferrer"
              >
                <span>
                  <Tooltip className="h-2 w-2" title="Click to invoice">
                    <InfoOutlinedIcon
                      className="bg-white mb-0.5   text-black"
                      fontSize="inherit"
                      fontWeight="900"
                      onClick={() => {
                        // setOpenDetailDialog(true);
                        // console.log("Clicked");
                      }}
                    />
                  </Tooltip>
                </span>
              </a>
            )} */}
          </div>
          <div className="flex flex-row ">
            <div className="flex text-sm whitespace-nowrap overflow-y-scroll scrollbar-hide">
              <p>Payment Name: </p>
              <PaymentIcon className="text-blue-500" />
              <p>{orderDetail?.paymentName || "Not specified"}</p>
            </div>
          </div>
          <div className="flex whitespace-nowrap text-sm gap-2">
            <p> Payment Status:</p>
            <p className="font-bold uppercase">
              {orderDetail?.invoice?.paymentStatus ?? "pending"}
            </p>
          </div>
        </div>
      </div>
      <div>
        <H5 className="text mb-1 font-semibold">Order Details</H5>
        <div className="flex text-sm sm:gap-20 gap-1 flex-col sm:flex-row whitespace-nowrap">
          <div>
            {orderStatusDetails?.shippingStatus ? (
              <p>{`Status: ${orderStatusDetails?.shippingStatus}`}</p>
            ) : (
              <div className="flex text-sm gap-2 ">
                <p>Status: </p>
                <p className="uppercase"> {orderDetail?.orderStatus}</p>

                {/* {isAuthorizedPaymentApprover(user) ? (
                        <div
                          onClick={() => {
                            console.log("Open Input");
                          }}
                        >
                          <EditIcon fontSize="5" />
                        </div>
                      ) : null} */}
              </div>
            )}
          </div>

          <div className="flex sm:flex-row flex-col text-sm gap-2">
            <p>{`${open ? "Add Tracking Url" : "Track your order"}`}:</p>
            {open ? (
              <div className="flex flex-row gap-1">
                <input
                  className="border-2 border-blue-600 pl-1 rounded-md outline-none"
                  value={inputValue}
                  onChange={(event) => setInputValue(event.target.value)}
                />
                <div
                  onClick={async () => {
                    setTrackingUrl(inputValue);

                    if (orderDetail?.providerId) {
                      await setOrderTrackingUrl({
                        orderId: orderDetail?.orderId ?? null,
                        trackingUrl: inputValue,
                      });
                      setOpen(false);
                    } else {
                      toast.error("Order Not dispatched Yet");
                      console.log("Order Not dispatched Yet");
                    }
                  }}
                >
                  <Tooltip title="Save" placement="top" arrow>
                    <CheckIcon className="text-green-700" color="green" />
                  </Tooltip>
                </div>
                <span
                  onClick={() => {
                    // setTrackingUrl(tr);
                    setOpen(false);
                  }}
                >
                  <Tooltip title="Cancel" placement="top" arrow>
                    <CloseIcon className="text-red-700" />
                  </Tooltip>
                </span>
              </div>
            ) : (
              <span className="flex flex-row gap-1">
                {trackingUrl ? (
                  <a href={trackingUrl} className="text-blue-600 ml-2">
                    {`${trackingUrl ? "here" : null}`}
                  </a>
                ) : (
                  <p>Tracking available soon</p>
                )}
                <span>
                  {isAuthorizedPaymentApprover(user) ? (
                    <div
                      onClick={() => {
                        if (orderDetail?.providerId) {
                          setOpen(true);
                          setInputValue(trackingUrl ?? "");
                          console.log("Open Input", trackingUrl);
                        } else {
                          toast.error("Order Not dispatched Yet");
                          console.log("Order Not dispatched Yet");
                        }
                      }}
                    >
                      <Tooltip title="Add Tracking Url" placement="top" arrow>
                        <EditIcon fontSize="5" />
                      </Tooltip>
                    </div>
                  ) : null}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col">
        <H5 className="text flex font-bold w-full h-10 text-black pl-8 items-center border-b-2">
          Items
        </H5>
        <div className="w-full  overflow-y-scroll scrollbar-hide">
          <DataTable
            orderId={orderDetail?.orderId}
            data={orderDetail?.items}
            admin={isAuthorizedPaymentApprover(user)}
          />
        </div>
      </div>
      {/* <ul>
          {orderDetail?.items?.map((product, index) => {
            const { ROWID, name, unitPrice, provider, quantity } = product;
            return (
              // <li key={ROWID}>{`${
              //   index + 1
              // }. Name: ${name}, Quantity: ${quantity}, UnitPrice: $${unitPrice},Provider: ${
              //   provider || "Not specified"
              // }`}</li>
            );
          })} */}
      {/* </ul> */}
      <div className="flex-col ">
        <p className="font-semibold">
          Billing note:{" "}
          <span id="billingNote" className="font-normal text-sm">
            {orderDetail?.billingNote}
          </span>
        </p>
        <div className=" w-full flex mt-3 md:mt-1 flex-col gap-3 md:flex-row md:justify-between">
          <p className="font-semibold">{`Start date: ${new Date(
            orderDetail?.startDate
          ).toDateString()}`}</p>
          <p className="font-semibold">{`Created Date: ${new Date(
            orderDetail?.createdTime
          ).toDateString()}`}</p>
        </div>
      </div>
      <hr className="w-100"></hr>
      <div>
        <H5 className="text mb-1 font-semibold">Agreement Signers</H5>
        <div className="block md:flex gap-2">
          <div className="w-[100%] text-sm md:w-1/2">
            <p>{`Growthzilla Signee: ${orderDetail?.signingStatus?.data?.growthzillaSigneeName}`}</p>
            <div className="flex">
              <p>Signing Status:</p>
              {orderDetail?.signingStatus?.data?.signedByGrowthzilla ? (
                <Tooltip title="Signed" placement="top" arrow>
                  <VerifiedIcon
                    className="ml-1"
                    style={{
                      color: "purple",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Not Signed" placement="top" arrow>
                  <PendingIcon
                    style={{
                      color: "#e68c07",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <div className="w-[100%] text-sm md:w-1/2">
            <p>{`Business Signee: ${orderDetail?.signingStatus?.data?.businessReprName}`}</p>
            <div className="flex">
              <p>Signing Status:</p>
              {orderDetail?.signingStatus?.data?.signedByBusiness ? (
                <Tooltip title="Signed" placement="top" arrow>
                  <VerifiedIcon
                    className="ml-1"
                    style={{
                      color: "purple",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Not Signed" placement="top" arrow>
                  <PendingIcon
                    style={{
                      color: "#e68c07",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
UzeliStatusContent.propTypes = {
  orderDetail: PropTypes.object,
  setLoading: PropTypes.func,
  user: PropTypes.object,
};

export default UzeliStatusContent;
