import { useState } from "react";

const usePaymentName = () => {
  // const itemId = "666c354b-bf1e-452e-81c3-39646413c822";
  const [loading, setLoading] = useState(true);

  const updatePayment = async (oid, iid, paymentName, paymentMethodId) => {
    setLoading(true);
    try {
      const response = await fetch(`/browser/uzeliOrder/paymentmethod/${iid}`, {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        cache: "no-cache",
        body: JSON.stringify({
          orderId: oid,
          infinityId: iid,
          paymentName: paymentName,
          paymentMethodId: paymentMethodId,
        }),
      });
      const resJson = await response.json();
      console.log(resJson);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  return { loading, setLoading, updatePayment };
};

export default usePaymentName;
