import axios from "axios";

const useItems = () => {
  const getItems = async () => {
    try {
      const res = await axios.get(`/browser/equipment/uzeliItems`);
      return res.data;
    } catch (err) {
      console.log("Something went wrong");
      return err;
    }
  };

  return { getItems };
};

export default useItems;
