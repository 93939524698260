import React from "react";
import HashLoader from "react-spinners/HashLoader";
import { css } from "styled-components";
import PropTypes from "prop-types";
const Spinner = ({ loading, message }) => {
  const override = css`
    display: block;
    margin: 0 auto;
    line-height: 10px;
    align-items: center;
  `;
  return (
    <div
      className={`hash-loader w-full `}
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div className="flex items-center justify-center ">
        <HashLoader
          css={override}
          size={50}
          color={"purple"}
          loading={loading}
        />
      </div>
      <div className=" flex w-full">
        {message ? (
          <div className=" flex mt-3 text-xl text-center align-middle font-semibold  w-fit ">
            {" "}
            {message}
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* {message && <div>{message}</div>} */}
    </div>
  );
};
Spinner.propTypes = {
  loading: PropTypes?.bool,
  message: PropTypes.string,
};
export default Spinner;
