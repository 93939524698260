import React from "react";
import PropTypes from "prop-types";
import CustomMenu from "./CustomMenu";
import LoggedInUser from "./LoggedInUser";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function User({ user, switchUser, login }) {
  const handleIconClick = () => {
    if (login) {
      login();
    }
  };
  return (
    <div className="flex flex-col w-full justify-center items-center relative ">
      <div
        style={{
          lineHeight: "40px",
        }}
        data-analytics-name="User Element"
        data-analytics-eventtype="Clicked"
        className="  w-full flex justify-center items-center "
      >
        <CustomMenu
          menuIcon={
            <AccountCircleIcon
              className="loggedIn"
              onClick={() => handleIconClick()}
              color="inherit"
              fontSize="inherit"
            />
          }
          menuContent={
            !login &&
            user?.id && (
              <LoggedInUser user={user} switchUser={switchUser} login={login} />
            )
          }
          noCheckOut={true}
          className="right-1 sm:-right-3"
        />{" "}
      </div>
      <div
        style={{
          textOverflow: "",
        }}
        className={` absolute  z-[50] capitalize  -bottom-4 -right-[20px] max-w-[70px]   text-black text-xs sm:text-sm  md:max-w-[80px] overflow-clip  whitespace-nowrap`}
      >
        {user?.fullname}
      </div>
    </div>
  );
}
User.propTypes = {
  user: PropTypes.object,
  switchUser: PropTypes.func,
  login: PropTypes.func,
};
export default User;
