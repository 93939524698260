import React from "react";
import PropTypes from "prop-types";

const Redirect = ({ message }) => {
  return message ? (
    <div>{message}</div>
  ) : (
    <div>You are being redirected please wait...</div>
  );
};

Redirect.propTypes = {
  message: PropTypes.string,
};

export default Redirect;
