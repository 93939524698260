import axios from "axios";

const useEquipmentInventory = () => {
  const getEquipmentInventory = async () => {
    try {
      const res = await axios.get("/browser/equipment/inventory", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res?.data;
    } catch (err) {
      console.log("Something went wrong");
      return err;
    }
  };

  return { getEquipmentInventory };
};

export default useEquipmentInventory;
