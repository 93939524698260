/* eslint-disable no-unused-vars */
import { Alert, Card } from "@mui/material";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  // useRef,
  useState,
} from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../components/Button";
import { US_STATES, getStateAbbreviation } from "../../../utils/USStates";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import { FooterButton } from "../../document/Document";
import { Div, H5, Input, P } from "../../document/components/CustomForm";
import ComplimentaryPayment from "../components/ComplimentaryPayment";
import ShoppingSiteContext from "../context";
import useEquipmentOrder from "../hooks/useEquipmentOrder";
import useDispatchEquipmentOrder from "../../../hooks/useDispatchEquipmentOrder";
import IFrameComponent from "../../document/components/IFrameComponent";
import PlacingEquipmentOrder from "./PlacingOrderPage";
import PaymentIcon from "@mui/icons-material/Payment";
import { getDiscountObj } from "../../../utils/discount";
import { getItemFromSession, saveItemsToSession } from "../utils";
import updateEquipmentOrder from "../hooks/updateEquipmentOrder";
//../../assets/images/vuexy-logo.webp"
/**
 * @description
 * When admin user is logged in all business list are given in the following format:
 * admin {
 * businesses: [{}, {}]
 * }
 * But when non-admin user is logged in, only  businesses corresponding to that user is given in the following format:
 * business: [
 * {
 * business:{}
 * }, {
 * business:{}
 * }]
 */
const BusinessInformation = () => {
  const {
    decrementPage,
    inventory,
    cartItems,
    getTotalPrice,
    getTotalDiscountPrice,
  } = useContext(ShoppingSiteContext);
  const [taxRates, setTaxRates] = useState(null);
  const [complimentaryPaymentApprovers, setComplimentaryPaymentApprovers] =
    useState(null);
  const [complimentaryPayment, setComplimentaryPayment] = useState(false);
  const [user, setUser] = useState(null);
  const [business, setBusiness] = useState([]);
  const [showFrame, setShowFrame] = useState(false);
  const [preInvoice, setPreInvoice] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  // const [gettingData, setGetting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paidAmount, setPaidAmount] = useState(null);
  const [shippingState, setShippingState] = useState(null);
  const [associatedBusiness, setAssociatedBusiness] = useState(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  // const [selectedBusiness, setSelectedBusiness] = useState(null);
  const { dispatchEquipmentOrder } = useDispatchEquipmentOrder();
  const { createEquipmentOrder } = useEquipmentOrder();
  const discountObj = getDiscountObj(search);
  const withDiscountObject = discountObj?.status ? true : false;
  const [iframeSource, setIframeSource] = useState(null);

  // console.log({ discountObj });
  const { updateOrder } = updateEquipmentOrder();

  const showPaymentWindow = () => {
    setShowFrame(false);
    setIframeSource(
      `${getEnvironmentValue().EMBED_PAYMENT_URL}&bid=${associatedBusiness?.id}`
    );
    setShowFrame(true);
  };

  const getApproverId = (authPaymentAdmins, user) => {
    // console.log({ authPaymentAdmins, user });
    const authUser = authPaymentAdmins?.find((item) => {
      if (item?.email === user?.email) {
        return item;
      } else return null;
    });
    return authUser?.ROWID;
  };

  // console.log(
  //   getApproverId(complimentaryPaymentApprovers, discountObj?.data?.approvedBy)
  // );

  const listener = {
    paymentSelectedResponse: (action, paymentData) => {
      if (action === "back") {
        setShowFrame(false);
        return;
      }
      setShowFrame(false);
      setLoading(true);
      setPaymentData(paymentData);
    },
    invoiceResponse: (status, invoiceData) => {
      // console.log("Invoice Resposne", invoiceData);
      if (status) {
        // console.log("Invoice Response from IFrame", invoiceData);
        setInvoiceData(invoiceData);
      } else {
        setLoading(false);
        const defaultMessage =
          "Payment failed. Error occurred while creating invoice.";
        const messageFromInvoiceResponse = invoiceData.type
          ? invoiceData?.type
          : "" + "  Error in  invoice creation.";
        const errorMessage = messageFromInvoiceResponse
          ? messageFromInvoiceResponse
          : defaultMessage;
        toast.error(errorMessage, {
          toastId: "payment-error",
        });
        saveItemsToSession("storeFailed", true);
        navigate("/app/error/store/failed");
      }
    },
  };

  useEffect(() => {
    const currentUser = getItemFromSession("user-s");
    // console.log("Getting user 1 ", currentUser);
    if (currentUser?.id) {
      setUser(currentUser);
      setShowFrame(false);
    }
  }, []);

  const isEligibleLink = getItemFromSession("isEligibleLink");
  const userIsAdmin = user?.role === "ADMIN" ? true : false;

  const disableSelectBusiness = !userIsAdmin && isEligibleLink ? true : false;

  useEffect(() => {
    // console.log("I just landed here");
    if (!user?.id) return;
    const currentUser = getItemFromSession("user-s");
    // console.log({ "user changed": currentUser });
    if (currentUser?.id) {
      saveItemsToSession(`fromCheckout`, true);
      const initialAdminBusiness = getItemFromSession(
        `admin-${currentUser?.id}`
      );
      const initialAssociatedBusiness = getItemFromSession(
        `linkBusiness-${currentUser?.id}`
      );
      setBusiness(initialAdminBusiness);
      if (initialAssociatedBusiness?.id) {
        setAssociatedBusiness(initialAssociatedBusiness);
        setValue("selectedBusiness", initialAssociatedBusiness);
      }

      if (initialAdminBusiness?.length === 0) {
        toast.error("No associated businesses are found for this account!", {
          toastId: "no-business",
        });
      }
    }
  }, [user]);

  const fetchTaxRates = async () => {
    try {
      const taxResponse = await axios.get("/browser/taxrates", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return taxResponse?.data;
    } catch (err) {
      console.log("Error occurred while getting taxes ", err);
    }
  };

  const fetchAuthorizedAdmins = async () => {
    try {
      const response = await axios.get("/browser/authPaymentAdmins", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setComplimentaryPaymentApprovers(response?.data);
    } catch (err) {
      console.log("Error occurred while getting auth admins", err);
    }
  };

  const fetchAndSetTaxrates = async () => {
    const sessionTaxRates = getItemFromSession("taxRates");
    if (!sessionTaxRates) {
      const taxResponse = await fetchTaxRates();
      saveItemsToSession("taxRates", taxResponse);
      setTaxRates(taxResponse);
    } else {
      setTaxRates(sessionTaxRates);
    }
  };

  useEffect(async () => {
    await fetchAndSetTaxrates();
    await fetchAuthorizedAdmins();
    window.scrollTo(0, 0);
    if (!cartItems) {
      navigate("/app/store");
    }
  }, []);

  const getTaxRateByState = (state) => {
    if (!taxRates || !taxRates.length) return 0;
    return (
      taxRates?.find((item) => item?.state === getStateAbbreviation(state))
        ?.taxRate || 0
    );
  };

  const isAuthorizedPaymentApprover = () => {
    return user?.role === "ADMIN";
  };

  let initialValues = {
    selectedBusiness: null,
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddressCity: "",
    shippingAddressState: "",
    shippingAddressZipCode: "",
    contactName: "",
    contactNumber: "+1",
    contactEmail: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: initialValues,
    shouldFocusError: true,
    mode: "all",
  });

  const shippingAddressState = watch("shippingAddressState");

  const selectedBusinessValue = watch("selectedBusiness");
  // console.log({ associatedBusiness, selectedBusinessValue });
  const getTaxAmount = () => {
    const formData = getItemFromSession("formdata_s_" + user?.id);
    const taxRate = getTaxRateByState(formData?.shippingAddressState);
    const totalSalesPrice = getTotalPrice();
    const totalDiscount = getTotalDiscountPrice();
    const afterDiscountPrice = totalSalesPrice - totalDiscount;
    const taxAmount = (afterDiscountPrice * taxRate) / 100;
    return taxAmount.toFixed(2);
  };

  const getTotalPaymentAmount = (city) => {
    const sessionTaxRates = getItemFromSession("taxRates");
    let taxes = taxRates || sessionTaxRates;

    const validState = taxes?.find(
      (item) => item?.state === getStateAbbreviation(city)
    );
    // console.log({ city, validState });
    if (city) {
      const taxRate = validState?.taxRate || 0;
      const totalSalesPrice = getTotalPrice();
      const totalDiscount = getTotalDiscountPrice();

      const afterDiscountPrice = totalSalesPrice - totalDiscount;
      const taxAmount = (afterDiscountPrice * taxRate) / 100;
      const totalAmount = afterDiscountPrice + taxAmount;
      // console.log({ taxRates, city, taxes, totalAmount });
      // console.log({ totalSalesPrice, totalAmount, totalDiscount });
      return totalAmount.toFixed(2);
    }
  };

  // const totalAmount = getTotalPaymentAmount(shippingAddressState);
  // console.log(totalAmount);

  useEffect(() => {
    // console.log({ shippingState, shippingAddressState });
    if (
      (shippingState && shippingState?.length) ||
      (shippingAddressState && shippingAddressState?.length)
    ) {
      const totalPaymentAmount = getTotalPaymentAmount(
        shippingState || shippingAddressState
      );
      // console.log("Setting Payment Amount");
      setPaidAmount(totalPaymentAmount);
    } else {
      setPaidAmount(null);
    }
  }, [shippingState, shippingAddressState]);

  const getBoughtItems = useCallback(() => {
    let boughtItems = [];
    inventory?.forEach((product) => {
      const item = cartItems?.find((item) => item?.id === product?.ROWID);

      if (item?.quantity && item?.quantity >= 1) {
        const updatedProduct = {
          ...product,
          quantity: item?.quantity,
        };
        boughtItems.push(updatedProduct);
      }
    });
    return boughtItems;
  }, [inventory, cartItems]);

  // create invoice when payment method is selected
  useEffect(async () => {
    if (!user?.id) return;
    if (paymentData) {
      const formData = getItemFromSession("formdata_s_" + user?.id);
      const tax = getTaxRateByState(formData?.shippingAddressState);
      saveItemsToSession(`taxRate-${user?.id}`, tax);
      // console.log({ paymentData });
      const invoiceDetails = {
        paymentMethodId: paymentData?.id,
        userId: paymentData?.userId,
        items: getBoughtItems()?.map((item) => {
          const { name, unitPrice, quantity } = item;
          return { label: name, quantity, price: Number(unitPrice) };
        }),
        currency: "USD",
        taxPercentage: getTaxRateByState(formData?.shippingAddressState),
        discountType: "a",

        discountValue: Number(getTotalDiscountPrice()),
        discountLabel:
          paymentData?.name === "complimentary"
            ? "complimentary"
            : "Orders Discount",
        onlyInvoice: paymentData?.name === "complimentary" ? true : false,
      };
      if (paymentData?.name === "complimentary") {
        setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
        setShowFrame(false);
      }
      console.log("To generate Invoice ", invoiceDetails);
      setPreInvoice({
        send: true,
        type: "generateInvoice",
        postMessage: {
          from: "orders-growthzilla",
          createInvoice: true,
          invoiceData: invoiceDetails,
        },
        src: getEnvironmentValue().ORIGIN,
      });
    }
  }, [paymentData]);

  const clearCartItemsFromSession = () => {
    const currentUser = getItemFromSession("user-s");
    const adminBusiness = getItemFromSession(`admin-${currentUser?.id}`);
    sessionStorage.clear();
    saveItemsToSession("user-s", currentUser);
    saveItemsToSession(`admin-${currentUser?.id}`, adminBusiness);
  };

  // const discountApprover =
  // create equipment order when invoice is created
  useEffect(async () => {
    if (invoiceData) {
      // console.log({ invoiceData });
      const invoiceId = invoiceData?.invoice?.id || invoiceData?.id;
      const paymentStatus = invoiceData?.invoice?.status || invoiceData?.status;
      const isComplimentary = paymentData?.name === "complimentary";
      const totalAmountPaid =
        invoiceData?.invoice?.amount || invoiceData?.amount;
      setPaidAmount(totalAmountPaid);
      const formData = getItemFromSession("formdata_s_" + user?.id);

      const complimentaryReason = getItemFromSession("complimentaryReason")
        ?.map((reason) => reason.value)
        .join(", ");
      const {
        selectedBusiness,
        shippingAddress1,
        shippingAddress2,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressZipCode,
        contactName,
        contactEmail,
        contactNumber,
      } = formData;
      // console.log({ selectedBusiness });
      const equipmentOrderRes = await createEquipmentOrder({
        businessId: selectedBusiness?.id,
        businessName:
          selectedBusiness?.business?.name ||
          selectedBusiness?.name ||
          selectedBusiness?.label,

        businessAddress: selectedBusiness?.business?.address,
        businessCity: selectedBusiness?.business?.city,
        businessState: selectedBusiness?.business?.state,
        businessZipCode: selectedBusiness?.business?.zipcode,
        shippingAddress1: shippingAddress1,
        shippingAddress2: shippingAddress2,
        shippingAddressCity: shippingAddressCity,
        shippingAddressState: shippingAddressState,
        shippingAddressZipCode: shippingAddressZipCode,
        items: getBoughtItems(),
        totalPrice: totalAmountPaid,
        taxAmount: getTaxAmount(),
        discountAmount: getTotalDiscountPrice(),
        discountObject: withDiscountObject ? discountObj?.data : {},
        salesPrice: getTotalPrice(),
        orderStatus: "pending",
        approvedBy: withDiscountObject
          ? getApproverId(
              complimentaryPaymentApprovers,
              discountObj?.data?.approvedBy
            )
          : null,
        approvalLink: isComplimentary
          ? `${window.location.protocol}//${window.location.host}/app/store/pendingorders`
          : "",
        uzeliUser: {
          id: user?.id,
          email: user?.email,
          name: user?.fullname,
        },
        businessReprName: contactName,
        businessReprEmail: contactEmail,
        businessReprPhone: "+" + contactNumber,
        complimentaryReason: isComplimentary ? complimentaryReason || "" : "",
        invoiceId: invoiceId,
        paymentStatus: paymentStatus,
        paymentName: paymentData?.name,
        paymentMethodId: paymentData?.id,
      });

      if (!equipmentOrderRes?.success) {
        toast.error("Error while creating equipment order!", {
          toastId: "equipment-order-error",
        });
        saveItemsToSession("storeFailed", true);
        navigate("/app/error/store/failed");
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const dispatchOrder = isComplimentary
        ? false
        : invoiceId && (paymentStatus === "paid" || paymentStatus === "pending")
        ? true
        : false;

      if (paymentData?.name === "complimentary") {
        toast.success("Complementary Order has placed successfully", {
          toastId: "complimentary-payment",
        });

        // navigate(`/app/store/status/${equipmentOrderRes?.data?.order?.ROWID}`);
        clearCartItemsFromSession();
        window.open(
          `${window.location.protocol}//${window.location.host}/app/store`,
          "_self"
        );
      } else {
        if (dispatchOrder) {
          // console.log("Dispatching Order");
          const dispatchedOrder = await dispatchEquipmentOrder({
            recipientName: contactName,
            recipientAddress: {
              shippingAddress1: shippingAddress1,
              shippingAddress2: shippingAddress2,
              shippingAddressCity: shippingAddressCity,
              shippingAddressZipCode: shippingAddressZipCode,
              shippingAddressState: getStateAbbreviation(shippingAddressState),
              businessName:
                selectedBusiness?.business?.name ||
                selectedBusiness?.name ||
                selectedBusiness?.label,
            },
            recipientEmail: contactEmail,
            recipientPhone: "+" + contactNumber,
            products: getBoughtItems(),
            referenceId: `STO:${
              equipmentOrderRes?.data.order?.ROWID
            }-GZ-INVOICE:${invoiceData?.invoice?.id || invoiceData?.id}`,
            invoiceId: invoiceData?.invoice?.id || invoiceData?.id,
            paymentStatus: invoiceData?.invoice?.status,
          });
          // console.log("Dispatched Order", dispatchedOrder);
          const dispatchedOrderStatus =
            dispatchedOrder?.data?.dispatchedOrderStatus;
          const toastMessage = dispatchedOrder?.data?.message;
          if (
            dispatchedOrderStatus?.shipbobId &&
            dispatchedOrderStatus?.emailStatus
          ) {
            const updatedOrder = {
              orderId: equipmentOrderRes?.data.order?.ROWID,
              orderStatus: "approved",
            };
            // console.log("Order Placed");
            toast.success(toastMessage, {
              toastId: "order-approved-success",
            });
            await updateOrder(updatedOrder);
            window.GZAnalytics.customLog({
              name: "Dispatch Order",
              eventtype: "dispatched",
            });
            navigate(
              `/app/store/ordersuccess/${equipmentOrderRes?.data.order?.ROWID}/finished`
            );
          } else {
            // updatedOrder = {
            //   ...updatedOrder,
            //   orderStatus: "pending",
            // };
            console.log("Error While dispatching order");
            toast.error(toastMessage, {
              toastId: "order-approve-failed",
            });
            window.GZAnalytics.customLog({
              name: "Dispatch Order",
              eventtype: "error",
            });
            // await updateOrder(updatedOrder);
            saveItemsToSession("storeFailed", true);
            navigate("/app/error/store/failed");
          }
        }
      }
    }
  }, [invoiceData]);

  const businessOption = business
    ?.filter((b) => b?.business?.status === "ACTIVE" || b?.status === "ACTIVE")
    ?.map((item) => {
      return {
        ...item,
        label: item?.business?.name || item?.name,
        value: item?.business?.id || item?.id,
      };
    });
  // console.log("Business Options", businessOption, associatedBusiness);

  const hasDifferentShippingAddress = getItemFromSession("hasDiffShipment");

  const [differentShippingAddress, setDifferentShippingAddress] = useState(
    hasDifferentShippingAddress ?? false
  );

  useEffect(() => {
    // console.log("Populating formData", user);
    const currentUser = getItemFromSession("user-s");
    const formState = getItemFromSession("formdata_s_" + currentUser?.id);
    // console.log({ formState });
    const initialAssociatedBusiness = getItemFromSession(
      `linkBusiness-${currentUser?.id}`
    );
    if (formState) {
      setShippingState(formState?.shippingAddressState);
      initialValues = {
        ...formState,
        selectedBusiness: formState?.selectedBusiness?.id
          ? formState?.selectedBusiness
          : initialAssociatedBusiness,
        shippingAddress1: formState?.shippingAddress1
          ? formState?.shippingAddress1
          : initialAssociatedBusiness?.business?.address ||
            initialAssociatedBusiness?.address,
        shippingAddressCity: formState?.shippingAddressCity
          ? formState?.shippingAddressCity
          : initialAssociatedBusiness?.business?.city ||
            initialAssociatedBusiness?.city,
        shippingAddressState: formState?.shippingAddressState
          ? formState?.shippingAddressState
          : initialAssociatedBusiness?.business?.state ||
            initialAssociatedBusiness?.state,
        shippingAddressZipCode: formState?.shippingAddressZipCode
          ? formState?.shippingAddressZipCode
          : initialAssociatedBusiness?.business?.zipcode ||
            initialAssociatedBusiness?.zipcode,
      };

      reset(initialValues);
    } else {
      initialValues.selectedBusiness = initialAssociatedBusiness;
      setShippingState(
        initialAssociatedBusiness?.business?.state ||
          initialAssociatedBusiness?.state
      );
      // console.log("Setting Here", initialAssociatedBusiness, initialValues);
      reset({
        ...initialValues,
        contactName: user?.fullname || initialValues.contactName,
        contactNumber: user?.phone || initialValues.contactNumber,
        contactEmail: user?.email || initialValues.contactEmail,
        shippingAddress1:
          initialAssociatedBusiness?.business?.address ||
          initialAssociatedBusiness?.address,
        shippingAddressCity:
          initialAssociatedBusiness?.business?.city ||
          initialAssociatedBusiness?.city,
        shippingAddressState:
          initialAssociatedBusiness?.business?.state ||
          initialAssociatedBusiness?.state,
        shippingAddressZipCode:
          initialAssociatedBusiness?.business?.zipcode ||
          initialAssociatedBusiness?.zipcode,
      });
    }
  }, []);

  const saveFormState = () => {
    const formState = getValues();
    const user = getItemFromSession("user-s");
    // console.log("Saving Form state ", user);
    saveItemsToSession("formdata_s_" + user?.id, formState);
    // console.log({ associatedBusiness, selectedBusinessValue });
    saveItemsToSession(
      `linkBusiness-${user?.id}`,
      selectedBusinessValue?.id ? selectedBusinessValue : associatedBusiness
    );
  };

  const onSubmit = (data) => {
    saveFormState();
    console.log({ data });
    if (isAuthorizedPaymentApprover()) {
      setShowAdminModal(true);
    } else {
      showPaymentWindow();
    }
  };

  const displayIframeStyle = {
    background: "rgb(247,247,247)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
  };

  const hiddenIframeStyle = {
    display: "none",
  };
  // const initialSelectValue =
  //   initialValues?.selectedBusiness || associatedBusiness;

  // useEffect(() => {
  //   const previousSelectedBusiness = getValues()?.selectedBusiness;
  //   if (!previousSelectedBusiness) {
  //     console.log("null found");
  //     setValue(
  //       "selectedBusiness",
  //       initialValues?.selectedBusiness || associatedBusiness
  //     );
  //     setSelectedBusiness(
  //       initialValues?.selectedBusiness || associatedBusiness
  //     );
  //   }
  //   console.log({
  //     previousSelectedBusiness,
  //     initial: initialValues?.selectedBusiness,
  //     associatedBusiness,
  //   });
  // }, [initialValues?.selectedBusiness, associatedBusiness]);
  const activeValue = businessOption?.find((e) => {
    if (selectedBusinessValue?.id) {
      return e?.id === selectedBusinessValue?.id;
    } else if (associatedBusiness?.id) {
      return e?.id === associatedBusiness?.id;
    }
  });

  const handleChangeShipment = (data) => {
    console.log({ data });
    window.GZAnalytics &&
      window.GZAnalytics.customLog({
        name: "Shipment Changed",
        eventtype: "clicked",
        payload: { shipToDIfferentAddress: data },
      });
    setDifferentShippingAddress(data);
    console.log({ associatedBusiness });
    if (!data) {
      setValue(
        "shippingAddress1",
        associatedBusiness?.business?.address || associatedBusiness?.address
      );

      setValue(
        "shippingAddressCity",
        associatedBusiness?.business?.city || associatedBusiness?.city
      );
      setValue(
        "shippingAddressState",
        associatedBusiness?.business?.state || associatedBusiness?.state
      );
      setValue(
        "shippingAddressZipCode",
        associatedBusiness?.business?.zipcode || associatedBusiness?.zipcode
      );
    }
    // if (data) {
    //   setValue("shippingAddress1", "");
    // }
    saveItemsToSession("hasDiffShipment", data);
  };
  // console.log("Active Value", activeValue);

  Modal.setAppElement("#root");
  return (
    <div className="flex justify-center scrollbar-hide">
      {/* <div className=" md:p-10 sm:p-10 p-10 w-full flex justify-center">
        <div className=" z-50 flex w-4/5  justify-between align bg-[#4D1F70] p-2 fixed">
          <img src={UzeliLogo} alt="logo" />
          <div className="flex justify-end text-white items-center text-4xl">
            <Cart equipment={cartItems} />
            {user?.id && (
              <User
                user={user}
                switchUser={() => {
                  setShowFrame(true);
                  setIframeSource(getEnvironmentValue().EMBED_LOGIN_URL);
                }}
              />
            )}
          </div>
        </div>
      </div> */}

      <div className="flex pt-20 container justify-center lg:w-4/5 md:mx-auto">
        {loading && (
          <div className="h-[85vh] w-full">
            <PlacingEquipmentOrder />
          </div>
        )}
        {/* {gettingData && (
          <div className="h-[85vh] w-full">
            <Spinner loading={true} message={"Getting Data"} />
          </div>
        )} */}
        {iframeSource && (
          <div style={showFrame ? displayIframeStyle : hiddenIframeStyle}>
            {
              <IFrameComponent
                listener={listener}
                messageToIFrame={preInvoice}
                iFrameSrc={iframeSource}
              />
            }
          </div>
        )}

        {showAdminModal && (
          <div className="container px-4 lg:w-4/5 md:mx-auto">
            <div className="flex flex-col items-center justify-center  border-none ">
              <ComplimentaryPayment
                complimentaryPayment={complimentaryPayment}
                setComplimentaryPayment={setComplimentaryPayment}
                inventory={inventory}
                cartItems={cartItems}
                setPaymentData={setPaymentData}
                setShowAdminModal={setShowAdminModal}
                totalPrice={Number(getTotalPrice())}
                user={user}
                showPaymentWindow={showPaymentWindow}
                setLoading={setLoading}
                differentShippingAddress={differentShippingAddress}
              />
            </div>
          </div>
        )}
        {!showFrame && !loading && !showAdminModal && (
          <div>
            {/* {console.log("Form Rendered")} */}

            <div>
              <div className="my-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Card className="p-[2rem]">
                    <div>
                      <H5 className="capitalize font-bold text-xl">
                        Shipping Address Information
                      </H5>
                      <label htmlFor="selectedBusiness">
                        <H5 className="text-base font-medium capitalize mb-2 mt-4">
                          Select Your Business*
                        </H5>
                      </label>
                      {/* {console.log({ selectedBusiness })} */}
                      <Div>
                        <Controller
                          name="selectedBusiness"
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={activeValue}
                              defaultValue={activeValue}
                              options={businessOption}
                              isClearable={true}
                              isSearchable={true}
                              id="selectBusiness"
                              onChange={(value) => {
                                window.GZAnalytics &&
                                  window.GZAnalytics.customLog({
                                    name: "Business Changed",
                                    eventtype: "clicked",
                                    payload: { business: value },
                                  });
                                // console.log(value);
                                setShippingState("");
                                setValue("shippingAddress2", "");
                                setAssociatedBusiness(value);
                                setValue("selectedBusiness", value);
                                setValue(
                                  "shippingAddress1",
                                  value?.business?.address || value?.address
                                );
                                setValue(
                                  "shippingAddressCity",
                                  value?.business?.city || value?.city
                                );
                                setValue(
                                  "shippingAddressState",
                                  value?.business?.state || value?.state
                                );
                                setValue(
                                  "shippingAddressZipCode",
                                  value?.business?.zipcode || value?.zipcode
                                );
                              }}
                              isDisabled={disableSelectBusiness}
                            />
                          )}
                          control={control}
                          rules={{ required: true }}
                        />
                        {errors.selectedBusiness && (
                          <P>Please select your business</P>
                        )}
                      </Div>

                      <H5 className="text-gray-500 font-semibold text-sm mt-6">
                        Would you like us to deliver the ordered equipment to
                        your business address or a different address?
                      </H5>
                      <div className="inline-flex items-center mt-2">
                        <Button
                          active={!differentShippingAddress}
                          label="Ship to business Address"
                          type="primary"
                          handleClick={() => {
                            handleChangeShipment(false);
                          }}
                        />
                        <Button
                          active={differentShippingAddress}
                          label="Ship to different Address"
                          type="secondary"
                          handleClick={() => handleChangeShipment(true)}
                        />
                      </div>
                      {!differentShippingAddress && (
                        <H5 className="text-gray-500 font-semibold text-sm mt-6">
                          Please contact the administrator if you wish to modify
                          the business address.
                        </H5>
                      )}
                      {
                        <div>
                          <Div className="mt-2">
                            <label htmlFor="shippingAddress1">
                              <H5 className="text-base font-medium capitalize mb-2">
                                Address1*
                              </H5>
                            </label>
                            <Input
                              defaultValue={initialValues?.shippingAddress1}
                              placeholder="9476 Cambridge St"
                              {...register("shippingAddress1", {
                                validate: (value) => value?.length > 0,
                                maxLength: 80,
                                // disabled: !differentShippingAddress,
                              })}
                              disabled={!differentShippingAddress}
                            />
                            {errors.shippingAddress1 && (
                              <P>Shipping address is required</P>
                            )}
                          </Div>
                          <Div>
                            <label htmlFor="shippingAddress2">
                              <H5 className="text-base font-medium capitalize mb-2">
                                Address2
                              </H5>
                            </label>
                            <Input
                              defaultValue={initialValues?.shippingAddress2}
                              placeholder="Suite 100"
                              {...register("shippingAddress2", {
                                required: false,
                                maxLength: 80,
                                // disabled: !differentShippingAddress,
                              })}
                              disabled={!differentShippingAddress}
                            />
                          </Div>
                          <Div>
                            <label htmlFor="shippingAddressCity">
                              <H5 className="text-base font-medium capitalize mb-2">
                                City*
                              </H5>
                            </label>
                            <Input
                              defaultValue={initialValues?.shippingAddressCity}
                              placeholder="Gotham City"
                              {...register("shippingAddressCity", {
                                required: true,
                                maxLength: 80,
                                validate: (value) => value?.length > 0,
                                // disabled: !differentShippingAddress,
                              })}
                              disabled={!differentShippingAddress}
                            />
                            {errors.shippingAddressCity && (
                              <P>City is required</P>
                            )}
                          </Div>
                          <Div>
                            <label htmlFor="shippingAddressState">
                              <H5 className="text-base font-medium capitalize mb-2">
                                State*
                              </H5>
                            </label>
                            <Input
                              defaultValue={initialValues?.shippingAddressState}
                              placeholder="CA or California"
                              {...register("shippingAddressState", {
                                required: true,
                                validate:
                                  ((value) =>
                                    Object.values(US_STATES)?.includes(value) ||
                                    Object.keys(US_STATES)?.includes(value)) ||
                                  "Enter a valid state",
                                maxLength: 20,
                                // disabled: !differentShippingAddress,
                              })}
                              disabled={!differentShippingAddress}
                              onChange={(event) => {
                                const stateValue = event.target.value;
                                setShippingState(stateValue);
                                // if (
                                //   Object.values(US_STATES)?.includes(
                                //     stateValue
                                //   ) ||
                                //   Object.keys(US_STATES)?.includes(stateValue)
                                // ) {
                                //   setState(stateValue);
                                // } else if (!stateValue || stateValue === "") {
                                //   setState(null);
                                // }
                              }}
                            />
                            {getTaxRateByState(
                              shippingAddressState || shippingState
                            ) !== 0 && (
                              <Alert
                                variant="filled"
                                severity="warning"
                                style={{
                                  backgroundColor: "#eda109",
                                }}
                              >
                                {`You will be subjected to a ${getTaxRateByState(
                                  shippingAddressState || shippingState
                                )}% equipment tax rate.`}
                              </Alert>
                            )}
                            {errors.shippingAddressState && (
                              <P>Valid state name is required</P>
                            )}
                          </Div>
                          <Div>
                            <label htmlFor="shippingAddressZipCode">
                              <H5 className="text-base font-medium capitalize mb-2">
                                Zip Code*
                              </H5>
                            </label>
                            <Input
                              disabled={!differentShippingAddress}
                              defaultValue={
                                initialValues?.shippingAddressZipCode
                              }
                              placeholder="07093"
                              {...register("shippingAddressZipCode", {
                                validate: (value) => value?.length > 0,
                                required: true,
                                maxLength: 80,
                              })}
                            />
                            {errors.shippingAddressZipCode && (
                              <P>Zip code is required</P>
                            )}
                          </Div>
                        </div>
                      }
                    </div>

                    {/* Contact Information  */}

                    {
                      <div className="mt-10">
                        <H5 className="capitalize font-bold text-xl">
                          Contact Information*
                        </H5>
                        <H5 className="text-gray-500  font-semibold text-sm mb-4">
                          Review your information and edit if necessary
                        </H5>
                        <Div>
                          <label htmlFor="contactName">
                            <H5 className="text-base font-medium capitalize mb-2">
                              Enter Your Name*
                            </H5>
                          </label>
                          <Input
                            defaultValue={initialValues.contactName}
                            placeholder=""
                            {...register("contactName", {
                              validate: (value) => value?.length > 0,
                            })}
                          />
                          {errors.contactName && <P> Your name is required</P>}
                        </Div>

                        <Div>
                          <label htmlFor="contactNumber">
                            <H5 className="text-base font-medium capitalize mb-2">
                              Enter Your Phone Number*
                            </H5>
                          </label>
                          <Controller
                            name="contactNumber"
                            control={control}
                            defaultValue={initialValues.contactNumber}
                            rules={{
                              validate: (value) =>
                                isValidPhoneNumber(value, "US"),
                            }}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                inputProps={{
                                  required: true,
                                  autoFocus: true,
                                }}
                                value={value}
                                onChange={onChange}
                                country={"us"}
                                countryCodeEditable={false}
                                onlyCountries={["us"]}
                                id="contactNumber"
                                inputStyle={{
                                  width: "100%",
                                  height: "45px",
                                }}
                              />
                            )}
                          />
                          {errors.contactNumber && <P>Invalid phone number</P>}
                        </Div>

                        <Div>
                          <label htmlFor="contactEmail">
                            <H5 className="text-base font-medium capitalize mb-2">
                              Enter Your Email*
                            </H5>
                          </label>
                          <Input
                            defaultValue={initialValues.contactEmail}
                            placeholder="@"
                            {...register("contactEmail", {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email",
                              },
                              validate: (value) => value?.length > 0,
                            })}
                          />
                          {errors.contactEmail && (
                            <P>
                              {" "}
                              {errors.contactEmail?.message ||
                                "Email is required"}
                            </P>
                          )}
                        </Div>
                      </div>
                    }
                  </Card>
                  <div className="flex justify-between py-4">
                    <FooterButton
                      className="flex items-center text-white bg-[#4D1F70] hover:text-yellow-500"
                      label={"Continue to Setup Payment Method"}
                      type="button"
                      onClick={() => {
                        window.GZAnalytics &&
                          window.GZAnalytics.customLog({
                            name: "Back Button Element",
                            eventtype: "clicked",
                          });
                        decrementPage();
                        saveFormState();
                      }}
                    >
                      <ChevronLeft />
                      Go Back
                    </FooterButton>
                    <FooterButton
                      type="submit"
                      className="flex justify-center items-center  text-white bg-[#4D1F70] hover:text-yellow-500 "
                      label={"Continue to Setup Payment Method"}
                      data-analytics-name="Pay Button Element"
                      data-analytics-eventtype="Clicked"
                      data-analytics-payload={JSON.stringify({
                        from: "store",
                      })}
                    >
                      <span className="hidden sm:block">
                        Proceed to payment
                      </span>
                      <span className="block sm:hidden">Pay</span>
                      {paidAmount > 0 ? (
                        <span className=" flex justify-center text-lg items-center pl-1 md:pl-5 font-bold ">
                          <PaymentIcon /> ${paidAmount}
                        </span>
                      ) : (
                        <></>
                      )}
                      <ChevronRight />
                    </FooterButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessInformation;
