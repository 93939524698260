import React, { useContext, useEffect } from "react";
import CustomForm from "../components/CustomForm";
import SwitchContext from "../context";
import { useNavigate } from "react-router-dom";

const SeventhPage = () => {
  const navigate = useNavigate();
  const { contactInfo } = useContext(SwitchContext);
  const isFromProma =
    JSON.parse(sessionStorage.getItem("fromProma") ?? "{}") ?? false;
  const sessionKey = isFromProma ? "leadString" : "itemId";
  // console.log({ isFromProma, sessionKey });
  const itemId = JSON.parse(sessionStorage.getItem(sessionKey)) ?? "";
  // console.log({ itemId });
  useEffect(() => {
    if (itemId === "") {
      navigate("/app/formFailed");
    }
  }, []);

  // console.log("Item ID", itemId);
  return (
    <div>
      <p className="text-xl md:text-3xl mb-4 font-medium">
        7. Please provide us some information about your business
      </p>
      <CustomForm
        key={"Customform"}
        showContact={contactInfo}
        itemId={itemId}
      />
    </div>
  );
};

export default SeventhPage;
