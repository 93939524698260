/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import useToggle from "../../document/components/ToggleComponent";

const LinkGeneratorComponent = ({
  showElements,
  product,
  discounts,
  setDiscounts,
}) => {
  const initialValue = discounts?.find((item) => item?.itemId === product?.id);
  const [percent, setPercent] = useState({
    item: product?.id,
    status:
      initialValue?.percent === true
        ? true
        : initialValue?.percent === false
        ? false
        : true,
  });
  const [type, setType] = useState({
    item: product?.id,
    status:
      initialValue?.type === "total"
        ? true
        : initialValue?.type === "each"
        ? false
        : true,
  });
  const { Toggle: Percentage } = useToggle({
    initialValue: false,
    onEnable: () => {
      setDiscounts(
        discounts?.map((item) => {
          if (item?.itemId === product?.id) {
            setPercent({ item: item?.itemId, status: false });
            return {
              ...item,
              percent: false,
            };
          } else return item;
        })
      );
    },
    onDisable: () => {
      setDiscounts(
        discounts?.map((item) => {
          if (item?.itemId === product?.id) {
            setPercent({ item: item?.itemId, status: true });
            return {
              ...item,
              percent: true,
            };
          } else return item;
        })
      );
    },
  });
  const { Toggle: Total } = useToggle({
    initialValue: false,
    onEnable: () => {
      setDiscounts(
        discounts?.map((item) => {
          if (item?.itemId === product?.id) {
            setType({ item: item?.itemId, status: false });
            return {
              ...item,
              type: "each",
            };
          } else return item;
        })
      );
    },
    onDisable: () => {
      setDiscounts(
        discounts?.map((item) => {
          if (item?.itemId === product?.id) {
            setType({ item: item?.itemId, status: true });
            return {
              ...item,
              type: "total",
            };
          } else return item;
        })
      );
    },
  });

  return (
    <div
      className={`sm:w-9/12 w-11/12 flex ${
        product?.quantity ? "bg-[#F8D795]" : "bg-[#DEDEDE]"
      } rounded-xl shadow-lg justify-start items-center`}
    >
      <div className="w-full ">
        {showElements && (
          <div className=" flex w-full sm:flex-row flex-col gap-1.5 justify-center px-5 py-1   sm:p-1 ">
            <div className=" flex  flex-row justify-between ">
              <div className=" flex  flex-col  gap-1  items-center justify-center ">
                <label
                  htmlFor="thresholdQuantity"
                  className="text-xs font-medium "
                >
                  Threshold
                </label>
                <input
                  className={`  ${
                    product?.quantity ? "bg-[#fefef]" : "bg-[#ffffff]"
                  }  bg-gray-200 rounded-md outline-none p-0.5 px-1  w-24 md:w-10 lg:w-24 font-medium text-sm`}
                  defaultValue={initialValue?.qtyThreshold}
                  type="number"
                  placeholder=""
                  id="thresholdQuantity"
                  onChange={(event) => {
                    setDiscounts(
                      discounts?.map((item) => {
                        if (item?.itemId === product?.id) {
                          return {
                            ...item,
                            qtyThreshold: event.target.value,
                          };
                        } else return item;
                      })
                    );
                  }}
                />
              </div>
              <div className="px-1 flex flex-col gap-1 items-center justify-center">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="discountAmount"
                    className={`text-xs rounded-md px-1 py-0.5 ${
                      initialValue?.percent ? "bg-[#1A83E5]  text-white" : ""
                    } font-medium -pb-2 flex items-center h-full`}
                  >
                    percent
                  </label>
                  <label
                    htmlFor="discountAmount"
                    className={`text-xs rounded-md px-1 py-0.5 ${
                      !initialValue?.percent ? "bg-[#1A83E5]  text-white " : ""
                    } font-medium -pb-2`}
                  >
                    amount
                  </label>
                </div>
                <div className="w-full flex justify-center items-start text-lg">
                  {Percentage}
                </div>
              </div>
            </div>
            <div className=" flex flex-row justify-between">
              <div className="px-1 flex flex-col gap-1 items-center justify-center">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="discountAmount"
                    className={`text-xs rounded-md px-1 py-0.5 ${
                      type?.item === product?.id && type?.status
                        ? "bg-[#1A83E5]  text-white"
                        : ""
                    } font-medium -pb-2 flex items-center h-full`}
                  >
                    total
                  </label>
                  <label
                    htmlFor="discountAmount"
                    className={`text-xs rounded-md px-1 py-0.5 ${
                      type?.item === product?.id && !type?.status
                        ? "bg-[#1A83E5]  text-white "
                        : ""
                    } font-medium -pb-2`}
                  >
                    each
                  </label>
                </div>
                <div className="w-full flex justify-center items-start text-lg">
                  {Total}
                </div>
              </div>
              <div className=" flex  flex-col w-full gap-1 items-center justify-center ">
                <label
                  htmlFor="discountAmount"
                  className="text-xs font-medium "
                >
                  Discount
                </label>
                <input
                  className={`  ${
                    product?.quantity ? "bg-[#efefef]" : "bg-[#ffffff]"
                  }  w-24 md:w-10 lg:w-24 bg-gray-200 rounded-md  outline-none p-0.5 px-1 mr-1 font-medium text-sm `}
                  defaultValue={initialValue?.discount}
                  onChange={(event) => {
                    setDiscounts(
                      discounts?.map((item) => {
                        if (item?.itemId === product?.id) {
                          return {
                            ...item,
                            discount: event.target.value,
                          };
                        } else return item;
                      })
                    );
                  }}
                  type="number"
                  id="discountAmount"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

LinkGeneratorComponent.propTypes = {
  showElements: PropTypes.bool,
  product: PropTypes.object,
  discounts: PropTypes.array,
  setDiscounts: PropTypes.func,
};
export default LinkGeneratorComponent;
