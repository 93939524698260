import React from "react";
import { Link, useParams } from "react-router-dom";
import { getItemFromSession } from "../../shoppingsite/utils";

const ErrorPage = () => {
  const params = useParams();
  const { page, status, pending } = params;
  // console.log("Params", params);

  const leadId = getItemFromSession("leadId");
  const pageLink =
    page === "store" && pending === "p"
      ? "/app/store/pendingOrders"
      : page === "store" && (pending === "n" || "" || !pending)
      ? "/app/store"
      : page === "order" && pending === "p"
      ? "/app/uzeli/pendingorders"
      : page === "order" && (pending === "n" || "" || !pending)
      ? `/app/order?id=${leadId}`
      : null;

  const buttonName =
    pending === "p"
      ? "Orders Status"
      : page === "store"
      ? "Store"
      : page === "order"
      ? "Orders"
      : null;
  console.log({ pageLink, buttonName });
  return (
    <div
      className="h-screen flex w-full flex-col align-middle justify-center items-center"
      style={{ backgroundColor: "#051527" }}
    >
      {/* <div className="font-extrabold text-8xl text-slate-50 mb-9">401</div> */}
      {status === "alreadySigned" ? (
        <div>
          <div className="text-slate-400 mb-3 w-full flex justify-center sm:text-3xl text-xl">
            Document is already signed!
          </div>
          <div className="mb-5 flex justify-center">
            <hr className="w-80 text-slate-400 " />
          </div>
        </div>
      ) : status === "cancelled" ? (
        <div>
          <div className="">
            <div className="text-slate-400 sm:text-3xl text-xl ">
              Order is cancelled
            </div>
            <div className="text-slate-400 mb-3 text-l flex justify-center">
              Please place your order again
            </div>
            <div className="mb-5 flex justify-center">
              <hr className="w-20 text-slate-400" />
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="text-slate-400 sm:text-3xl text-xl">
            {" "}
            Error Occurred{" "}
          </div>

          {pending === "e" ? (
            <div className="text-slate-400 mb-3 text-l flex justify-center">
              Please contact admin
            </div>
          ) : (
            <div className="text-slate-400 mb-3 text-l flex justify-center">
              Please try again Later
            </div>
          )}
          <div className="mb-5 flex justify-center">
            <hr className="w-20 text-slate-400" />
          </div>
        </div>
      )}

      {pageLink && buttonName ? (
        <div className="flex w-full mt-10 font-semibold hover:underline  text-xs sm:text-base justify-center">
          <Link to={pageLink} className="text-blue-600 ml-2">
            <button className=" bg-[#4D1F70]  text-white rounded-md p-2 shadow-xl">
              Go to <span className="capitalize">{buttonName}</span>
            </button>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default ErrorPage;
