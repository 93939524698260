/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import getImageByteArray from "../hooks/getImage";
import { getImageArray, toBase64 } from "../utils";
import ShoppingSiteContext from "../context";
import placeHolder from "../../../assets/images/placeholder.png";
import ImageWithAuth from "./ImageWithAuth";

export function ImageComponent({ item }) {
  const [image, setImage] = useState(null);
  const { inventory } = useContext(ShoppingSiteContext);

  const findImage = async () => {
    const imageIdArray = getImageArray(
      inventory?.find((v) => v?.providerAssignedId === item?.providerAssignedId)
        ?.imageId
    );
    const imageId = imageIdArray.length ? imageIdArray[0] : false;
    if (imageId) {
      setImage(imageId);
    }
  };
  useEffect(() => {
    findImage();
  }, []);
  return (
    <>
      {/* {image ? (
        <ImageWithAuth url={`/browser/image/${image}`} />
      ) : ( */}
      <img
        width={80}
        height={25}
        src={image ? `/browser/image/${image}` : placeHolder}
        alt={item?.name}
      />
      {/* )} */}
    </>
  );
}
ImageComponent.propTypes = {
  item: PropTypes.object,
};
