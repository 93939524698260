import { Menu, Transition } from "@headlessui/react";
import React, { useState } from "react";

import PropTypes from "prop-types";

// import { getItemFromSession } from "../../shoppingsite/utils";
// import useDeviceCounter from "../hooks/useDeviceCounter";

const CustomMenu = ({ menuIcon, menuContent, gotoCheckout, className }) => {
  // console.log({ className });
  // const leadId = getItemFromSession("leadId");
  // const { currentPage } = useDeviceCounter(leadId);
  const [clicked, setClicked] = useState(false);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  // console.log("here in custom menu");

  let validData = false;
  if (currentUser?.id) {
    const values = JSON.parse(
      sessionStorage.getItem(`values-${currentUser && currentUser?.id}`)
    );
    const allPageVisited = JSON.parse(sessionStorage.getItem("checkout"));
    const openValues = values?.openValues;
    // console.log({ openValues });
    if (
      openValues &&
      (openValues["Kiozzk"] || openValues["Computer"]) > 0 &&
      allPageVisited
    ) {
      validData = true;
    }
  }

  let showCheckout = false;
  if (gotoCheckout) {
    showCheckout = true;
  }

  // console.log({ validData, showCheckout });
  // console.log({ validData });
  return (
    <Menu as="div" className="relative inline-block t">
      <div className=" loggedIn flex items-center">
        <Menu.Button
          onClick={() => setClicked(!clicked)}
          className="inline-flex w-full   justify-center rounded-md  font-extrabold  "
        >
          {menuIcon}
        </Menu.Button>
      </div>
      <Transition
        as="div"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="w-full"
      >
        <Menu.Items
          className={` z-[400] absolute ${
            className ? className : " -right-3 sm:-right-12 md:right-20"
          }  mt-2 w-32 origin-top-right divide-y divide-gray-100  dark:bg-darkPrimaryBackground bg-lightPrimaryBackground shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <Menu.Item as="div">{menuContent}</Menu.Item>
          {validData && showCheckout && (
            <Menu.Item as="div">
              <div className=" bg-white  w-56 sm:w-[18rem] text-sm  sm:text-base md:text-lg  -mt-1 md:w-[22rem] pb-3  flex justify-center rounded-b-2xl">
                <button
                  data-analytics-name="CheckOut Element"
                  data-analytics-eventtype="Clicked"
                  className="rounded-lg shadow-xl px-2 py-1 border-[1px] border-black"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#ffffff",
                    color: "black",
                  }}
                  onClick={() => {
                    if (gotoCheckout) {
                      gotoCheckout();
                    }
                  }}
                >
                  Go to checkout
                </button>
              </div>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
CustomMenu.propTypes = {
  menuIcon: PropTypes.element,
  menuContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  className: PropTypes.string,
  gotoCheckout: PropTypes.func,
};
export default CustomMenu;
