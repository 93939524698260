import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getEnvironmentValue } from "../../../utils/getEnvironmentValues";
import { saveItemsToSession } from "../../shoppingsite/utils";

const IFrameComponent = ({ listener, messageToIFrame, iFrameSrc }) => {
  // console.log({ listener, messageToIFrame, iFrameSrc });
  const [iFrameLoaded, setiFrameLoaded] = useState(false);
  const [messageQueue, setMessageQueue] = useState([]);
  const iFrameRef = React.useRef(null);

  const sendMessage = (message, src) => {
    if (!iFrameRef.current) return;
    // console.log("Message sent to Iframe", message);
    iFrameRef.current.contentWindow.postMessage(message, src);
  };

  // console.log({ window: window.GZAnalytics });
  const handleEvents = (e) => {
    const message = e?.data;
    if (message?.from !== "uzeli-dashboard") return;
    // console.log("Event from IFrame ", e);
    if (message?.status === "loggedIn") {
      if (listener.loginResponse) {
        const adminBusinesses =
          message?.data?.admin?.businesses || message?.data?.business;
        listener.loginResponse(message?.data?.user, adminBusinesses);
        if (message?.data?.user?.id) {
          window.GZAnalytics &&
            window.GZAnalytics.setUser(message?.data?.user?.id);
          window.GZAnalytics &&
            window.GZAnalytics.customLog({
              name: "UserLog",
              eventtype: "loggedIn",
              payload: {
                userId: message?.data?.user?.id,
                userName: message?.data?.user?.username,
                fullName: message?.data?.user?.fullName,
                userEmail: message?.data?.user?.email,
                userRole: message?.data?.user?.role,
                userStatus: message?.data?.user?.status,
                isOwner: message?.data?.user?.isOwner,
              },
            });
          saveItemsToSession("loggedIn", message?.data?.user);
        }
      }
    } else if (message?.status === "paymentSelected") {
      if (listener.paymentSelectedResponse) {
        listener.paymentSelectedResponse(message?.data?.action, message?.data);
        window.GZAnalytics &&
          window.GZAnalytics.customLog({
            name: "Payment Method",
            eventtype: "selected",
            payload: {
              paymentData: message?.data,
            },
          });
      }
    } else if (message?.type === "loaded") {
      setiFrameLoaded(true);
      if (listener.loadedResponse) {
        listener.loadedResponse(message?.data?.status);
      }
    } else if (
      message?.type === "invoiceResponse" &&
      message?.data?.success === true
    ) {
      if (listener.invoiceResponse) {
        listener.invoiceResponse(message?.data?.success, message?.data?.data);
        window.GZAnalytics &&
          window.GZAnalytics.customLog({
            name: "Invoice",
            eventtype: "generated",
            payload: {
              success: message?.data?.success,
            },
          });
      }
    } else if (
      message?.type === "invoiceResponse" &&
      (message?.data?.status === false || message?.data?.success === false)
    ) {
      if (listener.invoiceResponse) {
        console.log({ InvoiceResponseErrorLogged: message?.data });
        listener.invoiceResponse(
          false,
          message?.data?.message || message?.data?.error
        );
      }
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Invoice",
          eventtype: "generated",
          payload: {
            success: message?.data?.success || message?.data?.status,
          },
        });
    }
  };
  const processQueue = () => {
    // console.log("Message Queue", messageQueue);
    if (!messageQueue.length) return;
    const firstItem = messageQueue.pop();
    setMessageQueue([...messageQueue]);
    if (firstItem) {
      sendMessage(messageToIFrame.postMessage, messageToIFrame.src);
      processQueue();
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleEvents);
    return () => {
      window.removeEventListener("message", handleEvents);
    };
  }, []);

  useEffect(() => {
    if (messageToIFrame?.send) {
      // console.log("Received Message", messageToIFrame);
      if (!iFrameLoaded) {
        console.log("IFrame is not loaded , message in queue");
        const temp = messageQueue;
        temp.push(messageToIFrame);
        setMessageQueue([...temp]);
        return;
      }
      sendMessage(messageToIFrame.postMessage, messageToIFrame.src);
    }
  }, [messageToIFrame]);

  useEffect(() => {
    if (iFrameLoaded) {
      // console.log("Queue processed");
      processQueue();
    }
  }, [iFrameLoaded]);

  const displayIframeStyle = {
    // backgroundColor: "#AA6FD8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };

  const sendMessageToIFrameOnLoad = () => {
    sendMessage(
      {
        from: "orders-growthzilla",
        embedMode: true,
      },
      getEnvironmentValue().ORIGIN
    );
  };

  return (
    <div className="  flex h-full w-full  justify-center">
      <div
        className=" w-full  flex justify-center  scrollbar-hide"
        style={{ displayIframeStyle }}
      >
        <iframe
          className=" h-[93vh] md:w-[70vw] sm:w-[50vw] w-[95vw]  flex justify-center"
          id="dashboard-iframe"
          onLoad={() => {
            sendMessageToIFrameOnLoad();
          }}
          ref={iFrameRef}
          src={iFrameSrc}
        ></iframe>
      </div>
    </div>
  );
};
IFrameComponent.propTypes = {
  listener: PropTypes.object,
  messageToIFrame: PropTypes.object,
  iFrameSrc: PropTypes.string,
};
export default React.memo(IFrameComponent);
