import React, { useContext, useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import Kiozzk from "../../../assets/images/kiozzk1.png";
import Computer from "../../../assets/images/mac-uzeli-left1.png";
import SwitchContext from "../context";
import { Alert } from "@mui/material";
import { FooterButton } from "../Document";
import { ChevronLeft, ChevronRight } from "react-feather";
import { toast } from "react-toastify";
import Card from "../components/Card";
const SecondPage = () => {
  const {
    openValues,
    toggleSwitch,
    disable,
    isOutOfStock,
    isUpperLimitReached,
    incrementPage,
    decrementPage,
  } = useContext(SwitchContext);
  const computer = openValues.Computer;
  const kiozzk = openValues.Kiozzk;
  const [computerWarning, setComputerWarning] = useState("");
  const [kiozzkWarning, setKiozzkWarning] = useState("");

  useEffect(() => {
    if (computer === 1) {
      disable(["Kiozzk"]);
    }
  }, [computer]);

  useEffect(() => {
    if (kiozzk === 1) {
      disable(["Computer"]);
    }
  }, [kiozzk]);
  return (
    <div>
      <Card>
        <div className="flex justify-end"></div>
        <div>
          <div className="w-full lg:mr-auto">
            <p className="text-xl md:text-3xl capitalize mb-4 font-medium">
              2. Cashier Device
            </p>
            <div className="pl-0 md:pl-8 text-paragraph">
              <p className="mb-4 leading-relaxed">
                {`
               A cashier device is required to use Uzeli. You can bring your
               own computer (Windows or Mac) to run Uzeli's POS application
               (cashier device) or you can use Growthzilla's Kiozzk Tablet.
               This is the main device where staff will manage customer
               wait-list, view client notes, manage appointments and ring
               service / product items.
              `}
              </p>
            </div>
          </div>
          <div className="block md:flex md:space-x-2 > * + *">
            <div className="text-center">
              <img
                src={Computer}
                alt=""
                className="mx-auto mb-4"
                style={{ height: "200px" }}
              />
              <div className="flex flex-row justify-center  w-full items-center my-2 ">
                <h5 className="text-xl whitespace-nowrap  w-full justify-end ">
                  Bring your Desktop
                </h5>
                <div className="w-[55%] flex justify-center">
                  <ReactSwitch
                    onChange={() => {
                      if (isOutOfStock("Computer")) {
                        setComputerWarning("Currently out of stock");
                        setTimeout(() => {
                          setComputerWarning("");
                        }, 2000);
                        return;
                      } else if (isUpperLimitReached("Computer")) {
                        setComputerWarning("Maximum quantity reached");
                        setTimeout(() => {
                          setComputerWarning("");
                        }, 2000);
                        return;
                      }
                      toggleSwitch("Computer");
                      window.GZAnalytics &&
                        window.GZAnalytics.customLog({
                          name: "Cart Items",
                          eventtype: "changed",
                          payload: {
                            computer: !openValues.Computer,
                          },
                        });
                    }}
                    checked={computer === 1}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <p className="text-paragraph">
                Desktop computers can provide larger screen real-estate and
                speed but occupy counter space, require higher maintenance and
                are more expensive. Desktops are recommended for business
                locations that take appointments and have more than 6-8 staff.
                Uzeli performs better on Macs than Windows and should be
                considered by busy locations.
              </p>

              {
                <div className="flex">
                  <div className="w-[45%] flex justify-start items-start ml-4">
                    {computerWarning && (
                      <div className=" h-[0.09rem]">
                        <Alert
                          variant="filled"
                          severity="warning"
                          className="p-1"
                        >
                          {computerWarning}
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>

            <div className="text-center">
              <img
                src={Kiozzk}
                alt=""
                className="mx-auto mb-4"
                style={{ height: "200px" }}
              />
              <div className="flex flex-row justify-center  w-full items-center my-2 ">
                <h5 className="text-xl w-full justify-end">Buy a Kiozzk</h5>
                <div className="w-[55%] flex justify-center items-center ">
                  <ReactSwitch
                    onChange={() => {
                      if (isOutOfStock("Kiozzk")) {
                        setKiozzkWarning("Currently out of stock");
                        setTimeout(() => {
                          setKiozzkWarning("");
                        }, 2000);
                        return;
                      } else if (isUpperLimitReached("Kiozzk")) {
                        setKiozzkWarning("Maximum quantity reached");
                        setTimeout(() => {
                          setKiozzkWarning("");
                        }, 2000);
                        return;
                      }
                      toggleSwitch("Kiozzk");
                      window.GZAnalytics &&
                        window.GZAnalytics.customLog({
                          name: "Cart Items",
                          eventtype: "changed",
                          payload: {
                            Kiozzk: !openValues.kiozzk,
                          },
                        });
                    }}
                    checked={kiozzk === 1}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <p className="text-paragraph">
                Kiozzk tablets are easier to use, thanks to their
                touch-interface. Requiring small counter space, lower
                maintenance and a cheaper price are additional benefits.
                Recommended for business locations that have less than 8 staff.
                If your business location does not take appointments, Kiozzk is
                a better option even if you have more than 8 staff.
              </p>
              {
                <div className="flex">
                  <div className="w-[45%] flex justify-start items-start ml-4">
                    {kiozzkWarning && (
                      <div className=" h-[0.09rem]">
                        <Alert
                          variant="filled"
                          severity="warning"
                          className="p-1"
                        >
                          {kiozzkWarning}
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Card>
      <div className="flex items-end justify-end py-4 gap-4">
        <FooterButton
          className="flex text-white bg-[#4D1F70] hover:text-yellow-500"
          label={"Previous"}
          type="button"
          onClick={decrementPage}
        >
          <ChevronLeft />
          Previous
        </FooterButton>
        <FooterButton
          type="submit"
          className="flex text-white bg-[#4D1F70] hover:text-yellow-500"
          label={"Next"}
          onClick={() => {
            if (computer === 0 && kiozzk === 0) {
              toast.error("Please select your cashier device to continue");
              return;
            }
            incrementPage();
          }}
        >
          Next
          <ChevronRight />
        </FooterButton>
      </div>
    </div>
  );
};

export default SecondPage;
