import React from "react";
import PropTypes from "prop-types";
const Discounts = ({
  equipDiscount,
  qualifyingEquipmentPrice,
  planPermDiscount,
  planTempDiscount,
  tempDiscountExpiryDate,
  qualifyingPlanPrice,
}) => {
  // console.log({
  //   equipDiscount,
  //   qualifyingEquipmentPrice,
  //   planPermDiscount,
  //   planTempDiscount,
  //   tempDiscountExpiryDate,
  //   qualifyingPlanPrice,
  // });
  const isTempDiscountExpired = new Date() > new Date(tempDiscountExpiryDate);
  const effectiveTempDiscount = isTempDiscountExpired ? 0 : planTempDiscount;
  // const noDiscounts =
  //   (!planPermDiscount || planPermDiscount === 0) &&
  //   (!planPermDiscount || effectiveTempDiscount === 0) &&
  //   (!equipDiscount || equipDiscount === 0);

  // const multipleDiscounts =
  //   (planPermDiscount > 0 && effectiveTempDiscount > 0) ||
  //   (planPermDiscount > 0 && equipDiscount > 0) ||
  //   (effectiveTempDiscount > 0 && equipDiscount > 0);

  // const allDiscounts =
  //   planPermDiscount > 0 && effectiveTempDiscount > 0 && equipDiscount > 0;
  const hasMultipleDiscounts =
    (planPermDiscount > 0 && effectiveTempDiscount > 0) ||
    (planPermDiscount > 0 && equipDiscount > 0) ||
    (effectiveTempDiscount > 0 && equipDiscount > 0);

  const hasMultiplePlanDiscount =
    planPermDiscount > 0 && effectiveTempDiscount > 0;

  const hasPlanDiscount = planPermDiscount > 0 || effectiveTempDiscount > 0;

  const hasEquipmentDiscount =
    equipDiscount > 0 && qualifyingEquipmentPrice > 0;

  const message =
    "Your Growthzilla sales partner has offered you the following " +
    (hasMultipleDiscounts ? "discounts:" : "discount:");

  return !(hasPlanDiscount || hasEquipmentDiscount) ? (
    <></>
  ) : (
    <div
      className="text-left bg-yellow-100 border-l-4 border-r-4 border-yellow-500 text-yellow-700 px-4  gap-1"
      role="alert"
    >
      <p className="font-bold">{message}</p>
      <ul>
        {hasEquipmentDiscount && (
          <li>
            <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
            ${equipDiscount} off on setup cost, when your setup cost exceeds $
            {qualifyingEquipmentPrice}
          </li>
        )}

        {qualifyingPlanPrice > 0 && hasPlanDiscount && (
          <li>
            <span className="bg-yellow-700 rounded-full h-2 w-2 inline-block mr-2"></span>
            Additionally, you will get following{" "}
            {hasMultiplePlanDiscount ? "discounts" : "discount"} if monthly plan
            fee exceeds ${qualifyingPlanPrice}
            {effectiveTempDiscount > 0 && (
              <li className="ml-2">
                <span className="bg-yellow-700 rounded-full h-[6px] w-[6px] inline-block mr-2"></span>
                ${planTempDiscount} off on monthly fee until{" "}
                {tempDiscountExpiryDate}
              </li>
            )}
            {planPermDiscount > 0 && (
              <li className="ml-2">
                <span className="bg-yellow-700 rounded-full h-[6px] w-[6px] inline-block mr-2"></span>
                ${planPermDiscount} off on monthly fee for the agreement term
              </li>
            )}{" "}
          </li>
        )}
      </ul>
    </div>
  );
};

Discounts.propTypes = {
  planPermDiscount: PropTypes.number,
  planTempDiscount: PropTypes.number,
  equipDiscount: PropTypes.number,
  qualifyingEquipmentPrice: PropTypes.number,
  tempDiscountExpiryDate: PropTypes.string,
  qualifyingPlanPrice: PropTypes.number,
};

export default Discounts;
