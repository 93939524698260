/* eslint-disable no-unused-vars */
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../components/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import CheckBox from "../../../components/Checkbox";
import {
  generateBillingNote,
  requestPdf,
} from "../../../utils/agreementDocument";
import { US_STATES } from "../../../utils/USStates";
import { FooterButton } from "../Document";
import { CONCAT_KEY, CURRENT_SOFTWARES, LOCATION_TYPES } from "../const/index";
import SwitchContext from "../context";
import useUpdateItem from "../hooks/useUpdateItem";
import Card from "./Card";
import { Alert } from "@mui/material";
import { itemFromSession } from "../../shoppingsite/utils";
import { saveLeadActivity } from "../../../hooks/updateLeadActivity";
import("flatpickr/dist/themes/dark.css");
import("react-phone-input-2/lib/style.css");
export const H5 = styled.h5`
  --tw-text-opacity: 1;
  color: rgba(73, 80, 87, var(--tw-text-opacity));
`;
export const Span = styled.span`
  --tw-text-opacity: 1;
  color: rgba(73, 80, 87, var(--tw-text-opacity));
  font-style: italic;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px 15px;
  font-size: 14px;
`;
export const Select = styled.select`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px 15px;
  font-size: 14px;
`;

export const P = styled.p`
  color: red;
  font-size: 14px;
`;

export const Div = styled.div`
  margin-bottom: 0.7rem;
`;

let initialValues = {
  businessName: "",
  businessAddress: "",
  businessPhoneNumber: "",
  businessEmail: "",
  businessUnitOrSuite: "",
  businessCity: "",
  businessState: "",
  businessZip: "",
  businessWebsite: "",
  contactEmail: "",
  contactName: "",
  contactNumber: "",
  shippingAddress1: "",
  shippingAddress2: "",
  shippingAddressCity: "",
  shippingAddressState: "",
  shippingAddressZip: "",
  startDate: null,
  location: null,
  currentSoftware: null,
  otherSoftware: "",
};

// eslint-disable-next-line no-unused-vars
const CustomForm = ({ showContact, itemId }) => {
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentSoftware, setCurrentSoftware] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [otherSoftware, setOtherSoftware] = useState("");
  const [customError, setCustomError] = useState(null);
  const [taxRates, setTaxRates] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);

  const [paidAmount, setPaidAmount] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(null);
  const [shippingState, setShippingState] = useState(null);
  // console.log(showContact, currentSoftware, otherSoftware);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    if (currentUser?.id) {
      const user = {
        id: currentUser?.id,
        email: currentUser?.email,
        name: currentUser?.fullname,
      };
      setLoggedIn(user);
    }
    setLoading(false);
  }, []);

  const {
    user,
    monthlyFee,
    planPermDiscount,
    planTempDiscount,
    tempDiscountExpiryDate,
    planType,
    setUpFee,
    equipDiscount,
    discountOnSetupFee,
    discountOnMonthlyFee,
    formattedItem,
    equipment,
    uzeliPlan,
    totalSmartCheckout,
    contact,
    monthlySMSQuota,
    growthzillaSignee,
    setShowFrame,
    decrementPage,
    setLoading,
    showPaymentWindow,
    installments,
    minimumForInstallments,
    setNextButtonClicked,
  } = useContext(SwitchContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: "all",
    defaultValues: initialValues,
    shouldFocusError: true,
  });

  const businessState = watch("businessState");
  const shippingAddressState = watch("shippingAddressState");
  // console.log({ businessState });

  const { paymentLoading, updateItem } = useUpdateItem({
    onSuccess: async (resJson, data) => {
      // console.log("From Success Function");
      // console.log({ resJson });
      const orderId = resJson?.oid;
      const infinityItemId = resJson?.data?.id;
      const id = orderId + CONCAT_KEY + infinityItemId;
      // console.log({ id, infinityItemId });
      localStorage.setItem("id", id);
      let transactionResponse;
      // console.log({ resJson, data });
      // console.log({
      //   minimumForInstallments,
      //   installments,
      //   businessInfo: data,
      //   taxRates,
      //   businessState,
      //   equipment,
      //   uzeliPlan,
      //   setUpFee,
      //   equipDiscount,
      //   discountOnSetupFee,
      //   discountOnMonthlyFee,
      //   monthlyFee,
      //   planPermDiscount,
      //   planTempDiscount,
      //   tempDiscountExpiryDate,
      //   growthzillaSignee: growthzillaSignee,
      //   monthlySMSQuota: monthlySMSQuota,
      //   uzeliOrderId: orderId,
      // });

      if (orderId) {
        transactionResponse = await requestPdf({
          minimumForInstallments,
          installments,
          businessInfo: data,
          taxRates,
          businessState,
          equipment,
          uzeliPlan,
          setUpFee,
          equipDiscount,
          discountOnSetupFee,
          discountOnMonthlyFee,
          monthlyFee,
          planPermDiscount,
          planTempDiscount,
          tempDiscountExpiryDate,
          growthzillaSignee:
            growthzillaSignee !== "" && growthzillaSignee
              ? growthzillaSignee
              : "Shishir Bashyal",
          monthlySMSQuota: monthlySMSQuota,
          uzeliOrderId: orderId,
        });
      }
      // console.log({ transactionResponse });
      if (!transactionResponse?.success) {
        toast.error("Error while preparing agreement document", {
          toastId: "agreement-document-error",
        });
        window.GZAnalytics &&
          window.GZAnalytics.customLog({
            name: "Agreement Document",
            eventtype: "generationError",
          });
        navigate("/app/unauthorized");
        return;
      }
      localStorage.setItem("transaction_ref", transactionResponse?.data);
      localStorage.setItem("orderId", orderId);
      setLoading(false);
      setShowFrame(true);
    },
    onFailure: () => {
      toast.error("An error occured ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(`/app/formFailed`);
      window.GZAnalytics &&
        window.GZAnalytics.customLog({
          name: "Agreement Document",
          eventtype: "generationError",
        });
    },
  });

  const getStateAbbreviation = (state) => {
    return state?.length === 2 ? state : US_STATES[state];
  };

  const getTaxRateByState = (state) => {
    return (
      taxRates?.find((item) => item?.state === getStateAbbreviation(state))
        ?.taxRate || 0
    );
  };

  const formDisabled = !!paymentLoading;

  // useEffect(() => {
  //   console.log({ businessState });
  //   const taxRate =
  //     taxRates?.find(
  //       (item) => item?.state === getStateAbbreviation(businessState)
  //     )?.taxRate || 0;
  //   // setTax(taxRate);
  // }, [businessState]);

  const calculatePrices = (state, setUpFee) => {
    const validState = taxRates?.find(
      (item) => item?.state === getStateAbbreviation(state)
    );
    if (state && Object.keys(validState ?? {}).length) {
      const taxRate = validState?.taxRate;
      let afterDiscountPrice = setUpFee;
      if (equipDiscount > 0 && discountOnSetupFee) {
        afterDiscountPrice = setUpFee - equipDiscount;
      }
      const taxAmount = (afterDiscountPrice * taxRate) / 100;
      const totalAmount = afterDiscountPrice + taxAmount;
      // console.log({
      //   equipment,
      //   setUpFee,
      //   equipDiscount,
      //   openValues,
      //   equipmentPrice,
      //   totalAmount,
      // });
      const isElegibleForInstallments =
        setUpFee > (minimumForInstallments ?? 0) && installments > 0
          ? true
          : false;
      const firstInstallment = isElegibleForInstallments
        ? totalAmount / installments
        : totalAmount;

      // console.log({ totalAmount, firstInstallment, installments });
      return firstInstallment.toFixed(2);
    }
  };

  useEffect(() => {
    if (state) {
      const totalPaymentAmount = calculatePrices(state, setUpFee);
      setPaidAmount(totalPaymentAmount);
    } else {
      setPaidAmount(null);
    }
  }, [state, setUpFee, installments]);

  useEffect(() => {
    const fetchTaxRates = async () => {
      const taxResponse = await axios.get("/browser/taxrates", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      sessionStorage.setItem("taxRates", JSON.stringify(taxResponse?.data));
      setTaxRates(taxResponse?.data);
    };
    const sessionTaxRates = JSON.parse(sessionStorage.getItem("taxRates"));
    if (!sessionTaxRates) {
      fetchTaxRates();
    } else {
      setTaxRates(sessionTaxRates);
    }
  }, []);

  useEffect(() => {
    if (location + 1 && customError?.locationError) {
      setCustomError({ ...customError, locationError: null });
    }
  }, [location]);

  // console.log(
  //   generateBillingNote(
  //     tax,
  //     tempDiscountExpiryDate,
  //     equipDiscount,
  //     discountOnSetupFee,
  //     setUpFee,
  //     planTempDiscount,
  //     monthlyFee,
  //     planPermDiscount,
  //     discountOnMonthlyFee
  //   )
  // );

  // const totalAmount = calculatePrices(businessState);
  // const totalAmount = calculatePrices(businessState);

  useEffect(() => {
    const formState = sessionStorage.getItem("formState_" + user);
    if (formState) {
      initialValues = JSON.parse(formState);
      setState(initialValues?.businessState?.toLocaleUpperCase());
      setShippingAddress(
        initialValues?.shippingAddress2?.toLocaleUpperCase() ? true : false
      );
      reset(initialValues);
    } else {
      reset({
        ...initialValues,
        contactEmail: contact?.email ? contact?.email : "",
        contactName: contact?.personName ? contact.personName : "",
        contactNumber: contact?.phoneNumber
          ? contact?.phoneNumber.slice(1)
          : "",
      });
    }
  }, []);

  const saveFormState = async () => {
    const formState = getValues();
    sessionStorage.setItem("formState_" + user, JSON.stringify(formState));
    await saveLeadActivity("Continue to setup payment method clicked");
  };

  const onSubmit = async (data) => {
    const formattedItems = formattedItem().filter((e) => e.quantity);
    const leadId = itemFromSession("leadIdFromInfo");
    // console.log("Called from ON Submit");
    if (itemId) {
      if (formattedItems.length > 0) {
        if (getValues("location") === null) {
          setCustomError({
            locationError: "Please select a location.",
          });
          return;
        }
        if (!shippingAddress) {
          data.shippingAddress1 = "";
          data.shippingAddress2 = "";
          data.cityStateZip = "";
        }
        showPaymentWindow();
        data.businessState = data?.businessState?.toLocaleUpperCase();
        data.shippingAddressState =
          data?.shippingAddressState?.toLocaleUpperCase();
        // console.log({ leadId, data });
        await updateItem({
          data,
          monthlyFee,
          planType,
          setUpFee,
          formattedItems,
          user,
          itemId,
          equipment,
          uzeliPlan,
          totalSmartCheckout,
          equipDiscount: equipDiscount * Number(discountOnSetupFee),
          billingNote: generateBillingNote(
            minimumForInstallments ?? 0,
            installments ?? 0,
            taxRates,
            businessState,
            tempDiscountExpiryDate,
            equipDiscount,
            discountOnSetupFee,
            setUpFee,
            planTempDiscount,
            monthlyFee,
            planPermDiscount,
            discountOnMonthlyFee
          ),
          installments,
          minimumForInstallments,
          loggedIn,
          leadId,
        });
      } else {
        toast.error("Please select items to submit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const [shippingAddress, setShippingAddress] = useState(
    sessionStorage.getItem("shippingAddress") === "true" ? true : false
  );
  const showShippingAddress = () => {
    sessionStorage.setItem("shippingAddress", "true");
    setShippingAddress(true);
  };

  const hideShippingAddress = () => {
    sessionStorage.setItem("shippingAddress", "false");
    setShippingAddress(false);
  };

  const errorFieldRef = useRef(null);

  useEffect(() => {
    if (errorFieldRef.current) {
      // Scroll to the first error field using scrollIntoView
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [handleSubmit]);

  // console.log({ state });
  // console.log({ businessState });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="p-[2rem]">
          {user && (
            <div>
              <label htmlFor="businessName">
                <H5 className="text-base font-medium capitalize mb-2">
                  Business Legal Name*
                </H5>
              </label>
              <Div>
                <Input
                  defaultValue={initialValues.businessName}
                  placeholder="Beast Salon"
                  id="businessName"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.businessName && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("businessName", {
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.businessName && <P>Business Name is required</P>}
              </Div>
              <Div>
                <label htmlFor="businessAddress">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Address*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.businessAddress}
                  placeholder="555 Beverly Hills"
                  id="businessAddress"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.businessAddress && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("businessAddress", {
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.businessAddress && <P>Address is required</P>}
              </Div>

              <Div>
                <label htmlFor="businessUnitOrSuite">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Unit Or Suite #
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.businessUnitOrSuite}
                  placeholder="Suite 100"
                  disabled={formDisabled}
                  {...register("businessUnitOrSuite", {
                    required: false,
                    maxLength: 80,
                  })}
                />
              </Div>

              <Div>
                <label htmlFor="businessCity">
                  <H5 className="text-base font-medium capitalize mb-2">
                    City*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.businessCity}
                  placeholder="Los Angeles"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.businessCity && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("businessCity", {
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.businessCity && <P>Business City is Required</P>}
              </Div>
              <Div>
                <label htmlFor="businessState">
                  <H5 className="text-base font-medium capitalize mb-2">
                    State*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.businessState}
                  placeholder="CA"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.businessState && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("businessState", {
                    validate:
                      ((value) => {
                        const stateName = value?.toLocaleUpperCase();
                        return (
                          Object.values(US_STATES)?.includes(stateName) ||
                          Object.keys(US_STATES)?.includes(stateName)
                        );
                      }) || "Enter a valid state",
                    maxLength: 20,
                  })}
                  onChange={(event) => {
                    const value = event.target.value;
                    const stateName = value?.toLocaleUpperCase();

                    setState(stateName);
                    // console.log(state);
                    // if (
                    //   Object.values(US_STATES)?.includes(stateValue) ||
                    //   Object.keys(US_STATES)?.includes(stateValue)
                    // ) {
                    //   setState(stateValue);
                    // } else if (!stateValue || stateValue === "") {
                    //   setState(null);
                    // }
                  }}
                />
                {errors.businessState && (
                  <P> Valid Business State is required</P>
                )}
                {!shippingAddress &&
                  getTaxRateByState(businessState?.toLocaleUpperCase()) !==
                    0 && (
                    <Alert
                      variant="filled"
                      severity="warning"
                      className="my-1"
                      style={{
                        backgroundColor: "#eda109",
                      }}
                    >
                      {`You will be subjected to a ${getTaxRateByState(
                        businessState?.toLocaleUpperCase()
                      )}% equipment tax rate.`}
                    </Alert>
                  )}
              </Div>
              <Div>
                <label htmlFor="businessZip">
                  <H5 className="text-base font-medium capitalize mb-2">
                    ZipCode*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.businessZip}
                  placeholder="5555"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.businessZip && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("businessZip", {
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.businessZip && <P>Zip Code is required</P>}
              </Div>

              <Div
                ref={(e) => {
                  if (errors.businessPhoneNumber && !errorFieldRef.current) {
                    errorFieldRef.current = e;
                  }
                }}
              >
                <label htmlFor="businessPhoneNumber">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Business Phone number*
                  </H5>
                </label>
                <Controller
                  name="businessPhoneNumber"
                  control={control}
                  defaultValue={initialValues.businessPhoneNumber}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value, "US"),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      inputProps={{
                        name: "businessPhoneNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      value={value}
                      onChange={onChange}
                      country={"us"}
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      id="businessPhoneNumber"
                      inputStyle={{
                        width: "100%",
                        height: "45px",
                      }}
                    />
                  )}
                />
                {errors.businessPhoneNumber && <P>Invalid phone number</P>}
              </Div>

              <Div>
                <label htmlFor="businessEmail">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Email
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.businessEmail}
                  placeholder="@"
                  type="email"
                  name="businessEmail"
                  disabled={formDisabled}
                  {...register("businessEmail", {
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {errors.businessEmail && (
                  <P> {errors.businessEmail?.message}</P>
                )}
              </Div>
              <Div>
                <label htmlFor="businessWebsite">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Website
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.businessWebsite}
                  placeholder="https://"
                  disabled={formDisabled}
                  {...register("businessWebsite", {
                    required: false,
                    maxLength: 80,
                  })}
                />
              </Div>
            </div>
          )}

          <H5 className="text-base font-medium capitalize mb-2">
            should we ship hardware to above business address or a different
            address?
          </H5>
          <div className="inline-flex mt-2">
            {/* {console.log({ shippingAddress })} */}
            <Button
              active={!shippingAddress || shippingAddress !== "" ? false : true}
              label="Ship To Business Address"
              type="primary"
              handleClick={hideShippingAddress}
            />
            <Button
              active={shippingAddress}
              label="Ship To Different Address"
              type="secondary"
              handleClick={showShippingAddress}
            />
          </div>

          {/* Shipping Address */}

          {shippingAddress && (
            <div className="mt-10">
              <H5 className="capitalize font-bold text-xl mb-4">
                Shipping Address
              </H5>
              <Div>
                <label htmlFor="shippingAddress1">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Address1*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.shippingAddress1}
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.shippingAddress1 && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  placeholder=""
                  {...register("shippingAddress1", {
                    validate: (value) => value.length > 0,
                    maxLength: 80,
                  })}
                />
                {errors.shippingAddress1 && <P>Shipping Address is required</P>}
              </Div>
              <Div>
                <label htmlFor="shippingAddress2">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Address2
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.shippingAddress2}
                  disabled={formDisabled}
                  placeholder=""
                  {...register("shippingAddress2", {
                    required: false,
                    maxLength: 80,
                  })}
                />
              </Div>

              <Div>
                <label htmlFor="shippingAddressCity">
                  <H5 className="text-base font-medium capitalize mb-2">
                    City*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.shippingAddressCity}
                  placeholder="Gotham City"
                  id="shippingAddressCity"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.shippingAddressCity && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("shippingAddressCity", {
                    required: true,
                    maxLength: 80,
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.shippingAddressCity && <P>City is required</P>}
              </Div>
              <Div>
                <label htmlFor="shippingAddressState">
                  <H5 className="text-base font-medium capitalize mb-2">
                    State*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.shippingAddressState}
                  placeholder="CA "
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.shippingAddressState && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("shippingAddressState", {
                    required: true,
                    validate:
                      ((value) => {
                        const stateName = value.toLocaleUpperCase();
                        return (
                          Object.values(US_STATES)?.includes(stateName) ||
                          Object.keys(US_STATES)?.includes(stateName)
                        );
                      }) || "Enter a valid state",
                    maxLength: 20,
                  })}
                  onChange={(event) => {
                    const stateValue = event.target.value;
                    const stateName = stateValue.toLocaleUpperCase();
                    setShippingState(stateName);
                    // if (
                    //   Object.values(US_STATES)?.includes(
                    //     stateValue
                    //   ) ||
                    //   Object.keys(US_STATES)?.includes(stateValue)
                    // ) {
                    //   setState(stateValue);
                    // } else if (!stateValue || stateValue === "") {
                    //   setState(null);
                    // }
                  }}
                />
                {getTaxRateByState(shippingAddressState) !== 0 && (
                  <Alert
                    variant="filled"
                    severity="warning"
                    className="my-1"
                    style={{
                      backgroundColor: "#eda109",
                    }}
                  >
                    {`You will be subjected to a ${getTaxRateByState(
                      shippingAddressState
                    )}% equipment tax rate.`}
                  </Alert>
                )}
                {errors.shippingAddressState && (
                  <P>Valid state name is required</P>
                )}
              </Div>
              <Div>
                <label htmlFor="shippingAddressZipCode">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Zip Code*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues?.shippingAddressZip}
                  placeholder="07093"
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.shippingAddressZip && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("shippingAddressZip", {
                    validate: (value) => value.length > 0,
                    required: true,
                    maxLength: 80,
                  })}
                />
                {errors.shippingAddressZip && <P>Zip code is required</P>}
              </Div>
            </div>
          )}

          {/* Contact Information  */}

          {
            <div className="mt-10">
              <H5 className="capitalize font-bold text-xl mb-4">
                Contact Information*
              </H5>
              <Div>
                <label htmlFor="contactName">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Business Legal Representative Name*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.contactName}
                  disabled={formDisabled}
                  ref={(e) => {
                    if (errors.contactName && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  placeholder=""
                  {...register("contactName", {
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.contactName && (
                  <P>Business Legal Representative Name is required</P>
                )}
              </Div>
              <Div
                ref={(e) => {
                  if (errors.contactNumber && !errorFieldRef.current) {
                    errorFieldRef.current = e;
                  }
                }}
              >
                <label htmlFor="contactNumber">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Representative Phone Number*
                  </H5>
                </label>
                <Controller
                  name="contactNumber"
                  control={control}
                  defaultValue={initialValues.contactNumber}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value, "US"),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      inputProps={{
                        required: true,
                        autoFocus: true,
                      }}
                      value={value}
                      onChange={onChange}
                      country={"us"}
                      countryCodeEditable={false}
                      onlyCountries={["us"]}
                      id="contactNumber"
                      inputStyle={{
                        width: "100%",
                        height: "45px",
                      }}
                      containerClass={{
                        "&.react-tel-input .form-control:focus": {
                          borderColor: "#69e781",
                          boxShadow: "0px 0px 0px 1px #69e781",
                        },
                      }}
                    />
                  )}
                />
                {errors.contactNumber && <P>Invalid phone number</P>}
              </Div>
              <Div className="text-left bg-yellow-100 border-l-4 border-r-4 border-yellow-500 text-yellow-700 p-4  gap-1">
                <label htmlFor="contactDisclaimer">
                  <p className="font-medium  mb-2 text-base">
                    By entering your phone number, you provide consent to
                    receive text messages on behalf of Growthzilla Inc. sent by
                    an Automated Telephone Dialing System (ATDS). These text
                    messages may contain marketing, promotional and
                    informational content, two factor authentication, OTP,
                    message about business and its products and/or services. You
                    acknowledge that receiving text messages may incur carrier
                    charges depending on your telephone service provider. You
                    can unsubscribe from the text messages at any time by either
                    following the instructions included in each text message, or
                    by personally requesting with Growthzilla Inc.
                  </p>
                </label>
              </Div>
              <Div>
                <label htmlFor="contactEmail">
                  <H5 className="text-base font-medium capitalize mb-2">
                    Business Representative Email*
                  </H5>
                </label>
                <Input
                  defaultValue={initialValues.contactEmail}
                  placeholder="@"
                  ref={(e) => {
                    if (errors.contactEmail && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  {...register("contactEmail", {
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                    validate: (value) => value.length > 0,
                  })}
                />
                {errors.contactEmail && (
                  <P> {errors.contactEmail?.message || "Email is required"}</P>
                )}
              </Div>
            </div>
          }
          <H5 className="text-base font-medium mt-4 mb-2">
            How long has this location been in business?*
          </H5>

          <div className="flex flex-col">
            {LOCATION_TYPES.map((e, idx) => (
              <CheckBox
                key={`${e}-location`}
                text={e}
                checked={getValues("location") === idx}
                onChange={(event) => {
                  setLocation(event.target.checked ? idx : null);
                  setValue("location", event.target.checked ? idx : null);
                }}
              />
            ))}
          </div>
          {customError?.locationError && <P>{customError.locationError}</P>}

          <H5 className="text-base font-medium mt-4 mb-2">
            Were you currently using any software?
          </H5>
          <div className="flex flex-row gap-x-20">
            <div className="flex flex-col">
              {CURRENT_SOFTWARES.slice(
                0,
                Math.ceil(CURRENT_SOFTWARES.length / 2)
              ).map((e, idx) => (
                <CheckBox
                  key={`${e}-Software`}
                  text={e}
                  checked={getValues("currentSoftware") === idx}
                  onChange={(event) => {
                    setCurrentSoftware(event.target.checked ? idx : null);
                    setValue(
                      "currentSoftware",
                      event.target.checked ? idx : null
                    );
                  }}
                />
              ))}
            </div>
            <div className="flex flex-col">
              {CURRENT_SOFTWARES.slice(
                Math.ceil(CURRENT_SOFTWARES.length / 2),
                CURRENT_SOFTWARES.length
              ).map((e, idx) => (
                <CheckBox
                  key={e}
                  text={e}
                  checked={
                    getValues("currentSoftware") ===
                    idx + Math.ceil(CURRENT_SOFTWARES.length / 2)
                  }
                  onChange={(event) => {
                    setCurrentSoftware(
                      event.target.checked
                        ? idx + Math.ceil(CURRENT_SOFTWARES.length / 2)
                        : null
                    );
                    setValue(
                      "currentSoftware",
                      event.target.checked
                        ? idx + Math.ceil(CURRENT_SOFTWARES.length / 2)
                        : null
                    );
                  }}
                />
              ))}
              {getValues("currentSoftware") + 1 ===
                CURRENT_SOFTWARES.length && (
                <Input
                  placeholder="Please Specify.."
                  value={getValues("otherSoftware")}
                  onChange={(e) => {
                    setOtherSoftware(e.target.value);
                    setValue("otherSoftware", e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="mt-10">
            <H5 className="font-bold text-xl capitalize mb-4">go live</H5>
            <label htmlFor="startDate">
              <H5 className="text-base font-medium capitalize mb-2">
                what date (if possible) would you like to start using Uzeli?*
              </H5>
            </label>
            <Controller
              name="startDate"
              control={control}
              defaultValue={null}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Flatpickr
                  id="startDate"
                  options={{
                    dateFormat: "Y-m-d",
                    mode: "single",
                    minDate: "today",
                  }}
                  ref={(e) => {
                    if (errors.startDate && !errorFieldRef.current) {
                      errorFieldRef.current = e;
                    }
                  }}
                  disabled={formDisabled}
                  className="border border-gray-300 rounded-md w-full h-12 px-4 mt-2"
                  placeholder="Select a date"
                  onChange={(selectedDates) => field.onChange(selectedDates[0])}
                  value={moment(field.value).format("YYYY-MM-DD")}
                />
              )}
            />
            {errors.startDate && <P>Please select the date</P>}
          </div>
        </Card>

        <div className="flex justify-between py-4">
          <FooterButton
            className="flex text-white  items-center bg-[#4D1F70] hover:text-yellow-500"
            label={"Go Back"}
            type="button"
            onClick={() => {
              setNextButtonClicked(0);
              decrementPage();
              saveFormState();
              window.GZAnalytics &&
                window.GZAnalytics.customLog({
                  name: "Back Button Element",
                  eventtype: "clicked",
                });
            }}
          >
            <ChevronLeft />
            Go Back
          </FooterButton>
          <FooterButton
            type="submit"
            className="flex text-white h-full items-center bg-[#4D1F70] hover:text-yellow-500"
            label={"Continue to setup Payment Method"}
            onClick={saveFormState}
            data-analytics-name="Pay Button Element"
            data-analytics-eventtype="Clicked"
            data-analytics-payload={JSON.stringify({
              from: "ueliOrders",
            })}
          >
            Continue to setup Payment Method{" "}
            {paidAmount > 0 ? (
              <span className=" flex justify-center text-lg   pl-5 font-bold ">
                <PaymentIcon /> ${paidAmount}
              </span>
            ) : (
              <></>
            )}
            <ChevronRight />
          </FooterButton>
        </div>
      </form>
    </div>
  );
};

CustomForm.propTypes = {
  showContact: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default CustomForm;
